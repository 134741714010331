import React, { FC } from 'react';
import './Input.css';

interface InputProps {
  id: string;
  label?: string;
  type?: string;
  placeHolder?: string;
  disabled?: boolean;
  children?: any;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  className?: string;
  defaultValue?: string | number;
  labelClassName?: string;
  readOnly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  dataAttribute: string;
}

export const Input: FC<InputProps> = ({
  id,
  label = '',
  placeHolder = '',
  disabled = false,
  type,
  name,
  onChange = () => {},
  value,
  className,
  defaultValue,
  labelClassName,
  readOnly = false,
  onKeyDown,
  maxLength,
  dataAttribute,
}) => {
  return (
    <label className={`b-form-input ${disabled ? 'b-is-disabled' : ''}`}>
      <span
        className={`b-form-input__label ${labelClassName}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <input
        id={id}
        data-role={dataAttribute}
        className={`b-form-input__control input-${type} ${className}`}
        type={type}
        name={name}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        defaultValue={defaultValue}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
      />
    </label>
  );
};
