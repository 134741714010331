import Modal from '../../../atoms/Modal';
import './seeMore.scss';

interface Props {
  handleClose: () => void;
  data: string;
  onlySpiritCodes?: boolean;
}
function SeeMore({ handleClose, data, onlySpiritCodes=true }: Props) {  
  const stringValue = String(data);
  const spiritCodeArr = stringValue.split(',');

  const groupedArrays = [];
  while (spiritCodeArr.length > 0) {
    groupedArrays.push(spiritCodeArr.splice(0, 7));
  }
  return (
    <Modal titleSize="20px" title="Hotel Name(s)" onClose={handleClose}>
      <div className="Modal_custom_content_extend_offer">
        {groupedArrays.map((group, groupIndex) => (
          <div className={onlySpiritCodes ? 'group-spirit-code' : ''} key={groupIndex}>
            {group.map((item, itemIndex) => (
              <div key={itemIndex}>
                {item?.toUpperCase()}
                {itemIndex !== 6 && ','} &nbsp;
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default SeeMore;
