import { useEffect, useState } from 'react';
import Details from './assets/details';
import './headerDetails.scss';
import { useSelector } from 'react-redux';

export const HeaderDetails = () => {
  const { offers, offerCategoriesAndTypes } = useSelector((state: any) => state.offerTool);
  const [categoryIs, setCategoryIs] = useState('');
  const [typeIs, setTypeIs] = useState('');

  useEffect(() => {
    fetchCategoriesAndTypes();
  }, [offers, offerCategoriesAndTypes]);

  const fetchCategoriesAndTypes = () => {
    try {
      const category = offerCategoriesAndTypes.filter(
        (e: any) => e.name === offers?.offerCategory && e
      );
      setCategoryIs(category[0]?.title);
      const type = category[0]?.offerTypes.filter((e: any) => e.name === offers?.offerType && e);
      setTypeIs(type[0]?.title);
    } catch (error) {
      setCategoryIs(offers?.offerCategory);
      setTypeIs(offers?.offerType);
    }
  };

  return (
    <div className="page-header-details">
      <Details info={categoryIs} />
      <span> | </span>
      <Details info={offers?.offerId} />
      <span> | </span>
      <Details info={typeIs} />
    </div>
  );
};
