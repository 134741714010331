import { showHelpText } from '../store/offerToolSlice';
import { useDispatch } from 'react-redux';

export const useHelpText = () => {
  const dispatch = useDispatch();

  const clearHelpText = () => {
    dispatch(showHelpText({ helpKey: '' }));
  };
  
  const setHelpText = (pathname:string) => {
    try {
      let path = pathname;
      dispatch(showHelpText({ helpKey: path }));
    } catch (error) {}
  };
  return [setHelpText, clearHelpText];
};
