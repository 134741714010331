import Modal from '../../components/atoms/Modal';
import { Button } from '../../components/atoms/Button';
import './PricingDetails.css';
interface Props {
    handleClose: () => void;
}
function PricingDetailsModal({ handleClose }: Props) {
    return (
        <Modal cautionIconVisible={false} titleSize="25px" title="Change to Distribution Channels" onClose={handleClose} size='medium'>
            <>
                <div className='pricing-modal-body'>By Changing who qualifies for this offer, the Distribution Channels will be updated. Please review the Distribution Channels before submitting your offer.
                </div>
                <div className="next-back-btn-container button-container">
                    <Button id='pricing-detials-modal-primary-button' dataAttribute='pricing-detials-modal-button' label={'OK'} primary onClick={handleClose} />
                </div>
            </>
        </Modal>
    );
}

export default PricingDetailsModal;
