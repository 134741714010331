import { ComponentStory, ComponentMeta } from '@storybook/react';
import { Checkbox } from './index';

export default {
  title: 'Atoms/CheckBox',
  component: Checkbox,
} as ComponentMeta<typeof Checkbox>;

const Template: ComponentStory<typeof Checkbox> = (args) => <Checkbox {...args} />;

export const CheckBox = Template.bind({});
CheckBox.args = {
  label: 'Checkbox test',
};

export const CheckBoxDisabled = Template.bind({});
CheckBoxDisabled.args = {
  label: 'Checkbox Disabled',
  disabled: true,
};
