export const startEndData = [
  {
    label: 'Enter the dates when the offer is valid for the guest to book. ',
    subLabel: 'Cannot be within 15 business days of the offer submission date.',
    subLabelToolbox: 'Cannot be within 5 business days of the offer submission date.',
    cal1: 'Booking Start Date*',
    cal1Value: 'bookingStartDate',
    cal2: 'Booking End Date*',
    cal2Value: 'bookingEndDate',
  },
  {
    label: 'Enter the dates when the offer is valid for the guest to stay.',
    subLabel: 'Cannot be within 15 business days of the offer submission date.',
    subLabelToolbox: 'Cannot be within 5 business days of the offer submission date.',
    cal1: 'Stay Start Date*',
    cal1Value: 'stayStartDate',
    cal2: 'Stay End Date*',
    cal2Value: 'stayEndDate',
  },
];

export const hyattPriveBookingLabel = 'Enter the dates when the offer is valid for the travel advisor to book.' 