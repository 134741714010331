export interface QuestionIconPropType {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
export const QuestionIcon = ({ onClick }: QuestionIconPropType) => {
  return (
    <span onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#0072CE"
        height="22"
        width="22"
        viewBox="0 0 32 32"
        className=""
        aria-hidden="true"
      >
        <path d="M16 6c5.523 0 10 4.477 10 10 0 5.522-4.477 10-10 10-5.522 0-10-4.478-10-10 0-5.523 4.478-10 10-10zm0 13.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm.107-9.5a3.498 3.498 0 00-3.001 1.698 1 1 0 101.714 1.03l.079-.117A1.5 1.5 0 1116.107 15v-.001l-.054.003L16 15a1 1 0 00-1 1v1l.007.117A1 1 0 0017 17v-.115A3.501 3.501 0 0016.107 10z"></path>
      </svg>
    </span>
  );
};
