import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '../../components/atoms/Checkbox';
import { Button } from '../../components/atoms/Button';
import { PlusIcon } from '../../components/Icons/PlusIcon';
import { distribution2, showDistribution } from './distributionData';

import './DistributionChannels.css';
import { Input } from '../../components/atoms/Input';
import { handleChange, resetDistributionChennalCheckedData } from '../../store/offerToolSlice';
import Trashcan from '../../components/Icons/Trashcan';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { useLocation } from 'react-router-dom';

export const DistributionChannels: React.FC = () => {
  const {
    wholesaleConnected,
    wholesaleConnectedSpecificPartner,
    wholesaleNotConnected,
    wholesaleNotConnectedSpecificPartner,
    otaBundled,
    otaBundledSpecificPartner,
    luxuryConsortiaPartners,
    luxuryConsortiaPartnersSpecificPartner,
  } = useSelector((state: any) => state.offerTool.offers.distributionChannel);
  const { distributionChannel } = useSelector((state: any) => state.offerTool.offers);
  const { DistributionChennalcheckedData } = useSelector((state: any) => state.offerTool);
  const { showError } = useSelector((state: any) => state.offerTool);
  const {
    offers: {
      pricing: { qualifiers },
      offerType,
    },
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(()=>{    
    if(offerType === 'hyatt-prive' || offerType === 'hyatt-luxury-consortia'){      
      if(offerType === 'hyatt-prive'){
        dispatch(handleChange({ name: 'priveWebsite', value: true }));
      }
      dispatch(handleChange({ name: 'gds', value: true }));
      dispatch(handleChange({ name: 'voiceAndHotelDirect', value: true }));      
    }
  }, [offerType]);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-distributionchannels';
  }, []);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      handleChange({
        name: 'DistributionChennalcheckedData',
        value: { ...DistributionChennalcheckedData, [name]: value },
      })
    );
  }

  const handleDist = (dataParam: string) => {
    if (!DistributionChennalcheckedData[dataParam]) return;
    let data: { name: string; value: string };
    switch (dataParam) {
      case 'wholeSaleConnectedValue':
        dispatch(
          handleChange({
            name: 'wholesaleConnectedSpecificPartner',
            value: [
              ...wholesaleConnectedSpecificPartner,
              DistributionChennalcheckedData[dataParam],
            ],
          })
        );
        dispatch(
          handleChange({
            name: 'DistributionChennalcheckedData',
            value: { ...DistributionChennalcheckedData, [dataParam]: '' },
          })
        );
        // setCheckedData({ ...checkedData, [dataParam]: '' });
        break;
      case 'wholeSaleNotConnectedValue':
        dispatch(
          handleChange({
            name: 'wholesaleNotConnectedSpecificPartner',
            value: [
              ...wholesaleNotConnectedSpecificPartner,
              DistributionChennalcheckedData[dataParam],
            ],
          })
        );
        dispatch(
          handleChange({
            name: 'DistributionChennalcheckedData',
            value: { ...DistributionChennalcheckedData, [dataParam]: '' },
          })
        );
        break;
      case 'otaBundledValue':
        dispatch(
          handleChange({
            name: 'otaBundledSpecificPartner',
            value: [...otaBundledSpecificPartner, DistributionChennalcheckedData[dataParam]],
          })
        );
        dispatch(
          handleChange({
            name: 'DistributionChennalcheckedData',
            value: { ...DistributionChennalcheckedData, [dataParam]: '' },
          })
        );
        break;
      case 'luxuryValue':
        dispatch(
          handleChange({
            name: 'luxuryConsortiaPartnersSpecificPartner',
            value: [
              ...luxuryConsortiaPartnersSpecificPartner,
              DistributionChennalcheckedData[dataParam],
            ],
          })
        );
        dispatch(
          handleChange({
            name: 'DistributionChennalcheckedData',
            value: { ...DistributionChennalcheckedData, [dataParam]: '' },
          })
        );
        break;
      default:
        break;
    }
  };

  const onDelete = (key: number, partner: string) => {
    switch (partner) {
      case 'wholeSaleConnectedValue':
        const partnersToSave = wholesaleConnectedSpecificPartner.filter(
          (partner: string, index: number) => key !== index
        );
        dispatch(
          handleChange({ name: 'wholesaleConnectedSpecificPartner', value: partnersToSave })
        );
        break;
      case 'wholeSaleNotConnectedValue':
        const partnersNotConnectedToSave = wholesaleNotConnectedSpecificPartner.filter(
          (partner: string, index: number) => key !== index
        );
        dispatch(
          handleChange({
            name: 'wholesaleNotConnectedSpecificPartner',
            value: partnersNotConnectedToSave,
          })
        );
        break;
      case 'otaBundledValue':
        const otaBundledToSave = otaBundledSpecificPartner.filter(
          (partner: string, index: number) => key !== index
        );
        dispatch(handleChange({ name: 'otaBundledSpecificPartner', value: otaBundledToSave }));
        break;
      case 'luxuryValue':
        const luxuryConsortiaToSave = luxuryConsortiaPartnersSpecificPartner.filter(
          (partner: string, index: number) => key !== index
        );
        dispatch(
          handleChange({
            name: 'luxuryConsortiaPartnersSpecificPartner',
            value: luxuryConsortiaToSave,
          })
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 10 }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetDistributionChennalCheckedData());
    };
  }, []);

  const qualifierOption = 
    offerType === 'hyatt-prive' || offerType === 'hyatt-luxury-consortia' ?
      'all' : qualifiers;

  return (
    <section className="container distribution-main">
      <PageHeader
        title="Distribution Channels"
        description="What channels would you like this offer distributed across?"
      />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <article className="distribution-info">
        <span className="b-form-input__label choose-distribution">
          Choose distribution channels*
        </span>
        <div className="distribution">
          <div className="distribution-channels">
            {distribution2?.[offerType]?.[qualifierOption]?.map(
              ({ name, label }: { name: string; label: string }, index: number) => {
                return (
                  <div key={index}>
                    <Checkbox
                      id={`distribution-channel-${name}`}
                      dataAttribute={`distribution-channel-${name}-checkbox`}
                      labelClassName="p-base-medium"
                      label={label}
                      name={name}
                      checked={distributionChannel[name]}
                      disabled={false}
                      onChange={(e) => {
                        const targetName = e.target.name;
                        const targetChecked = e.target.checked;
                        const { hyattCom, priveWebsite } = distributionChannel;
                        
                        // gds cannot be unchecked if hyattCom or priveWebsite are checked
                        if (targetName === 'gds' && (hyattCom || priveWebsite)) {
                          return;
                        }
                        // if hyattCom or priveWebsite are checked then also check gds
                        if (targetChecked && (targetName === 'hyattCom' || targetName === 'priveWebsite')) {
                          dispatch(handleChange({ name: targetName, value: true }));
                          dispatch(handleChange({ name: 'gds', value: true }));
                        }
                        if (offerType === 'hyatt-luxury-consortia') {
                          if (name === 'otaStandalone') {
                            dispatch(handleChange({ name: targetName, value: targetChecked }))
                          }
                        } else {
                          dispatch(handleChange({ name: targetName, value: targetChecked }))
                        }           
                      }}
                    />
                  </div>
                );
              }
            )}
            {showDistribution?.[qualifierOption]?.[offerType]?.['wholesaleConnected'] && (
              <Checkbox
                id="distribution-channel-wholesale-connected"
                dataAttribute="distribution-channel-wholesale-connected-checkbox"
                label="Wholesale - Connected"
                labelClassName="p-base-medium"
                name="wholesaleConnected"
                checked={wholesaleConnected}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.checked }))
                }
              />
            )}
            {wholesaleConnected && (
              <div className="channels-details">
                <Input
                  id="distribution-channel-wholesale-connected-value"
                  dataAttribute="distribution-channel-wholesale-connected-value-input"
                  label="* List each specifc partner."
                  className="dist-input-ext"
                  labelClassName="p-base-small"
                  name="wholeSaleConnectedValue"
                  value={DistributionChennalcheckedData.wholeSaleConnectedValue}
                  onChange={handleData}
                  placeHolder="Partner name"
                />
                {showError &&
                  wholesaleConnected &&
                  wholesaleConnectedSpecificPartner?.length === 0 && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <WarningIcon width={15} height={15} />
                      <p style={{ fontSize: '12px', color: 'red' }}>Provide a partner name</p>
                    </div>
                  )}
                <Button
                  id="distribution-channel-wholesale-connected-add-patner"
                  dataAttribute="distribution-channel-wholesale-connected-add-patner-button"
                  label="Add Partner"
                  type="link"
                  icon={<PlusIcon bgColor="#0072ce" />}
                  className="distribution-add-button"
                  onClick={() => handleDist('wholeSaleConnectedValue')}
                />
                {wholesaleConnectedSpecificPartner?.map((item: string, index: number) => {
                  return (
                    <div className="partner-with-trash">
                      <Input
                        id="distribution-channel-wholesale-connected-specific-partner-value"
                        dataAttribute="distribution-channel-wholesale-connected-specific-partner-value-input"
                        key={index}
                        className="dist-input-ext dist-map"
                        name="wholeSaleConnectedValue"
                        value={item}
                        readOnly
                      />
                      <button
                        className="blackout-delete-button"
                        onClick={() => {
                          onDelete(index, 'wholeSaleConnectedValue');
                        }}
                      >
                        <Trashcan />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {showDistribution?.[qualifierOption]?.[offerType]?.['wholesaleNotConnected'] && (
              <Checkbox
                id="distribution-channel-wholesale-not-connected-partner-value"
                dataAttribute="distribution-channel-wholesale-not-connected-partner-value-checkbox"
                label="Wholesale - Not Connected"
                labelClassName="p-base-medium"
                name="wholesaleNotConnected"
                checked={wholesaleNotConnected}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.checked }))
                }
              />
            )}
            {wholesaleNotConnected && (
              <div className="channels-details">
                <Input
                  id="distribution-channel-wholesale-not-connected-partner-add-value"
                  dataAttribute="distribution-channel-wholesale-not-connected-partner-input"
                  label="* List each specifc partner."
                  className="dist-input-ext"
                  labelClassName="p-base-small"
                  placeHolder="Partner name"
                  name="wholeSaleNotConnectedValue"
                  value={DistributionChennalcheckedData.wholeSaleNotConnectedValue}
                  onChange={handleData}
                />
                {showError &&
                  wholesaleNotConnected &&
                  wholesaleNotConnectedSpecificPartner?.length === 0 && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <WarningIcon width={15} height={15} />
                      <p style={{ fontSize: '12px', color: 'red' }}>Provide a partner name</p>
                    </div>
                  )}
                <Button
                  id="add-distribution-channel-wholesale-not-connected-partner"
                  dataAttribute="distribution-channel-wholesale-not-connected-partner-value-add-button"
                  label="Add Partner"
                  type="link"
                  icon={<PlusIcon bgColor="#0072ce" />}
                  className="distribution-add-button"
                  onClick={() => handleDist('wholeSaleNotConnectedValue')}
                />
                {wholesaleNotConnectedSpecificPartner?.map((item: string, index: number) => {
                  return (
                    <div className="partner-with-trash">
                      <Input
                        id="distribution-channel-wholesale-not-connected-specific-partner-value"
                        dataAttribute="distribution-channel-wholesale-not-connected-specific-partner-input"
                        key={index}
                        className="dist-input-ext dist-map"
                        name="wholeSaleNotConnectedValue"
                        value={item}
                        readOnly
                      />
                      <button
                        className="blackout-delete-button"
                        onClick={() => {
                          onDelete(index, 'wholeSaleNotConnectedValue');
                        }}
                      >
                        <Trashcan />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {showDistribution?.[qualifierOption]?.[offerType]?.['otaBundled'] && (
              <Checkbox
                id="distribution-channel-ota-bundled"
                dataAttribute="distribution-channel-ota-bundled-checkbox"
                label="OTA - Bundled (OTA Bundled Opaque Rates)"
                labelClassName="p-base-medium"
                name="otaBundled"
                checked={otaBundled}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.checked }))
                }
              />
            )}
            {otaBundled && (
              <div className="channels-details">
                <Input
                  id="distribution-channel-ota-bundled-value"
                  dataAttribute="distribution-channel-ota-bundled-input"
                  label="* List each specifc partner."
                  className="dist-input-ext"
                  labelClassName="p-base-small"
                  name="otaBundledValue"
                  value={DistributionChennalcheckedData.otaBundledValue}
                  onChange={handleData}
                  placeHolder="Partner name"
                />
                {showError && otaBundled && otaBundledSpecificPartner?.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <WarningIcon width={15} height={15} />
                    <p style={{ fontSize: '12px', color: 'red' }}>Provide a partner name</p>
                  </div>
                )}
                <Button
                  id="add-distribution-channel-ota-bundled"
                  dataAttribute="distribution-channel-ota-bundled-button"
                  label="Add Partner"
                  type="link"
                  icon={<PlusIcon bgColor="#0072ce" />}
                  className="distribution-add-button"
                  onClick={() => handleDist('otaBundledValue')}
                />
                {otaBundledSpecificPartner?.map((item: string, index: number) => {
                  return (
                    <div className="partner-with-trash">
                      <Input
                        id="distribution-channel-ota-bundled-specific-partners-value"
                        dataAttribute="distribution-channel-ota-bundled-specific-partners-input"
                        key={index}
                        className="dist-input-ext dist-map"
                        name="otaBundledValue"
                        value={item}
                        readOnly
                      />
                      <button
                        className="blackout-delete-button"
                        onClick={() => {
                          onDelete(index, 'otaBundledValue');
                        }}
                      >
                        <Trashcan />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {!(offerType === 'percent-off-offer') && qualifiers === 'nonQualifiedCustomers' && (
              <Checkbox
                id="distribution-channel-luxury-consortia-partners"
                dataAttribute="distribution-channel-luxury-consortia-partners-checkbox"
                label="Luxury Consortia Partners"
                labelClassName="p-base-medium"
                name="luxuryConsortiaPartners"
                checked={luxuryConsortiaPartners}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.checked }))
                }
              />
            )}
            {luxuryConsortiaPartners && !(offerType === 'percent-off-offer') && (
              <div className="channels-details">
                <Input
                  id="distribution-channel-luxury-consortia-partners-value"
                  dataAttribute="distribution-channel-luxury-consortia-partners-input"
                  label="* List each specifc partner."
                  className="dist-input-ext"
                  labelClassName="p-base-small"
                  placeHolder="Partner name"
                  name="luxuryValue"
                  value={DistributionChennalcheckedData.luxuryValue}
                  onChange={handleData}
                />
                {showError &&
                  luxuryConsortiaPartners &&
                  luxuryConsortiaPartnersSpecificPartner?.length === 0 && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <WarningIcon width={15} height={15} />
                      <p style={{ fontSize: '12px', color: 'red' }}>Provide a partner name</p>
                    </div>
                  )}
                <Button
                  id="add-distribution-channel-luxury-consortia-partners"
                  dataAttribute="distribution-channel-luxury-consortia-partners-button"
                  label="Add Partner"
                  type="link"
                  icon={<PlusIcon bgColor="#0072ce" />}
                  className="distribution-add-button"
                  onClick={() => handleDist('luxuryValue')}
                />
                {luxuryConsortiaPartnersSpecificPartner?.map((item: string, index: number) => {
                  return (
                    <div className="partner-with-trash">
                      <Input
                        id="distribution-channel-luxury-consortia-specific-partners"
                        dataAttribute="distribution-channel-luxury-consortia-specific-partners-input"
                        key={index}
                        className="dist-input-ext dist-map"
                        name="wholeSaleNotConnectedValue"
                        value={item}
                        readOnly
                      />
                      <button
                        className="blackout-delete-button"
                        onClick={() => {
                          onDelete(index, 'luxuryValue');
                        }}
                      >
                        <Trashcan />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {showError && (
              // !distributionChannel.gds &&
              // !distributionChannel.hyattCom &&
              // !distributionChannel.luxuryConsortiaPartners &&
              // !distributionChannel.otaBundled &&
              // !distributionChannel.otaStandalone &&
              // !distributionChannel.voiceAndHotelDirect &&
              // !distributionChannel.wholesaleConnected &&
              // !distributionChannel.wholesaleNotConnected &&

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>
                  Provide distribution channels to continue
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="bottom-componenet"></div>
      </article>
    </section>
  );
};
