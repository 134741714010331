export const isEmpty = (data?: string): boolean => {
    return !data || data.length === 0;
};

export const extractToolkitReducerName = (name: string): string => {
    const parts = name.split('-');
    return parts.length > 1 ? parts[1] : name;
}

export const isEmptyObject = (obj: any | null | undefined) => {
    if (obj == null) {
        return false;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const hasUndefinedValues = (obj: any) => {
    return Object.values(obj).some(value => value === 'undefined' || value === undefined);
}

export const capitalizeWords = (inputString: string): string => {
    return inputString.replace(/\b\w/g, match => match.toUpperCase());
}

export const getLanguageNames = (keys: string[]): string[] => {
    const inputDictionary: Record<string, string> = {
        "es-ES": "Spanish (Universal)",
        "pt-PT": "Portuguese (Brazil)",
        "fr-FR": "French (France)",
        "de-DE": "German",
        "ru-RU": "Russian",
        "ko-KR": "Korean",
        "ja-JP": "Japanese",
        "zh-CN": "Simplified Chinese",
        "zh-HK": "Traditional Chinese"
    };

    const values: string[] = [];

    for (const key of keys) {
        const value = inputDictionary[key];
        if (value) {
            values.push(value);
        }
    }
    return values;
}


export const isHtmlPresent = (str: string): boolean => {
    return /<\/?[a-z][\s\S]*>/i.test(str);
}


export const validateRTEText = (str: string): boolean => {
    const regex = /^(?!.*<(?!\/?(p|a|ul|li|br)\b)[^>]*>)(?!.*\bstyle\s*=\s*(['"]?).*?\1)(<(?:\/?(?:p|a|ul|li|br))[^>]*>[\s\S]*<\/(?:p|a|ul|li|br)>)*(?:(?!<\/?(?:p|a|ul|li|br))[\s\S])*$/;
    return regex.test(str);
}

//if assignee prefix is there then remove and return the plain text from comment
export const formatComment = (comment: string) => {
    if (comment.substring(0, 9) === 'assignee:') {
      let str = comment;
      let substring = '/';
      let index = str.indexOf(substring);
      if (index !== -1) {
        return str.substring(index + substring.length);
      } else {
        return '';
      }
    }
    return comment;
  };

export const isAlphaNumericOrSpace = (input: string) => {
    return /^[a-zA-Z0-9\s]$/.test(input);
}