import React, { useState, useRef, useEffect } from 'react';
import './FileUploader.css';
import { WarningIcon } from '../../../components/Icons/WarningIcon';
interface FileUploaderProps {
  label: string;
  description: string;
  getFile?: (data: File) => void;
  accept?: string;
  errorString?: string;
  clear?: Boolean;
}

const FileUploader: React.FC<FileUploaderProps> = ({ label, description, getFile, accept, errorString, clear }) => {
  const [files, setFiles] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const showError = errorString && errorString.length > 0;
  const handleFile = (e: any) => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (files && getFile) {
      getFile(files);
    }
  }, [files]);

  useEffect(() => {
    if (clear) {
      setFiles(null);
      inputRef.current!.value = '';
    }
  }, [clear]);


  return (
    <div className="file-input-container">
      <p className="p-base-small">{label}</p>
      <div className="file-label">
        <span className="file-name">{files?.name}</span>
        <button className="file-button" onClick={handleFile}>
          BROWSE FILE
        </button>
      </div>
      <p className="p-base-small file-description">{description}</p>

      <input
        type="file"
        ref={inputRef}
        id={label}
        data-testid={label}
        name=""
        onChange={(e: any) => {
          setFiles(e.target.files[0]);
          e.target.value = '';
        }}
        hidden
        accept={accept}
      />
      {showError && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <WarningIcon width={15} height={15} />
          <p style={{ fontSize: '12px', color: 'red' }}>{errorString}</p>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
