import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import offerToolSlice from './offerToolSlice';
import { ENV } from '../config/constants';

export const store = configureStore({
  reducer: {
    offerTool: offerToolSlice,
  },
  middleware: (getDefaultMiddleware) => {
    if (ENV === 'dev' || !ENV) {
      return getDefaultMiddleware().concat(logger as any);
    } else {
      return getDefaultMiddleware().concat([]);
    }
  },
});
