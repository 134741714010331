import React, { FC, useState } from 'react';
import './Timeline.css';
import { Button } from '../Button';
import { Textarea } from '../Teaxtarea';
import { CommentsParams } from '../../../store/offerToolSlice';
import { formatDate } from '../../../utils/fileUtils';
import { capitalizeWords } from '../../../utils/textUtils';

export interface TimelineProps {
  events: CommentsParams[] | undefined;
  addNewComment: (newComment: string) => void;
  isSavingComments: boolean;
  isCommentDisabled: boolean;
}

const Timeline = ({ events, addNewComment, isSavingComments, isCommentDisabled }: TimelineProps) => {
  const [addingComment, setAddingComment] = useState(false);
  const [newComment, setNewComment] = useState('');

  const toggleCommentForm = () => {
    setAddingComment(!addingComment);
    setNewComment('');
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
  };

  const cancelComment = () => {
    setAddingComment(false);
    setNewComment('');
  };

  const addComment = async () => {
    await addNewComment(newComment);
    setAddingComment(false);
    setNewComment('');
  };

  const formatComments = (comment: string) => {    
    if (comment.substring(0, 9) === 'assignee:') {
      let str = comment;
      let substring = '/';
      let index = str.indexOf(substring);
      if (index !== -1) {
        let result = str.substring(index + substring.length);
        return result;
      } else {
        return '';
      }
    }
    return comment;
  };

  return (
    <div className="timeline">
      {events && events?.length > 0 ? (
        events?.map((event, index) => (
          <div key={index} className="timeline-event">
            <div className="timeline-header">
              <div className="timeline-date">{capitalizeWords(event.status)}</div>
              <div className="timeline-date">{formatDate(event.date)}</div>
            </div>
            <div className="timeline-content">{formatComments(event.comments)}</div>
            <div className="timeline-footer">
              <div className="timeline-date">- {event.author} -</div>
            </div>
          </div>
        ))
      ) : (
        <div>No Comments Found.</div>
      )}
      {addingComment && (
        <div className="timeline-event">
          <Textarea
            id='timeline-comments'
            dataAttribute='timeline-comments-input'
            label=""
            name="comments"
            className="commentsEditor"
            placeholder="Add a new comment..."
            value={newComment}
            onChange={handleCommentChange}
          />
          <div className="timeline-add-comment-button">
            <Button id='timeline-cancel' dataAttribute='timeline-cancel-button' label="Cancel" className="timeline-button-cancel" onClick={cancelComment} />
            <Button
              id='timeline-save'
              dataAttribute='timeline-save-button'
              label="Save"
              primary
              onClick={addComment}
              isloading={isSavingComments}
              isDisable={isSavingComments || !newComment}
            />
          </div>
        </div>
      )}

      {!addingComment && (
        <div className="timeline-footer">
          <Button isDisable={!isCommentDisabled} id='timeline-add-comment' dataAttribute='timeline-add-comment-button' label="Add Comment" onClick={toggleCommentForm} />
        </div>
      )}
    </div>
  );
};

export default Timeline;
