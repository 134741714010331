/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  AllOffersParam,
  GetOfferParams,
  HotelSpecialOffer,
  setOfferOffSetCount,
} from '../../store/offerToolSlice';
import Pagination from 'react-paginate';

import './dashboard.css';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { EllipsisIcon } from '../../components/Icons/EllipsisIcon';
import PopUpMenu from '../../components/atoms/PopupMenu';
import { TrashIcon } from '../../components/Icons/TrashIcon';
import { CommentsIcon } from '../../components/Icons/CommentsIcon';
import { EmptyResult } from './EmptyResult';
import { AgGridEvent } from 'ag-grid-community';
import { ExternalLinkIcon } from '../../components/Icons/ExternalLinkIcon';
import { NavLink } from 'react-router-dom';
import { LeftArrowIcon } from '../../components/Icons/LeftArrowIcon';
import { RightArrowIcon } from '../../components/Icons/RightArrowIcon';
import LoadingSpinner from '../../components/atoms/Spinner';
import { CalenderIcon } from '../../components/Icons/CalenderIcon';
import { CrossIcon } from '../../components/Icons/CrossIcon';
import ViewMoreModal from './Modals/ViewMoreModal';
import { CopyIcon } from '../../components/Icons/CopyIcon';
import { EditIcon } from '../../components/Icons/EditIcon';
import { isPastDate } from '../../utils/dateAndTimeUtils';

interface Props {
  data: AllOffersParam | undefined;
  setOffsetValue: (offsetValue: number) => void;
  setSortBy: (value: string) => void;
  setSortOrder: (value: string) => void;
  onCopy: (offerId: HotelSpecialOffer | undefined) => void;
  setDeleteOfferData: (params: string) => void;
  setShowDeleteModal: (value: boolean) => void;
  onExtendSelect: (param: GetOfferParams) => void;
  onEndSelect: (param: GetOfferParams) => void;
  setshowEndOfferModal: (value: boolean) => void;
  showCommentsModal: (value: boolean, param: GetOfferParams) => void;
  selectedOfferCategory: string;
  isRequestLoading: boolean;
  dashboardState: string;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  onEditSelect: (offerPath?: string) => void;
  canCreateOffer: boolean;
}

type modelValues = 'edit' | 'copy' | 'delete' | 'view' | 'comments' | 'extend-offer' | 'end-offer';

const TableData = ({
  data,
  setOffsetValue,
  setSortBy,
  setSortOrder,
  onCopy,
  setDeleteOfferData,
  setShowDeleteModal,
  onExtendSelect,
  onEndSelect,
  showCommentsModal,
  isRequestLoading,
  dashboardState,
  currentPage,
  setCurrentPage,
  onEditSelect,
  canCreateOffer,
}: Props) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [rowData, setRowData] = useState<HotelSpecialOffer[] | undefined>();
  const [itemsPerPage] = useState(25);
  const [onSelectedOffer, setOnSelectedOffer] = useState<GetOfferParams>();
  const modalRef = useRef<HTMLDivElement>(null);
  const [selectedModalValue, setSelectedModalValue] = useState<modelValues>();
  const [showMoreModalVisible, setShowMoreModalVisible] = useState(false);
  const [showMoreModalTitle, setShowMoreModalTitle] = useState('');
  const [showMoreModalContent, setShowMoreModalContent] = useState<string[]>([]);
  const [showAsList, setShowAsList] = useState(false);

  const getLinks = (
    params: any,
    onSelectedOffer: GetOfferParams | undefined,
    canCreateOffer: boolean,
    showCommentsModal: (value: boolean, param: GetOfferParams) => void,
    setSelectedModalValue: (value: modelValues) => void,
    setDeleteOfferData: (params: string) => void,
    setShowDeleteModal: (value: boolean) => void,
    onEndSelect: (param: GetOfferParams) => void,
    onExtendSelect: (param: GetOfferParams) => void
  ) => {
    const links = [
      {
        icon: <ExternalLinkIcon />,
        label: 'View',
        onClick: () => {
          setSelectedModalValue('view');
        },
      },
    ];

    if (
      canCreateOffer &&
      (params?.data?.status.toLocaleLowerCase() === 'draft' ||
        params?.data?.status.toLocaleLowerCase() === 'rejected')
    ) {
      links.push({
        icon: <TrashIcon />,
        label: 'Delete',
        onClick: () => {
          setDeleteOfferData(params?.data?.containingPagePath);
          setSelectedModalValue('delete');
          setShowDeleteModal(true);
        },
      });
    }

    links.push({
      icon: <CommentsIcon />,
      label: 'View Comments',
      onClick: () => {
        setSelectedModalValue('comments');
        showCommentsModal(true, onSelectedOffer!);
      },
    });

    if (canCreateOffer && onSelectedOffer && params?.data?.status.includes('Approved')) {
      if (!params?.data?.editOffer && !params?.data?.hasLaunch) {
        links.push({
          icon: <EditIcon />,
          label: 'Edit',
          onClick: () => {
            setSelectedModalValue('edit');
          },
        });
      }

      if (!isPastDate(params?.data?.endDate) && !params?.data?.hasLaunch) {
        links.push({
          icon: <CrossIcon />,
          label: 'End Offer',
          onClick: () => {
            setSelectedModalValue('end-offer');
            onEndSelect(onSelectedOffer);
          },
        });
      }

      if (!params?.data?.hasLaunch) {
        links.push({
          icon: <CalenderIcon />,
          label: 'Extend Offer',
          onClick: () => {
            setSelectedModalValue('extend-offer');
            onExtendSelect(onSelectedOffer);
          },
        });
      }
    }

    if (
      canCreateOffer &&
      onSelectedOffer &&
      !params?.data?.containingPagePath.includes('toolbox') &&
      !params?.data?.containingPagePath.includes('launches') &&
      !(params?.data?.offerType === 'hyattpriveluxury-offer' || params?.data?.offerType === 'free-night-offer')
    ) {
      links.push({
        icon: <CopyIcon />,
        label: 'Copy',
        onClick: () => {
          setSelectedModalValue('copy');
        },
      });
    }

    return links;
  };

  const columnDefs = [
    {
      headerName: 'Action',
      field: 'viewOffer',
      width: 110,
      sortable: false,
      unSortIcon: false,
      cellRenderer: function (params: any) {
        const path =
          canCreateOffer &&
          (params?.data?.status?.toLocaleLowerCase() === 'draft' ||
            params?.data?.status?.toLocaleLowerCase() === 'rejected')
            ? '/review-and-submit'
            : '/view-offer';
        const label = !canCreateOffer
          ? 'View Offer'
          : params?.data?.status?.toLocaleLowerCase() === 'rejected'
          ? 'Fix Offer'
          : params?.data?.editOffer && params?.data?.status?.toLocaleLowerCase() === 'draft'
          ? 'View Edit'
          : 'View Offer';
        const pagePath = params?.data?.containingPagePath;
        const viewUrl = `${path}?offerPath=${pagePath}`;
        return (
          <div className="view-offer" ref={modalRef}>
            <NavLink to={viewUrl}>
              <span>{label}</span>
            </NavLink>
          </div>
        );
      },
    },
    {
      headerName: 'Assigned to',
      field: 'assignee',
      width: 135,
      sortable: false,
      unSortIcon: false,
      cellRenderer: function (params: any) {
        return (
          <div className="offerId">
            <span>{params?.value}</span>
          </div>
        );
      },
    },
    {
      headerName: 'Offer ID',
      field: 'offerId',
      width: 150,
      sortable: true,
      unSortIcon: true,
      cellRenderer: function (params: any) {
        return (
          <div className="offerId" ref={modalRef}>
            <span>{params.value}</span>
            <button
              className="action-btn"
              onClick={() => {
                setOnSelectedOffer({
                  offer: params?.data,
                });
              }}
            >
              <EllipsisIcon />
              {onSelectedOffer?.offer?.containingPagePath === params?.data?.containingPagePath && (
                <PopUpMenu
                  customCss={params.rowIndex + 1 > 5 ? `popupModal root` : ''}
                  links={getLinks(
                    params,
                    onSelectedOffer,
                    canCreateOffer,
                    showCommentsModal,
                    setSelectedModalValue,
                    setDeleteOfferData,
                    setShowDeleteModal,
                    onEndSelect,
                    onExtendSelect
                  )}
                />
              )}
            </button>
          </div>
        );
      },
    },
    {
      headerName: 'Offer Name',
      field: 'promotionName',
      sortable: true,
      unSortIcon: true,
    },
    {
      headerName: 'Record Type',
      field: 'recordType',
      sortable: true,
      unSortIcon: true,
      width: 140,
      valueGetter: (params: { data: any }) => {
        return ['Single', 'hotelOnly'].includes(params?.data?.recordType)
          ? 'Single Hotel'
          : 'Multiple Hotel';
      },
    },
    {
      headerName: 'Hotel',
      field: 'hotel',
      sortable: false,
      unSortIcon: true,
      cellRenderer: function (params: any) {
        if (params?.data?.hotel?.length > 1) {
          return (
            <div className="" ref={modalRef}>
              <a
                href="#"
                onClick={() => {
                  setShowMoreModalVisible(true);
                  setShowMoreModalTitle('Hotel Name(s)');
                  setShowAsList(true);
                  setShowMoreModalContent(params?.data?.hotel);
                }}
              >
                <span>{params?.data?.hotel?.length + ' Hotels in offer'}</span>
              </a>
            </div>
          );
        } else return params?.data?.hotel;
      },
      comparator: () => 0,
    },
    {
      headerName: 'Spirit Code',
      field: 'spiritCode',
      sortable: false,
      unSortIcon: true,
      width: 125,
      comparator: () => 0,
      cellRenderer: function (params: any) {
        if (params?.data?.spiritCode?.length > 1) {
          return (
            <div className="" ref={modalRef}>
              <a
                href="#"
                onClick={() => {
                  setShowMoreModalVisible(true);
                  setShowAsList(false);
                  setShowMoreModalTitle('Spirit Code(s)');
                  setShowMoreModalContent(params?.data?.spiritCode);
                }}
              >
                <span>See Codes</span>
              </a>
            </div>
          );
        } else return params?.data?.spiritCode;
      },
    },
    {
      headerName: 'Booking Start',
      field: 'startDate',
      width: 150,
      sortable: true,
      unSortIcon: true,
      comparator: () => 0,
    },
    {
      headerName: 'Booking End',
      field: 'endDate',
      width: 140,
      sortable: true,
      unSortIcon: true,
      comparator: () => 0,
    },
    {
      headerName: 'Submitted Date',
      field: 'submittedDate',
      width: 160,
      sortable: true,
      unSortIcon: true,
      comparator: () => 0,
    },
  ];

  const search = useCallback(() => {
    if (data?.totalcount === 0) {
      setRowData([]);
    }
    if (data?.['offers']) {
      let rows: HotelSpecialOffer[] = [];
      data['offers'].map((item) => {
        rows.push(item);
        return false;
      });
      setRowData(rows);
      rows = [];
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % (data ? data.totalcount : 0);
    dispatch(setOfferOffSetCount(newOffset));
    setOffsetValue(newOffset);
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [dashboardState]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (
          String(event.target).includes('SVG') ||
          String(event.target).includes('HTMLSpanElement')
        ) {
          //TODO::
        } else {
          setSelectedModalValue(undefined);
          setOnSelectedOffer(undefined);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    const path =
      canCreateOffer && onSelectedOffer?.offer?.status?.toLocaleLowerCase() === 'draft'
        ? '/review-and-submit'
        : '/view-offer';
    const viewUrl = `${path}?offerPath=${onSelectedOffer?.offer?.containingPagePath}`;
    if (selectedModalValue) {
      switch (selectedModalValue) {
        case 'edit':
          onEditSelect(onSelectedOffer?.offer?.containingPagePath);
          setSelectedModalValue(undefined);
          setOnSelectedOffer(undefined);
          break;
        case 'view':
          window.open(viewUrl, '_blank');
          setSelectedModalValue(undefined);
          setOnSelectedOffer(undefined);
          break;
        case 'comments':
        case 'end-offer':
        case 'extend-offer':
          setSelectedModalValue(undefined);
          setOnSelectedOffer(undefined);
          break;
        case 'copy':
          onCopy(onSelectedOffer?.offer);
          setSelectedModalValue(undefined);
          setOnSelectedOffer(undefined);
          break;
        default:
          break;
      }
    }
  }, [selectedModalValue]);

  useEffect(() => {
    search();
  }, [search, data]);

  const onScrollStart = () => {
    setSelectedModalValue(undefined);
    setOnSelectedOffer(undefined);
  };

  const onSortChanged = useCallback((event: AgGridEvent) => {
    try {
      const e = event.columnApi.getColumnState().filter((e) => e.sortIndex === 0);
      setCurrentPage(0);
      if (e[0].sortIndex === 0 && e[0].sort) {
        if (e[0].colId === 'startDate') {
          setSortBy('booking' + e[0].colId.charAt(0).toUpperCase() + e[0].colId.slice(1));
          setSortOrder(e[0].sort);
        } else if (e[0].colId === 'endDate') {
          setSortBy('booking' + e[0].colId.charAt(0).toUpperCase() + e[0].colId.slice(1));
          setSortOrder(e[0].sort);
        } else if (e[0].colId === 'submittedDate') {
          setSortBy(e[0].colId.charAt(0) + e[0].colId.slice(1));
          setSortOrder(e[0].sort);
        } else if (e[0].colId === 'hotel') {
          setSortBy(e[0].colId + 'Name');
          setSortOrder(e[0].sort);
        } else {
          setSortBy(e[0].colId);
          setSortOrder(e[0].sort);
        }
      }
    } catch {
      setSortBy('');
      setSortOrder('');
    }
  }, []);

  return data && data.totalcount > 0 ? (
    <div className="ag-theme-alpine dasboard-table">
      <AgGridReact
        columnDefs={columnDefs}
        rowData={isRequestLoading ? [] : rowData}
        rowClassRules={{
          copiedRow: (params) => {
            return params?.data?.offerId === onSelectedOffer?.offer?.offerId;
          },
        }}
        onBodyScroll={onScrollStart}
        pagination={false}
        onSortChanged={onSortChanged}
        suppressNoRowsOverlay={!isRequestLoading}
        suppressMovableColumns={true}
      />
      {isRequestLoading && (
        <div className="spinning-state">
          <LoadingSpinner />
        </div>
      )}
      <div className="bottom-componenet">
        {data && data.totalcount > 0 && (
          <Pagination
            pageCount={Math.ceil((data ? data.totalcount : 0) / itemsPerPage)}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active-pagination'}
            className="pagination"
            previousLabel={<LeftArrowIcon />}
            nextLabel={<RightArrowIcon />}
            forcePage={currentPage}
          />
        )}
      </div>
      {showMoreModalVisible && (
        <ViewMoreModal
          title={showMoreModalTitle}
          content={showMoreModalContent}
          displayAsList={showAsList}
          handleClose={() => setShowMoreModalVisible(false)}
        />
      )}
    </div>
  ) : (
    <EmptyResult />
  );
};

export default TableData;
