import Modal from '../../../components/atoms/Modal';
import '../dashboard.css';
interface Props {
  handleClose: () => void;
  title: string;
  content: string[];
  displayAsList?: boolean
}

function ViewMoreModal({ handleClose, title, content, displayAsList = false }: Props) {
  let formatContent;
  if (displayAsList)
    formatContent = content.map((item, index) => <div key={index}>{item}</div>);
  else
    formatContent = content.join(", ");
  return (
    <Modal cautionIconVisible={false} titleSize="25px" title={title} onClose={handleClose} size='medium'>
      <div className='view-more-content'> {formatContent}</div>
    </Modal>
  );
}

export default ViewMoreModal;
