import React, { FC } from 'react';
import { InfoIcon } from '../../Icons/InfoIcon';
import './Checkbox.css';
interface CheckboxProps {
  id: string;
  label: string;
  disabled?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: boolean;
  checked?: boolean;
  value?: string | number;
  labelClassName?: string;
  dataAttribute: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  id,
  name = '',
  label = 'Check Box',
  disabled = false,
  onChange = () => {},
  icon = false,
  value,
  labelClassName,
  checked,
  dataAttribute,
}) => {
  return (
    <label className={`b-form-checkbox ${disabled ? 'b-is-disabled' : ''}`}>
      <input
        id={id}
        data-role={dataAttribute}
        type="checkbox"
        name={name}
        disabled={disabled}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <span className="b-form-checkbox__control"></span>
      {icon ? (
        <div className="checkbox-info">
          <span className={`b-form-checkbox__label checkbox-info-span ${labelClassName}`}>
            {label}
          </span>
          <InfoIcon />
        </div>
      ) : (
        <span className={`b-form-checkbox__label ${labelClassName}`}>{label} </span>
      )}
    </label>
  );
};
