import React from 'react';
interface detailsProps {
  info: any;
}

const getInfo = (info: string | string[]) => {
  try {
    if (typeof info !== 'object') {
      {
        return (
          <span className="infoText">
            {info === undefined || info === '' ? 'N/A' : info.toString()}
          </span>
        );
      }
    } else {
      if (info.length > 0) {
        return info?.map(
          (e: { [s: string]: React.ReactNode } | ArrayLike<React.ReactNode>, i: number) => (
            <>
              <span className="infoText">{Object.values(e)}</span>
              {info.length !== i + 1 && <span>, </span>}
            </>
          )
        );
      } else {
        return <span className="infoText">N/A</span>;
      }
    }
  } catch (error) {}
};

const Details: React.FC<detailsProps> = ({ info }) => {
  return <span>{getInfo(info)}</span>;
};

export default Details;
