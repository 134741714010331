import React, { type FunctionComponent, memo, useRef, useCallback, type ReactNode } from 'react';

import { Button } from '../../atoms/Button'

import nls from '../../../nls/common.json';

const { FILE_SIZE_ERROR, FILE_TYPE_ERROR } = nls;

type ValidationStatus = 'ok' | 'error';

interface FileValidationStatusParams {
    status: ValidationStatus;
    file?: File;
    description?: string;
}

class FileValidationStatus {
    status: ValidationStatus;
    file?: File;
    description?: string;

    constructor({
        status,
        file,
        description
    }: FileValidationStatusParams) {
        this.status! = status;
        this.file = file;
        this.description = description;
    }
}

interface Params {
    acceptedFileTypes: string[];
    buttonLabel?: string;
    className?: string;
    dataTestID?: string;
    description?: ReactNode;
    disable?: boolean;
    inputTestID?: string;
    maxFileSize?: number;
    onAddFile: (files: FileList) => void;
    onError: (err: FileValidationStatus) => void;
}

const DefaultDescription = memo(() => (
    <>
        <p className="drag-heading">Select a file or drag and drop here</p>
        <p className="p-base-small">JPG, PNG or PDF, file size no more than 10MB</p>
    </>
));

const fileSizeBelowMax = (file: File, max: number): boolean => file.size < max;

const fileTypeAccepted = (file: File, acceptedTypes: string[]): boolean => acceptedTypes.includes(file.type);

export const FilePicker: FunctionComponent<Params> = ({
    acceptedFileTypes,
    buttonLabel = 'BROWSE FILE',
    className,
    dataTestID,
    description = <DefaultDescription />,
    disable,
    inputTestID,
    maxFileSize = 1e7, // 10 megabytes default
    onAddFile,
    onError
}: Params) => {
    const dragAndDropRef = useRef<HTMLInputElement>(null);

    const validateAddedFiles = useCallback((files: FileList) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!fileSizeBelowMax(file, maxFileSize)) {
                return new FileValidationStatus({
                    status: 'error',
                    file,
                    description: FILE_SIZE_ERROR
                });
            }
            if (!fileTypeAccepted(file, acceptedFileTypes)) {
                return new FileValidationStatus({
                    status: 'error',
                    file,
                    description: FILE_TYPE_ERROR
                });
            }
        }

        return new FileValidationStatus({ status: 'ok' });
    }, [acceptedFileTypes, maxFileSize])

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        const result = validateAddedFiles(files);

        if (result.status === 'error') {
            onError(result);
            return;
        }

        onAddFile(files);
    };

    return (
        <div
            className={`drag-and-drop ${className}`}
            data-testid="drag-and-drop"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div>
                {description}
            </div>
            <input
                type="file"
                ref={dragAndDropRef}
                accept={acceptedFileTypes.join(', ')}
                hidden
                data-testid="otherFiles"
                multiple
                onChange={(e: any) => onAddFile(e.target.files)}
            />
            <Button
                id='attachments-browser-files'
                dataAttribute='attachments-browser-files-button'
                label={buttonLabel}
                primary
                className="file-button"
                isDisable={disable}
                onClick={() => dragAndDropRef.current?.click()}
            />
        </div>
    );
}
