export interface TickIconPropType {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  bgColor?: string;
  style?: string;
}
export const TickIcon = ({ bgColor = '#0072ce', onClick, style }: TickIconPropType) => {
  return (
    <span onClick={onClick} className={style}>
      <svg
        fill={bgColor}
        height="15"
        width="15"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 490 490"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 "></polygon>{' '}
        </g>
      </svg>
    </span>
  );
};
