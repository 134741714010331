import React, { ReactNode, useState } from 'react';
import './collapse.css';
import { ArrowUpIcon } from '../../Icons/ArrowUpIcon';
import { ArrowDownIcon } from '../../Icons/ArrowDownIcon';
import { PencilIcon } from '../../Icons/PencilIcon';
import Badge from '../Badge';

type CollapseProps = {
  title: string;
  children: ReactNode;
  isCollapsed: boolean;
  isEditable?: boolean;
  isError: boolean;
  badgeText: string;
  badgeClassName: 'success' | 'danger';
  onClickEdit?: () => void;
};

const Collapse: React.FC<CollapseProps> = ({
  title,
  children,
  isEditable = true,
  isCollapsed,
  isError,
  badgeText,
  badgeClassName,
  onClickEdit,
}) => {
  const [isOpen, setIsOpen] = useState(isCollapsed);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="root-toggle">
      <div onClick={toggleOpen} className="main-toggle">
        <div className="toggle-heading">
          <div>{title}</div>
          {isEditable && (
            <div className="toggle-edit-icon" onClick={onClickEdit}>
              <PencilIcon />
              <span>Edit</span>
            </div>
          )}
        </div>
        <div className="right-component">
          {isError && (
            <div>
              <Badge className={badgeClassName} text={badgeText} />
            </div>
          )}
          <div>{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
        </div>
      </div>
      {isOpen && <div className="collapse-row">{children}</div>}
    </div>
  );
};

export default Collapse;
