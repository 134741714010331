import { useEffect, useId } from 'react';
import { Radio } from '../../components/atoms/Radio';
import { Select } from '../../components/atoms/Select';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import nls from '../../nls/HTBToolBox.json';
import './advanced-booking-ext.css';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getOfferFrequency } from '../../service/api';
import featureFlag from '../../config/featureFlags';
import { useLocation } from 'react-router-dom';

export const AdvancedBookingExt = () => {
  const advExt = useId();
  const offerTypeExclution = [
    'weddingpromotion-offer',
    'meetingpromotion-offer',
  ];
  const {
    offers: {
      offerType,
      advancedBooking: {
        advancedBookingEligibility,
        advancedBookingDays,
        hyattBonusPoints,
        bonusPoints,
        maxBonusPoints,
        bonusPointFrequency,
      },
    },
    frequencyOptions,
    showError,
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const location = useLocation();

  useEffect(()=>{
    const from = location.state?.from;
    if(from === 'edit'){
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }    
  },[]);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-advancedbookingext';
  }, []);
  
  useEffect(() => {
    dispatch(getOfferFrequency());
  }, []);

  useEffect(() => {
    dispatch(handleChange({ name: 'isAuthroziedToCreateOffer', value: false }));
    dispatch(handleChange({ name: 'haveValidAccessToCreateOffer', value: false }));
  }, [
    advancedBookingEligibility,
    advancedBookingDays,
    hyattBonusPoints,
    bonusPoints,
    maxBonusPoints,
    bonusPointFrequency,
  ]);

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 5 }));
  }, []);

  return (
    <section className="container advanced-ext">
      <PageHeader title="Advanced booking" description={nls.ADVANCED_BOOKING_INSTRUCTIONS} />
      {showError && (
        <div style={{ width: '75%', marginBottom: '20px' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <article className="advanced-ext-main">
        <div className="offer-require">
          <p className="p-base-normal">
            * Does this offer require advanced booking for eligibility?
          </p>
          <div className="advnc-ext">
            <Radio
              id='advanced-booking-eligibility-radio-1'
              dataAttribute='advanced-booking-eligibility-radio-1-yes'
              label="Yes"
              name="advancedBookingEligibility"
              value="yes"
              labelClassName="p-base-medium radio-button-align"
              checked={advancedBookingEligibility === true}
              onChange={(e) =>
                dispatch(handleChange({ name: e.target.name, value: e.target.value }))
              }
            />
            <Radio
              id='advanced-booking-eligibility-radio-2'
              dataAttribute='advanced-booking-eligibility-radio-2-no'
              label="No"
              name="advancedBookingEligibility"
              value="no"
              labelClassName="p-base-medium radio-button-align"
              checked={advancedBookingEligibility === false}
              onChange={(e) =>
                dispatch(handleChange({ name: e.target.name, value: e.target.value }))
              }
            />
          </div>
          {showError && advancedBookingEligibility === null && (
            <div style={{ display: 'flex' }}>
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red' }}>Please choose an option</p>
            </div>
          )}
        </div>
        {advancedBookingEligibility && (
          <div className="leadtime-info ext-box-1">
            <p className="p-base-small advnc-p-small">
              * How far in advance the offer must be booked before the stay?
            </p>
            <div className="leadtime-input-main">
              <input
                className="leadtime-input"
                name="advancedBookingDays"
                value={advancedBookingDays}
                onKeyDown={(e) => {
                  if (e.key !== 'Backspace') {
                    const value = isNaN(parseInt(e.key));
                    value && e.preventDefault();
                  }
                }}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                }
              />
              <label className="leadtime-input-label">Day(s)</label>
            </div>
            {showError && advancedBookingDays === '' && (
              <div style={{ display: 'flex' }}>
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>
                  Provide the number of days the offer must be booked before stay
                </p>
              </div>
            )}
          </div>
        )}
        {!featureFlag.hideWOHBonusPoints &&
          <>
            {!offerTypeExclution.includes(offerType) && (
              <div className="offer-require">
                <p className="p-base-normal">* Does this offer include World of Hyatt Bonus Points?</p>
                <div className="advnc-ext">
                  <Radio
                    id='advanced-booking-woh-bonus-points-radio-1'
                    dataAttribute='advanced-booking-woh-bonus-points-radio-1-yes'
                    label="Yes"
                    value="yes"
                    name="hyattBonusPoints"
                    labelClassName="p-base-medium radio-button-align"
                    checked={hyattBonusPoints === true}
                    onChange={(e) => {
                      dispatch(handleChange({ name: 'bonusPointFrequency', value: '' }));
                      dispatch(handleChange({ name: e.target.name, value: e.target.value }));
                    }}
                  />
                  <Radio
                    id='advanced-booking-woh-bonus-points-radio-2'
                    dataAttribute='advanced-booking-woh-bonus-points-radio-2-no'
                    label="No"
                    value="no"
                    name="hyattBonusPoints"
                    labelClassName="p-base-medium radio-button-align"
                    checked={hyattBonusPoints === false}
                    onChange={(e) => {
                      dispatch(handleChange({ name: 'bonusPointFrequency', value: '' }));
                      dispatch(handleChange({ name: e.target.name, value: e.target.value }));
                    }}
                  />
                </div>
                {showError && hyattBonusPoints === null && (
                  <div style={{ display: 'flex' }}>
                    <WarningIcon width={15} height={15} />
                    <p style={{ fontSize: '12px', color: 'red' }}>Please choose an option</p>
                  </div>
                )}
              </div>
            )}
            {hyattBonusPoints && (
              <div className="bonous-points-offer">
                <p className="p-base-normal">
                  The offer will take up to 6 weeks to be completed if Bonus Points are included.
                </p>
                <div className="offer-fiels">
                  <div className="leadtime-info ext-box-1">
                    <p className="p-base-small advnc-p-small">* Bonus Point Amount</p>
                    <div className="leadtime-input-main">
                      <input
                        className="leadtime-input advnc-ext-input"
                        id={advExt}
                        name="bonusPoints"
                        value={bonusPoints}
                        onKeyDown={(e) => {
                          if (e.key !== 'Backspace') {
                            const value = isNaN(parseInt(e.key));
                            value && e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                        }
                      />
                      <label htmlFor={advExt} className="leadtime-input-label">
                        Point(s)
                      </label>
                    </div>
                    {showError && bonusPoints === '' && (
                      <div style={{ display: 'flex' }}>
                        <WarningIcon width={15} height={15} />
                        <p style={{ fontSize: '12px', color: 'red' }}>Provide a value</p>
                      </div>
                    )}
                  </div>
                  <div className="leadtime-info ext-box-1">
                    <p className="p-base-small advnc-p-small">* Bonus Point Max Amount</p>
                    <div className="leadtime-input-main">
                      <input
                        className="leadtime-input advnc-ext-input"
                        id={advExt}
                        name="maxBonusPoints"
                        value={maxBonusPoints}
                        onKeyDown={(e) => {
                          if (e.key !== 'Backspace') {
                            const value = isNaN(parseInt(e.key));
                            value && e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                        }
                      />
                      <label htmlFor={advExt} className="leadtime-input-label">
                        Point(s)
                      </label>
                      {showError && maxBonusPoints === '' && (
                        <div style={{ display: 'flex' }}>
                          <WarningIcon width={15} height={15} />
                          <p style={{ fontSize: '12px', color: 'red' }}>Provide a value</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="leadtime-info ext-box-1">
                    <p className="p-base-small advnc-p-small">* Bonus Point Frequency</p>
                    <div className="leadtime-input-main">
                      <Select
                        id='advanced-booking-frequency-option'
                        dataAttribute='advanced-booking-frequency-option-select'
                        label=""
                        className="advn-ext-select"
                        name="bonusPointFrequency"
                        value={bonusPointFrequency}
                        onChange={(e) =>
                          dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                        }
                        options={frequencyOptions}
                      />
                      {showError && bonusPointFrequency === '' && (
                        <div style={{ display: 'flex', marginTop: '-18px' }}>
                          <WarningIcon width={15} height={15} />
                          <p style={{ fontSize: '12px', color: 'red' }}>Provide a frequency</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      </article>
    </section>
  );
};
