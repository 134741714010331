import { createSlice } from '@reduxjs/toolkit';
import {
  getAllOffers,
  getComments,
  getInclutionTypes,
  getOfferBlackoutRange,
  getOfferCancellationPolicies,
  getOfferCategoriesAndTypes,
  getOfferFrequency,
  getOfferPolicyTerm,
  getPricingDropdown,
  getHotelOffer,
  getTasks,
  getTotalApprovedOfferCount,
  getTotalDraftsOfferCount,
  getTotalOfferCount,
  getTotalRejectedOfferCount,
  getTotalSubmittedOfferCount,
  getSpiritCode,
  getDataSerivceComments,
  getToolboxOffer,
  getWorkItemId,
  getTermsAndConditions,
  getFeatureList,
  getHelpText,
} from '../service/api';
import { distributionChannelReset } from '../pages/distributionChannels/distributionData';
import moment from 'moment';
import set from 'lodash/set';
import { hasUndefinedValues, isEmptyObject } from '../utils/textUtils';

interface OfferType {
  name: string;
  title: string;
  description: string;
}

export interface CommentsParams {
  comments: string;
  author: string;
  date: string;
  status: string;
}

export interface DAKReportParams {
  createdFrom: string;
  createdTo: string;
  submittedFrom: string;
  submittedTo: string;
}
export interface CategoryAndTypes extends OfferType {
  offerTypes: OfferType[];
}
export interface ExtendOfferParam {
  bookingEndDate?: string;
  stayEndDate?: string;
  offerPath?: string;
}
export interface AllOffersParam {
  totalcount: number;
  offers: HotelSpecialOffer[];
}

export interface AlertParams {
  showAlert: boolean;
  status?: number;
  message?: string;
}
export interface GetOfferParams {
  offer?: HotelSpecialOffer;
}

export interface HotelSpecialOffer {
  offerId: string;
  uuid: string;
  promotionName: string;
  recordType: 'Chain' | 'Single';
  hotelName: string[];
  spiritCode: string[];
  startDate: string;
  endDate: string;
  stayEndDate: string;
  submittedDate: string;
  assignedTo: string;
  status: string;
  containingPagePath: string;
}

export interface TasksParams {
  assignee: string;
  promotionName: string;
  offerId: string;
  offerPath: string;
  spiritCode: string[];
  submittedDate: string;
  hotelName: string[];
  recordType: 'Chain' | 'Single';
}

interface FailedRequest {
  isFailed: boolean;
  reason: string;
}

type Offers = {
  [key: string]: any;
};

export interface SpiritCode {
  name: string;
  title: string;
}
export interface CopyParams {
  sourcePath: string;
  destinationPath: string;
  newOfferName: string;
  additionalEmails: string[];
  spiritCodes: string[];
  hotelNames: string[];
  promotionName: string;
}
export interface FetchOffersParams {
  status?: string;
  offerType?: string;
  offerCategory: string;
  startDate?: string;
  endDate?: string;
  offset: number;
  sortBy?: string;
  sortOrder?: string;
  searchText?: string;
}

export type DashboardTabs = 'offers' | 'tasks';

const initialOffer = {
  offerCategory: 'all',
  offerType: '',
  promotionName: '',
  recordType: 'Single',
  owner: 'hyatt owner91',
  hotelName: [],
  spiritCode: [],
  additionalEmails: [],
  attachments: {
    translationfile: '',
    rdrmfile: '',
    offerasset: '',
  },
  dataservices: {
    crNumber: '',
    directSell: [],
    otherRatePlans: [],
    dsAssociateTime: '',
    dsMarketingTime: '',
  },
  inclusions: [],
  blackoutDates: [],
  bookingDayLeadTime: '',
  advancedBooking: {
    advancedBookingEligibility: null,
    advancedBookingDays: '',
    thirdPartyInclusions: null,
    alcoholicBeverages: null,
    hyattBonusPoints: null,
    bonusPoints: '',
    maxBonusPoints: '',
    bonusPointFrequency: '',
  },
  travelPartner: {
    travelPartnerBonus: true,
    travelPartnerName: '',
    travelPartnerPoints: 0,
    travelPartnerFrequency: 'perDay',
    travelPartnerMaxPointsEarned: 0,
  },
  bookingDates: {
    bookingStartDate: '',
    bookingEndDate: '',
    stayStartDate: '',
    stayEndDate: '',
    materialDueDate: '',
  },
  offerValidity: {
    maxStayNights: '',
    minStayNights: '',
    validDays: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
  },
  pricing: {
    offerRack: '',
    offerTypeValue: '',
    qualifiers: '',
    freeNightDetails: '',
    priveValueAdd: ['hyatt-prive'],
    consortiaOptions: [],
  },
  cancellationAndCodes: {
    offerCodes: [],
    depositPolicy: '',
    penaltyPolicy: '',
    cancellationPolicy: '',
  },
  distributionChannel: {
    hyattCom: false,
    voiceAndHotelDirect: false,
    gds: false,
    otaStandalone: false,
    communicationMethod: [],
    distributionChannels: [],
    wholesaleConnected: false,
    wholesaleConnectedSpecificPartner: [],
    wholesaleNotConnected: false,
    wholesaleNotConnectedSpecificPartner: [],
    otaBundled: false,
    otaBundledSpecificPartner: [],
    luxuryConsortiaPartners: false,
    luxuryConsortiaPartnersSpecificPartner: [],
    priveWebsite: false,
  },
  websiteOffers: {
    isOffer: false,
    changeOfferTitle: false,
    defaultOfferTitle: '',
    offerTitle: '',
    offerDescription: '',
    brandManagerID: '',
    materialsDueDate: '',
    translationRequired: false,
    languages: [],
    validBrandManagerID: true,
  },
  termsAndConditions: {
    mainText: '',
    closingText: '',
  },
};
interface InclutionTypes {
  value: string;
  text: string;
}
export type DashboardStates = 'all' | 'submitted' | 'approved' | 'approved' | 'rejected' | 'draft';
export type OfferCategoriesAndType = {
  name: string;
  description: string;
  title: string;
  offerTypes: OfferType[];
};
interface IncultionsParam {
  inclusionDetails: string;
  inclusionFrequency: string;
  inclusionText: string;
  hotelCreditAmount: string;
}
interface BlackoutParams {
  blackoutRange: string;
  blackoutStartDate: Date | undefined;
  blackoutEndDate: Date | undefined;
}
interface DistributionChennalParams {
  [key: string]: string;
  wholeSaleConnectedValue: string;
  wholeSaleNotConnectedValue: string;
  otaBundledValue: string;
  luxuryValue: string;
}

interface ReportingParams {
  createdFrom: Date | undefined;
  createdTo: Date | undefined;
  from: Date | undefined;
  to: Date | undefined;
  reportContentColumnHeader: string;
}

export interface DataServicesParams {
  crNumber: string;
  directSell: DSSalesParams[];
  otherRatePlans: DSSalesParams[];
  dsAssociateTime: string;
  dsMarketingTime: string;
}
export interface DSSalesParams {
  market: string;
  category: string;
  ratePlanCode: string;
}

export type LikeToAdd =
  | 'Hyatt.com offers page and/or change offer title'
  | 'Guarantee/Cancellation Policy'
  | 'Lead Time'
  | 'Inclusions';

export interface HelpText {
  name: string;
  title: string;
  text: string;
}

export interface StoreType {
  offers: Offers;
  allOffers: AllOffersParam | undefined;
  isLoading: boolean;
  isCountLoading: boolean;
  isRequestLoading: boolean;
  isRequestFailed: FailedRequest;
  isSavingDraft: boolean;
  isHotelSpiritCodesLoading: boolean;
  index: number;
  selectedHelpText: string;
  offset: number;
  dashboardState: DashboardStates;
  selectedTab: DashboardTabs;
  offerCategoriesAndTypes: OfferCategoriesAndType[];
  totalOffers: string;
  totalSubmittedOffers: string;
  totalRejectedOffers: string;
  totalApprovedOffers: string;
  totalDraftOffers: string;
  createOfferId: string;
  isAuthroziedToCreateOffer: boolean;
  haveValidAccessToCreateOffer: boolean;
  showError: boolean;
  offerCodeError: boolean;
  rejectError: boolean;
  acceptError: boolean;
  stepBackError: boolean;
  disableNext: boolean;
  basicsAdditionalEmail: string;
  inclutionTypes: InclutionTypes[] | undefined;
  frequencyOptions: InclutionTypes[] | undefined;
  blackoutRangeOptions: InclutionTypes[] | undefined;
  offerCacellationPolicies: InclutionTypes[] | undefined;
  offerCancelationPolicyTerm: InclutionTypes[] | undefined;
  offerDepositPolicies: InclutionTypes[] | undefined;
  offerPricingRackDetails: InclutionTypes[] | undefined;
  offerPricingDropdown: InclutionTypes[] | undefined;
  authUser: string | null;
  canCreateOffer: boolean;
  incultionState: IncultionsParam;
  blackoutState: BlackoutParams;
  offerCodeState: string;
  DistributionChennalcheckedData: DistributionChennalParams;
  page: number;
  reporting: ReportingParams | undefined;
  allTasks: Offers | undefined;
  isUpdate: boolean;
  isCopyOffer: boolean;
  fromReviewAndSubmit: boolean;
  allowRequest: boolean;
  allHotelNames: SpiritCode[] | undefined;
  dsDirectSalesState: DSSalesParams;
  dsOtherRatePlaneState: DSSalesParams;
  dataServiceNotes: string;
  dataServiceActionReason: string;
  dataServiceStepBackReason: string;
  dataServiceAssignee: string;
  showRejectOfferScreen: boolean;
  toolboxOffer: {
    likeToAdd: LikeToAdd[];
  };
  showAcceptOfferScreen: boolean;
  showStepBackOfferScreen: boolean;
  workflowItemId: string;
  helpTexts: any;
  stepBackOptionLoading: boolean;
  isBackRoutesValueExists: boolean;
  offerComments: any;
  isAttachmentLoading: boolean;
  stepBackAssignee: string;
  showDataServiceError: boolean;
  dataServiceActiveTabIndex: number;
  viewOfferActiveTabIndex: number;
  isCommentsLoading: boolean;
  disableSubmit: boolean;
  isAddHotelChian: boolean;
  spiritCodeToBeAdded: string[];
  featureList: any[];
  isLaunchOffer: boolean;
}

const initialState: StoreType = {
  offers: initialOffer,
  allOffers: undefined,
  isLoading: false,
  isCountLoading: false,
  isRequestLoading: false,
  isSavingDraft: false,
  isHotelSpiritCodesLoading: false,
  isRequestFailed: {
    isFailed: false,
    reason: '',
  },
  index: 0,
  selectedHelpText: '',
  offset: 0,
  dashboardState: 'all',
  selectedTab: 'offers',
  offerCategoriesAndTypes: [],
  totalOffers: '',
  totalSubmittedOffers: '',
  totalRejectedOffers: '',
  totalApprovedOffers: '',
  totalDraftOffers: '',
  createOfferId: '',
  inclutionTypes: undefined,
  frequencyOptions: undefined,
  blackoutRangeOptions: undefined,
  offerCacellationPolicies: undefined,
  offerCancelationPolicyTerm: undefined,
  offerDepositPolicies: undefined,
  offerPricingRackDetails: undefined,
  offerPricingDropdown: undefined,
  isAuthroziedToCreateOffer: false,
  haveValidAccessToCreateOffer: false,
  showError: false,
  stepBackError: false,
  rejectError: false,
  acceptError: false,
  offerCodeError: false,
  disableNext: false,
  basicsAdditionalEmail: '',
  authUser: null,
  canCreateOffer: false,
  incultionState: {
    inclusionDetails: '',
    inclusionText: '',
    inclusionFrequency: '',
    hotelCreditAmount: '',
  },
  blackoutState: {
    blackoutRange: '',
    blackoutStartDate: undefined,
    blackoutEndDate: undefined,
  },
  offerCodeState: '',
  DistributionChennalcheckedData: {
    wholeSaleConnectedValue: '',
    wholeSaleNotConnectedValue: '',
    otaBundledValue: '',
    luxuryValue: '',
  },
  page: 0,
  reporting: undefined,
  allTasks: undefined,
  allHotelNames: undefined,
  isUpdate: false,
  isCopyOffer: false,
  fromReviewAndSubmit: false,
  allowRequest: false,
  dsDirectSalesState: {
    market: 'VOLM',
    category: '',
    ratePlanCode: '',
  },
  dsOtherRatePlaneState: {
    market: 'WHLA – ALLOTTED WHOLESALERS',
    category: '',
    ratePlanCode: '',
  },
  dataServiceNotes: '',
  dataServiceActionReason: '',
  dataServiceStepBackReason: '',
  dataServiceAssignee: '',
  showRejectOfferScreen: false,
  toolboxOffer: {
    likeToAdd: [
      'Guarantee/Cancellation Policy',
      'Inclusions',
      'Lead Time',
      'Hyatt.com offers page and/or change offer title',
    ],
  },
  showAcceptOfferScreen: false,
  showStepBackOfferScreen: false,
  workflowItemId: '',
  helpTexts: undefined,
  stepBackOptionLoading: false,
  isBackRoutesValueExists: false,
  offerComments: undefined,
  isAttachmentLoading: false,
  stepBackAssignee: '',
  showDataServiceError: false,
  dataServiceActiveTabIndex: 1,
  viewOfferActiveTabIndex: 1,
  isCommentsLoading: false,
  disableSubmit: false,
  isAddHotelChian: false,
  spiritCodeToBeAdded: [],
  featureList: [],
  isLaunchOffer: false,
};

const offerToolSlice = createSlice({
  name: 'OfferTool',
  initialState,
  reducers: {
    handleChange: (state, action) => {
      const { name, value } = action.payload;
      switch (name) {
        case 'isRequestLoading':
          state.isRequestLoading = value;
          break;
        case 'isRequestFailed':
          state.isRequestFailed = value;
          break;
        case 'isSavingDraft':
          state.isSavingDraft = value;
          break;
        case 'isHotelSpiritCodesLoading':
          state.isHotelSpiritCodesLoading = value;
          break;
        case 'additionalEmailControlled':
          const email = { email: value };
          state.offers.additionalEmails = [...state.offers.additionalEmails, email];
          break;
        case 'hotelChain':
          state.offers.spiritCode = value;
          const selectedHotels: string[] =
            state.offers.recordType === 'Chain'
              ? state.allHotelNames?.filter((hotel) =>
                    state.offers.spiritCode
                      .map((code: string) => code.toLowerCase())
                      .includes(hotel.name)
                  )
                  .map((hotel) => hotel.title)
              : state.offers.hotelName;
          state.offers.hotelName = selectedHotels;
          break;
        case 'spiritCodeToBeAdded':
          state.spiritCodeToBeAdded = value;
          break;
        case 'bookingDayLeadTime':
          state.offers.bookingDayLeadTime = value;
          break;
        case 'whoIsEligable':
          state.offers.pricing.qualifiers = value;
          break;
        case 'percentOff':
          state.offers.pricing.offerRack = value;
          break;
        case 'likeToAdd':
          state.toolboxOffer.likeToAdd = value;
          break;
        case 'attachementsRdrmfile':
          if (state.offers.attachments) {
            state.offers.attachments.rdrmfile = value;
          } else {
            state.offers.attachments = {};
            state.offers.attachments.rdrmfile = value;
          }
          break;
        case 'attachementsOfferasset':
          if (state.offers.attachments) {
            state.offers.attachments.offerasset = value;
          } else {
            state.offers.attachments = {};
            state.offers.attachments.offerasset = value;
          }
          break;
        case 'inclusion':
          break;
        case 'frequency':
          break;
        case 'inclusionDetails':
          break;
        case 'advancedBookingEligibility':
          if (value === 'yes') {
            state.offers.advancedBooking[name] = true;
          } else if (value === 'no') {
            state.offers.advancedBooking[name] = false;
            state.offers.advancedBooking.advancedBookingDays = '';
          }
          break;
        case 'hyattBonusPoints':
          if (value === 'yes') {
            state.offers.advancedBooking[name] = true;
          } else if (value === 'no') {
            state.offers.advancedBooking[name] = false;
            state.offers.advancedBooking.bonusPoints = '';
            state.offers.advancedBooking.maxBonusPoints = '';
          }
          break;
        case 'thirdPartyInclusions':
        case 'alcoholicBeverages':
          if (value === 'yes') {
            state.offers.advancedBooking[name] = true;
          } else if (value === 'no') {
            state.offers.advancedBooking[name] = false;
          }
          break;
        case 'advancedBookingDays':
          if (value > 365) return;
          state.offers.advancedBooking[name] = value;
          break;
        case 'bonusPointFrequency':
        case 'bonusPoints':
        case 'maxBonusPoints':
          // if (value <= 0) return;
          state.offers.advancedBooking[name] = value;
          break;

        case 'travelPartnerName':
        case 'travelPartnerFrequency':
          state.offers.travelPartner[name] = value;
          break;

        case 'travelPartnerMaxPointsEarned':
        case 'travelPartnerPoints':
          state.offers.travelPartner[name] = parseInt(value);
          break;
        case 'travelPartnerBonus':
          if (value === 'yes') {
            state.offers.travelPartner[name] = true;
          } else if (value === 'no') {
            state.offers.travelPartner[name] = false;
          }
          break;
        case 'offerType':
          if (!(state.offers.offerType === '') && !(state.offers.offerType === value)) {
            state.offers.pricing.qualifiers = '';
            state.offers.pricing.offerRack = '';
            state.offers.pricing.offerTypeValue = '';
            state.offers.pricing.freeNightDetails = '';
            state.offerPricingDropdown = undefined;
            state.offers.offerType = value;
          } else {
            state.offers.offerType = value;
          }
          break;
        case 'inclusions':
          state.offers.inclusions = value;
          break;
        case 'incultionState':
          state.incultionState = value;
          break;
        case 'toolboxInclusions':
          state.offers.inclusions = value;
          break;
        case 'blackoutState':
          state.blackoutState = value;
          break;
        case 'bookingStartDate':
          state.offers.bookingDates.bookingStartDate = value;
          break;
        case 'bookingEndDate':
          state.offers.bookingDates.bookingEndDate = value;
          break;
        case 'stayStartDate':
          state.offers.bookingDates.stayStartDate = value;
          break;
        case 'stayEndDate':
          state.offers.bookingDates.stayEndDate = value;
          break;
        case 'crNumber':
          state.offers.dataservices.crNumber = value;
          break;
        case 'offerCodeState':
          state.offerCodeState = value;
          break;
        case 'offerCodes':
          state.offers.cancellationAndCodes.offerCodes = value;
          break;
        case 'offerCacellationPolicies':
          state.offerCacellationPolicies = value;
          break;
        case 'offerCancelationPolicyTerm':
          state.offerCancelationPolicyTerm = value;
          break;
        case 'offerDepositPolicies':
          state.offerDepositPolicies = value;
          break;
        case 'depositPolicy':
        case 'penaltyPolicy':
        case 'cancellationPolicy':
          state.offers.cancellationAndCodes[name] = value;
          break;
        case 'distributionChannel':
          state.offers.distributionChannel = value;
          break;
        case 'validDays':
          state.offers.offerValidity.validDays = value;
          break;
        case 'blackoutDates':
          state.offers.blackoutDates = [...state.offers.blackoutDates, value];
          break;
        case 'maxStayNights':
          state.offers.offerValidity[name] = value;
          break;
        case 'minStayNights':
          state.offers.offerValidity[name] = value;
          break;
        case 'offerTypeValue':
          if (isNaN(value) || value < 0 || value > 100) return state;
          state.offers.pricing.offerTypeValue = value;
          break;
        case 'freeNightDetails':
          state.offers.pricing.freeNightDetails = value;
          break;
        case 'qualifiers':
          if (
            (value === 'nonQualifiedCustomers' && state.offers.offerType === 'percent-off-offer') ||
            state.offers.offerType === 'hyattpriveluxury-offer'
          ) {
            state.offers.distributionChannel =
              distributionChannelReset[value][state.offers.offerType];
            state.offers.pricing.qualifiers = value;
          } else {
            state.offers.pricing.qualifiers = value;
          }
          break;
        case 'priveValueAdd':
          state.offers.pricing.priveValueAdd = value;
          break;
        case 'consortiaOptions':
          state.offers.pricing.consortiaOptions = value;
          break;
        case 'offerRack':
          state.offers.pricing.offerRack = value;
          break;
        case 'blackoutDatesNew':
          state.offers.blackoutDates = value;
          break;

        case 'websiteOffers':
          if (value === 'yes') {
            state.offers[name].isOffer = true;
          } else if (value === 'no') {
            state.offers[name].isOffer = false;
          }
          break;
        case 'haveValidAccessToCreateOffer':
          state.haveValidAccessToCreateOffer = value;
          break;
        case 'isAuthroziedToCreateOffer':
          state.isAuthroziedToCreateOffer = value;
          break;
        case 'disableNext':
          state.disableNext = value;
          break;
        case 'showError':
          state.showError = value;
          break;
        case 'stepBackError':
          state.stepBackError = value;
          break;
        case 'rejectError':
          state.rejectError = value;
          break;
        case 'acceptError':
          state.acceptError = value;
          break;
        case 'offerCodeError':
          state.offerCodeError = value;
          break;
        case 'basicsAdditionalEmail':
          state.basicsAdditionalEmail = value;
          break;
        case 'authUser':
          state.authUser = value;
          break;
        case 'canCreateOffer':
          state.canCreateOffer = value;
          break;
        case 'wholesaleConnected':
        case 'wholesaleNotConnected':
        case 'otaBundled':
        case 'luxuryConsortiaPartners':
        case 'hyattCom':
        case 'voiceAndHotelDirect':
        case 'gds':
        case 'otaStandalone':
        case 'priveWebsite':
          state.offers.distributionChannel[name] = value;
          if (name === 'hyattCom' && !value) {
            state.offers.websiteOffers = {
              isOffer: false,
              changeOfferTitle: false,
              defaultOfferTitle: '',
              offerTitle: '',
              offerDescription: '',
              brandManagerID: '',
              materialsDueDate: '',
              translationRequired: false,
              languages: [],
              validBrandManagerID: true,
            };
          }
          switch (name) {
            case 'wholesaleConnected':
              if (!value) {
                state.offers.distributionChannel['wholesaleConnectedSpecificPartner'] = [];
              }
              break;
            case 'wholesaleNotConnected':
              if (!value) {
                state.offers.distributionChannel['wholesaleNotConnectedSpecificPartner'] = [];
              }
              break;
            case 'otaBundled':
              if (!value) {
                state.offers.distributionChannel['otaBundledSpecificPartner'] = [];
              }
              break;
            case 'luxuryConsortiaPartners':
              if (!value) {
                state.offers.distributionChannel['luxuryConsortiaPartnersSpecificPartner'] = [];
              }
              break;
            default:
              break;
          }
          break;
        case 'wholesaleConnectedSpecificPartner':
        case 'wholesaleNotConnectedSpecificPartner':
        case 'otaBundledSpecificPartner':
        case 'luxuryConsortiaPartnersSpecificPartner':
          state.offers.distributionChannel[name] = value;
          break;
        case 'createOfferId':
          state.createOfferId = value;
          break;
        case 'DistributionChennalcheckedData':
          state.DistributionChennalcheckedData = value;
          break;
        case 'isOffer':
        case 'changeOfferTitle':
        case 'offerTitle':
        case 'offerDescription':
        case 'defaultOfferTitle':
        case 'brandManagerID':
        case 'materialsDueDate':
        case 'translationRequired':
        case 'languages':
        case 'validBrandManagerID':
          state.offers.websiteOffers[name] = value;
          break;
        case 'page':
          state.page = value;
          break;
        case 'reportingCreatedFrom':
          state.reporting = value;
          break;
        case 'isUpdate':
          state.isUpdate = value;
          break;
        case 'isCopyOffer':
          state.isCopyOffer = value;
          break;
        case 'isAddHotelChian':
          state.isAddHotelChian = value;
          break;
        case 'fromReviewAndSubmit':
          state.fromReviewAndSubmit = value;
          break;
        case 'allowRequest':
          state.allowRequest = value;
          break;
        case 'dsNotes':
          state.dataServiceNotes = value;
          break;
        case 'addDsAttachments': {
          state.offers.dataservices.attachments.dataServices.push(...value);
          break;
        }
        case 'dsDirectSell':
          state.offers.dataservices.directSell = value;
          break;
        case 'dsOtherRatePlans':
          state.offers.dataservices.otherRatePlans = value;
          break;
        case 'dsAssociateTime':
          state.offers.dataservices.dsAssociateTime = value;
          break;
        case 'dsMarketingTime':
          state.offers.dataservices.dsMarketingTime = value;
          break;
        case 'dsDirectSalesState':
          state.dsDirectSalesState = value;
          break;
        case 'dsOtherRatePlaneState':
          state.dsOtherRatePlaneState = value;
          break;
        case 'actionReason':
          state.dataServiceActionReason = value;
          break;
        case 'stepBackReason':
          state.dataServiceStepBackReason = value;
          break;
        case 'dataServiceAssignee':
          state.dataServiceAssignee = value;
          break;
        case 'showRejectOfferScreen':
          state.showRejectOfferScreen = value;
          break;
        case 'showAcceptOfferScreen':
          state.showAcceptOfferScreen = value;
          break;
        case 'showStepBackOfferScreen':
          state.showStepBackOfferScreen = value;
          break;
        case 'workflowItemId':
          state.workflowItemId = value;
          break;
        case 'isBackRoutesValueExists':
          state.isBackRoutesValueExists = value;
          break;
        case 'isAttachmentLoading':
          state.isAttachmentLoading = value;
          break;
        case 'stepBackAssignee':
          state.stepBackAssignee = value;
          break;
        case 'showDataServiceError':
          state.showDataServiceError = value;
          break;
        case 'dataServiceActiveTabIndex':
          state.dataServiceActiveTabIndex = value;
          break;
        case 'viewOfferActiveTabIndex':
          state.viewOfferActiveTabIndex = value;
          break;
        case 'disableSubmit':
          state.disableSubmit = value;
          break;
        case 'isCountLoading':
          state.isCountLoading = value;
          break;
        case 'isLaunchOffer':
          state.isLaunchOffer = value;
          break;
        default:
          state.offers[name] = value;
      }
    },
    showHelpText: (state, action) => {
      return { ...state, selectedHelpText: action.payload.helpKey };
    },
    setOfferOffSetCount: (state, action) => {
      state.offset = action.payload;
    },
    setDashboardState: (state, action) => {
      state.dashboardState = action.payload;
    },
    setDashboardTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setOfferStateToDefault: (state) => {
      state.offers = initialOffer;
      state.isCopyOffer = false;
      state.dataServiceAssignee = '';
      state.isLaunchOffer = false;
    },
    setPropertyWebsiteOfferToDefault: (state) => {
      state.offers.websiteOffers = {
        isOffer: false,
        changeOfferTitle: false,
        defaultOfferTitle: '',
        offerTitle: '',
        offerDescription: '',
        brandManagerID: '',
        materialsDueDate: '',
        translationRequired: false,
        languages: [],
        validBrandManagerID: true,
      };
    },
    setDataServiceToDefault: (state) => {
      state.offers.dataservices = {
        crNumber: '',
        directSell: [],
        otherRatePlans: [],
        dsAssociateTime: '',
        dsMarketingTime: '',
      };
      state.dataServiceNotes = '';
      state.dataServiceActionReason = '';
      state.dataServiceStepBackReason = '';
      state.workflowItemId = '';
      state.offerComments = undefined;
      state.stepBackAssignee = '';
      state.showDataServiceError = false;
      state.dataServiceActiveTabIndex = 1;
      state.viewOfferActiveTabIndex = 1;
      state.isBackRoutesValueExists = false;
    },
    setOfferToDefault: (state) => {
      state.offers = initialOffer;
      state.page = 0;
      state.isLaunchOffer = false;
    },
    setRequestStatusToDefault: (state) => {
      state.isRequestFailed = {
        isFailed: false,
        reason: '',
      };
    },
    resetCancellationPolicy: (state) => {
      state.offers.cancellationAndCodes = {
        offerCodes: [],
        depositPolicy: '',
        penaltyPolicy: '',
        cancellationPolicy: '',
      };
    },
    resetOfferComments: (state) => {
      state.offerComments = undefined;
    },
    resetDistributionChennalCheckedData: (state) => {
      state.DistributionChennalcheckedData = {
        wholeSaleConnectedValue: '',
        wholeSaleNotConnectedValue: '',
        otaBundledValue: '',
        luxuryValue: '',
      };
    },
    resetDistributionChennalAndInclutions: (state) => {
      state.offers.distributionChannel = {
        hyattCom: false,
        voiceAndHotelDirect: false,
        gds: false,
        otaStandalone: false,
        communicationMethod: [],
        distributionChannels: [],
        wholesaleConnected: false,
        wholesaleConnectedSpecificPartner: [],
        wholesaleNotConnected: false,
        wholesaleNotConnectedSpecificPartner: [],
        otaBundled: false,
        otaBundledSpecificPartner: [],
        luxuryConsortiaPartners: false,
        luxuryConsortiaPartnersSpecificPartner: [],
        priveWebsite: false,
      };
    },
    resetNonQualifiedDistributionChennalAndInclutions: (state) => {
      state.offers.distributionChannel = {
        hyattCom: true,
        voiceAndHotelDirect: false,
        gds: true,
        otaStandalone: false,
        communicationMethod: [],
        distributionChannels: [],
        wholesaleConnected: false,
        wholesaleConnectedSpecificPartner: [],
        wholesaleNotConnected: false,
        wholesaleNotConnectedSpecificPartner: [],
        otaBundled: false,
        otaBundledSpecificPartner: [],
        luxuryConsortiaPartners: false,
        luxuryConsortiaPartnersSpecificPartner: [],
      };
    },
    enablePriveDistributionChannelDefaultOptions: (state) => {      
      state.offers.distributionChannel = {
        hyattCom: true,
        voiceAndHotelDirect: true,
        gds: true,
        otaStandalone: false,
        wholesaleConnected: false,
        wholesaleConnectedSpecificPartner: [],
        wholesaleNotConnected: false,
        wholesaleNotConnectedSpecificPartner: [],
        otaBundled: false,
        otaBundledSpecificPartner: [],
        luxuryConsortiaPartners: false,
        luxuryConsortiaPartnersSpecificPartner: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferCategoriesAndTypes.pending, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(getOfferCategoriesAndTypes.fulfilled, (state, action) => {
        return { ...state, isLoading: false, offerCategoriesAndTypes: [...action.payload] };
      })
      .addCase(getOfferCategoriesAndTypes.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(getHotelOffer.pending, (state, action) => {})
      .addCase(getHotelOffer.fulfilled, (state, action) => {
        const resp = action.payload;
        if (!resp.offers.distributionChannel) {
          set(resp, 'offers.distributionChannel.priveWebsite', false);
          set(resp, 'offers.distributionChannel.hyattCom', false);
          set(resp, 'offers.distributionChannel.voiceAndHotelDirect', false);
          set(resp, 'offers.distributionChannel.gds', false);
          set(resp, 'offers.distributionChannel.otaStandalone', false);
        }
        if (
          !resp.offers.cancellationAndCodes ||
          isEmptyObject(
            resp.offers.cancellationAndCodes || hasUndefinedValues(resp.offers.cancellationAndCodes)
          )
        ) {
          set(resp, 'offers.cancellationAndCodes.offerCodes', []);
          set(resp, 'offers.cancellationAndCodes.depositPolicy', '');
          set(resp, 'offers.cancellationAndCodes.penaltyPolicy', '');
          set(resp, 'offers.cancellationAndCodes.cancellationPolicy', '');
        }
        if (!resp.offers.cancellationAndCodes.offerCodes) {
          set(resp, 'offers.cancellationAndCodes.offerCodes', []);
        }
        if (!resp.offers.websiteOffers) {
          set(resp, 'offers.websiteOffers.isOffer', false);
          set(resp, 'offers.websiteOffers.offerTitle', '');
          set(resp, 'offers.websiteOffers.changeOfferTitle', '');
          set(resp, 'offers.websiteOffers.offerDescription', '');
          set(resp, 'offers.websiteOffers.brandManagerID', '');
          set(resp, 'offers.websiteOffers.validBrandManagerID', true);
          set(resp, 'offers.websiteOffers.materialsDueDate', '');
          set(resp, 'offers.websiteOffers.translationRequired', false);
          set(resp, 'offers.websiteOffers.languages', []);
        }
        if (resp.offers.websiteOffers) {
          resp.offers.websiteOffers.isOffer = resp.offers.websiteOffers.includeInWebsite;
          resp.offers.websiteOffers.changeOfferTitle = resp.offers.websiteOffers.changeOfferTitle;
          resp.offers.websiteOffers.offerTitle = resp.offers.websiteOffers.offerTitle
            ? resp.offers.websiteOffers.offerTitle
            : '';
          resp.offers.websiteOffers.offerDescription = resp.offers.websiteOffers.offerDescription
            ? resp.offers.websiteOffers.offerDescription
            : '';
          resp.offers.websiteOffers.brandManagerID = resp.offers.websiteOffers.brandManagerID
            ? resp.offers.websiteOffers.brandManagerID
            : '';
          resp.offers.websiteOffers.validBrandManagerID = resp.offers.websiteOffers
            .validBrandManagerID
            ? resp.offers.websiteOffers.validBrandManagerID
            : true;
          resp.offers.websiteOffers.translationRequired = resp.offers.websiteOffers.languages
            ? resp.offers.websiteOffers.languages.length > 0
            : false;
          resp.offers.websiteOffers.languages = resp.offers.websiteOffers.languages
            ? resp.offers.websiteOffers.languages
            : [];
          resp.offers.websiteOffers.materialsDueDate = resp.offers.websiteOffers.materialsDueDate
            ? resp.offers.websiteOffers.materialsDueDate
            : '';
        }
        if (!resp.offers.bookingDates || isEmptyObject(resp.offers.bookingDates)) {
          set(resp, 'offers.bookingDates.bookingStartDate', '');
          set(resp, 'offers.bookingDates.bookingEndDate', '');
          set(resp, 'offers.bookingDates.stayStartDate', '');
          set(resp, 'offers.bookingDates.stayEndDate', '');
        }
        else {
          if (resp.offers.bookingDates.bookingStartDate === undefined) {
            set(resp, 'offers.bookingDates.bookingStartDate', '');
          }
          if (resp.offers.bookingDates.bookingEndDate === undefined) {
            set(resp, 'offers.bookingDates.bookingEndDate', '');
          }
          if (resp.offers.bookingDates.stayStartDate === undefined) {
            set(resp, 'offers.bookingDates.stayStartDate', '');
          }
          if (resp.offers.bookingDates.stayEndDate === undefined) {
            set(resp, 'offers.bookingDates.stayEndDate', '');
          }
        }
        if (!resp.offers.offerValidity) {
          set(resp, 'offers.offerValidity', {
            maxStayNights: '',
            minStayNights: '',
            validDays: [
              'sunday',
              'monday',
              'tuesday',
              'wednesday',
              'thursday',
              'friday',
              'saturday',
            ],
          });
        } else if (!resp.offers.offerValidity.validDays) {
          set(resp, 'offers.offerValidity.validDays', [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
          ]);
        }
        if (!resp.offers.pricing) {
          set(resp, 'offers.pricing.qualifiers', '');
          set(resp, 'offers.pricing.offerRack', '');
          set(resp, 'offers.pricing.offerTypeValue', '');
          set(resp, 'offers.pricing.freeNightDetails', '');
        }
        if (
          (resp.offers.offerType === 'hyatt-prive' ||
            resp.offers.offerType === 'hyatt-luxury-consortia') &&
          !resp.offers.pricing.priveValueAdd
        ) {
          set(resp, 'offers.pricing.priveValueAdd', ['hyatt-prive']);
        }
        if (
          (resp.offers.offerType === 'hyatt-prive' ||
            resp.offers.offerType === 'hyatt-luxury-consortia') &&
          (resp.offers.pricing.consortiaOptions?.length === 0 ||
            !resp.offers.pricing.consortiaOptions)
        ) {
          set(resp, 'offers.pricing.consortiaOptions', []);
        }
        if (!resp?.offers?.pricing?.offerRack) {
          set(resp, 'offers.pricing.offerRack', '');
        }
        if (!resp?.offers?.pricing?.qualifiers) {
          set(resp, 'offers.pricing.qualifiers', '');
        }
        if (!resp?.offers?.pricing?.offerTypeValue) {
          set(resp, 'offers.pricing.offerTypeValue', '');
        }
        if (!resp?.offers?.pricing?.freeNightDetails) {
          set(resp, 'offers.pricing.freeNightDetails', '');
        }
        if (!resp?.offers?.termsAndConditions) {
          set(resp, 'offers.termsAndConditions.closingText', '');
          set(resp, 'offers.termsAndConditions.mainText', '');
        }

        resp.offers?.distributionChannel?.distributionChannels?.map((e: string) => {
          if( e === 'priveWebsite' ){
            resp.offers.distributionChannel.priveWebsite = true;
          }
          if (e === 'hyatt') {
            resp.offers.distributionChannel.hyattCom = true;
          }
          if (e === 'voiceAndHotel') {
            resp.offers.distributionChannel.voiceAndHotelDirect = true;
          }
          if (e === 'gds') {
            resp.offers.distributionChannel.gds = true;
          }
          if (e === 'otaStandalone') {
            resp.offers.distributionChannel.otaStandalone = true;
          }
        });

        const addEmails = resp.offers?.additionalEmails;
        if (!addEmails) {
          resp.offers.additionalEmails = [];
        }
        if (resp.offers.websiteOffers) {
          resp.offers.websiteOffers.isOffer = resp.offers.websiteOffers.includeInWebsite;
        }

        const inclutionsAndFrequency = resp.offers?.inclusions;
        if (!inclutionsAndFrequency) {
          resp.offers.inclusions = [];
        }

        const blackout = resp.offers?.blackoutDates;
        if (!blackout) {
          resp.offers.blackoutDates = [];
        } else {
          //format blackout dates date value
          const arr: {
            blackoutRange: string;
            blackoutStartDate: string;
            blackoutEndDate: string;
          }[] = [];
          blackout.map(
            (e: { blackoutRange: string; blackoutStartDate: string; blackoutEndDate: string }) => {
              try {
                const rage = e.blackoutRange;
                const startDate = moment(
                  e.blackoutStartDate,
                  'YYYY-MM-DD h:mm:ss A Z'
                ).toISOString();
                let endDate = e.blackoutEndDate;
                if (endDate) {
                  endDate = moment(endDate, 'YYYY-MM-DD h:mm:ss A Z').toISOString();
                }
                arr.push({
                  blackoutRange: rage,
                  blackoutStartDate: startDate,
                  blackoutEndDate: endDate,
                });
              } catch (error) {}
            }
          );
          resp.offers.blackoutDates = arr;
        }

        //format the advanced booking object
        const adBookingObj = resp.offers?.advancedBooking;
        if (adBookingObj?.advancedBookingDays === 0) {
          resp.offers.advancedBooking.advancedBookingDays = '';
        }
        if (adBookingObj?.bonusPoints === 0) {
          resp.offers.advancedBooking.bonusPoints = '';
        }
        if (adBookingObj?.maxBonusPoints === 0) {
          resp.offers.advancedBooking.maxBonusPoints = '';
        }
        if (!adBookingObj?.bonusPointFrequency) {
          set(resp, 'offers.advancedBooking.bonusPointFrequency', '');
        }

        //format dates
        const bookingStartAndEndDateObj = resp.offers?.bookingDates;
        if (bookingStartAndEndDateObj?.bookingStartDate) {
          const date = moment(
            bookingStartAndEndDateObj.bookingStartDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.bookingStartDate = date;
        }
        if (bookingStartAndEndDateObj?.bookingEndDate) {
          const date = moment(
            bookingStartAndEndDateObj.bookingEndDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.bookingEndDate = date;
        }
        if (bookingStartAndEndDateObj?.stayStartDate) {
          const date = moment(
            bookingStartAndEndDateObj.stayStartDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.stayStartDate = date;
        }
        if (bookingStartAndEndDateObj?.stayEndDate) {
          const date = moment(
            bookingStartAndEndDateObj.stayEndDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.stayEndDate = date;
        }

        //format the disrtribution chennal object
        const distributionObj = resp.offers?.distributionChannel;
        if (!distributionObj?.wholesaleConnectedSpecificPartner) {
          resp.offers.distributionChannel.wholesaleConnectedSpecificPartner = [];
        }
        if (!distributionObj?.wholesaleNotConnectedSpecificPartner) {
          resp.offers.distributionChannel.wholesaleNotConnectedSpecificPartner = [];
        }
        if (!distributionObj?.otaBundledSpecificPartner) {
          resp.offers.distributionChannel.otaBundledSpecificPartner = [];
        }
        if (!distributionObj?.luxuryConsortiaPartnersSpecificPartner) {
          resp.offers.distributionChannel.luxuryConsortiaPartnersSpecificPartner = [];
        }
        if (!distributionObj?.wholesaleConnected || distributionObj?.wholesaleConnected === 'false') {
          resp.offers.distributionChannel.wholesaleConnected = false;
        } else {
          resp.offers.distributionChannel.wholesaleConnected = true;
        }
        if (!distributionObj?.wholesaleNotConnected || distributionObj?.wholesaleNotConnected === 'false') {
          resp.offers.distributionChannel.wholesaleNotConnected = false;
        } else {
          resp.offers.distributionChannel.wholesaleNotConnected = true;
        }
        if (!distributionObj?.luxuryConsortiaPartners || distributionObj?.luxuryConsortiaPartners === 'false') {
          resp.offers.distributionChannel.luxuryConsortiaPartners = false;
        } else {
          resp.offers.distributionChannel.luxuryConsortiaPartners = true;
        }
        if (!distributionObj?.otaBundled || distributionObj?.otaBundled === 'false') {
          resp.offers.distributionChannel.otaBundled = false;
        } else {
          resp.offers.distributionChannel.otaBundled = true;
        }
        const dataServiceObj = resp.offers?.dataservices;
        if (dataServiceObj) {
          const dataService = dataServiceObj;
          const directSalesArr: DSSalesParams[] = [];
          if (dataService.directSell) {
            for (let i = 0; i < Object.keys(dataService.directSell).length; i++) {
              const item = dataService.directSell[i];
              if (item) {
                directSalesArr.push({
                  market: item.market,
                  category: item.category,
                  ratePlanCode: item.ratePlanCode,
                });
              }
            }
          }
          const otherRatePlanArr: DSSalesParams[] = [];
          if (dataService.otherRatePlans) {
            for (let i = 0; i < Object.keys(dataService.otherRatePlans).length; i++) {
              const item = dataService.otherRatePlans[i];
              if (item) {
                otherRatePlanArr.push({
                  market: item.market,
                  category: item.category,
                  ratePlanCode: item.ratePlanCode,
                });
              }
            }
          }
          resp.offers.dataservices = {
            attachments: dataServiceObj.attachments ?? { dataServices: [] },
            crNumber: dataService.crNumber === 'undefined' ? '' : dataService.crNumber,
            directSell: directSalesArr,
            otherRatePlans: otherRatePlanArr,
            dsMarketingTime:
              dataService.dsMarketingTime === 'undefined' ? '' : dataService.dsMarketingTime,
            dsAssociateTime:
              dataService.dsAssociateTime === 'undefined' ? '' : dataService.dsAssociateTime,
          };
        } else {
          resp.offers.dataservices = {
            attachments: {
              dataServices: []
            },
            crNumber: '',
            directSell: [],
            otherRatePlans: [],
            dsAssociateTime: '',
            dsMarketingTime: '',
          };
        }
        return { ...state, isLoading: false, offers: resp.offers };
      })
      .addCase(getHotelOffer.rejected, (state, action) => {
        return { ...state, isLoading: false };
      })
      .addCase(getToolboxOffer.pending, (state, action) => {})
      .addCase(getToolboxOffer.fulfilled, (state, action) => {
        const resp = action.payload;
        if (!resp.offers.bookingDates || isEmptyObject(resp.offers.bookingDates)) {
          set(resp, 'offers.bookingDates.bookingStartDate', '');
          set(resp, 'offers.bookingDates.bookingEndDate', '');
          set(resp, 'offers.bookingDates.stayStartDate', '');
          set(resp, 'offers.bookingDates.stayEndDate', '');
        }
        else {
          if (resp.offers.bookingDates.bookingStartDate === undefined) {
            set(resp, 'offers.bookingDates.bookingStartDate', '');
          }
          if (resp.offers.bookingDates.bookingEndDate === undefined) {
            set(resp, 'offers.bookingDates.bookingEndDate', '');
          }
          if (resp.offers.bookingDates.stayStartDate === undefined) {
            set(resp, 'offers.bookingDates.stayStartDate', '');
          }
          if (resp.offers.bookingDates.stayEndDate === undefined) {
            set(resp, 'offers.bookingDates.stayEndDate', '');
          }
        }
        const addEmails = resp.offers?.additionalEmails;
        if (!addEmails) {
          resp.offers.additionalEmails = [];
        }
        const toolboxInclutions = resp.offers?.inclusions;
        if (!toolboxInclutions) {
          resp.offers.inclusions = [];
        }
        if (
          Array.isArray(toolboxInclutions) &&
          toolboxInclutions.length === 1 &&
          JSON.stringify(toolboxInclutions) === JSON.stringify([{}])
        ) {
          resp.offers.inclusions = [];
        }
        if (!resp?.offers?.pricing?.offerRack) {
          set(resp, 'offers.pricing.offerRack', '');
        }
        if (!resp?.offers?.pricing?.eligibility) {
          set(resp, 'offers.pricing.qualifiers', '');
        } else {
          resp.offers.pricing.qualifiers = resp?.offers?.pricing?.eligibility;
        }
        if (
          !resp.offers.cancellationAndCodes ||
          isEmptyObject(
            resp.offers.cancellationAndCodes || hasUndefinedValues(resp.offers.cancellationAndCodes)
          )
        ) {
          set(resp, 'offers.cancellationAndCodes.offerCodes', []);
          set(resp, 'offers.cancellationAndCodes.depositPolicy', '');
          set(resp, 'offers.cancellationAndCodes.penaltyPolicy', '');
          set(resp, 'offers.cancellationAndCodes.cancellationPolicy', '');
        }
        if (!resp?.offers?.offerValidity?.validDays) {
          set(resp, 'offers.offerValidity.validDays', [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
          ]);
        }
        if (!resp.offers.cancellationAndCodes.offerCodes) {
          set(resp, 'offers.cancellationAndCodes.offerCodes', []);
        }
        const inclutionsAndFrequency = resp.offers?.inclusions;
        if (!inclutionsAndFrequency) {
          resp.offers.inclusions = [];
        }
        resp.offers.bookingDayLeadTime = resp.offers?.advancedBooking?.advancedBookingDays;

        if (!resp.offers.websiteOffers) {
          set(resp, 'offers.websiteOffers.isOffer', false);
          set(resp, 'offers.websiteOffers.changeOfferTitle', false);
          set(resp, 'offers.websiteOffers.offerTitle', '');
          set(resp, 'offers.websiteOffers.materialsDueDate', '');
          set(resp, 'offers.websiteOffers.languages', []);
          set(resp, 'offers.websiteOffers.translationRequired', false);
          set(resp, 'offers.websiteOffers.validBrandManagerID', true);
        }
        if (resp.offers.websiteOffers) {
          resp.offers.websiteOffers.isOffer = resp.offers.websiteOffers.includeInWebsite;
          resp.offers.websiteOffers.changeOfferTitle = resp.offers.websiteOffers.changeOfferTitle;
          resp.offers.websiteOffers.offerTitle = resp.offers.websiteOffers.offerTitle
            ? resp.offers.websiteOffers.offerTitle
            : '';
          resp.offers.websiteOffers.materialsDueDate =
            resp.offers.websiteOffers.materialsDueDate === 'undefined'
              ? ''
              : resp.offers.websiteOffers.materialsDueDate;
          resp.offers.websiteOffers.translationRequired = resp.offers.websiteOffers.languages
            ? resp.offers.websiteOffers.languages.length > 0
            : false;
          resp.offers.websiteOffers.languages = resp.offers.websiteOffers.languages
            ? resp.offers.websiteOffers.languages
            : [];
          resp.offers.websiteOffers.validBrandManagerID = resp.offers.websiteOffers
            .validBrandManagerID
            ? resp.offers.websiteOffers.validBrandManagerID
            : true;
        }
        //format dates
        const bookingStartAndEndDateObj = resp.offers?.bookingDates;
        if (bookingStartAndEndDateObj.bookingStartDate) {
          const date = moment(
            bookingStartAndEndDateObj.bookingStartDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.bookingStartDate = date;
        }
        if (bookingStartAndEndDateObj.bookingEndDate) {
          const date = moment(
            bookingStartAndEndDateObj.bookingEndDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.bookingEndDate = date;
        }
        if (bookingStartAndEndDateObj.stayStartDate) {
          const date = moment(
            bookingStartAndEndDateObj.stayStartDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.stayStartDate = date;
        }
        if (bookingStartAndEndDateObj.stayEndDate) {
          const date = moment(
            bookingStartAndEndDateObj.stayEndDate,
            'YYYY-MM-DD h:mm:ss A Z'
          ).toISOString();
          resp.offers.bookingDates.stayEndDate = date;
        }
        const dataServiceObj = resp.offers?.dataservices;
        if (dataServiceObj) {
          const dataService = dataServiceObj;
          const directSalesArr: DSSalesParams[] = [];
          if (dataService.directSell) {
            for (let i = 0; i < Object.keys(dataService.directSell).length; i++) {
              const item = dataService.directSell[i];
              if (item) {
                directSalesArr.push({
                  market: item.market,
                  category: item.category,
                  ratePlanCode: item.ratePlanCode,
                });
              }
            }
          }
          const otherRatePlanArr: DSSalesParams[] = [];
          if (dataService.otherRatePlans) {
            for (let i = 0; i < Object.keys(dataService.otherRatePlans).length; i++) {
              const item = dataService.otherRatePlans[i];
              if (item) {
                otherRatePlanArr.push({
                  market: item.market,
                  category: item.category,
                  ratePlanCode: item.ratePlanCode,
                });
              }
            }
          }
          resp.offers.dataservices = {
            attachments: dataServiceObj.attachments ?? { dataServices: [] },
            crNumber: dataService.crNumber === 'undefined' ? '' : dataService.crNumber,
            directSell: directSalesArr,
            otherRatePlans: otherRatePlanArr,
            dsMarketingTime:
              dataService.dsMarketingTime === 'undefined' ? '' : dataService.dsMarketingTime,
            dsAssociateTime:
              dataService.dsAssociateTime === 'undefined' ? '' : dataService.dsAssociateTime,
          };
        } else {
          resp.offers.dataservices = {
            attachments: {
              dataServices: []
            },
            crNumber: '',
            directSell: [],
            otherRatePlans: [],
            dsAssociateTime: '',
            dsMarketingTime: '',
          };
        }
        return { ...state, isLoading: false, offers: resp.offers };
      })
      .addCase(getToolboxOffer.rejected, (state, action) => {
        return { ...state, isLoading: false };
      })

      .addCase(getTasks.pending, (state, action) => {})
      .addCase(getTasks.fulfilled, (state, action) => {
        return { ...state, isLoading: false, allTasks: action.payload };
      })
      .addCase(getTasks.rejected, (state, action) => {
        return { ...state, isLoading: false };
      })
      .addCase(getSpiritCode.pending, (state, action) => {})
      .addCase(getSpiritCode.fulfilled, (state, action) => {
        return { ...state, isLoading: false, allHotelNames: action.payload };
      })
      .addCase(getSpiritCode.rejected, (state, action) => {
        return { ...state, isLoading: false };
      })
      .addCase(getAllOffers.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getAllOffers.fulfilled, (state, action) => {
        return { ...state, isLoading: false, allOffers: action.payload };
      })
      .addCase(getAllOffers.rejected, (state, action) => {
        return { ...state, isLoading: false, allOffers: undefined };
      })
      .addCase(getTotalOfferCount.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getTotalOfferCount.fulfilled, (state, action) => {
        return { ...state, isLoading: false, totalOffers: action.payload.totalcount };
      })
      .addCase(getTotalOfferCount.rejected, (state, action) => {
        return { ...state, isLoading: false, totalOffers: '' };
      })
      .addCase(getTotalSubmittedOfferCount.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getTotalSubmittedOfferCount.fulfilled, (state, action) => {
        return { ...state, isLoading: false, totalSubmittedOffers: action.payload.totalcount };
      })
      .addCase(getTotalSubmittedOfferCount.rejected, (state, action) => {
        return { ...state, isLoading: false, totalSubmittedOffers: '' };
      })
      .addCase(getTotalRejectedOfferCount.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getTotalRejectedOfferCount.fulfilled, (state, action) => {
        return { ...state, isLoading: false, totalRejectedOffers: action.payload.totalcount };
      })
      .addCase(getTotalRejectedOfferCount.rejected, (state, action) => {
        return { ...state, isLoading: false, totalRejectedOffers: '' };
      })
      .addCase(getTotalApprovedOfferCount.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getTotalApprovedOfferCount.fulfilled, (state, action) => {
        return { ...state, isLoading: false, totalApprovedOffers: action.payload.totalcount };
      })
      .addCase(getTotalApprovedOfferCount.rejected, (state, action) => {
        return { ...state, isLoading: false, totalApprovedOffers: '' };
      })
      .addCase(getTotalDraftsOfferCount.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getTotalDraftsOfferCount.fulfilled, (state, action) => {
        return { ...state, totalDraftOffers: action.payload.totalcount };
      })
      .addCase(getTotalDraftsOfferCount.rejected, (state, action) => {
        return { ...state, totalDraftOffers: '' };
      })
      .addCase(getFeatureList.fulfilled, (state, action) => {
        return { ...state, featureList: action.payload.featureList };
      })
      .addCase(getInclutionTypes.pending, (state) => {
        return { ...state };
      })
      .addCase(getInclutionTypes.fulfilled, (state, action) => {
        const newIncultionOptions = action.payload.map((item: { value: string; text: string }) => ({
          value: item.value,
          label: item.text,
        }));
        newIncultionOptions.unshift({ label: 'Select an inclusion', value: '' });
        return { ...state, isLoading: false, inclutionTypes: newIncultionOptions };
      })
      .addCase(getInclutionTypes.rejected, (state) => {
        return { ...state, isLoading: false, inclutionTypes: undefined };
      })
      .addCase(getOfferFrequency.pending, (state) => {
        return { ...state };
      })
      .addCase(getOfferFrequency.fulfilled, (state, action) => {
        const newFrequencyOptions = action.payload.map((item: { value: string; text: string }) => ({
          value: item.value,
          label: item.text,
        }));
        newFrequencyOptions.unshift({ label: 'Select a frequency', value: '' });
        return { ...state, isLoading: false, frequencyOptions: newFrequencyOptions };
      })
      .addCase(getOfferFrequency.rejected, (state) => {
        return { ...state, isLoading: false, frequencyOptions: undefined };
      })
      .addCase(getOfferBlackoutRange.pending, (state) => {
        return { ...state };
      })
      .addCase(getOfferBlackoutRange.fulfilled, (state, action) => {
        const newBlackoutOptions = action.payload.map((item: { value: string; text: string }) => ({
          value: item.value,
          label: item.text,
        }));
        newBlackoutOptions.unshift({ label: 'Select a range', value: '' });
        return { ...state, isLoading: false, blackoutRangeOptions: newBlackoutOptions };
      })
      .addCase(getOfferBlackoutRange.rejected, (state) => {
        return { ...state, isLoading: false, blackoutRangeOptions: undefined };
      })
      .addCase(getOfferCancellationPolicies.pending, (state) => {
        return { ...state };
      })
      .addCase(getOfferCancellationPolicies.fulfilled, (state, action) => {
        const CancellationPolicyOption = action.payload.map(
          (item: { value: string; text: string }) => ({
            value: item.value,
            label: item.text,
          })
        );

        let filteredCancellationPolicy: any = [];
        if (
          state.offers.offerType === 'percent-off-offer' &&
          state.offers.pricing.qualifiers === 'nonQualifiedCustomers'
        ) {
          filteredCancellationPolicy = CancellationPolicyOption.filter(
            (item: any) => item.value === '72-hours'
          );
          filteredCancellationPolicy.unshift({ label: 'Select a cancel policy', value: '' });
        } else {
          filteredCancellationPolicy = CancellationPolicyOption;
          filteredCancellationPolicy.unshift({ label: 'Select a cancel policy', value: '' });
        }
        return { ...state, isLoading: false, offerCacellationPolicies: filteredCancellationPolicy };
      })
      .addCase(getOfferCancellationPolicies.rejected, (state) => {
        return { ...state, isLoading: false, offerCacellationPolicies: undefined };
      })
      .addCase(getOfferPolicyTerm.pending, (state) => {
        return { ...state };
      })
      .addCase(getOfferPolicyTerm.fulfilled, (state, action) => {
        const CancellationPolicyTermOption = action.payload.map(
          (item: { value: string; text: string }) => ({
            value: item.value,
            label: item.text,
          })
        );
        CancellationPolicyTermOption.unshift({ label: '', value: '' });
        return {
          ...state,
          isLoading: false,
          offerCancelationPolicyTerm: CancellationPolicyTermOption,
        };
      })
      .addCase(getOfferPolicyTerm.rejected, (state) => {
        return { ...state, isLoading: false, offerCancelationPolicyTerm: undefined };
      })
      .addCase(getPricingDropdown.pending, (state) => {
        return { ...state };
      })
      .addCase(getPricingDropdown.fulfilled, (state, action) => {
        const offerPricingDropdownOptions = action.payload.map(
          (item: { value: string; text: string }) => ({
            value: item.value,
            label: item.text,
          })
        );
        return {
          ...state,
          isLoading: false,
          offerPricingDropdown: offerPricingDropdownOptions,
        };
      })
      .addCase(getPricingDropdown.rejected, (state) => {
        return { ...state, isLoading: false, offerPricingRackDetails: undefined };
      })
      .addCase(getComments.pending, (state, action) => {
        return { ...state, isCommentsLoading: true };
      })
      .addCase(getComments.fulfilled, (state, action) => {
        const offerComments = action.payload;
        return { ...state, offerComments: offerComments, isCommentsLoading: false };
      })
      .addCase(getComments.rejected, (state, action) => {
        return { ...state, isCommentsLoading: false };
      })
      .addCase(getTermsAndConditions.fulfilled, (state, action) => {
        return {
          ...state,
          offers: {
            ...state.offers,
            termsAndConditions: action.payload.termsAndConditions,
          },
        };
      })
      .addCase(getDataSerivceComments.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getDataSerivceComments.fulfilled, (state, action) => {
        let firstObject = '';
        for (const key in action.payload) {
          if (typeof action.payload[key] === 'object') {
            if (action.payload[key]['jcr:description'].split('/')[0] === 'DSNotes') {
              //there a prefix for all the comments if the prefix is DSNotes it means its a data service note.
              //remove the prefix from the comment and show only the comment
              firstObject = action.payload[key]['jcr:description']?.split('/').slice(1).join('/');
              break;
            }
          }
        }
        return {
          ...state,
          isLoading: false,
          dataServiceNotes: firstObject,
        };
      })
      .addCase(getDataSerivceComments.rejected, (state, action) => {
        return { ...state, isLoading: false, totalDraftOffers: '' };
      })
      .addCase(getWorkItemId.pending, (state, action) => {
        return { ...state };
      })
      .addCase(getWorkItemId.fulfilled, (state, action) => {
        const getWorkId = () => {
          for (const key in action.payload) {
            const item = action.payload[key];
            if (item.actionIds && item.actionIds.includes('workflow-stepback')) {
              return item.workItemId;
            }
          }
        };
        return { ...state, isLoading: false, workflowItemId: getWorkId() };
      })
      .addCase(getWorkItemId.rejected, (state, action) => {
        return { ...state, isLoading: false, totalDraftOffers: '' };
      })
      .addCase(getHelpText.pending, (state) => {
        return { ...state };
      })
      .addCase(getHelpText.fulfilled, (state, action) => {
        return { ...state, helpTexts: action.payload };
      })
      .addCase(getHelpText.rejected, (state) => {
        return { ...state };
      });
  },
});

export const {
  handleChange,
  showHelpText,
  setOfferOffSetCount,
  setDashboardState,
  setDashboardTab,
  setOfferStateToDefault,
  setPropertyWebsiteOfferToDefault,
  setDataServiceToDefault,
  setOfferToDefault,
  setRequestStatusToDefault,
  resetCancellationPolicy,
  resetOfferComments,
  resetDistributionChennalAndInclutions,
  resetNonQualifiedDistributionChennalAndInclutions,
  resetDistributionChennalCheckedData,
} = offerToolSlice.actions;
export default offerToolSlice.reducer;
