export const LeftArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="16"
      height="16"
      viewBox="0 0 256 256"
    >
      <defs></defs>
      <g
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <polygon
          points="66.79,0 68.21,1.42 24.64,45 68.21,88.58 66.79,90 21.79,45 "
          transform="  matrix(1 0 0 1 0 0) "
        />
      </g>
    </svg>
  );
};
