/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { OfferData } from './offerData';
import nlsCommon from '../../../nls/common.json';
import './offerDetails.css';
import {
  getHotelOffer,
  getOfferCategoriesAndTypes,
  getTermsAndConditions,
  getToolboxOffer,
} from '../../../service/api';
import { handleChange } from '../../../store/offerToolSlice';
import { convertToMMDDYYYY } from '../../../utils/dateAndTimeUtils';
import MenuEdit from './assets/MenuEdit';
import {
  extractOfferId,
  extractOfferCategory,
  extractPath,
} from '../../../utils/fileUtils';
import ShimmerLoading from '../../atoms/Shimmer';
import { Title } from '../../atoms/Title';
import LoadingSpinner from '../../atoms/Spinner';
import { store } from '../../../store/store';
import Banner from '../../atoms/Banner';
import { options } from '../../../pages/offrerCodeAndCancellationPolicy/useDropdownOfferCodeOptions';
import SeeMore from './assets/SeeMore';



export const GroupedOfferDetails = () => {
  const location = useLocation();

  const offerId = extractOfferId(location.search);
  const offerCategoryParam = extractOfferCategory(location.search);
  const pagePath = extractPath(location.search);
  const [typeIs, setTypeIs] = useState('');
  const [showSeeMoreSpiritCodeModal, setShowSeeMoreSpiritCodeModal] = useState(false);

  const {
    offers,
    disableSubmit,
    offerCategoriesAndTypes,
    isRequestLoading,
    isRequestFailed,
    offerPricingDropdown,
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    try {
      if (offerCategoriesAndTypes.length === 0) {
        dispatch(getOfferCategoriesAndTypes());
      }
      const category = offerCategoriesAndTypes.filter(
        (e: any) => e.name === offers?.offerCategory && e
      );
      const type = category[0]?.offerTypes.filter((e: any) => e.name === offers?.offerType && e);
      setTypeIs(type[0]?.title);
    } catch (error) {
      setTypeIs(offers?.offerType);
    }
  }, [dispatch, offerCategoriesAndTypes, offers]);

  const getOfferPricingText = (value: string): string | null => {
    if (!offerPricingDropdown) {
      const pricingDetails =
        offers.pricing?.offerRack?.charAt(0).toUpperCase() + offers.pricing?.offerRack?.slice(1);
      return pricingDetails?.toString().replaceAll('-', ' ');
    }
    for (let item of offerPricingDropdown) {
      if (item.value === value) {
        return item.label;
      }
    }
    return null;
  };

  const isValidOffer = () => {
    const offerObj = store.getState().offerTool.offers;
    dispatch(handleChange({ name: 'disableSubmit', value: false }));
    const arr = [];
    if (location.pathname.includes('/review-and-submit')) {
      if (offerObj) {
        if (offerCategoryParam === 'hotel-special-offer') {
          const {
            advancedBooking: { advancedBookingEligibility },
            bookingDates: { bookingStartDate, bookingEndDate },
            pricing: { offerRack, qualifiers },
            cancellationAndCodes: { depositPolicy },
            distributionChannel,
            websiteOffers: { isOffer, offerTitle },
          } = offerObj;
          if (advancedBookingEligibility === null) {
            arr.push('advancedBooking');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (bookingStartDate === '' || bookingEndDate === '') {
            arr.push('bookingDates');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (offerRack === '' || qualifiers === '') {
            arr.push('pricing');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (depositPolicy === '') {
            arr.push('offerCode');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          const hasTrueValue = Object.values(distributionChannel).some((value) => value === true);
          if (!hasTrueValue) {
            arr.push('distributionChannel');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (isOffer && offerTitle === '') {
            arr.push('websiteOffer');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
        } else {
          const {
            bookingDates: { bookingStartDate, bookingEndDate },
          } = offerObj;
          if (bookingStartDate === '' || bookingEndDate === '') {
            arr.push('bookingDates');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
        }
      }
    }
  };

  useEffect(() => {
    if (offerId) {
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      if (offerCategoryParam === 'toolbox-offer') {
        dispatch(getToolboxOffer({ path: pagePath })).then(({ meta }) => {
          dispatch(getTermsAndConditions(pagePath));
          dispatch(handleChange({ name: 'isRequestLoading', value: false }));
          if (meta.requestStatus === 'rejected') {
            dispatch(
              handleChange({
                name: 'isRequestFailed',
                value: {
                  isFailed: true,
                  reason: 'No data found for resource given identifier.',
                },
              })
            );
          }
          isValidOffer();
        });
      } else {
        dispatch(
          getHotelOffer({
            path: pagePath,
          })
        ).then(({ meta }) => {
          dispatch(getTermsAndConditions(pagePath));
          dispatch(handleChange({ name: 'isRequestLoading', value: false }));
          if (meta.requestStatus === 'rejected') {
            dispatch(
              handleChange({
                name: 'isRequestFailed',
                value: {
                  isFailed: true,
                  reason: 'No data found for resource given identifier.',
                },
              })
            );
          }
          isValidOffer();
        });
      }
    }
  }, [offerId]);

  const getDistributionChennals = () => {
    const arr: string[] = [];
    if(offers?.distributionChannel){
      const { gds, hyattCom, voiceAndHotelDirect, otaStandalone } = offers?.distributionChannel;
      if (hyattCom) {
        arr.push('Hyatt.com');
      }
      if (voiceAndHotelDirect) {
        arr.push('Voice and Hotel Direct');
      }
      if (gds) {
        arr.push('GDS');
      }
      if (otaStandalone) {
        arr.push('OTA - Standalone (OTA Public Rates');
      }
    }
    return arr;
  };

  const getBookingTravelDates = () => {
    const arr: string[] = [];
    if (offers?.bookingDates?.bookingStartDate) {
      arr.push('Booking Start Date : ' + convertToMMDDYYYY(offers.bookingDates.bookingStartDate))
    };
    if (offers?.bookingDates?.bookingEndDate) {
      arr.push('Booking End Date : ' + convertToMMDDYYYY(offers.bookingDates.bookingEndDate))
    };
    if (offers?.bookingDates?.stayStartDate) {
      arr.push('Stay Start Date : ' + convertToMMDDYYYY(offers.bookingDates.stayStartDate))
    };
    if (offers?.bookingDates?.stayEndDate) {
      arr.push('Stay End Date : ' + convertToMMDDYYYY(offers.bookingDates.stayEndDate))
    };
    return arr
  }

  const getFormattedDays = () => {
    try {
      if (offers?.offerValidity?.validDays?.length > 0) {
        const days: string[] = [];
        offers?.offerValidity?.validDays?.map((e: string) => {
          days.push(e.charAt(0).toUpperCase() + e.slice(1));
        });
        const weekdayOrder: string[] = [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ];
        const sortedDays = days?.sort((a, b) => {
          const indexA = weekdayOrder.indexOf(a);
          const indexB = weekdayOrder.indexOf(b);
          return indexA - indexB;
        });
        return sortedDays.join(', ');
      }
    } catch (error) { }
  };

  const seeAdditionDetails = () => {
    const originalURL = window.location.href;
    const modifiedURL = new URL(originalURL);
    modifiedURL.searchParams.append('more', 'true');
    window.location.href = decodeURIComponent(modifiedURL.toString());
  }

  const getLabelForDepositPolicyOption = (value: any) => {
    if (value === 'House Policy') {
      return 'House Policy';
    } else {
      const option = options.depositPolicyOptions.find((option) => option.value === value);
      return option ? option.label : 'Label not found';
    }
  };

  const getLabelForCancelPolicyOption = (value: any) => {
    if (value === 'House Policy') {
      return 'House Policy';
    } else {
      const option = options.cancelPolicyOptions.find((option) => option.value === value);
      return option ? option.label : 'Label not found';
    }
  };

  const blackOutDateData = () => {
    const blackOutData: string[] = [];
    let dateString: string;
    if (offers?.blackoutDates?.length > 0) {

      offers?.blackoutDates?.forEach(
        (date: {
          blackoutRange: string;
          blackoutStartDate: string;
          blackoutEndDate: string;
        }) => {
          if (date.blackoutRange === 'date-range') {
            dateString = 'Start Date: ' + convertToMMDDYYYY(date.blackoutStartDate) + ' - ' + 'End Date : ' + convertToMMDDYYYY(date.blackoutEndDate);
          }
          else {
            dateString = 'Date: ' + convertToMMDDYYYY(date.blackoutStartDate)
          }
          blackOutData.push(dateString);
        })
      return blackOutData
    }
    else {
      return 'N/A'
    }
  }
  
  const hotelNameDisplay = offers?.hotelName?.map((hotel: string) => hotel.replace(/,/g, ' -')) + ' , ' + offers.spiritCode.toString().toUpperCase();
  const minMaxStayDisplay = offers?.offerValidity?.minStayNights ? offers?.offerValidity?.minStayNights
    + ' Nights / ' + offers?.offerValidity?.maxStayNights + ' Nights' : 'N/A';

  let enableSeeMoreOption = false;
  const getSpiritCodeArray = () => {
    const hotelArr = offers?.hotelName;    
    if (hotelArr.length > 3) {
      enableSeeMoreOption = true;
    }
    if(offers?.recordType === 'Single'){
      return offers?.hotelName+' - '+offers.spiritCode.toString().toUpperCase()
    }else{
      return hotelArr.slice(0, 3).map((hotel: string) => hotel.replace(/,/g, ' -'));
    }
  };

  const onSeeMoreSpiritCodeClick = () => {
    setShowSeeMoreSpiritCodeModal(true);
  };

  return (
    <div>
      {showSeeMoreSpiritCodeModal && (
        <SeeMore
          handleClose={() => {
            setShowSeeMoreSpiritCodeModal(false);
          }}
          onlySpiritCodes={false}
          data={hotelNameDisplay}
        />
      )}
      {disableSubmit && (
        <div className="error-message-component">
          <Banner
            title={nlsCommon.REQUIRED_FIELDS}
            message={nlsCommon.REQUIRED_FIELDS_ARE_EMPTY_IN_DRAFT_OFFERS}
          />
        </div>
      )}
      {!isRequestLoading ? (
        !isRequestFailed.isFailed ? (
          <div className="root-view">
            <OfferData
              isCollapsed={true}
              isEditable={false}
              title="Group 1"
              children={
                <>
                  <MenuEdit
                    name={offers?.recordType === 'Single' ? 'Hotel Name' : 'Hotel Name(s)'}
                    info={getSpiritCodeArray()}
                    enableSeeMore={enableSeeMoreOption}
                    onSeeMoreSpiritCodeClick={onSeeMoreSpiritCodeClick}
                  />
                  <MenuEdit name="Offer Name" info={offers?.promotionName} />
                  <MenuEdit name="Offer ID" info={offers?.offerId} />
                  <MenuEdit name="Advanced Booking (Lead Time)" info={offers.bookingDayLeadTime ? offers?.bookingDayLeadTime + ' Days'
                    : 'N/A'} />
                  <MenuEdit name="Qualified or Non Qualified" info={offers?.pricing?.qualifiers
                    ? offers?.pricing?.qualifiers === 'qualifiedCustomers'
                      ? 'Qualified Customers'
                      : 'Non-Qualified Customers'
                    : 'N/A'} />
                </>
              }
            />
            <OfferData
              isCollapsed={true}
              title="Group 2"
              isEditable={false}

              children={
                <>
                  <MenuEdit name="Pricing" info={getOfferPricingText(offers.pricing?.offerRack)} />
                  <MenuEdit name="Guarantee / Deposit Policy" info={getLabelForDepositPolicyOption(
                    offers?.cancellationAndCodes?.depositPolicy
                  )} />
                  <MenuEdit name="Cancel Policy" info={getLabelForCancelPolicyOption(
                    offers?.cancellationAndCodes?.cancellationPolicy
                  )} />
                  <MenuEdit name="Distribution Channels" info={getDistributionChennals()} />
                  <MenuEdit name="Offer Type" info={typeIs} />
                  <MenuEdit name="Booking & Travel Dates" info={getBookingTravelDates()} />
                  <MenuEdit name="Inclusions" info={
                    offers?.inclusions[0]?.inclusionText
                      ? offers?.inclusions[0]?.inclusionText +
                      `${offers?.inclusions[0]?.inclusionDetails ? ',' : ''} ` +
                      (offers?.inclusions[0]?.inclusionDetails
                        ? offers?.inclusions[0]?.inclusionDetails
                        : '')
                      : ''
                  } />
                </>
              }
            />
            <OfferData
              isEditable={false}
              isCollapsed={true}
              title="Group 3"
              children={
                <>
                  <MenuEdit name="Min and Max Length of Stay" info={minMaxStayDisplay} />
                  <MenuEdit name="Blackout Dates" info={blackOutDateData()} />
                  <MenuEdit name="Valid Days of the Week" info={getFormattedDays()} />
                </>
              }
            />
            <a className="see-additional" onClick={seeAdditionDetails}> see additional offer details</a>
            <div className="bottom-componenet"></div>
          </div>
        ) : (
          <>
            <Title text="404" type="h1" />
            <Title text={isRequestFailed.reason} type="h3" />
          </>
        )
      ) : location.pathname === '/review-and-submit' ||
        location.pathname === '/view-data-services-task' ? (
        <ShimmerLoading count={13} />
      ) : (
        <>
          <LoadingSpinner />
        </>
      )}
    </div>
  );
};
