import React, { useState, useRef, useEffect } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import FileUploader from './assets/FileUploader';
import './attachments.css';
import { deleteOfferAttachment, uploadOfferAttachment } from '../../service/api';
import Banner from '../../components/atoms/Banner';
import { useDispatch, useSelector } from 'react-redux';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { handleChange } from '../../store/offerToolSlice';
import featureFlag from '../../config/featureFlags';
import nls from '../../nls/common.json';
import attachmentNls from '../../nls/attachments.json';
import { isEmpty } from '../../utils/textUtils';
import UploadedFile from './assets/UploadedFile';
import {
  extractOfferCategory,
  extractPath,
  getFileName,
} from '../../utils/fileUtils';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/atoms/Button';
export const acceptedFileTypes = [
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls',
  '.xlsx',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.pdf',
  'application/pdf',
  '.jpg',
  '.jpeg',
  'image/jpeg',
  '.png',
  'image/png',
  '.txt',
  'text/plain',
  '.eml',
];

export const Attachments: React.FC = () => {
  const dispatch = useDispatch();
  const { offers, showError, isAttachmentLoading } = useSelector((state: any) => state.offerTool);
  const location = useLocation();
  const offerCategory = extractOfferCategory(location.search);
  const pagePath = extractPath(location.search);
  const offerPath = pagePath;
  const [dragNdrop, setDragnDrop] = useState<File | null>(null);
  const [rdrmFile, setRdrmFile] = useState<File | null>(null);
  const [showRdrmError, setShowRdrmError] = useState('');
  const [showTslnError, setShowTslnError] = useState('');
  const [showOfasError, setShowOfasError] = useState('');
  const [clearRdrm, setClearRdrm] = useState(false);
  const rdRmFileName = offers?.attachments?.rdrmfile
    ? getFileName(offers.attachments.rdrmfile[0])
    : undefined;
  const dragNdropRef = useRef<HTMLInputElement>(null);
  const maxSizeinBytes = 10485760;
  const generalErrorMessage = nls.GENERAL_ERROR_MESSAGE;
  const isToolBoxOffer = offerCategory === 'toolbox-offer';

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  const handleFileRDRM = (data: File) => {
    setShowRdrmError('');
    setRdrmFile(data);
    handleFile(data, 'rdrmfile');
    setClearRdrm(false);
  };
  const handleFileTrans = (data: File) => {
    setShowTslnError('');
    handleFile(data, 'translationfile');
  };
  useEffect(() => {
    if (dragNdrop) {
      setShowOfasError('');
      handleFile(dragNdrop, 'offerasset');
    }
  }, [dragNdrop]);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-attachments';
  }, []);

  useEffect(() => {
    if (showError && offers.attachments.rdrmfile === '') {
      setShowRdrmError(attachmentNls.ATTACH_RDRM_ERROR);
    }
  }, [showError]);

  const deleteFile = (attachmentType: string, filePath: string) => {
    dispatch(handleChange({ name: 'isAttachmentLoading', value: true }));
    deleteOfferAttachment(filePath).then(({ status }) => {
      dispatch(handleChange({ name: 'isAttachmentLoading', value: false }));
      if (status === 201) {
        if (attachmentType === 'rdrmfile') {
          dispatch(handleChange({ name: 'attachementsRdrmfile', value: '' }));
        } else if (attachmentType === 'offerasset') {
          const deletedAttachment = offers.attachments.offerasset.filter((path:string) => path !== filePath);
          dispatch(handleChange({ name: 'attachementsOfferasset', value: deletedAttachment }));
        }
      } else {
        setError(attachmentType, generalErrorMessage);
        if (attachmentType === 'rdrmfile') {
          dispatch(handleChange({ name: 'attachementsRdrmfile', value: '' }));
        } else if (attachmentType === 'offerasset') {
          dispatch(handleChange({ name: 'attachementsOfferasset', value: '' }));
        }
      }
    });
  }

  const clearFile = (attachmentType: string) => {
    if (attachmentType === 'rdrmfile') {
      setClearRdrm(true);
      if (showRdrmError === '') {
        handleFile(null, attachmentType);
      }
      setRdrmFile(null);
      setShowRdrmError('');
    } else if (attachmentType === 'offerasset') {
      if (showOfasError === '') {
        handleFile(null, attachmentType);
      }
      setDragnDrop(null);
      setShowOfasError('');
    }
  };
  const isFileAccepted = (file: File | null) => {
    if (file === null) return true;
    const fileType = file.type.toLowerCase();
    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();

    return acceptedFileTypes.some((acceptedType) => {
      return fileType === acceptedType || fileExtension === acceptedType;
    });
  };

  const handleFile = (data: File | null, attachmentType: string) => {
    if (!isFileAccepted(data)) {
      setError(attachmentType, nls.FILE_TYPE_ERROR);
      return;
    }

    if (data && data.size > maxSizeinBytes) {
      setError(attachmentType, nls.FILE_SIZE_ERROR);
    } else if (data) {
      dispatch(handleChange({ name: 'isAttachmentLoading', value: true }));
      uploadOfferAttachment(data, attachmentType, offerPath).then(({ data, status }) => {
        dispatch(handleChange({ name: 'isAttachmentLoading', value: false }));
        if (status === 201) {
          const { offerPath } = data;
          if (attachmentType === 'rdrmfile') {
            dispatch(handleChange({ name: 'attachementsRdrmfile', value: offerPath }));
          } else if (attachmentType === 'offerasset') {
            if (offers?.attachments?.offerasset) {
              dispatch(handleChange({ name: 'attachementsOfferasset', value: [...offers.attachments.offerasset, offerPath[0]] }));
            }
            else {
              dispatch(handleChange({ name: 'attachementsOfferasset', value: offerPath }));
            }
          }
        } else {
          setError(attachmentType, generalErrorMessage);
          if (attachmentType === 'rdrmfile') {
            dispatch(handleChange({ name: 'attachementsRdrmfile', value: '' }));
          } else if (attachmentType === 'offerasset') {
            dispatch(handleChange({ name: 'attachementsOfferasset', value: '' }));
          }
        }
      });
    }
  };

  const setError = (attachmentType: string, errorMessage: string) => {
    switch (attachmentType) {
      case 'rdrmfile':
        setShowRdrmError(errorMessage);
        break;
      case 'translationfile':
        setShowTslnError(errorMessage);
        break;
      case 'offerasset':
        setShowOfasError(errorMessage);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragnDrop(e.dataTransfer.files[0]);
  };

  useEffect(() => {
    if (isToolBoxOffer) {
      dispatch(handleChange({ name: 'page', value: 8 }));
    } else {
      dispatch(handleChange({ name: 'page', value: 12 }));
    }
  }, []);

  
  const offerAssetFiles = offers?.attachments?.offerasset.length ? offers.attachments.offerasset.map((offer: any) => {
    return <UploadedFile
      filename={getFileName(offer)}
      fileUrl={offer}
      clear={() => deleteFile('offerasset', offer)}
      type={'offerasset'}
    />
  }) : null;

  return (
    <section className="container" data-testid="attachments-component">
      <PageHeader
        title="Attachments"
        description="Include any documentation, photos, or approvals needed for this offer."
      />

      <article className="file-uploader-main">
        {(!isEmpty(showRdrmError) || !isEmpty(showTslnError) || !isEmpty(showOfasError)) && (
          <div style={{ width: '75%', marginBottom: '20px' }}>
            <Banner
              title="Errors on page"
              message="Errors must be corrected before moving forward."
            />
          </div>
        )}
        {!featureFlag.hideRDRMTranslation && (
          <FileUploader
            label="* Attach RDRM Email"
            description="Please attach the email from your RDRM stating this offer is being added to your property offers page. Offers without this approval will NOT be added to the properties offers page."
            getFile={handleFileRDRM}
            accept={acceptedFileTypes.join(', ')}
            errorString={showRdrmError}
            clear={clearRdrm}
          />
        )}
        {(rdrmFile || rdRmFileName) && (
          <UploadedFile
            filename={rdRmFileName}
            fileUrl={offers?.attachments?.rdrmfile}
            clear={() => clearFile('rdrmfile')}
            type={'rdrmfile'}
          />
        )}
        {!featureFlag.hideUploadTranslation && (
          <FileUploader
            label="Translation Document"
            description="Please attach the translation document if the property website is in a language other than english."
            getFile={handleFileTrans}
            accept={acceptedFileTypes.join(', ')}
            errorString={showTslnError}
          />
        )}
        <div
          className="drag-and-drop"
          data-testid="drag-and-drop"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div>
            <p className="drag-heading">Select a file or drag and drop here</p>
            <p className="p-base-small">JPG, PNG or PDF, file size no more than 10MB</p>
          </div>
          <input
            type="file"
            ref={dragNdropRef}
            accept={acceptedFileTypes.join(', ')}
            hidden
            data-testid="otherFiles"
            onChange={(e: any) => setDragnDrop(e.target.files[0])}
          />
          <Button
            id='attachments-browser-files'
            dataAttribute='attachments-browser-files-button'
            label="BROWSE FILE"
            primary
            className="file-button"
            isDisable={isAttachmentLoading}
            onClick={() => dragNdropRef.current?.click()}
          />
        </div>
        {(dragNdrop || offers?.attachments?.offerasset.length>0) && offerAssetFiles}
        {showOfasError && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <WarningIcon width={15} height={15} />
            <p style={{ fontSize: '12px', color: 'red' }}>{showOfasError}</p>
          </div>
        )}
      </article>
    </section>
  );
};
