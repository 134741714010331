import { PageHeader } from '../../components/molecules/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import { handleChange, StoreType } from '../../store/offerToolSlice';
import './Welcome.css';
import Banner from '../../components/atoms/Banner';
import { useEffect, useState } from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getOfferCategoriesAndTypes } from '../../service/api';
import LoadingSpinner from '../../components/atoms/Spinner';

export const Welcome = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const {
    offers: { offerCategory },
    showError,
    offerCategoriesAndTypes,
  } = useSelector((state: any): StoreType => state.offerTool);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 0 }));
    window.utag_data.page_name = 'offertool-welcome';
  }, []);

  useEffect(() => {
    if (offerCategoriesAndTypes.length === 0) {
      dispatch(handleChange({ name: 'showError', value: false }));
      setIsRequestLoading(true);
      dispatch(getOfferCategoriesAndTypes()).then(() => {
        setIsRequestLoading(false);
      });
    }
  }, [offerCategoriesAndTypes]);

  const liveInText: { [key: string]: string } = {
    'hotel-special-offer': '(Live in 15 business days)',
    'toolbox-offer': '(Live in 5 business days)',
  };

  const getCategories = () => {
    if (isRequestLoading) {
      return <LoadingSpinner />;
    } else {
      return offerCategoriesAndTypes.map(({ title, description, name }, id) => (
        <button
          key={id}
          name="offerCategory"
          className={`welcome-buttons-container ${offerCategory === name && 'welcome-btn-active'}`}
          value={name}
          onClick={(e: any) => {
            dispatch(handleChange({ name: 'offerCategory', value: name }));
            dispatch(handleChange({ name: 'offerType', value: '' }));
          }}
        >
          <h1 className="welcome-title">{title}</h1>
          <p className="welcome-para">{description}</p>
          <p>{liveInText[name]}</p>
        </button>
      ));
    }
  };
  return (
    <section className="container welcome center">
      <PageHeader
        title="Welcome to The Offer Tool"
        description="What kind of Offer would you like to create?"
      />
      {showError && (
        <div style={{ width: 345 * offerCategoriesAndTypes.length + 'px' }}>
          <Banner title="Offer category error" message="Please choose an offer category." />
        </div>
      )}
      <div className="welcome-btns">{getCategories()}</div>
    </section>
  );
};
