export const PencilIcon = ({ bgColor = '#0072ce' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="12"
      height="12"
      viewBox="0 0 256 256"
      fill={bgColor}
    >
      <defs></defs>
      <g transform="translate(254.5934065934066 1.406593406593359) rotate(90) scale(2.81 2.81)">
        <path
          d="M 89 90 c -0.125 0 -0.251 -0.023 -0.371 -0.071 l -22.976 -9.182 c -0.126 -0.051 -0.24 -0.126 -0.336 -0.222 L 3.149 18.357 C 1.119 16.326 0 13.626 0 10.753 S 1.119 5.181 3.15 3.15 C 5.181 1.119 7.881 0 10.753 0 s 5.573 1.119 7.604 3.149 l 62.168 62.168 c 0.096 0.096 0.171 0.21 0.222 0.336 l 9.182 22.976 c 0.148 0.371 0.062 0.796 -0.222 1.078 C 89.516 89.898 89.26 90 89 90 z M 66.586 78.966 l 20.62 8.24 l -8.24 -20.62 L 16.943 4.563 C 15.29 2.911 13.091 2 10.753 2 S 6.217 2.911 4.564 4.564 S 2 8.416 2 10.753 s 0.911 4.536 2.563 6.189 L 66.586 78.966 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 66.705 67.705 c -0.256 0 -0.512 -0.098 -0.707 -0.293 L 21.504 22.918 c -0.391 -0.391 -0.391 -1.023 0 -1.414 s 1.023 -0.391 1.414 0 l 44.494 44.494 c 0.391 0.391 0.391 1.023 0 1.414 C 67.217 67.607 66.961 67.705 66.705 67.705 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 89 90 c -0.125 0 -0.251 -0.023 -0.371 -0.071 l -22.976 -9.182 c -0.313 -0.125 -0.541 -0.398 -0.608 -0.728 s 0.034 -0.671 0.272 -0.908 l 13.794 -13.794 c 0.237 -0.238 0.582 -0.342 0.908 -0.272 c 0.329 0.067 0.603 0.296 0.728 0.608 l 9.182 22.976 c 0.148 0.371 0.062 0.796 -0.222 1.078 C 89.516 89.898 89.26 90 89 90 z M 67.805 79.453 l 19.401 7.753 l -7.753 -19.401 L 67.805 79.453 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 89 90 c -0.125 0 -0.251 -0.023 -0.371 -0.071 l -11.487 -4.591 c -0.313 -0.125 -0.541 -0.398 -0.608 -0.728 s 0.034 -0.671 0.272 -0.908 l 6.896 -6.896 c 0.238 -0.238 0.581 -0.342 0.908 -0.272 c 0.329 0.067 0.603 0.296 0.728 0.608 l 4.591 11.487 c 0.148 0.371 0.062 0.796 -0.222 1.078 C 89.516 89.898 89.26 90 89 90 z M 79.293 84.044 l 7.913 3.162 l -3.162 -7.913 L 79.293 84.044 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 8.909 23.702 c -0.256 0 -0.512 -0.098 -0.707 -0.293 l -5.052 -5.052 C 1.119 16.326 0 13.626 0 10.753 S 1.119 5.181 3.15 3.15 C 5.181 1.119 7.881 0 10.753 0 s 5.573 1.119 7.604 3.149 l 5.052 5.052 c 0.391 0.391 0.391 1.023 0 1.414 L 9.616 23.409 C 9.42 23.604 9.165 23.702 8.909 23.702 z M 10.753 2 C 8.415 2 6.217 2.911 4.564 4.564 S 2 8.415 2 10.753 s 0.91 4.536 2.563 6.189 l 4.345 4.345 L 21.288 8.909 l -4.345 -4.345 C 15.29 2.91 13.092 2 10.753 2 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
