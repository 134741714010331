import LoadingSpinner from '../Spinner';
import './Button.css';

interface ButtonProps {
  id: string;
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  type?: 'link' | 'default' | 'blue' | 'green' | 'red';
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /* Icon if needed */
  icon?: any;
  name?: string;
  value?: string | number;
  className?: string;
  isDisable?: boolean;
  isloading?: boolean;
  loadingText?: string;
  dataAttribute: string;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  id,
  primary = false,
  type,
  backgroundColor,
  label,
  icon,
  className,
  name,
  value,
  isDisable = false,
  isloading,
  loadingText,
  dataAttribute,
  ...props
}: ButtonProps) => {
  let mode = primary ? 'b-button-account' : 'b-button-account-ol';
  if (type === 'link') {
    mode = 'b-button-link';
  }
  if (type === 'default') {
    mode = 'b-button-default';
  }
  if (type === 'blue') {
    mode = 'b-button-blue';
  }
  if (type === 'green') {
    mode = 'b-button-green';
  }
  if (type === 'red') {
    mode = 'b-button-red';
  }

  const getContent = () => {
    if (isloading) {
      return (
        <>
          <LoadingSpinner /> {loadingText}
        </>
      );
    } else {
      return (
        <>
          {icon}
          <span>{label}</span>
        </>
      );
    }
  };

  return (
    <button
      id={id}
      data-role={dataAttribute}
      type="button"
      value={value}
      name={name}
      className={`${['b-button', mode].join(' ')} ${className}`}
      style={{ backgroundColor }}
      disabled={isDisable}
      {...props}
    >
      {getContent()}
    </button>
  );
};
