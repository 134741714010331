import Modal from '../../../components/atoms/Modal';
import '../dashboard.css';
import LoadingSpinner from '../../../components/atoms/Spinner';
import { useEffect } from 'react';
interface Props {
  handleClose: () => void;
  copyResp: {
    isRequesting: boolean;
    showModal: boolean;
    resp: any;
    path?: string;
  };
}

function CopyModal({ handleClose, copyResp }: Props) {
  const getByTitle = (): string => {
    if (copyResp.isRequesting) {
      return 'Copying!';
    } else {
      if (copyResp.resp === 200) {
        return 'Offer Copied';
      } else {
        return 'Request Failed';
      }
    }
  };

  useEffect(() => {
    if (copyResp.resp === 200 && copyResp.path) {
      handleClose();
      window.open(copyResp.path, '_blank');
    }
  }, [copyResp]);

  return (
    <Modal
      cautionIconVisible={false}
      titleSize="25px"
      centerTitle
      title={getByTitle()}
      onClose={handleClose}
    >
      <div className="Modal_custom_content">
        {copyResp.isRequesting ? (
          <LoadingSpinner />
        ) : (
          <>
            {copyResp.resp === 200 ? (
              <p>
                The offer has been copied. redirecting please wait to update the required
                screens.&nbsp;
              </p>
            ) : (
              <p className="copy-request-error">
                Can't copy the offer. please try again in a while.
              </p>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default CopyModal;
