import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import './dashboard.css';
import { Option, Select } from '../../components/atoms/Select';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input } from '../../components/atoms/Input';
import { DatePickerComponent } from '../../components/molecules/datePicker';
import { Button } from '../../components/atoms/Button';
import {
  AlertParams,
  CategoryAndTypes,
  ExtendOfferParam,
  GetOfferParams,
  HotelSpecialOffer,
  handleChange,
  resetOfferComments,
  setDashboardTab,
  setOfferToDefault,
  setRequestStatusToDefault,
} from '../../store/offerToolSlice';
import TableData from './table';
import { Title } from '../../components/atoms/Title';
import Alert from '../../components/atoms/Alert';
import { v4 as uuid } from 'uuid';
import { Tasks } from '../tasks/task';
import {
  deleteOffer,
  editOffer,
  endCurrentyOffer,
  extendCurrentyOffer,
  getAllOffers,
  getComments,
  getHelpText,
  getOfferCategoriesAndTypes,
  getTotalApprovedOfferCount,
  getTotalDraftsOfferCount,
  getTotalOfferCount,
  getTotalRejectedOfferCount,
  getTotalSubmittedOfferCount,
} from '../../service/api';
import CommentsModal from './Modals/CommentsModal';
import ExtendOffer from './ExtendOffer/ExtendOffer';
import EndOffer from './EndOffer/EndOffer';
import CopyModal from './Modals/CopyModal';
import DeleteModal from './Modals/DeleteModal';
import EditModal from './Modals/EditModal';
import { useUserGroupRoles } from '../../utils/userUtils';

export const Dashboard: React.FC = () => {
  const {
    offerCategoriesAndTypes,
    allOffers,
    dashboardState,
    allowRequest,
    isRequestLoading,
    selectedTab,
    canCreateOffer,
  } = useSelector((state: any) => state.offerTool);
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState('');
  const [searchText, setSearchText] = useState('');
  const [startedDate, setStartedDate] = useState<Date>();
  const [endedDate, setEndedDate] = useState<Date>();
  const [offerCategories, setOfferCategories] = useState<Option[]>([]);
  const [offerTypes, setOfferTypes] = useState<Option[]>([]);
  const [selectedOfferCategory, setSelectedOfferCategory] = useState<string>('hotel-special-offer');
  const [selectedOfferType, setSelectedOfferType] = useState<string>();
  const [offsetValue, setOffsetValue] = useState(0);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showCopyModal, setShowCopyModal] =
    useState<{ isRequesting: boolean; showModal: boolean; resp: any; path?: string }>();
  const [showExtendOfferModal, setShowExtendOfferModal] = useState(false);
  const [showExtendOfferAlert, setShowExtendOfferAlert] = useState<AlertParams>();
  const [selectedOffer, setselectedOffer] = useState<HotelSpecialOffer>();
  const [showEndOfferModal, setshowEndOfferModal] = useState(false);
  const [showEndOfferAlert, setShowEndOfferAlert] = useState<AlertParams>();
  const [deleteOfferData, setDeleteOfferData] = useState('');
  const [showDeleteOfferAlert, setShowDeleteOfferAlert] = useState(false);
  const [showDeleteOfferErrorAlert, setShowDeleteOfferErrorAlert] = useState(false);
  const [showEditOfferErrorAlert, setShowEditOfferErrorAlert] = useState(false);
  const [earlierCount, setEarlierCount] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [extendOffer, setExtendOffer] = useState<ExtendOfferParam>();
  const [endOffer, setEndOffer] = useState<ExtendOfferParam>();
  const [isExtendLoading, setIsExtendLoading] = useState(false);
  const [isEndLoading, setIsEndLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const userSession = sessionStorage.getItem('offer-tool-authenticated-user');

  const groupRoles = useUserGroupRoles();
  const canViewTasksTab = useMemo(() => Object.values(groupRoles).includes(true), [groupRoles]);
  
  let userGroup = false;
  if (userSession) {
    userGroup = JSON.parse(userSession).group !== 'pe-content-author';
  }

  useEffect(() => {
    dispatch(getHelpText());
    const mainCategory = location.state?.offerCategory;
    if (mainCategory) {
      setSelectedOfferCategory(mainCategory);
    } else {
      setSelectedOfferCategory('all');
    }
    window.utag_data.page_name = 'offertool-dashboard';
  }, []);

  useEffect(() => {
    const mainCategory = location.state?.offerCategory;
    const fetchOffer = async () => {
      const payload = {
        status: dashboardState,
        searchText: searchText,
        offerCategory: 'all',
        offerType: selectedOfferType && selectedOfferType,
        startDate: startedDate && new Date(startedDate).toISOString().split('T')[0],
        endDate: endedDate && new Date(endedDate).toISOString().split('T')[0],
        offset: offsetValue,
        sortBy: sortBy && sortBy,
        sortOrder: sortOrder && sortOrder,
      };
      await dispatch(getAllOffers(payload)).then(() => {
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
        !isPageLoaded && setIsPageLoaded(true);
      });
    };
    if (dashboardState) {
      fetchOffer();
    }
    if (mainCategory) {
      window.history.replaceState({}, '', undefined);
    }
  }, [dashboardState]);

  useEffect(() => {
    dispatch(handleChange({ name: 'isRequestLoading', value: true }));
    dispatch(setOfferToDefault());
    dispatch(setRequestStatusToDefault());
    const fetchData = async () => {
      await dispatch(getOfferCategoriesAndTypes());
      dispatch(handleChange({ name: 'isCountLoading', value: true }));
      await dispatch(
        getTotalDraftsOfferCount(
          selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
        )
      );
      await dispatch(
        getTotalSubmittedOfferCount(
          selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
        )
      );
      await dispatch(
        getTotalApprovedOfferCount(
          selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
        )
      );
      await dispatch(
        getTotalRejectedOfferCount(
          selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
        )
      );
      await dispatch(
        getTotalOfferCount(selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer')
      ).then(() => {
        dispatch(handleChange({ name: 'isCountLoading', value: false }));
      });
    };
    if (allowRequest) {
      fetchData();
    }
  }, [allowRequest]);

  const updateActiveState = useCallback((state: Categories) => {
    setSeatchInputToDefult();
    dispatch(setDashboardTab(state));
  }, []);

  //add offer categories to dropdown
  const fetchOfferCategories = useCallback(() => {
    const offerCategories: CategoryAndTypes[] = offerCategoriesAndTypes;
    const categoryArray: Option[] = [{ label: 'All Offers', value: 'all' }];
    offerCategories?.map((offerCategory) =>
      categoryArray.push({ label: offerCategory.title, value: offerCategory.name })
    );
    setOfferCategories(categoryArray);
    setSearchText('');
  }, [offerCategoriesAndTypes]);

  //once the offer category is selected the offer types will get loaded
  const fetchOfferType = useCallback(() => {
    const offerCategories: CategoryAndTypes[] = offerCategoriesAndTypes;
    const arr: Option[] = [{ label: 'All', value: '' }];
    offerCategories?.filter((e) => {
      if (e.name === selectedOfferCategory) {
        e.offerTypes.map((e) => arr.push({ label: e.title, value: e.name }));
      }
      return false;
    });
    setOfferTypes(arr);
    setSearchText('');
  }, [selectedOfferCategory, offerCategoriesAndTypes]);

  const setSeatchInputToDefult = () => {
    setSearchInput('');
    setSelectedOfferType('');
    setSortBy('');
    setSortOrder('');
    setOffsetValue(0);
    setSearchText('');
    setEndedDate(undefined);
    setStartedDate(undefined);
    dispatch(setDashboardTab('offers'));
    setCurrentPage(0);
    setSelectedOfferCategory('');
  };

  const onCopyClicked = async (offer: HotelSpecialOffer | undefined) => {
    if (offer) {
      setShowCopyModal({ isRequesting: true, showModal: true, resp: undefined });
      const containingPagePath = offer.containingPagePath;
      setShowCopyModal({
        isRequesting: false,
        showModal: true,
        resp: 200,
        path: `/special-offer/basics?offerPath=${containingPagePath}`,
      });
    }
  };

  const displayCommentsModal = (flag: boolean, { offer }: GetOfferParams) => {
    if (offer) {
      dispatch(getComments(offer.containingPagePath));
      setShowCommentsModal(flag);
    }
  };

  const handleOnOfferCommentsModalClose = () => {
    dispatch(resetOfferComments());
    setShowCommentsModal(false);
  };

  const handleOnCopyModalClose = () => {
    setShowCopyModal({ isRequesting: false, showModal: false, resp: undefined });
  };

  useEffect(() => {
    if (sortOrder !== '') {
      offsetValue !== 0 && setEarlierCount(offsetValue);
      setOffsetValue(0);
    } else {
      setOffsetValue(earlierCount);
    }
  }, [sortBy, sortOrder]);

  const resetOffsetValues = () => {
    setOffsetValue(0);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (searchInput.length === 0 || searchInput.length >= 3) {
      resetOffsetValues();
      setSearchText(searchInput);
    }
  }, [searchInput]);

  const search = useCallback(() => {
    if (isPageLoaded) {
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      dispatch(
        getAllOffers({
          status: dashboardState,
          searchText: searchText,
          offerCategory: selectedOfferCategory,
          offerType: selectedOfferType,
          startDate: startedDate && new Date(startedDate).toISOString().split('T')[0],
          endDate: endedDate && new Date(endedDate).toISOString().split('T')[0],
          offset: offsetValue,
          sortBy: sortBy,
          sortOrder: sortOrder,
        })
      ).then((e) => {
        dispatch(handleChange({ name: 'isRequestLoading', value: false }));
      });
    }
  }, [
    selectedOfferCategory,
    selectedOfferType,
    startedDate,
    endedDate,
    dashboardState,
    offsetValue,
    sortOrder,
    searchText,
  ]);

  useEffect(() => {
    showDeleteOfferAlert &&
      setTimeout(() => {
        setShowDeleteOfferAlert(false);
      }, 3000);
  }, [showDeleteOfferAlert]);

  useEffect(() => {
    showDeleteOfferErrorAlert &&
      setTimeout(() => {
        setShowDeleteOfferErrorAlert(false);
      }, 3000);
  }, [showDeleteOfferErrorAlert]);

  useEffect(() => {
    showEditOfferErrorAlert &&
      setTimeout(() => {
        setShowEditOfferErrorAlert(false);
      }, 3000);
  }, [showEditOfferErrorAlert]);

  useEffect(() => {
    setOffsetValue(0);
    updateActiveState(userGroup ? 'offers' : 'tasks');
  }, [dashboardState]);

  useEffect(() => {
    search();
  }, [
    search,
    searchText,
    selectedOfferCategory,
    selectedOfferType,
    startedDate,
    endedDate,
    dashboardState,
  ]);

  useEffect(() => {
    fetchOfferCategories();
  }, [fetchOfferCategories]);

  useEffect(() => {
    fetchOfferType();
  }, [selectedOfferCategory, fetchOfferType]);

  const handleDeleteOffer = () => {
    setIsDeletingInProgress(true);
    deleteOffer(deleteOfferData).then(({ status, statusText }) => {
      if (status === 200) {
        setShowDeleteModal(false);
        setIsDeletingInProgress(false);
        setShowDeleteOfferAlert(true);
        setTimeout(() => {
          search();
          dispatch(
            getTotalSubmittedOfferCount(
              selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
            )
          );
          dispatch(
            getTotalRejectedOfferCount(
              selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
            )
          );
          dispatch(
            getTotalApprovedOfferCount(
              selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
            )
          );
          dispatch(
            getTotalDraftsOfferCount(
              selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
            )
          );
          dispatch(
            getTotalOfferCount(
              selectedOfferCategory ? selectedOfferCategory : 'hotel-special-offer'
            )
          );
        }, 800);
        setDeleteOfferData('');
      } else {
        setShowDeleteOfferErrorAlert(true);
      }
    });
  };

  const onExtendSelect = ({ offer }: GetOfferParams) => {
    setShowExtendOfferModal(true);
    setselectedOffer(offer);
  };

  const onEndSelect = ({ offer }: GetOfferParams) => {
    setshowEndOfferModal(true);
    setselectedOffer(offer);
  };

  const onEditSelect = async (offerPath?: string) => {
    setShowEditModal(true);
    const resp = await editOffer(offerPath);
    setShowEditModal(false);
    if (resp.status === 201) {
      window.open(`/review-and-submit?offerPath=${resp.body}`, '_blank');
    } else {
      setShowEditOfferErrorAlert(true);
    }
  };

  const onExtendOffer = async () => {
    let offerPath = selectedOffer?.containingPagePath;
    if (offerPath?.includes('launches')) {
      offerPath = '/' + offerPath.split('/').slice(-5).join('/');
    }
    setIsExtendLoading(true);
    await extendCurrentyOffer({
      bookingEndDate: extendOffer?.bookingEndDate,
      stayEndDate: extendOffer?.stayEndDate,
      offerPath: offerPath,
    }).then(({ status }) => {
      setIsExtendLoading(false);
      setShowExtendOfferModal(false);
      setExtendOffer(undefined);
      if (status === 201) {
        setShowExtendOfferAlert({
          showAlert: true,
          status: status,
          message: `The offer has been submitted to be extended. you can find it in 'Submitted'`,
        });
        search();
      } else {
        setShowExtendOfferAlert({
          showAlert: true,
          status: status,
          message: 'Something went wrong please try again',
        });
      }
    });
  };

  const onEndOffer = async () => {
    let offerPath = selectedOffer?.containingPagePath;
    if (offerPath?.includes('launches')) {
      offerPath = '/' + offerPath.split('/').slice(-5).join('/');
    }
    setIsEndLoading(true);
    await endCurrentyOffer({
      bookingEndDate: endOffer?.bookingEndDate,
      stayEndDate: endOffer?.stayEndDate,
      offerPath: offerPath,
    }).then(({ status }) => {
      setIsEndLoading(false);
      setshowEndOfferModal(false);
      setEndOffer(undefined);
      if (status === 201) {
        setShowEndOfferAlert({
          showAlert: true,
          status: status,
          message: `The offer has been submitted to be end.`,
        });
        search();
      } else {
        setShowEndOfferAlert({
          showAlert: true,
          status: status,
          message: 'Something went wrong please try again',
        });
      }
    });
  };

  useEffect(() => {
    showExtendOfferAlert &&
      setTimeout(() => {
        setShowExtendOfferAlert({ showAlert: false });
      }, 3000);
  }, [showExtendOfferAlert]);

  useEffect(() => {
    showEndOfferAlert &&
      setTimeout(() => {
        setShowEndOfferAlert({ showAlert: false });
      }, 3000);
  }, [showEndOfferAlert]);

  useEffect(() => {
    resetOffsetValues();
  }, [startedDate, endedDate]);

  return (
    <section className="container">
      {showDeleteOfferErrorAlert && (
        <Alert
          message={'Failed To Delete Offer'}
          title="Delete Failed"
          type="error"
          onClose={() => {
            setShowDeleteOfferErrorAlert(false);
          }}
        />
      )}
      {showEditOfferErrorAlert && (
        <Alert
          message={'Failed To Edit Offer, please try again'}
          title="Edit Failed"
          type="error"
          onClose={() => {
            setShowDeleteOfferErrorAlert(false);
          }}
        />
      )}
      {showDeleteOfferAlert && (
        <Alert
          message={'Offer has been deleted'}
          title="Offer Deleted"
          type="success"
          onClose={() => {
            setShowDeleteOfferAlert(false);
          }}
        />
      )}
      {showExtendOfferAlert?.showAlert && (
        <Alert
          message={showExtendOfferAlert.message!}
          title={showExtendOfferAlert.status === 201 ? 'Offer Extended' : 'Request Falied'}
          type={showExtendOfferAlert.status === 201 ? 'success' : 'error'}
          onClose={() => {
            setShowExtendOfferAlert({ showAlert: false });
          }}
        />
      )}
      {showEndOfferAlert?.showAlert && (
        <Alert
          message={showEndOfferAlert.message!}
          title={showEndOfferAlert.status === 201 ? 'Offer Ended' : 'Request Falied'}
          type={showEndOfferAlert.status === 201 ? 'success' : 'error'}
          onClose={() => {
            setShowEndOfferAlert({ showAlert: false });
          }}
        />
      )}
      <div className="dash-heading">
        {selectedTab !== 'tasks' ? (
          <h3>{dashboardState.charAt(0).toUpperCase() + dashboardState.slice(1)} Offers</h3>
        ) : (
          <div></div>
        )}
        <Button
          id='dashboard-create-new-offer'
          dataAttribute='dashboard-create-new-offer-button'
          label={'CREATE NEW OFFER'}
          isDisable={!canCreateOffer}
          primary
          onClick={() => {
            const uid = uuid();
            const id = uid;
            dispatch(handleChange({ name: 'createOfferId', value: id }));
            dispatch(handleChange({ name: 'fromReviewAndSubmit', value: false }));
            dispatch(handleChange({ name: 'isCopyOffer', value: false }));
            navigate(`/welcome/${id}`);
          }}
        />
      </div>
      {canViewTasksTab && 
        <div className="date-chooser-panel">
          {userGroup && (
            <label
              className={`label ${selectedTab === 'offers' && 'active'}`}
              onClick={() => {
                updateActiveState('offers');
              }}
            >
              Offers
            </label>
          )}
          <label
            className={`label ${selectedTab === 'tasks' && 'active'}`}
            onClick={() => {
              updateActiveState('tasks');
            }}
          >
            Tasks
          </label>
        </div>
      }
      {selectedTab === 'offers' ? (
        <div>
          <div className="search-filters">
            <Input
              id='dashboard-search'
              dataAttribute='dashboard-search-input'
              label=""
              value={searchInput}
              placeHolder="Enter Offer ID, Offer Code, Hotel, Keyword, Spirit Code"
              className="search-input"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <Select
              id='dashboard-select-offer-category'
              dataAttribute='dashboard-select-offer-category-input'
              label=""
              options={offerCategories}
              value={selectedOfferCategory}
              onChange={(e) => {
                setOfferTypes([{ label: 'All', value: '' }]);
                setSelectedOfferType('');
                setSelectedOfferCategory(e.target.value);
                setSearchInput('');
                resetOffsetValues();
              }}
            />
            <Select
              id='dashboard-select-offer-type'
              dataAttribute='dashboard-select-offer-type-input'
              label=""
              options={offerTypes}
              value={selectedOfferType}
              onChange={(e) => {
                setSelectedOfferType(e.target.value);
                resetOffsetValues();
              }}
            />
            <DatePickerComponent
              label=""
              placeholder="Start Date"
              className="date-chooser"
              value={startedDate}
              format="dd-MM-yyyy"
              showTimeInput={false}
              onChange={(date) => setStartedDate(date)}
            />
            <DatePickerComponent
              label=""
              placeholder="End Date"
              className="date-chooser"
              value={endedDate}
              format="dd-MM-yyyy"
              showTimeInput={false}
              onChange={(date) => setEndedDate(date)}
            />
            <Button
              id='dashboard-clear'
              dataAttribute='dashboard-clear-button'
              label="Clear Filters"
              onClick={() => {
                setSeatchInputToDefult();
              }}
              primary={true}
              type="default"
              className="clear-button"
            />
          </div>
          <div>
            {allOffers ? (
              <TableData
                setDeleteOfferData={setDeleteOfferData}
                data={allOffers}
                setOffsetValue={setOffsetValue}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                onCopy={(offer) => {
                  onCopyClicked(offer);
                }}
                setShowDeleteModal={setShowDeleteModal}
                onExtendSelect={onExtendSelect}
                onEndSelect={onEndSelect}
                onEditSelect={onEditSelect}
                setshowEndOfferModal={setshowEndOfferModal}
                showCommentsModal={displayCommentsModal}
                selectedOfferCategory={selectedOfferCategory}
                isRequestLoading={isRequestLoading}
                dashboardState={dashboardState}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                canCreateOffer={canCreateOffer}
              />
            ) : (
              <Title text="No Data To Show" type="h3" />
            )}
          </div>
        </div>
      ) : (
        <Tasks />
      )}
      {showCommentsModal && <CommentsModal handleClose={handleOnOfferCommentsModalClose} />}
      {showEditModal && <EditModal />}
      {showCopyModal?.showModal && (
        <CopyModal handleClose={handleOnCopyModalClose} copyResp={showCopyModal} />
      )}
      {showDeleteModal && (
        <DeleteModal
          isDeletingInProgress={isDeletingInProgress}
          handleSubmit={() => {
            handleDeleteOffer();
          }}
          handleClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
      {showExtendOfferModal && (
        <ExtendOffer
          handleSubmit={onExtendOffer}
          handleClose={() => {
            setShowExtendOfferModal(false);
            setExtendOffer(undefined);
          }}
          isExtendLoading={isExtendLoading}
          setExtendOffer={setExtendOffer}
          extendOffer={extendOffer}
          currentEndDate={selectedOffer?.endDate!}
        />
      )}
      {showEndOfferModal && selectedOffer && (
        <EndOffer
          handleSubmit={onEndOffer}
          handleClose={() => {
            setshowEndOfferModal(false);
            setEndOffer(undefined);
          }}
          isEndLoading={isEndLoading}
          setEndOffer={setEndOffer}
          endOffer={endOffer}
          currentBookingEndDate={selectedOffer?.endDate}
          currentStayEndDate={selectedOffer?.stayEndDate}
        />
      )}
    </section>
  );
};

export type Categories = 'offers' | 'tasks';
