export const CommentsIcon = ({ bgColor = '#0072ce' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 32 32">
      <g id="Hyatt-Digital/Icons/general-icons/no-circle/speech-bubble" stroke="none" fill="none">
        <g id="Contact" transform="translate(4, 6)" fill={bgColor}>
          <path d="M23.5001198,0 C23.776196,0 24,0.226091666 24,0.50498987 L24,14.5046498 C24,14.783548 23.776196,15.0096397 23.5001198,15.0096397 L10.3092297,15.0090337 L5.615627,19.85267 C5.32111782,20.1475469 4.83437403,19.9705101 4.77078379,19.5806934 L4.76385842,19.4939989 L4.76355849,15.009497 L0.499880078,15.010103 C0.254344708,15.010103 0.0501808078,14.8312744 0.00799246811,14.5955485 L0,14.5047224 L0.010997365,0.504599121 C0.0112108692,0.225853585 0.234952257,0 0.510877443,0 Z M22.9999396,1.00937375 L1.01045774,1.00937375 L0.999460377,13.9995173 L5.26373864,14.0001232 C5.50913973,14.0001232 5.71323931,14.1787636 5.75556513,14.4143405 L5.76361887,14.5051131 L5.76331894,18.2824272 L9.75169065,14.1459787 C9.82658207,14.0709937 9.92250126,14.0223407 10.0253569,14.0058789 L10.103579,13.9996599 L22.9999396,13.999054 L22.9999396,1.00937375 Z M20.5,10 C20.7761424,10 21,10.2238576 21,10.5 C21,10.7761424 20.7761424,11 20.5,11 L3.5,11 C3.22385763,11 3,10.7761424 3,10.5 C3,10.2238576 3.22385763,10 3.5,10 L20.5,10 Z M20.5,7 C20.7761424,7 21,7.22385763 21,7.5 C21,7.77614237 20.7761424,8 20.5,8 L3.5,8 C3.22385763,8 3,7.77614237 3,7.5 C3,7.22385763 3.22385763,7 3.5,7 L20.5,7 Z M20.5,4 C20.7761424,4 21,4.22385763 21,4.5 C21,4.77614237 20.7761424,5 20.5,5 L3.5,5 C3.22385763,5 3,4.77614237 3,4.5 C3,4.22385763 3.22385763,4 3.5,4 L20.5,4 Z"></path>
        </g>
      </g>
    </svg>
  );
};
