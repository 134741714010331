import React from 'react'
import './dashboard.css';
import { Title } from '../../components/atoms/Title';
import FaceImage from './images/no-result-image.png'

export const EmptyResult = () => {
  return (
    <div className='empty-result-root'>
        <img src={FaceImage} style={{width:'120px'}}/>
        <h1>No matching results</h1>
        <p>Try your search again?</p>
    </div>
  )
}
