import React, { useEffect } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '../../components/atoms/Input';
import { Button } from '../../components/atoms/Button';
import { Select } from '../../components/atoms/Select';
import { PlusIcon } from '../../components/Icons/PlusIcon';
import { handleChange } from '../../store/offerToolSlice';
import './Inclusions.css';
import Trashcan from '../../components/Icons/Trashcan';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getInclutionTypes } from '../../service/api';
import Banner from '../../components/atoms/Banner';
import { useLocation } from 'react-router-dom';
import { Textarea } from '../../components/atoms/Teaxtarea';

export const frequency: any = {
  'per-day': 'Per Day',
  'twice-a-day': 'Twice A Day',
  'whole-day': 'Whole Day',
  'every-night': 'Every Night',
  'per-night': 'Per Night',
  'per-stay': 'Per Stay',
};

export const Inclusions: React.FC = () => {
  const {
    offers: { inclusions, offerType },
    incultionState,
    inclutionTypes,
    showError,
  } = useSelector((state: any) => state.offerTool);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const location = useLocation();
  const isPriveOffer = offerType === 'hyatt-prive';
  const descriptionText = isPriveOffer ? 'These are additional inclusions, you do not need to fill out standard inclusions offered by your hotel in the Hyatt Privé program.'
    : 'Indicate additional inclusions in the offer, the frequency and any additional details of the inclusion.';

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-inclusions';
  }, []);

  const addInclusion = () => {
    if (!incultionState?.inclusionText && !incultionState?.inclusionFrequency) {
      return;
    }
    if (
      (incultionState?.inclusionText && !incultionState?.inclusionFrequency) ||
      (incultionState?.inclusionFrequency && !incultionState?.inclusionText)
    ) {
      dispatch(handleChange({ name: 'showError', value: true }));
    } else {
      if (inclusions) {
        dispatch(handleChange({ name: 'inclusions', value: [...inclusions, incultionState] }));
      } else {
        dispatch(handleChange({ name: 'inclusions', value: [incultionState] }));
      }
      dispatch(
        handleChange({
          name: 'incultionState',
          value: {
            inclusionDetails: '',
            inclusionText: '',
            inclusionFrequency: '',
          },
        })
      );
    }
  };

  const handleDelete = (id: number) => {
    const filteredValue = inclusions.filter((item: any, idx: number) => idx !== id);
    dispatch(handleChange({ name: 'inclusions', value: filteredValue }));
  };

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 4 }));
  }, []);

  useEffect(() => {
    dispatch(getInclutionTypes());
  }, []);

  return (
    <section className="container inclusions-container">
      <PageHeader
        title="Inclusions and frequency"
        description={descriptionText}
        width='1096px'
      />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner
            title="Required Fields"
            message="Please provide inclusion text and frequency or leave everthing as empty"
          />
        </div>
      )}
      <div className="inclusions-form-main-container">
        <form className="inclusions-form-container">
          <div className="inclusions-first-row">
            <Input
              id='inclusions-text'
              dataAttribute='inclusions-text-input'
              label="Inclusion"
              value={incultionState?.inclusionText}
              className="inclusion-main-select"
              name="inclusionText"
              labelClassName="p-base-small"
              onChange={(e) => {
                dispatch(
                  handleChange({
                    name: 'incultionState',
                    value: { ...incultionState, [e.target.name]: e.target.value },
                  })
                );
              }}
            />
            <Select
              id='inclusions-frequency'
              dataAttribute='inclusions-frequency-select'
              label="Frequency"
              options={inclutionTypes}
              className="inclusions-frequency-select"
              name="inclusionFrequency"
              value={incultionState?.inclusionFrequency}
              labelClassName="p-base-small"
              onChange={(e) => {
                dispatch(
                  handleChange({
                    name: 'incultionState',
                    value: { ...incultionState, [e.target.name]: e.target.value },
                  })
                );
              }}
            />
          </div>
          <div className="inclusion-details-container">
            <Textarea
              id='inclusions-details'
              dataAttribute='inclusions-details-input'
              label="Inclusion Details"
              className="inclusion-details-input"
              labelClassName="p-base-small"
              name="inclusionDetails"
              value={incultionState?.inclusionDetails}
              onChange={(e) => {
                dispatch(
                  handleChange({
                    name: 'incultionState',
                    value: { ...incultionState, [e.target.name]: e.target.value },
                  })
                );
              }}
            />
          </div>
          <Button
            id='inclusions-add'
            dataAttribute='inclusions-add-button'
            label="Add Inclusion"
            className="inclusion-add-btn"
            type="link"
            isDisable={inclusions?.length === 10}
            icon={<PlusIcon bgColor={inclusions?.length === 10 ? '#b6b6b6' : '#0072ce'} />}
            onClick={addInclusion}
          />
        </form>
        {inclusions &&
          inclusions?.map(
            ({ inclusionText, inclusionFrequency, inclusionDetails }: any, index: number) => {
              return (
                <div key={index} className="inclusions-form-container inclusions-map">
                  <div className="inclusions-form-container magin-top-10">
                    <div className="inclusions-first-row">
                      <Input id='inclusions-readonly-text' dataAttribute='inclusions-readonly-text-value' value={inclusionText} readOnly className="inclusion-main-select" />
                      <Input
                        id='inclusions-readonly-frequency'
                        dataAttribute='inclusions-readonly-frequency-value'
                        value={
                          frequency[inclusionFrequency]
                            ? frequency[inclusionFrequency]
                            : inclusionFrequency
                        }
                        readOnly
                        className="inclusion-main-select-frequency"
                      />
                      <button onClick={() => handleDelete(index)}>
                        <Trashcan />
                      </button>
                    </div>
                    {inclusionDetails && (
                      <div className="list-inclusion-details-container">
                        <Textarea
                          id='inclusions-readonly-details'
                          dataAttribute='inclusions-readonly-details-value'
                          className="inclusion-details-input"
                          label=""
                          defaultValue={inclusionDetails}
                          readOnly={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          )}
      </div>
    </section>
  );
};
