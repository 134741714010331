import { store } from '../../../store/store';

interface HelpTextParam {
  paragraph?: string;
}

interface HelpText {
  path: string;
  helptext: {
    html: string;
  };
}

export const getHelpOptions = (page: string): HelpTextParam | undefined => {
  const helpTextObj = store.getState().offerTool.helpTexts;

  const helpTextArray: HelpText[] = helpTextObj?.data?.helpTextList?.items;

  const getHelpText = (screen: string) => {
    return {
      paragraph: helpTextArray?.find((item) => item.path.endsWith(screen))?.helptext.html,
    };
  };

  switch (page) {
    case 'Dashboard':
      return getHelpText('dashboard');
    case 'Tasks Tab':
      return getHelpText('tasks-tab');
    case 'Draft':
      return getHelpText('draft');
    case 'Submitted':
      return getHelpText('submitted');
    case 'Approved':
      return getHelpText('approved');
    case 'Rejected':
      return getHelpText('rejected');
    case 'Welcome Offer Category':
      return getHelpText('welcome-offer-category');
    case 'Offer Types':
      return getHelpText('offer-types');
    case 'HTB Offer Types':
      return getHelpText('htb-offer-types');
    case 'Basics Page':
      return getHelpText('basics');
    case 'HTB Basics':
      return getHelpText('htb-basics');
    case 'HTB Specific':
      return getHelpText('htb-specific');
    case 'Inclusions & Frequency':
      return getHelpText('inclusions-and-frequency');
    case 'Lead Time':
      return getHelpText('lead-time');
    case 'Advanced Booking':
      return getHelpText('advanced-booking');
    case 'Booking Dates':
      return getHelpText('booking-dates');
    case 'HTB Booking Dates':
      return getHelpText('htb-booking-dates');
    case 'Blackout Dates':
      return getHelpText('blackout-dates');
    case 'Valid Days of Week':
      return getHelpText('valid-days-of-week');
    case 'Pricing Details':
      return getHelpText('pricing-details');
    case 'Offer Code & Cancel Policy':
      return getHelpText('offer-code-and-cancel-policy');
    case 'HTB Offer Codes & Cancellation Policy':
      return getHelpText('htb-offer-code-and-cancel-policy');
    case 'Distribution Channels':
      return getHelpText('distribution-chennels');
    case 'Property website offers page':
      return getHelpText('property-website-offers-page');
    case 'HTB Hotel Website Offer':
      return getHelpText('htb-hotel-website-offer');
    case 'Attachments':
      return getHelpText('attachments');
    case 'Comments':
      return getHelpText('comments');
    case 'HTB Review and submit':
      return getHelpText('htb-review-and-submit');
    case 'Terms & Conditions':
      return getHelpText('terms-and-conditions');
    case 'Reporting':
      return getHelpText('reporting');
    case 'Package with Inclusions':
      return getHelpText('package-with-inclusions');
    case 'Percent Off':
      return getHelpText('percent-off');
    case 'Advanced Purchase':
      return getHelpText('advanced-purchase');
    case 'Hotel Resort Credit Inclusions Options':
      return getHelpText('hotel-resort-credit-inclusions-options');
    case 'Meeting Promotion':
      return getHelpText('meeting-promotion');
    case 'Wedding Promotion':
      return getHelpText('wedding-promotion');
    default:
      break;
  }
};
