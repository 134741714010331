export const ArrowUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="15"
      height="15"
      viewBox="0 0 256 256"
    >
      <defs></defs>
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 90 66.75 c 0 0.384 -0.146 0.768 -0.439 1.061 c -0.586 0.586 -1.535 0.586 -2.121 0 L 45 25.371 L 2.561 67.811 c -0.585 0.586 -1.536 0.586 -2.121 0 c -0.586 -0.586 -0.586 -1.535 0 -2.121 l 43.5 -43.5 c 0.585 -0.586 1.536 -0.586 2.121 0 l 43.5 43.5 C 89.854 65.982 90 66.366 90 66.75 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
