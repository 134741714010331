export const ENV = process.env.REACT_APP_ENVIRONMENT;

const BASE_URLS = {
  dev: 'https://author-p23457-e64040.adobeaemcloud.com',
  qa: 'https://author-p23457-e243162.adobeaemcloud.com',
  uat: 'https://author-p23457-e86981.adobeaemcloud.com',
  prod: 'https://author-p23457-e98757.adobeaemcloud.com',
};

export const BASE_URL = process.env.REACT_APP_ENVIRONMENT
  ? BASE_URLS[process.env.REACT_APP_ENVIRONMENT]
  : 'http://localhost:4502';

const REACT_URLS = {
  dev: 'https://offers-app.dev.hyatt.global',
  qa: 'https://offers-app.qa.hyatt.global',
  uat: 'https://offers-app.uat.hyatt.global',
  prod: 'https://offers-app.hyatt.global',
};

export const REACT_URL = process.env.REACT_APP_ENVIRONMENT
  ? REACT_URLS[process.env.REACT_APP_ENVIRONMENT]
  : 'http://localhost:3000';

export const OFFER_CATEGORIES_TYPES_URL = `${BASE_URL}/content/offers/hotels/jcr:content.offercategoriesandtypes.json`;
export const HOTEL_OFFER = (path: string) =>
  `${BASE_URL + path}/jcr:content/offers.offerstool.json`;
export const CREATE_PAGE_URL = `${BASE_URL}/libs/wcm/core/content/sites/createpagewizard/jcr:content`;
export const UPDATE_OFFERS_PAGE_URL = (path: string) => `${BASE_URL + path}/jcr:content/offers`;
export const CSRF_TOKEN = `${BASE_URL}/libs/granite/csrf/token.json`;
export const ALL_OFFERS = `${BASE_URL}/content/offers/hotels/jcr:content.offerstoolsearch.json`;
export const LOGIN_TOKEN = `${BASE_URL}/bin/userInfo.json`;
export const COPY_OFFER = () =>
  `${BASE_URL}/content/offers/jcr:content.offercopy.json`;
export const FEATURE_LIST = `${BASE_URL}/content/offers/jcr:content.featureflags.json`;
export const OFFER_INCLUTIONS = `${BASE_URL}/etc/acs-commons/lists/offerservice/inclusion-types/jcr:content.list.json`;
export const OFFER_FREQUENCY = `${BASE_URL}/etc/acs-commons/lists/offerservice/frequency-types/jcr:content.list.json`;
export const OFFER_BLACKOUT_RANGE_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/black-out-range-types/jcr:content.list.json`;
export const OFFER_RACK_DETAILS_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/offer-rack-details-types/jcr:content.list.json`;
export const OFFER_CODE_USE_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/offer-code-use-types/jcr:content.list.json`;
export const OFFER_COMMENTS = (path: string) => `${BASE_URL + path}/jcr:content.comments.json`;
export const OFFER_CANCELATION_POLICY_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/cancellation-policy-types/jcr:content.list.json`;
export const OFFER_POLICY_TERM_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/policy-terms-types/jcr:content.list.json`;
export const OFFER_RATE_PAN_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/rate-pan-types/jcr:content.list.json`;
export const OFFER_SPECIAL_INSTRUCTION_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/special-instructions-type/jcr:content.list.json`;
export const OFFER_LUXURY_CONSORTIA_PARTNERS_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/luxury-consortia-partners-types/jcr:content.list.json`;
export const OFFER_RECORD_TYPES = `${BASE_URL}/etc/acs-commons/lists/offerservice/record-types/jcr:content.list.json`;
export const OFFER_ATTACHMENT_UPLOAD = `${BASE_URL}/content/offers/jcr:content.offerupload.json`;
export const OFFER_ATTACHMENT_DELETE = `${BASE_URL}/content/offers/jcr:content.offerdeletefile.json`;
export const OFFER_SUBMIT = `${BASE_URL}/var/workflow/instances`;
export const TASKS_DATA = `${BASE_URL}/bin/getUserTask`;
export const OFFER_DROPDOWN_TYPES = (offer: string) => {
  return `${BASE_URL}/etc/acs-commons/lists/offerservice/${offer}/jcr:content.list.json`;
};
export const OFFER_TERMS_AND_CONDITIONS = (path: string) =>
  `${BASE_URL + path}/_jcr_content/offers.termsandconditions.json`;
export const DELETE_OFFER = `${BASE_URL}/bin/wcmcommand`;
export const SPIRITCODES = `${BASE_URL}/content/offers/hotels/jcr:content.hotelnames.json`;
export const UPDATE_TASK = (path: string) => `${BASE_URL + path}`;
export const COMPLETE_TASK = `${BASE_URL}/bin/workflow/inbox`;
export const GET_WORKFLOW = (path: string) => `${BASE_URL}/resource-status/editor${path}.1.json`;
export const GET_ROUTES = (workflowId: string) => `${BASE_URL}/${workflowId}.routes.json`;
export const GET_BACK_ROUTES = (workflowId: string) => `${BASE_URL}/${workflowId}.backroutes.json`;
export const UPDATE_DATA_SERVICES = (path: string) => {
  return `${BASE_URL + path}/jcr:content/offers/dataServices`;
};
export const GET_DATA_SERVICE_COMMENTS = (path: string) => {
  return `${BASE_URL + path}/jcr:content/comments.3.json`;
};
export const GET_CONTENT_SERVICE_PATH = (path: string) => `${BASE_URL}/editor.html${path}.html`;
export const EXPORT_DAK = `${BASE_URL}/bin/dakReport`;
export const EXTEND_OFFER = (path: string) =>
  `${BASE_URL}${path}/jcr:content/offers.extendoffer.json`;
export const END_OFFER = (path: string) => `${BASE_URL}${path}/jcr:content/offers.endoffer.json`;
export const GET_STEP_BACK_OPTIONS = (workflowId: string) =>
  `${BASE_URL}/${workflowId}.filteredbackroutes.json`;
export const BUILD_CONTENT_URL = (uuid:string ) => `${BASE_URL}/editor.html/content/contentservice/contentblock/language-masters/en/offers/${uuid}.html`;
export const EDIT_OFFER = (path: string) => `${BASE_URL}${path}/jcr:content/offers.editoffer.json`;
export const APPROVAL_REPORT = `${BASE_URL}/bin/offersApprovalReport`;
export const HELP_TEXT = `${BASE_URL}/graphql/execute.json/offerservice/getHelpTextList;pagePath=/content/dam/offerservice/content-fragments/help-text;variation=master`;
export const HBM_IMAGE_VALIDATION = `${BASE_URL}/bin/getRemoteDAMAssets`;
