import React from 'react';

interface TextareaProps {
  id: string;
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  labelClassName?: string;
  readOnly?: boolean;
  dataAttribute: string;
}

export const Textarea: React.FC<TextareaProps> = ({
  id,
  label,
  disabled = false,
  defaultValue,
  name,
  value,
  placeholder,
  className,
  onChange,
  labelClassName,
  readOnly = false,
  dataAttribute,
}) => {
  return (
    <label className={`b-form-textarea ${disabled ? 'b-is-disabled' : ''}`}>
      <span className={`b-form-textarea__label ${labelClassName}`}>{label}</span>
      <textarea
        id={id}
        data-role={dataAttribute}
        className={`b-form-textarea__control ${className}`}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      ></textarea>
    </label>
  );
};
