import React, { useEffect } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import { days } from './days';
import { Checkbox } from '../../components/atoms/Checkbox';
import { handleChange } from '../../store/offerToolSlice';
import './ValidDaysOfWeek.css';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { useLocation } from 'react-router-dom';

export const ValidDaysOfWeek: React.FC = () => {
  const { offers, showError } = useSelector((state: any) => state.offerTool);
  const {
    offerValidity: { validDays },
  } = offers;
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-validdaysofweek';
  }, []);
  
  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 8 }));
  }, []);

  return (
    <section className="container valid-days-container">
      <PageHeader
        title="Valid days of the week"
        description="Select the days of the week the offer can be applied."
      />
      {showError && (
        <div style={{ width: '75%', marginBottom: '20px' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <div className="valid-days-main">
        <div className="valid-days">
          <p className="p-base-normal">* Days of the week the offer IS VALID</p>
          <div className="days">
            {days.map((day, index) => (
              <Checkbox
                id={`valid-day-is-${day}`}
                dataAttribute={`input-of-valid-day-is-${day}`}
                key={index}
                label={day}
                name={day}
                labelClassName="p-base-medium"
                checked={validDays?.includes(day.toLowerCase())}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(
                      handleChange({ name: 'validDays', value: [...validDays, day.toLowerCase()] })
                    );
                  } else if (!e.target.checked) {
                    const validDaysToSave = validDays.filter((dayIs: any) => {
                      return day.toLowerCase() !== dayIs;
                    });
                    dispatch(handleChange({ name: 'validDays', value: validDaysToSave }));
                  }
                }}
              />
            ))}
          </div>
          {showError && validDays.length === 0 && (
            <div style={{ display: 'flex' }}>
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red', marginBottom: '10px' }}>
                Please provide the offer valid days
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
