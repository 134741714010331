import { Button } from '../../components/atoms/Button';
import { formatComment } from '../../utils/textUtils';

interface StatusParams {
  status: 'rejected' | 'draft' | 'submitted' | 'approved' | 'approved failed synch';
  reason: string;
}

export default function ViewComponent({ status, reason }: StatusParams) {
  const statusToTypeMap: Record<string, string> = {
    submitted: 'blue',
    approved: 'green',
    draft: 'default',
    rejected: 'red',
    'approved failed synch': 'green',
  };
  const getType = (status: string = 'link'): string => statusToTypeMap[status];

  return (
    <>
      <Button
        id='comment-status'
        dataAttribute='comment-status-button'
        label={status?.toLocaleUpperCase()}
        primary
        type={getType(status?.toLocaleLowerCase()) as any}
        className="status-button"
      />
      {status?.toLocaleLowerCase() === 'rejected' && (
        <p className="reject-reason">{formatComment(reason)}</p>
      )}
    </>
  );
}
