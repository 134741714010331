import React from 'react';
import './Title.css';

interface TitleProps {
  text: string;
  type?: 'h1' | 'h2' | 'h3';
}

export const Title = ({ text, type }: TitleProps) => {
  switch (type) {
    case 'h1':
      return <h1 className="title">{text}</h1>;
    case 'h2':
      return <h2 className="title">{text}</h2>;
    case 'h3':
      return <h3 className="title">{text}</h3>;
    default:
      return <h1 className="title">{text}</h1>;
  }
};
