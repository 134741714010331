export const CalenderIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="32"
        width="32"
        viewBox="0 0 32 32"
        aria-hidden="true"
      >
        <path d="M20.5 6a.5.5 0 01.492.41L21 6.5v1.496h3.492c.106 0 .208.034.292.096l.06.05.061.072a.502.502 0 01.095.293v16.996a.5.5 0 01-.146.354l-.07.062a.495.495 0 01-.292.095H7.508a.495.495 0 01-.352-.146l-.061-.071A.502.502 0 017 25.503V8.507c0-.132.052-.26.146-.353l.067-.06a.493.493 0 01.295-.098H11V6.5a.5.5 0 01.992-.09L12 6.5v1.496h8V6.5a.5.5 0 01.5-.5zM11 8.995H8v16h16l-.001-11.999H8v-1h15.999v-3L21 8.995v1.501a.5.5 0 01-.992.09l-.008-.09V8.995h-8v1.501a.5.5 0 01-.992.09l-.008-.09V8.995zm5.999 12.251a.25.25 0 01.057.494l-.057.006H15a.25.25 0 01-.057-.493l.057-.007h1.999zm-5 0a.25.25 0 01.057.494l-.057.006H10a.25.25 0 01-.057-.493l.057-.007h1.999zm5-3a.25.25 0 01.057.494l-.057.006H15a.25.25 0 01-.057-.493l.057-.007h1.999zm5 0a.25.25 0 01.057.494l-.057.006H20a.25.25 0 01-.057-.493l.057-.007h1.999zm-10 0a.25.25 0 01.057.494l-.057.006H10a.25.25 0 01-.057-.493l.057-.007h1.999zm5-3a.25.25 0 01.057.494l-.057.006H15a.25.25 0 01-.057-.493l.057-.007h1.999zm5 0a.25.25 0 01.057.494l-.057.006H20a.25.25 0 01-.057-.493l.057-.007h1.999zm-10 0a.25.25 0 01.057.494l-.057.006H10a.25.25 0 01-.057-.493l.057-.007h1.999z"></path>
      </svg>
    </span>
  );
};
