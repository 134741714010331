import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from '../../components/atoms/Select';
import { Button } from '../../components/atoms/Button';
import { PlusIcon } from '../../components/Icons/PlusIcon';
import { DatePickerComponent } from '../../components/molecules/datePicker';
import { CalenderIcon } from '../../components/Icons/CalenderIcon';
import { Input } from '../../components/atoms/Input';
import { handleChange } from '../../store/offerToolSlice';
import Trashcan from '../../components/Icons/Trashcan';

import './BlackoutDates.css';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getOfferBlackoutRange } from '../../service/api';
import { format, parse } from 'date-fns';
import { convertToMMDDYYYY, setTimeTo9AMCST } from '../../utils/dateAndTimeUtils';
import { useLocation } from 'react-router-dom';
import { WarningIcon } from '../../components/Icons/WarningIcon';

export const BlackoutRange: any = {
  week: 'Week',
  'date-range': 'Date Range',
  month: 'Month',
  'single-day': 'Single Day',
};
export const BlackoutDates = () => {
  const {
    offers: { blackoutDates,bookingDates },
    blackoutRangeOptions,
    blackoutState,
    showError
  } = useSelector((state: any) => state.offerTool);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-blackoutdates';
  }, []);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  const handleDateChange = (date: Date, name: string) => {
    dispatch(
      handleChange({
        name: 'blackoutState',
        value: { ...blackoutState, [name]: setTimeTo9AMCST(date.toDateString()) },
      })
    );
  };

  const handleBlackout = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      handleChange({
        name: 'blackoutState',
        value: { ...blackoutState, [e.target.name]: e.target.value },
      })
    );
  };
  
  useEffect(() => {
    if (showError) {
      switch (blackoutState.blackoutRange) {
        case '':
          setErrorMessage('Please select a value for Blackout Dates');
          break;
        case 'date-range':
          setErrorMessage('Please select Start Date and End Date');
          break;
        case 'single-day':
          setErrorMessage('Please select Start Date');
      }
    } else {
      setErrorMessage('');
    }
  }, [showError, blackoutState.blackoutRange]);

  const handleData = () => {
    if (
      (blackoutState.blackoutRange === 'date-range' &&
        (!blackoutState.blackoutStartDate || !blackoutState.blackoutEndDate)) ||
      (blackoutState.blackoutRange === 'single-day' && !blackoutState.blackoutStartDate) ||
      blackoutState.blackoutRange === ''
    ) {
      return;
    } else {
      dispatch(handleChange({ name: 'blackoutDates', value: blackoutState }));
      dispatch(
        handleChange({
          name: 'blackoutState',
          value: {
            blackoutRange: '',
            blackoutStartDate: undefined,
            blackoutEndDate: undefined,
          },
        })
      );
    }
  };

  const handleDelete = (data: number) => {
    const filteredData = blackoutDates.filter(
      ({ blackoutStartDate }: any, index: number) => data !== index
    );
    dispatch(handleChange({ name: 'blackoutDatesNew', value: filteredData }));
  };

  useEffect(() => {
    dispatch(getOfferBlackoutRange());
  }, []);

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 7 }));
  }, []);

  const getblackoutStartDate = (startDate?: string) => {
    try {
      if (blackoutState.blackoutStartDate || startDate) {
        try {
          const blackoutStartDateIO = parse(
            startDate ? startDate : blackoutState.blackoutStartDate,
            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            new Date()
          );
          const formattedDate = format(blackoutStartDateIO, 'MMM-dd-yyyy');
          return new Date(formattedDate);
        } catch (error) {
          try {
            const blackoutStartDateIO = parse(
              startDate!.split(' ').slice(0, 2).join(' '),
              'MM-dd-yyyyh:mm:ss a',
              new Date()
            );
            const formattedDate = format(blackoutStartDateIO, 'MMM-dd-yyyy');
            const formattedDateObject = new Date(formattedDate);
            return formattedDateObject;
          } catch (error) {
            return undefined;
          }
        }
      } else {
        return undefined;
      }
    } catch (error) {}
  };

  const getBlockoutEndDate = (endDate?: string) => {
    try {
      if (blackoutState.blackoutEndDate || endDate) {
        try {
          const bookingEndDateIO = parse(
            endDate ? endDate : blackoutState.blackoutEndDate,
            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            new Date()
          );
          const formattedDate = format(bookingEndDateIO, 'MMM-dd-yyyy');
          return new Date(formattedDate);
        } catch (error) {
          try {
            const blackoutStartDateIO = parse(
              endDate!.split(' ').slice(0, 2).join(' '),
              'MM-dd-yyyyh:mm:ss a',
              new Date()
            );
            const formattedDate = format(blackoutStartDateIO, 'MMM-dd-yyyy');
            const formattedDateObject = new Date(formattedDate);
            return formattedDateObject;
          } catch (error) {
            return undefined;
          }
        }
      } else {
        return undefined;
      }
    } catch (error) {}
  };

  return (
    <section className="container blackout-main">
      <PageHeader
        title="Blackout dates"
        description="Enter any dates or range of dates that the offer is not available for the guest to stay and utilize the offer. <br>Please note, these dates will appear in the Terms & Conditions of your offer."
      />
      <article className="blackout-data">
        {bookingDates?.bookingStartDate &&
          <div>
            <p className="p-base-normal">Booking and Stay dates you chose:</p>
            <div className="booking-dates-section">
              <div className="booking-dates-column">
                <p className="booking-dates-block"><b>Booking Start Date: </b>{convertToMMDDYYYY(bookingDates?.bookingStartDate)}</p>
                <p className="booking-dates-block"><b>Booking End Date: </b>{convertToMMDDYYYY(bookingDates?.bookingEndDate)}</p>
              </div>
              <div className="booking-dates-column">
                <p className="booking-dates-block"> <b>Stay Start Date:</b> {convertToMMDDYYYY(bookingDates?.stayStartDate)}</p>
                <p className="booking-dates-block"> <b>Stay End Date:</b> {convertToMMDDYYYY(bookingDates?.stayEndDate)}</p>
              </div>
            </div>
          </div>
        }
        <div className="blackout-input-select">
          <div className="blackout-date-btn">
            <Select
              id='blackout-range' 
              dataAttribute='blackout-range-input'
              label="Blackout Dates"
              options={blackoutRangeOptions}
              className="blackout-dates-select"
              labelClassName="p-base-normal"
              name="blackoutRange"
              value={blackoutState?.blackoutRange}
              onChange={handleBlackout}
            />
             {showError && (
                  <div className="blackout-error-message">
                    <div className="error-container">
                      <WarningIcon width={15} height={15} />
                      <p className="error-message">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                )}
            <Button
              id='blackout-add-dates' 
              dataAttribute='blackout-add-dates-button'
              label="Add blackout dates"
              type="link"
              icon={<PlusIcon bgColor="#0072ce" />}
              className="blackout-add-button"
              onClick={handleData}
            />
          </div>
          <DatePickerComponent
            label="Start Date"
            labelClassName="p-base-small"
            placeholder="Select date"
            className="start-date-picker"
            format="MMM-dd-yyyy"
            value={getblackoutStartDate()}
            name="blackoutStartDate"
            onChange={(date) => handleDateChange(date, 'blackoutStartDate')}
          />
          {blackoutState?.blackoutRange !== 'single-day' && (
            <DatePickerComponent
              label="End Date"
              placeholder="Select date"
              labelClassName="p-base-small"
              className="end-date-picker"
              format="MMM-dd-yyyy"
              value={getBlockoutEndDate()}
              name="blackoutEndDate"
              onChange={(date) => handleDateChange(date, 'blackoutEndDate')}
            />
          )}
        </div>
        <div className="blackout-date-btn">
          {blackoutDates?.map((blackout: any, index: number) => {
            const { blackoutEndDate, blackoutRange, blackoutStartDate } = blackout;
            if (blackoutRange === 'single-day') {
              return (
                <div key={index} className="blackout-items">
                  <Input
                    id={`blackout-range-${index}`}
                    dataAttribute={`blackout-range-input-${index}`}
                    className="blackout-date-input blackout-dates-select"
                    label=""
                    defaultValue={BlackoutRange[blackoutRange]}
                  />
                  <div className="blackout-data-main blackout-end-date-input start-date-picker">
                    <CalenderIcon />
                    <input
                      className="blackout-data-input"
                      value={getblackoutStartDate(blackoutStartDate)?.toString()}
                      readOnly
                    />
                  </div>
                  <button className="blackout-delete-button" onClick={() => handleDelete(index)}>
                    <Trashcan />
                  </button>
                </div>
              );
            }

            return (
              <div key={index} className="blackout-items">
                <Input
                  id='view-blackout-range' 
                  dataAttribute='view-blackout-range-input'
                  className="blackout-date-input blackout-dates-select"
                  label=""
                  defaultValue={BlackoutRange[blackoutRange]}
                />
                <div className="blackout-data-main blackout-end-date-input start-date-picker">
                  <CalenderIcon />
                  <input
                    className="blackout-data-input"
                    value={getblackoutStartDate(blackoutStartDate)
                      ?.toString()
                      .split(' ')
                      .slice(1, 4)
                      .join(' ')}
                    readOnly
                  />
                </div>
                <div className="blackout-data-main blackout-end-date-input start-date-picker">
                  <CalenderIcon />
                  <input
                    className="blackout-data-input"
                    value={getBlockoutEndDate(blackoutEndDate)
                      ?.toString()
                      .split(' ')
                      .slice(1, 4)
                      .join(' ')}
                    readOnly
                  />
                </div>
                <button className="blackout-delete-button" onClick={() => handleDelete(index)}>
                  <Trashcan />
                </button>
              </div>
            );
          })}
        </div>
      </article>
    </section>
  );
};
