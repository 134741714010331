import Modal from '../../../components/atoms/Modal';
import { Button } from '../../../components/atoms/Button';
import '../dashboard.css';
import ExtendOnlyDates from './ExtendOnlyDates';
import { ExtendOfferParam } from '../../../store/offerToolSlice';
import { getDaysBetweenDates } from '../../../utils/dateAndTimeUtils';
interface Props {
  handleClose: () => void;
  handleSubmit: () => void;
  setExtendOffer: (param: ExtendOfferParam) => void;
  extendOffer: ExtendOfferParam | undefined;
  currentEndDate: string;
  isExtendLoading: boolean;
}
function ExtendOffer({
  handleClose,
  handleSubmit,
  setExtendOffer,
  extendOffer,
  currentEndDate,
  isExtendLoading,
}: Props) {
  const isDisabled = (): boolean => {
    if (!extendOffer?.bookingEndDate || !extendOffer?.stayEndDate || isExtendLoading) {
      return true;
    } else {
      const stayEndDateAfterBookingEnd = getDaysBetweenDates(new Date(extendOffer.stayEndDate), new Date(extendOffer.bookingEndDate)) > 0;
      if(stayEndDateAfterBookingEnd)
        return true
      return false;
    }
  };

  return (
    <Modal titleSize="20px" title="Extend Offer" onClose={handleClose}>
      <div className="Modal_custom_content_extend_offer">
        <ExtendOnlyDates
          setExtendOffer={setExtendOffer}
          extendOffer={extendOffer}
          currentEndDate={currentEndDate}
        />
        <div className="button-container">
          <div className="custom-button">
            <Button id='cancel-extend-offer' dataAttribute='cancel-extend-offer-button' onClick={handleClose} primary={false} label={'Cancel'}></Button>
          </div>
          <div className="custom-button">
            <Button
              id='submit-extend-offer' 
              dataAttribute='submit-extend-offer-button'
              onClick={handleSubmit}
              primary
              isDisable={isDisabled()}
              label="Extend offer"
            ></Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ExtendOffer;
