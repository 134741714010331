import { Description } from '../../atoms/Description';
import { Title } from '../../atoms/Title';
import './pageHeader.scss';

export interface PageHeaderProps {
  title: string;
  description?: string;
  width?: string;
  descriptionClassName?: string;
}
export const PageHeader = ({
  title,
  description,
  width = '984px',
  descriptionClassName,
}: PageHeaderProps) => {
  return (
    <div className="page-header-container">
      <Title text={title} />
      {description && (
        <Description width={width} text={description} className={descriptionClassName} />
      )}
    </div>
  );
};
