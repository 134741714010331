import React from 'react';
import './banner.css';
import { WarningIcon } from '../../Icons/WarningIcon';

interface BannerProps {
  message: string;
  title: string;
  className?: string;
}

const Banner: React.FC<BannerProps> = ({ title, message, className }) => {
  return (
    <div className={className ? className : 'banner-root'}>
      <WarningIcon/>
      <h2>{title}</h2>
      <p>{message}</p>
    </div>
  );
};

export default Banner;
