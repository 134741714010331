export const CautionIcon = () => {
  return (
    <span>
      <svg
        height="80px"
        width="80px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/1999/xlink"
        viewBox="-19.71 -19.71 236.56 236.56"
        xmlSpace="preserve"
        fill="#9c9a91"
        stroke="#9c9a91"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
          <rect
            x="-19.71"
            y="-19.71"
            width="236.56"
            height="236.56"
            rx="118.28"
            fill="#FFB610"
            strokeWidth="0"
          />
        </g>

        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="#CCCCCC"
          stroke-width="0.394286"
        />

        <g id="SVGRepo_iconCarrier">
          {' '}
          <g>
            {' '}
            <g>
              {' '}
              <path
                style={{ fill: '#f0f0f0' }}
                d="M195.031,166.074l-85.592-148.24c-2.226-3.89-6.403-6.306-10.89-6.306 c-4.477,0-8.65,2.412-10.894,6.292L1.68,166.747c-2.24,3.876-2.24,8.689,0,12.565c2.24,3.887,6.413,6.302,10.887,6.302h172.01 c6.929,0,12.565-5.644,12.565-12.58C197.143,170.447,196.377,167.956,195.031,166.074z M184.577,178.324H12.571 c-1.882,0-3.643-1.009-4.585-2.645c-0.945-1.636-0.948-3.665,0-5.3L93.961,21.456c0.941-1.628,2.698-2.645,4.588-2.645 c1.882,0,3.654,1.016,4.592,2.645l85.764,148.537c0.626,0.895,0.966,1.943,0.966,3.046 C189.871,175.952,187.491,178.324,184.577,178.324z"
              />{' '}
              <polygon
                style={{ fill: '#f0f0f0' }}
                points="102.504,134.938 104.486,67.255 89.709,67.255 91.681,134.938 "
              />{' '}
              <path
                style={{ fill: '#f0f0f0' }}
                d="M97.096,144.637c-5.146,0-8.879,3.905-8.879,9.28c0,5.39,3.733,9.294,8.879,9.294 c5.229,0,8.886-3.815,8.886-9.294C105.982,148.452,102.328,144.637,97.096,144.637z"
              />{' '}
            </g>{' '}
          </g>{' '}
        </g>
      </svg>
    </span>
  );
};
