import React, { useState, useRef, useEffect } from 'react';
import './Dropdown.css';
import { DropDownIcon } from '../../Icons';

export interface DropdownProps {
  title: string;
  options: string[];
  selectedOptions: string[];
  onSelect: (selected: string[]) => void;
  isDisabled?: boolean;
  handleClearDropdown: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  title,
  options,
  selectedOptions,
  onSelect,
  isDisabled = false,
  handleClearDropdown,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: string) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    onSelect(newSelectedOptions);
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      onSelect([]);
    } else {
      onSelect(options);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const getDropDownLabel = () => {
    if (selectedOptions.length > 0) {
      if (selectedOptions.length !== 1 && selectedOptions.length === options.length) {
        return `(ALL)`;
      }
      return `(${selectedOptions.length})`;
    }
    return '';
  };

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown} disabled={isDisabled}>
        {title} {getDropDownLabel()}
        <span className={`dropdown-icon ${isOpen ? 'open' : ''}`}>
          <DropDownIcon />
        </span>
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {options?.length > 1 && (
            <div className="dropdown-header">
              Select All
              <input
                type="checkbox"
                checked={selectedOptions.length !== 1 && selectedOptions.length === options.length}
                onChange={handleSelectAll}
              />
            </div>
          )}
          {options.map((option) => (
            <div key={option}>
              {option}
              <input
                id={`multi-hotels-filtering-option-${option}-id`}
                data-role={`multi-hotels-filtering-option-${option}-input`}
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionClick(option)}
              />
            </div>
          ))}
          <hr />
          <div className="dropdown-footer" onClick={handleClearDropdown}>
            X Clear
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
