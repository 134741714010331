import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './menuEdit.scss';
import { ExternalLinkIcon } from '../../../Icons/ExternalLinkIcon';
interface IMenuEditProps {
  name: string;
  info: any;
  onClick?: () => {};
  className?: string;
  infoType?: 'link' | string;
  linkUrl?: string;
  isRichText?: boolean;
  enableSeeMore?: boolean;
  onSeeMoreSpiritCodeClick?: () => void;
}

const getInfo = (
  info: any,
  isRichText: boolean,
  infoType?: string,
  linkUrl?: string
): React.ReactNode => {
  try {
    if (typeof info !== 'object') {
      if (
        infoType === 'link' &&
        info !== undefined &&
        info !== '' &&
        linkUrl !== undefined &&
        linkUrl !== ''
      ) {
        return (
          <a href={linkUrl} download>
            {info.toString()}
          </a>
        );
      } else if (isRichText) {
        return (
          <span>
            {info === undefined || info === '' ? 'N/A' : ReactHtmlParser(info.toString())}
          </span>
        );
      } else {
        return <span>{info === undefined || info === '' ? 'N/A' : info.toString()}</span>;
      }
    } else {
      if (info.length > 0) {
        return info?.map(
          (e: { [s: string]: React.ReactNode } | ArrayLike<React.ReactNode>, i: number) => (
            <div key={i}>
              <span>{Object.values(e)}</span>
              {info.length !== i + 1 && <span>, </span>}
            </div>
          )
        );
      } else {
        return <span>N/A</span>;
      }
    }
  } catch (error) {}
};

const MenuEdit: React.FC<IMenuEditProps> = ({
  name,
  info,
  onClick,
  className,
  infoType,
  linkUrl,
  isRichText = false,
  enableSeeMore = false,
  onSeeMoreSpiritCodeClick,
}) => {
  return (
    <div className={`offer-information-data ${className || ''}`}>
      <div className="information">
        <span className="b-form-textarea__label">
          {name}{' '}
          {enableSeeMore && (
            <a className="review-and-submit-see-more" onClick={onSeeMoreSpiritCodeClick}>
              <ExternalLinkIcon width="15" height="15" />
              See More
            </a>
          )}
        </span>
        {getInfo(info, isRichText, infoType, linkUrl)}
      </div>
    </div>
  );
};

export default MenuEdit;
