export const PersonIcon = () => {
  return (
    <span>
      <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="#282828" fillRule="evenodd" transform="translate(-6 -6)">
          <path d="m16 7c4.9705627 0 9 4.0294373 9 9 0 2.6374415-1.1344855 5.0099096-2.9420826 6.6560307-1.2108066-2.1055131-3.4695407-3.7148542-6.0579028-3.7148542-2.5900622 0-4.8515869 1.6028857-6.06224309 3.70971-1.80538287-1.645364-2.93777151-4.0158835-2.93777151-6.6508865 0-4.9705627 4.0294373-9 9-9zm.0000219 4c-1.9329999 0-3.5 1.5801715-3.5 3.5294118s1.5670001 3.5294117 3.5 3.5294117c1.9329853 0 3.4999854-1.5801714 3.4999854-3.5294117s-1.5670001-3.5294118-3.4999854-3.5294118z"></path>
          <path d="m16 7c4.9705627 0 9 4.0294373 9 9s-4.0294373 9-9 9-9-4.0294373-9-9 4.0294373-9 9-9zm0 1c-4.418278 0-8 3.581722-8 8s3.581722 8 8 8 8-3.581722 8-8-3.581722-8-8-8z"></path>
        </g>
      </svg>
    </span>
  );
};
