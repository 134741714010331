export const PlusIcon = ({ bgColor = 'black' }) => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={bgColor}
        height="22"
        width="22"
        viewBox="0 0 32 32"
        aria-hidden="true"
      >
        <path d="M16 6c5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10S10.477 6 16 6zm0 4a1 1 0 00-1 1v4h-4a1 1 0 100 2h4v4a1 1 0 002 0v-4h4a1 1 0 000-2h-4v-4a1 1 0 00-1-1z"></path>
      </svg>
    </span>
  );
};
