import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { Select } from '../../components/atoms/Select';
import { Input } from '../../components/atoms/Input';
import './OfferCode.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { ThunkDispatch } from '@reduxjs/toolkit';
import useDropdownOfferCodeOptions from './useDropdownOfferCodeOptions';
import nls from '../../nls/HTBToolBox.json';
import { Button } from '../../components/atoms/Button';
import { PlusIcon } from '../../components/Icons/PlusIcon';
import Trashcan from '../../components/Icons/Trashcan';
import { extractOfferCategory } from '../../utils/fileUtils';
import { useLocation } from 'react-router-dom';

type depositOptionsType = {
  label: string;
  value: string;
}
type cancellationPolicyType = {
  label: string;
  value: string;
}
export const OfferCode: React.FC = () => {
  const [offerCodeError, setOfferCodeError] = useState(false);
  const {
    offers: { offerType, pricing },
    offers,
    showError,
    offerCodeState,
    offerCacellationPolicies,
    offerDepositPolicies,
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isPriveOffer = offerType === 'hyatt-prive';
  const isPriveAmexToolBoxOffer = offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox';
  const [shouldAddCreditCard, setShouldAddCreditCard] = useState(true); // Flag to control the addition
  const [shouldRemoveHousePolicy, setShouldRemoveHousePolicy] = useState(true);
  const [offerDepositPolicyOptionsState, setOfferDepositPolicyOptionsState] = useState<depositOptionsType[]>([]);
  const [cancellationPolicyOptions, setCancellationPolicyOptions]= useState<cancellationPolicyType[]>([]);
  useEffect(() => {
    window.utag_data.page_name = 'offertool-offercode';
  }, []);

  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [penaltyPolicyText, setPenaltyPolicyText] = useState('');
  const location = useLocation();
  let isToolBoxOffer = extractOfferCategory(location.search) === 'toolbox-offer';
  
  const {
    cancellationAndCodes: { offerCodes, depositPolicy, penaltyPolicy, cancellationPolicy },
  } = useSelector((state: any) => state.offerTool.offers);
  const { assignCorrespondingToDeposit, options } = useDropdownOfferCodeOptions();

  useEffect(()=>{
    switch (offerType) {
      case 'htb-percent-off':
        setHeading(nls.HEADING);
        setSubHeading(nls.OFFER_CODE_GUARANTEE_TOOLBOX);
        setCancellationPolicyOptions(offerCacellationPolicies);
        setPenaltyPolicyText(nls.SELECT_ABOVE_POLICY)
        setOfferDepositPolicyOptionsState(offerDepositPolicies);
        break;
  
      case 'htb-rack-plus-attribute':
        setHeading(nls.HEADING);                
        setSubHeading(nls.OFFER_CODE_GUARANTEE_TOOLBOX);
        setCancellationPolicyOptions(offerCacellationPolicies);
        setPenaltyPolicyText(nls.SELECT_ABOVE_POLICY)
        setOfferDepositPolicyOptionsState(offerDepositPolicies);
        break;
        
      case 'hyatt-prive':
        setHeading(nls.HEADING_PRIVE);                
        setSubHeading(nls.OFFER_CODE_PRIVE);
        setCancellationPolicyOptions(offerCacellationPolicies);
        setPenaltyPolicyText(nls.SELECT_ABOVE_POLICY)
        setOfferDepositPolicyOptionsState(offerDepositPolicies);
          break;
      case 'hyatt-luxury-consortia':
        setHeading(nls.HEADING_PRIVE);    
        setSubHeading(nls.OFFER_CODE_LUXURY_CONSORTIA);
        setCancellationPolicyOptions(offerCacellationPolicies);
        setPenaltyPolicyText(nls.SELECT_ABOVE_POLICY)
        setOfferDepositPolicyOptionsState(offerDepositPolicies);
          break;
      case 'hyatt-prive-amex-luxury-consortia-discount-toolbox':
        setHeading(nls.HEADING_PRIVE);  
        setSubHeading('');
        setCancellationPolicyOptions(offerCacellationPolicies);
        setPenaltyPolicyText(nls.SELECT_ABOVE_POLICY)
        setOfferDepositPolicyOptionsState(offerDepositPolicies);
        break;
      default:
        setHeading(nls.HEADING);      
        setSubHeading(nls.OFFER_CODE_GUARANTEE_OTHER);
        setCancellationPolicyOptions(offerCacellationPolicies);
        setPenaltyPolicyText(nls.SELECT_ABOVE_POLICY)
        setOfferDepositPolicyOptionsState(offerDepositPolicies);
    }
    if (shouldAddCreditCard) {
      // Check the condition to add "Credit Card"
      if (pricing?.offerRack === 'equal-or-greater-than-rack') {
        setOfferDepositPolicyOptionsState(prevOptions => {
          const optionsArray = Array.isArray(prevOptions) ? prevOptions : [];
          return [
            ...optionsArray,
            { label: 'Credit Card', value: 'Credit Card' }
          ]
        });
        setShouldAddCreditCard(false); // Ensure it only adds once
      }
    }
    if (shouldRemoveHousePolicy) {
      // Check the condition to remove "House Policy"
      if (offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox') {
        setCancellationPolicyOptions(prevOptions => {
          const optionsArray = Array.isArray(prevOptions) ? prevOptions : [];
          return optionsArray.filter(option => option.value !== 'House Policy');
        });
        setOfferDepositPolicyOptionsState(prevOptions => {
          const optionsArray = Array.isArray(prevOptions) ? prevOptions : [];
          return optionsArray.filter(option => option.value !== 'House Policy');
        });
        setShouldRemoveHousePolicy(false); // Ensure it only adds once
      }
    }
  },[offerCacellationPolicies, offerDepositPolicies, offerType, pricing?.offerRack]);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
    if (offerType === 'htb-rack-plus-attribute' && depositPolicy === '') {
      dispatch(handleChange({ name: 'cancellationPolicy', value: 'House Policy' }));
      dispatch(handleChange({ name: 'penaltyPolicy', value: 'House Policy' }));
      dispatch(handleChange({ name: 'depositPolicy', value: 'House Policy' }));
    } else if (offerType === 'htb-rack-plus-attribute') {
      dispatch(handleChange({ name: 'offerCodes', value: [] }));
      dispatch(handleChange({ name: 'offerCodeState', value: 'HYVALUE' }));
    } else if(isPriveOffer || offerType === 'hyatt-luxury-consortia'){
      dispatch(handleChange({ name: 'cancellationPolicy', value: 'House Policy' }));
      dispatch(handleChange({ name: 'penaltyPolicy', value: 'House Policy' }));
      dispatch(handleChange({ name: 'depositPolicy', value: 'House Policy' }));
    } else if (pricing?.offerRack === 'less-than-rack' && depositPolicy === '') {
      dispatch(handleChange({ name: 'depositPolicy', value: '1-night-deposit' }));
      dispatch(handleChange({ name: 'penaltyPolicy', value: '1-night-rm-tax' }));
    }
  }, []);



  const handleOfferCode = () => {
    if (!offerCodeState || offerCodeState.length < 4) {
      setOfferCodeError(true);
    } else {
      const newOfferCode = offerCodeState;
      dispatch(handleChange({ name: 'offerCodes', value: [...offerCodes, newOfferCode] }));
      dispatch(handleChange({ name: 'offerCodeState', value: '' }));
      setOfferCodeError(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  };

  const handleOfferCodeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(handleChange({ name: 'offerCodeState', value: value }));
  };

  const handleDelete = (index: number) => {
    const filteredValue = offers?.cancellationAndCodes?.offerCodes.filter(
      (item: any, key: number) => key !== index
    );
    dispatch(handleChange({ name: 'offerCodes', value: filteredValue }));
  };

  useEffect(() => {
    if (isToolBoxOffer) {
      dispatch(handleChange({ name: 'page', value: 6 }));
    } else {
      dispatch(handleChange({ name: 'page', value: 9 }));
    }
  }, []);

  useEffect(() => {
    if (showError) {
      const offerCode = offerCodeState;
      if (offerCode || offerCode.length < 4) {
        setOfferCodeError(true);
      }
    } else {
      setOfferCodeError(false);
    }
  }, [showError]);

  const handleDepositPolicy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(handleChange({ name: e.target.name, value: e.target.value }));
    const map = assignCorrespondingToDeposit[e.target.value];
    const penaltyPolicyValue = options.penaltyPolicyOptions.find((e) => e.label === map);
    dispatch(handleChange({ name: 'penaltyPolicy', value: penaltyPolicyValue?.value }));
  };

  const getPenaltyPolicyValue = (value: string) => {
    if (value === 'House Policy') {
      return 'House Policy';
    } else {
      const option = options.penaltyPolicyOptions.find((option) => option.value === value);
      return option ? option.label : 'Select Penalty Policy';
    }
  };

  const OfferCodeLabel = nls.OFFER_CODE;
  const addOfferCodeLabel = isToolBoxOffer ? nls.ADD_OFFER_CODE_OPT : nls.ADD_OFFER_CODE;

  return (
    <section className="container" style={{ alignItems: 'center' }}>
      <PageHeader
        title={heading}
        description={subHeading}
        descriptionClassName="offer-code-description"
      />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <article className="offer-code-info">
        {!isPriveOffer && offerType !== 'hyatt-luxury-consortia' && !isPriveAmexToolBoxOffer &&
          <>
            <div className="code1-info">
              <div className="code1-link">
                <Input
                  id='offer-code-and-cancellation-policy-offer-code-value'
                  dataAttribute='offer-code-and-cancellation-policy-offer-code-input'
                  label={OfferCodeLabel}
                  labelClassName="p-base-normal"
                  value={offerCodeState}
                  placeHolder=""
                  type="text"
                  onChange={handleOfferCodeValue}
                  onKeyDown={handleKeyDown}
                  maxLength={9}
                  readOnly={offerType === 'htb-rack-plus-attribute'}
                />
                {offerCodeError && offerCodeState !== '' && offerCodeState.length < 4 && (
                  <div className="error-componenet">
                    <WarningIcon width={15} height={15} />
                    <p className="error-message">
                      Offer codes must be a minimum of 4 characters or a maximum of 9 characters.
                    </p>
                  </div>
                )}
                {offerType !== 'htb-rack-plus-attribute' ? <Button
                  id='offer-code-and-cancellation-policy-add-offer-code'
                  dataAttribute='offer-code-and-cancellation-policy-offer-code-button'
                  label={addOfferCodeLabel}
                  type="link"
                  icon={<PlusIcon bgColor="#0072ce" />}
                  onClick={handleOfferCode}
                  className="line-1"
                />
                  : <div className='offer-code-separator'> </div>
                }
                {offerCodes?.map((offer: any, index: number) => {
                  return (
                    <div
                      style={{ display: 'flex', alignItems: 'baseline', marginLeft: '30px' }}
                      key={index}
                    >
                      <Input
                        id='offer-code-and-cancellation-policy-offer-codes'
                        dataAttribute='offer-code-and-cancellation-policy-offer-codes-list'
                        className="additional-offer-code-added"
                        placeHolder=""
                        type="text"
                        key={index}
                        value={offer}
                        readOnly
                      />
                      <button
                        className="addtional-offer-delete-button"
                        onClick={() => handleDelete(index)}
                      >
                        <Trashcan />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <hr className="offer-code-hr" />
          </>
        }

        <div className="offer-code-info2">
          <div className="cancellation"></div>
          <Select
            id='offer-code-and-cancellation-deposite-policy'
            dataAttribute='offer-code-and-cancellation-deposite-policy-select'
            label="* Guarantee/Deposit Policy"
            className="offer-code-use"
            labelClassName="p-base-normal"
            name="depositPolicy"
            value={depositPolicy}
            onChange={(e) => handleDepositPolicy(e)}
            options={offerDepositPolicyOptionsState}
            disabled={isPriveOffer || offerType === 'hyatt-luxury-consortia'}
          />
          {showError && depositPolicy === '' && (
            <div className="inline-error">
              <WarningIcon width={15} height={15} />
              <p className="error-label">Select a deposit policy</p>
            </div>
          )}
          <Input
            id='offer-code-and-cancellation-penalty-policy'
            dataAttribute='offer-code-and-cancellation-penalty-policy-select'
            label="* Penalty Policy"
            labelClassName="p-base-normal"
            name="penaltyPolicy"
            placeHolder={penaltyPolicyText}
            className={isPriveOffer || offerType === 'hyatt-luxury-consortia' ? "offer-code-use-disabled" : "offer-code-use"}
            value={getPenaltyPolicyValue(penaltyPolicy)}
            type="text"
            readOnly
          ></Input>
          <Select
            id='offer-code-and-cancellation-policy'
            dataAttribute='offer-code-and-cancellation-policy-select'
            label="* Cancel Policy"
            className="offer-code-use"
            labelClassName="p-base-normal"
            options={cancellationPolicyOptions}
            name="cancellationPolicy"
            value={cancellationPolicy}
            disabled={isPriveOffer || offerType === 'hyatt-luxury-consortia'}
            onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
          />
          {showError && !cancellationPolicy && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                marginTop: '-10px',
              }}
            >
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red' }}>Select a cancel policy</p>
            </div>
          )}
        </div>
        <div className="bottom-componenet"></div>
      </article>
    </section>
  );
};
