import { FC } from 'react';
import { InfoIcon } from '../../Icons/InfoIcon';
import './Select.css';
export interface Option {
  label: string;
  value: string | number;
}
interface SelectProps {
  id: string
  label: string;
  options: Option[];
  disabled?: boolean;
  children?: any;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onClick?: () => void;
  className?: string;
  icon?: boolean;
  value?: string;
  labelClassName?: string;
  customCss?: string;
  isReporting?: boolean;
  dataAttribute: string;
}

export const Select: FC<SelectProps> = ({
  id,
  label,
  options = [],
  disabled = false,
  name,
  onChange,
  onClick,
  icon = false,
  className,
  value,
  labelClassName,
  customCss,
  isReporting,
  dataAttribute,
}) => {
  return (
    <label className={`b-form-select ${customCss} ${disabled ? 'select-disabled' : ''}`}>
      {icon ? (
        <div className="select-info">
          <span
            className="b-form-select__label select-info-span"
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {/* <InfoIcon /> */}
        </div>
      ) : (
        <span
          className={`b-form-select__label ${labelClassName}`}
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}

      <select
        id={id}
        data-role={dataAttribute}
        onChange={onChange}
        onClick={onClick}
        className={`b-form-select__control custom-select ${className} ${
          isReporting && 'dropDownReporting'
        }`}
        disabled={disabled}
        name={name}
        value={value}
      >
        {options.map(({ label, value }, index) => {
          return (
            <option key={index} value={value} className="p-base-normal">
              {label}
            </option>
          );
        })}
      </select>
    </label>
  );
};
