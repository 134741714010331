export interface CrossIconPropType {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  style?: string;
}
export const CrossIcon = ({ onClick, style="cross-icon" }: CrossIconPropType) => {
  return (
    <span className={style} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 32 32"
        aria-hidden="true"
      >
        <path d="M25.733 6.266a.91.91 0 01.076 1.2l-.076.086-8.448 8.447 8.448 8.449a.91.91 0 01-1.2 1.361l-.085-.075L16 17.284l-8.448 8.45a.91.91 0 01-1.362-1.2l.076-.086L14.714 16 6.266 7.552a.91.91 0 011.2-1.361l.086.075 8.447 8.448 8.448-8.448a.91.91 0 011.286 0z"></path>
      </svg>
    </span>
  );
};
