import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
interface option {
  label: string;
  value: string;
}
interface Options {
  [key: string]: option[];
}
export const options: Options = {
  depositPolicyOptions: [
    { label: 'Select a deposit policy', value: '' },
    { label: '1 Night Deposit', value: '1-night-deposit' },
    { label: '2 Night Deposit', value: '2-night-deposit' },
    { label: '50% Deposit', value: '50-percent-deposit' },
    { label: '100% Deposit', value: '100-percent-deposit' },
    {
      label: '1 Nt Deposit (NR) - Remaining at Cxl (NR)',
      value: '1-nt-deposit-nr-remaining-cxl-nr',
    },
    {
      label: '2 Nt Deposit (NR) - Remaining at CxI (NR)',
      value: '2-nt-deposit-nr-remaining-cxi-nr',
    },
    {
      label: '50% Deposit (NR) - Remaining at Cxl (NR)',
      value: '50-percent-deposit-nr-remaining-cxl-nr',
    }, {
      label: 'House Policy', value: 'House Policy',
    },
  ],
  penaltyPolicyOptions: [
    { label: 'Select a penalty policy', value: '' },
    { label: '1 Night Rm/Tax', value: '1-night-rm-tax' },
    { label: '2 Night Rm/Tax', value: '2-night-rm-tax' },
    { label: '50% of Stay', value: '50-percent-of-stay' },
    { label: '100% of Stay', value: '100-percent-of-stay' },
    {
      label: '1 Nt Deposit (NR) - Remaining at Cxl (NR)',
      value: '1-nt-deposit-nr-remaining-cxl-nr',
    },
    {
      label: '2 Nt Deposit (NR) - Remaining at CxI (NR)',
      value: '2-nt-deposit-nr-remaining-cxi-nr',
    },
    {
      label: '50% Deposit (NR) - Remaining at Cxl (NR)',
      value: '50-percent-deposit-nr-remaining-cxl-nr',
    }, {
      label: 'House Policy', value: 'House Policy',
    },
    { label: 'Credit Card', value: 'Credit Card' },
  ],
  cancelPolicyOptions: [
    { label: 'Select a cancel policy', value: '' },
    { label: '72H', value: '72H' },
    { label: '7D', value: '7D' },
    { label: '14D', value: '14D' },
    { label: '21D', value: '21D' },
    { label: '30D', value: '30D' },
    { label: '60D/90D', value: '60D/90D' },
    { label: 'No Free Cancel', value: 'no-free-cancel' }, {
      label: 'House Policy', value: 'House Policy',
    },
  ],
};
const assignCorrespondingToDeposit: { [key: string]: string } = {
  '': '',
  '1-night-deposit': '1 Night Rm/Tax',
  '2-night-deposit': '2 Night Rm/Tax',
  '50-percent-deposit': '50% of Stay',
  '100-percent-deposit': '100% of Stay',
  '1-nt-deposit-nr-remaining-cxl-nr': '1 Nt Deposit (NR) - Remaining at Cxl (NR)',
  '2-nt-deposit-nr-remaining-cxi-nr': '2 Nt Deposit (NR) - Remaining at CxI (NR)',
  '50-percent-deposit-nr-remaining-cxl-nr': '50% Deposit (NR) - Remaining at Cxl (NR)',
  'House Policy': 'House Policy',
  'Credit Card': 'Credit Card'
};
export default function useDropdownOfferCodeOptions() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    dispatch(handleChange({ name: 'offerDepositPolicies', value: options.depositPolicyOptions }));
    dispatch(
      handleChange({ name: 'offerCacellationPolicies', value: options.cancelPolicyOptions })
    );
    dispatch(handleChange({ name: 'offerCancelationPolicyTerm', value: options.penalty }));
  }, []);

  return { assignCorrespondingToDeposit, options };
}
