import Modal from '../../../components/atoms/Modal';
import '../dashboard.css';
import LoadingSpinner from '../../../components/atoms/Spinner';

function EditModal() {
  return (
    <Modal
      cautionIconVisible={false}
      titleSize="25px"
      centerTitle
      title={'Please wait'}
      onClose={() => {}}
    >
      <div className="Modal_custom_content">
        <LoadingSpinner />
      </div>
    </Modal>
  );
}

export default EditModal;
