import React from 'react';

export const InfoIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="special-offers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="05-inclusions" transform="translate(-326.000000, -315.000000)" fill="#282828">
          <g id="Info" transform="translate(326.125000, 315.125000)">
            <path
              d="M6.875,0 C10.6720625,0 13.75,3.0779375 13.75,6.875 C13.75,10.671375 10.6720625,13.75 6.875,13.75 C3.078625,13.75 0,10.671375 0,6.875 C0,3.0779375 3.078625,0 6.875,0 Z M6.875,5.5 C6.49530423,5.5 6.1875,5.80780423 6.1875,6.1875 L6.1875,10.3125 C6.1875,10.6921958 6.49530423,11 6.875,11 C7.25469577,11 7.5625,10.6921958 7.5625,10.3125 L7.5625,6.1875 C7.5625,5.80780423 7.25469577,5.5 6.875,5.5 Z M6.875,2.75 C6.40038029,2.75 6.015625,3.13475529 6.015625,3.609375 C6.015625,4.08399471 6.40038029,4.46875 6.875,4.46875 C7.34961971,4.46875 7.734375,4.08399471 7.734375,3.609375 C7.734375,3.13475529 7.34961971,2.75 6.875,2.75 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
