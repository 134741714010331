import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import './tasks.scss';
import { Title } from '../../components/atoms/Title';
import { getTasks } from '../../service/api';
import TableData from './TableData';
import { handleChange } from '../../store/offerToolSlice';
import LoadingSpinner from '../../components/atoms/Spinner';

export const Tasks: React.FC = () => {
  const { allTasks, isRequestLoading } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    dispatch(handleChange({ name: 'isRequestLoading', value: true }));
    dispatch(getTasks()).then(() => {
      dispatch(handleChange({ name: 'isRequestLoading', value: false }));
    });
  }, []);

  return (
    <div style={{ marginTop: '20px', height: '100%' }}>
      {allTasks ? (
        <TableData data={allTasks} />
      ) : isRequestLoading ? (
        <LoadingSpinner />
      ) : (
        <Title text="No Data To Show" type="h3" />
      )}
    </div>
  );
};
