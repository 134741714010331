import React, { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalenderIcon } from '../../Icons/CalenderIcon';
import { InfoIcon } from '../../Icons/InfoIcon';
import './DatePicker.css';
interface Props {
  label: string;
  className?: string;
  placeholder?: string;
  icon?: boolean;
  value?: Date;
  onChange: (date: Date) => void;
  format?: string;
  locale?: string;
  name?: string;
  labelClassName?: string;
  showTimeInput?: boolean;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
}

export const DatePickerComponent: FunctionComponent<Props> = ({
  label,
  className,
  placeholder,
  icon = false,
  value,
  onChange,
  format = 'dd-MM-yyyy',
  locale,
  labelClassName,
  name,
  showTimeInput = false,
  minDate,
  maxDate,
}) => {
  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <label htmlFor="custom-date" className="b-form-input ">
        {icon ? (
          <div className="calender-info">
            <span className={`calender-info-span ${labelClassName}`}>{label}</span>
            <InfoIcon />
          </div>
        ) : (
          <span className={labelClassName}>{label}</span>
        )}

        <div className={`custom-date-picker ${className}+plp`} id="custom-date">
          <span onClick={props.onClick}>
            <CalenderIcon />
          </span>

          <label onClick={props.onClick} style={{ opacity: props.value ? '1' : '0.5' }} ref={ref}>
            {props.value || props.placeholder}
          </label>
        </div>
      </label>
    );
  });

  return (
    <div className="date-picker">
      <DatePicker
        selected={value}
        dateFormat={format}
        onChange={(date: any) => {
          onChange(date);
        }}
        showTimeInput={showTimeInput}
        timeInputLabel="Time:"
        placeholderText={placeholder}
        shouldCloseOnSelect={true}
        customInput={<CustomInput />}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown
        showYearDropdown
        monthsShown={1}
        />
    </div>
  );
};
