import { ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../../store/offerToolSlice';
import { PageHeader } from '../../../components/molecules/PageHeader';
import nls from '../../../nls/specialOffer.json';
import './ChainHotel.scss';
import { Button } from '../../../components/atoms/Button';
import EditableChipsTextarea from './EditableChipsTextarea';
import Banner from '../../../components/atoms/Banner';
import Dropdown from '../../../components/atoms/Dropdown';

interface Props {
  setShowAlert: (val: boolean) => void;
  chainHotels: any;
}

interface HotelNames {
  name: string;
  title: string;
  country: string;
  region: string;
  brand: string;
  state: string;
}

interface OfferToolState {
  spiritCodeToBeAdded: any;
  allHotelNames: HotelNames[];
}

// Defining the root state interface to assing the type for allHotelNames
interface RootState {
  offerTool: OfferToolState;
}

export const ChainHotel = ({ setShowAlert, chainHotels }: Props) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { spiritCodeToBeAdded, allHotelNames } = useSelector((state: RootState) => state.offerTool);
  const [isSpiritCodeError, setIsSpiritCodeError] = useState(false);
  const [editableIndex, setEditableIndex] = useState<number | null>(null);
  const [onlyOneSpiritFound, setOnlyOneSpiritFound] = useState(false);

  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedSubRegions, setSelectedSubRegions] = useState<string[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedHotels, setSelectedHotels] = useState<string[]>([]);
  const [isApplyButtonDisabled, setApplyButtonDisabled] = useState(true);
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [availableRegions, setAvailableRegions] = useState<string[]>([]);
  const [availableCountries, setAvailableCountries] = useState<string[]>([]);
  const [availableStates, setAvailableStates] = useState<string[]>([]);
  const [availableHotels, setAvailableHotels] = useState<string[]>([]);
  const [lastChangedDropdown, setLastChangedDropdown] = useState<string | null>(null);

  const hasNonUSCountry = (): boolean => {
    return selectedCountries.some((country) => country !== 'United States');
  };

  const filterOptions = () => {
    let filteredHotels = allHotelNames;
    if (selectedBrands.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) => selectedBrands.includes(hotel.brand));
    }
    if (selectedCountries.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) => selectedCountries.includes(hotel.country));
    }
    if (selectedStates.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) => selectedStates.includes(hotel.state));
    }
    if (selectedSubRegions.length > 0) {
      filteredHotels = filteredHotels.filter((hotel) => selectedSubRegions.includes(hotel.region));
    }

    return {
      brands:
        lastChangedDropdown === 'selectedBrands'
          ? availableBrands
          : Array.from(new Set(filteredHotels.map((hotel) => hotel.brand))),
      regions:
        lastChangedDropdown === 'selectedSubRegions'
          ? availableRegions
          : Array.from(new Set(filteredHotels.map((hotel) => hotel.region))),
      countries:
        lastChangedDropdown === 'selectedCountries'
          ? availableCountries
          : Array.from(new Set(filteredHotels.map((hotel) => hotel.country))),
      states:
        lastChangedDropdown === 'selectedStates'
          ? availableStates
          : Array.from(new Set(filteredHotels.map((hotel) => hotel.state))),
      hotels:
        lastChangedDropdown === 'selectedHotels'
          ? availableHotels
          : Array.from(new Set(filteredHotels.map((hotel) => hotel.title))),
    };
  };

  const onFilter = () => {
    const filtered = allHotelNames
      ?.filter(
        (hotel) =>
          (selectedBrands.length === 0 || selectedBrands.includes(hotel.brand)) &&
          (selectedSubRegions.length === 0 || selectedSubRegions.includes(hotel.region)) &&
          (selectedCountries.length === 0 || selectedCountries.includes(hotel.country)) &&
          (selectedStates.length === 0 || selectedStates.includes(hotel.state)) &&
          (selectedHotels.length === 0 || selectedHotels.includes(hotel.title))
      )
      .map((hotel) => hotel.name);
    dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: filtered }));
  };

  useEffect(() => {
    const filteredOptions = filterOptions();
    setAvailableBrands(filteredOptions.brands?.filter(Boolean).sort());
    setAvailableRegions(filteredOptions.regions?.filter(Boolean).sort());
    setAvailableCountries(filteredOptions.countries?.filter(Boolean).sort());
    setAvailableStates(filteredOptions.states?.filter(Boolean).sort());
    setAvailableHotels(filteredOptions.hotels?.filter(Boolean).sort());
  }, [
    selectedBrands,
    selectedSubRegions,
    selectedCountries,
    selectedStates,
    allHotelNames,
    lastChangedDropdown,
  ]);

  useEffect(() => {
    return () => {
      dispatch(handleChange({ name: 'isAddHotelChian', value: false }));
    };
  }, []);

  const onAddHotelChain = () => {
    dispatch(handleChange({ name: 'hotelChain', value: spiritCodeToBeAdded }));
    dispatch(handleChange({ name: 'isAddHotelChian', value: false }));
    dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: [] }));
    setShowAlert(true);
  };

  useEffect(() => {
    if (chainHotels.length > 0) {
      dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: chainHotels }));
    }
  }, []);

  const onCloseHotelChain = () => {
    if (spiritCodeToBeAdded.length === 0) {
      dispatch(handleChange({ name: 'hotelChain', value: [] }));
    }
    dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: [] }));
    dispatch(handleChange({ name: 'isAddHotelChian', value: false }));
  };

  const handleClearDropdown = (dropdownName: string) => {
    switch (dropdownName) {
      case 'brand':
        setSelectedBrands([]);
        break;
      case 'regions':
        setSelectedSubRegions([]);
        break;
      case 'country':
        setSelectedCountries([]);
        break;
      case 'state':
        setSelectedStates([]);
        break;
      case 'hotel':
        setSelectedHotels([]);
        break;
      default:
        break;
    }
  };

  const handleBrandSelect = (selected: string[]) => {
    setSelectedBrands(selected);
    setLastChangedDropdown('selectedBrands'); 

    const filteredHotels = allHotelNames.filter(
      (hotel) =>
        selected.includes(hotel.brand) &&
        (selectedCountries.length === 0 || selectedCountries.includes(hotel.country)) &&
        (selectedSubRegions.length === 0 || selectedSubRegions.includes(hotel.region)) &&
        (selectedStates.length === 0 || selectedStates.includes(hotel.state))
    );
  
    const filteredCountries = Array.from(new Set(filteredHotels.map((hotel) => hotel.country)));
    const filteredSubRegions = Array.from(new Set(filteredHotels.map((hotel) => hotel.region)));
    const filteredStates = Array.from(new Set(filteredHotels.map((hotel) => hotel.state)));
    const filteredHotelsList = Array.from(new Set(filteredHotels.map((hotel) => hotel.title)));
  
    setAvailableCountries((prev) => prev.filter(country => filteredCountries.includes(country)));
    setAvailableRegions((prev) => prev.filter(region => filteredSubRegions.includes(region)));
    setAvailableStates((prev) => prev.filter(state => filteredStates.includes(state)));
    setAvailableHotels((prev) => prev.filter(hotel => filteredHotelsList.includes(hotel)));
  
    setSelectedCountries((prev) => prev.filter((country) => filteredCountries.includes(country)));
    setSelectedSubRegions((prev) => prev.filter((region) => filteredSubRegions.includes(region)));
    setSelectedStates((prev) => prev.filter((state) => filteredStates.includes(state)));
    setSelectedHotels((prev) => prev.filter((hotel) => filteredHotelsList.includes(hotel)));
  };
  

  const handleSubRegionSelect = (selected: string[]) => {
    setSelectedSubRegions(selected);
    setLastChangedDropdown('selectedSubRegions');
  
    const filteredHotels = allHotelNames.filter(
      (hotel) =>
        selected.includes(hotel.region) &&
        (selectedBrands.length === 0 || selectedBrands.includes(hotel.brand)) &&
        (selectedCountries.length === 0 || selectedCountries.includes(hotel.country)) &&
        (selectedStates.length === 0 || selectedStates.includes(hotel.state))
    );
  
    const filteredBrands = Array.from(new Set(filteredHotels.map((hotel) => hotel.brand)));
    const filteredCountries = Array.from(new Set(filteredHotels.map((hotel) => hotel.country)));
    const filteredStates = Array.from(new Set(filteredHotels.map((hotel) => hotel.state)));
    const filteredHotelsList = Array.from(new Set(filteredHotels.map((hotel) => hotel.title)));
  
    setAvailableBrands((prev) => prev.filter(brand => filteredBrands.includes(brand)));
    setAvailableCountries((prev) => prev.filter(country => filteredCountries.includes(country)));
    setAvailableStates((prev) => prev.filter(state => filteredStates.includes(state)));
    setAvailableHotels((prev) => prev.filter(hotel => filteredHotelsList.includes(hotel)));
  
    setSelectedBrands((prev) => prev.filter((brand) => filteredBrands.includes(brand)));
    setSelectedCountries((prev) => prev.filter((country) => filteredCountries.includes(country)));
    setSelectedStates((prev) => prev.filter((state) => filteredStates.includes(state)));
    setSelectedHotels((prev) => prev.filter((hotel) => filteredHotelsList.includes(hotel)));
  };
  

  const handleCountrySelect = (selected: string[]) => {
    setSelectedCountries(selected);
    setLastChangedDropdown('selectedCountries');
  
    const filteredHotels = allHotelNames.filter(
      (hotel) =>
        selected.includes(hotel.country) &&
        (selectedBrands.length === 0 || selectedBrands.includes(hotel.brand)) &&
        (selectedSubRegions.length === 0 || selectedSubRegions.includes(hotel.region)) &&
        (selectedStates.length === 0 || selectedStates.includes(hotel.state))
    );
  
    const filteredBrands = Array.from(new Set(filteredHotels.map((hotel) => hotel.brand)));
    const filteredSubRegions = Array.from(new Set(filteredHotels.map((hotel) => hotel.region)));
    const filteredStates = Array.from(new Set(filteredHotels.map((hotel) => hotel.state)));
    const filteredHotelsList = Array.from(new Set(filteredHotels.map((hotel) => hotel.title)));
  
    setAvailableBrands((prev) => prev.filter(brand => filteredBrands.includes(brand)));
    setAvailableRegions((prev) => prev.filter(region => filteredSubRegions.includes(region)));
    setAvailableStates((prev) => prev.filter(state => filteredStates.includes(state)));
    setAvailableHotels((prev) => prev.filter(hotel => filteredHotelsList.includes(hotel)));
  
    setSelectedBrands((prev) => prev.filter((brand) => filteredBrands.includes(brand)));
    setSelectedSubRegions((prev) => prev.filter((region) => filteredSubRegions.includes(region)));
    setSelectedStates((prev) => prev.filter((state) => filteredStates.includes(state)));
    setSelectedHotels((prev) => prev.filter((hotel) => filteredHotelsList.includes(hotel)));
  };
  

  const handleStateSelect = (selected: string[]) => {
    setSelectedStates(selected);
    setLastChangedDropdown('selectedStates');
  
    const filteredHotels = allHotelNames.filter(
      (hotel) =>
        selected.includes(hotel.state) &&
        (selectedBrands.length === 0 || selectedBrands.includes(hotel.brand)) &&
        (selectedCountries.length === 0 || selectedCountries.includes(hotel.country)) &&
        (selectedSubRegions.length === 0 || selectedSubRegions.includes(hotel.region))
    );
  
    const filteredBrands = Array.from(new Set(filteredHotels.map((hotel) => hotel.brand)));
    const filteredCountries = Array.from(new Set(filteredHotels.map((hotel) => hotel.country)));
    const filteredSubRegions = Array.from(new Set(filteredHotels.map((hotel) => hotel.region)));
    const filteredHotelsList = Array.from(new Set(filteredHotels.map((hotel) => hotel.title)));
  
    setAvailableBrands((prev) => prev.filter(brand => filteredBrands.includes(brand)));
    setAvailableCountries((prev) => prev.filter(country => filteredCountries.includes(country)));
    setAvailableRegions((prev) => prev.filter(region => filteredSubRegions.includes(region)));
    setAvailableHotels((prev) => prev.filter(hotel => filteredHotelsList.includes(hotel)));
  
    setSelectedBrands((prev) => prev.filter((brand) => filteredBrands.includes(brand)));
    setSelectedCountries((prev) => prev.filter((country) => filteredCountries.includes(country)));
    setSelectedSubRegions((prev) => prev.filter((region) => filteredSubRegions.includes(region)));
    setSelectedHotels((prev) => prev.filter((hotel) => filteredHotelsList.includes(hotel)));
  };
  

  const handleHotelSelect = (selected: string[]) => {
    setSelectedHotels(selected);
    setLastChangedDropdown('selectedHotels');
  };

  useEffect(() => {
    if (
      selectedBrands.length > 0 ||
      selectedSubRegions.length > 0 ||
      selectedCountries.length > 0 ||
      selectedStates.length > 0 ||
      selectedHotels.length > 0
    ) {
      setApplyButtonDisabled(false);
    } else {
      setApplyButtonDisabled(true);
    }
  }, [selectedBrands, selectedCountries, selectedSubRegions, selectedStates, selectedHotels]);

  return (
    <section className="chain-container">
      <PageHeader title={nls.ADD_CHAIN_HOTEL_TITLE} description={nls.ADD_CHAIN_HOTEL_DESCRIPTION} />
      {isSpiritCodeError && (
        <div className="error-heading">
          <Banner
            title="Errors on page"
            message="Double click the spirit code in red to edit and click checkmark to save."
          />
        </div>
      )}
      {!isSpiritCodeError && onlyOneSpiritFound && (
        <div className="error-heading">
          <Banner
            title="Errors on page"
            message="You must enter more than 1 spirit code to create a Multiple Hotel Offer"
          />
        </div>
      )}
      <div className="find-hotels-container">
        <h3 className="h1">Find Hotels</h3>
        <div className="filter-components">
          <Dropdown
            title={selectedBrands.length > 1 ? 'Brands' : 'Brand'}
            selectedOptions={selectedBrands}
            onSelect={handleBrandSelect}
            options={availableBrands}
            handleClearDropdown={() => handleClearDropdown('brand')}
          />
          <Dropdown
            title={selectedSubRegions.length > 1 ? 'Regions' : 'Region'}
            onSelect={handleSubRegionSelect}
            options={availableRegions}
            selectedOptions={selectedSubRegions}
            handleClearDropdown={() => handleClearDropdown('regions')}
          />
          <Dropdown
            title={selectedCountries.length > 1 ? 'Countries' : 'Country'}
            onSelect={handleCountrySelect}
            options={availableCountries}
            selectedOptions={selectedCountries}
            handleClearDropdown={() => handleClearDropdown('country')}
          />
          <Dropdown
            title={selectedStates.length > 1 ? 'States' : 'State'}
            onSelect={handleStateSelect}
            options={availableStates}
            selectedOptions={selectedStates}
            isDisabled={hasNonUSCountry() || selectedCountries.length === 0}
            handleClearDropdown={() => handleClearDropdown('state')}
          />
          <Dropdown
            title={selectedHotels.length > 1 ? 'Hotels' : 'Hotel'}
            onSelect={handleHotelSelect}
            options={availableHotels}
            selectedOptions={selectedHotels}
            handleClearDropdown={() => handleClearDropdown('hotel')}
          />
          <Button
            id="basics-chain-hotels-add-hotels"
            dataAttribute="basics-chain-hotels-add-hotels-button"
            isloading={false}
            label="Apply"
            primary
            isDisable={isApplyButtonDisabled}
            onClick={() => {
              onFilter();
            }}
          />
        </div>
      </div>
      <article className="comments-main">
        <p className="p-base-medium">* Spirit Codes</p>
        <EditableChipsTextarea
          validSpiritCodes={allHotelNames}
          setOnlyOneSpiritFound={setOnlyOneSpiritFound}
          spiritCodeToBeAdded={spiritCodeToBeAdded}
          setIsSpiritCodeError={setIsSpiritCodeError}
          isSpiritCodeError={isSpiritCodeError}
          setEditableIndex={setEditableIndex}
          editableIndex={editableIndex}
        />
      </article>
      <div className="bottom-container">
        <Button
          id="basics-chain-hotels-cancel-hotels"
          dataAttribute="basics-chain-hotels-cancel-hotels-button"
          label="CANCEL"
          isDisable={false}
          onClick={() => {
            onCloseHotelChain();
          }}
        />
        <Button
          id="basics-chain-hotels-add-hotels"
          dataAttribute="basics-chain-hotels-add-hotels-button"
          isloading={false}
          label="ADD HOTELS"
          primary
          isDisable={isSpiritCodeError || spiritCodeToBeAdded.length < 2 || editableIndex !== null}
          onClick={() => {
            onAddHotelChain();
          }}
        />
      </div>
    </section>
  );
};
