import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import './ChainHotel.scss';
import { CrossIcon } from '../../../components/Icons/CrossIcon';
import { WarningIcon } from '../../../components/Icons/WarningIcon';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { handleChange } from '../../../store/offerToolSlice';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Alert from '../../../components/atoms/Alert';
import { TickIcon } from '../../../components/Icons/TickIcon';

interface HotelValidationProps{
  name: string,
  title: string
}
interface Props {
  setIsSpiritCodeError: (value: boolean) => void;
  setOnlyOneSpiritFound: (value: boolean) => void;
  isSpiritCodeError: boolean;
  spiritCodeToBeAdded: string[];
  setEditableIndex: (value: number | null) => void;
  editableIndex: number | null;
  validSpiritCodes: HotelValidationProps[];
}

const EditableChipsTextarea = ({
  setIsSpiritCodeError,
  setOnlyOneSpiritFound,
  isSpiritCodeError,
  spiritCodeToBeAdded,
  setEditableIndex,
  editableIndex,
  validSpiritCodes,
}: Props) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [currentSentence, setCurrentSentence] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value.replace(/,/g, '');
    setCurrentSentence(inputValue.toLowerCase().trim());
  };

  useEffect(() => {
    showAlert &&
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
  }, [showAlert]);

  const handleKeyPress = (event: any) => {
    if ((event.key === ',' || event.key === ' ') && currentSentence.trim() !== '') {
      const newSentences = currentSentence.split(/[,\s]+/);
      const validSentences = newSentences.filter((sentence) => sentence.trim() !== '');
      if (editableIndex !== null) {
        const updatedSentences = [...spiritCodeToBeAdded];
        if (
          spiritCodeToBeAdded.includes(validSentences[0]) &&
          updatedSentences[editableIndex] !== validSentences[0]
        ) {
          setShowAlert(true);
        } else {
          updatedSentences[editableIndex] = validSentences[0];
          dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: updatedSentences }));
        }
      } else {
        const newChips = validSentences.map((sentence) => sentence.trim());
        const uniqueValidSentences = newChips.filter(
          (sentence) => !spiritCodeToBeAdded.includes(sentence)
        );
        dispatch(
          handleChange({
            name: 'spiritCodeToBeAdded',
            value: [...spiritCodeToBeAdded, ...uniqueValidSentences],
          })
        );
      }
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setEditableIndex(null);
      setCurrentSentence('');
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData('text').toLowerCase();
    const newSentences = pastedText.split(/[,\s]+/);
    const uniqueArray: string[] = Array.from(new Set(newSentences));
    if (newSentences.length !== uniqueArray.length) {
      setShowAlert(true);
    }
    const validSentences = uniqueArray.filter((sentence) => sentence.trim() !== '');
    const uniqueValidSentences = validSentences.filter(
      (sentence) => !spiritCodeToBeAdded.includes(sentence)
    );
    const updatedSentences = [...spiritCodeToBeAdded];
    dispatch(
      handleChange({
        name: 'spiritCodeToBeAdded',
        value: [...updatedSentences, ...uniqueValidSentences],
      })
    );
    setCurrentSentence('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    event.preventDefault();
  };

  const handleChipRemove = (index: number) => {
    const updatedSentences = [...spiritCodeToBeAdded];
    updatedSentences.splice(index, 1);
    dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: updatedSentences }));
    setEditableIndex(null);
    setCurrentSentence('');
  };

  const handleChipEnter = (index: number) => {
    setEditableIndex(index);
    setCurrentSentence(spiritCodeToBeAdded[index]);
  };

  const handleEdit = () => {
    handleKeyPress({ key: ',' });
    setEditableIndex(null);
    setCurrentSentence('');
  };

  const isValidSpiritCode = (sentence: string) => {
    const foundHotel = validSpiritCodes.find(
      (spiritCode) => spiritCode.name === sentence.toLowerCase()
    );
    return foundHotel ? 'valid' : 'invalid';
  };

  const checkItems = (itemsToCheck: string[]) => {
    const notFoundItems = itemsToCheck.filter(
      (item) => !validSpiritCodes.some((hotel) => hotel.name === item.toLowerCase())
    );
    if (notFoundItems.length > 0) {
      setIsSpiritCodeError(true);
    } else {
      setIsSpiritCodeError(false);
    }
    if (itemsToCheck.length === 1) {
      setOnlyOneSpiritFound(true);
    } else {
      setOnlyOneSpiritFound(false);
    }
  };

  useEffect(() => {
    checkItems(spiritCodeToBeAdded);
  }, [spiritCodeToBeAdded]);

  const findHotelNameBySpiritCode = (name: string): string | undefined => {
    const hotel = validSpiritCodes.find((h) => h.name === name.toLowerCase());
    return hotel ? hotel.title : undefined;
  };

  const clearSpiritCodes = () => {
    dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: [] }));
  };

  return (
    <div>
      {showAlert && (
        <Alert
          css={'chain-alert'}
          message="Duplicate spirit codes will be removed when adding spirit codes."
          title="Warning"
          type="error"
          onClose={() => {
            setShowAlert(false);
          }}
        />
      )}
      <div className="editable-container">
        {spiritCodeToBeAdded.map((sentence, index) => (
          <div
            key={index}
            className={`editable-chip ${isValidSpiritCode(sentence)}`}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={findHotelNameBySpiritCode(sentence)}
            onDoubleClick={() => handleChipEnter(index)}
          >
            {editableIndex === index ? (
              <input
                className="chip-input"
                ref={inputRef}
                value={currentSentence}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                onPaste={handlePaste}
              />
            ) : (
              sentence.toUpperCase()
            )}
            <ReactTooltip id="my-tooltip" place="bottom" />
            {editableIndex === index ? (
              <TickIcon style="edit-tick" bgColor="#ffffff" onClick={handleEdit} />
            ) : (
              <CrossIcon style="remove-button" onClick={() => handleChipRemove(index)} />
            )}
          </div>
        ))}
        <div className="editable-chip">
          {!editableIndex && editableIndex !== 0 && (
            <textarea
              ref={textareaRef}
              cols={1}
              placeholder=""
              value={currentSentence}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              onPaste={handlePaste}
            />
          )}
        </div>
      </div>
      <div className="footer-chain">
        <>
          {isSpiritCodeError && (
            <>
              <div className="inline-error">
                <WarningIcon width={15} height={15} />
                <p className="error-componenet">Incorrect spirit code</p>
              </div>
            </>
          )}
        </>
        <>{spiritCodeToBeAdded.length} Hotels selected</>
        <div>
          {spiritCodeToBeAdded.length >= 1 && (
            <a className="clear-button" onClick={clearSpiritCodes}>
              {' '}
              <CrossIcon style="icon-close" /> Clear all hotels
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableChipsTextarea;
