import React from 'react';
import './errorPage.css';
import { Header } from '../Header';

const RunTimeError: React.FC = () => {
  return (
    <div className="not-found-container">
      <Header appName="Special Offer Tool" pathname={'/'} />
      <h1 className="not-found-heading">500</h1>
      <p className="not-found-text">Sorry, It's not you. It's us</p>
      <p className="not-found-description">
        We are experiencing internal server error. Please contact developer team to fix this.
      </p>
    </div>
  );
};

export default RunTimeError;
