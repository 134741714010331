import React, { useEffect } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { Button } from '../../components/atoms/Button';
import { Description } from '../../components/atoms/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './rejectOffer.scss';
import { handleChange, setOfferStateToDefault } from '../../store/offerToolSlice';
import { extractOfferCategory } from '../../utils/fileUtils';

export const RejectOffer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    window.utag_data.page_name = 'offertool-rejectoffer';
  }, []);
  return (
    <section className="container reject-offer-container">
      <PageHeader title="Offer Rejected" description="You have successfully rejected this offer." />
      <Description text="Details and status of this offer are available on your Dashboard." />
      <div className="btn-container">
        <Button
          id='reject-offer-view-reject-offers-button'
          dataAttribute='view-reject-offers'
          label="See Rejected Offer"
          onClick={() => {
            dispatch(handleChange({ name: 'page', value: 0 }));
            dispatch(setOfferStateToDefault());
            navigate('/', {
              state: { from: 'rejected', offerCategory: extractOfferCategory(location.search) },
            });
          }}
        />
        <Button
          id='button-reject'
          dataAttribute='reject-offer-button'
          label="Go to Dashboard"
          primary
          onClick={() => {
            dispatch(handleChange({ name: 'page', value: 0 }));
            dispatch(setOfferStateToDefault());
            navigate('/');
          }}
        />
      </div>
    </section>
  );
};
