import React, { useEffect, useMemo, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { handleChange, SpiritCode } from '../../store/offerToolSlice';
import { Button } from '../../components/atoms/Button';
import { Input } from '../../components/atoms/Input';
import { Select } from '../../components/atoms/Select';
import { PlusIcon } from '../../components/Icons/PlusIcon';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import './Basics.scss';
import nls from '../../nls/HTBToolBox.json';
import Trashcan from '../../components/Icons/Trashcan';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { store } from '../../store/store';
import { getHotelOffer, getSpiritCode } from '../../service/api';
import { ThunkDispatch } from '@reduxjs/toolkit';
import TypeAheadSelect from '../../components/atoms/TypeAheadSelect/TypeAheadSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChainHotel } from './ChainHotel';
import Alert from '../../components/atoms/Alert';
import { SuccessIcon } from '../../components/Icons/SuccessIcon';
import { isAlphaNumericOrSpace } from '../../utils/textUtils';

interface ErrorMessageParams {
  error: boolean;
  message?: string;
}
declare global {
  interface Window {
    utag_data: any;
  }
}

export const Basics: React.FC = () => {
  const {
    offers: {
      promotionName,
      recordType,
      hotelName,
      additionalEmails,
      spiritCode,
      offerCategory,
      offerType,
    },
    isCopyOffer,
    basicsAdditionalEmail,
    showError,
    isAddHotelChian,
    allHotelNames,
    isUpdate,
  } = useSelector((state: any) => state.offerTool);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [emailError, setEmailError] = useState<ErrorMessageParams>({
    error: false,
  });
  const [addEmailClicked, setAddEmailClicked] = useState(false);
  const [additionEmailFromStore, setAdditionEmailFromStore] = useState('');
  const [isComesFromUpdate, setIsComesFromUpdate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [fetchingCopiedOffer, setFetchingCopiedOffer] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const recordTypes = [
    { value: 'Single', label: 'Single Hotel' },
    { value: 'Chain', label: 'Multiple Hotel' },
  ];

  useEffect(() => {
    window.utag_data.page_name = 'offertool-basics';
  }, []);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
      if (recordType === 'Chain') {
        const stringValue = String(spiritCode);
        const spiritCodeArr = stringValue.split(',');
        dispatch(handleChange({ name: 'spiritCode', value: spiritCodeArr }));
      }
    }
  }, []);

  const fetchCopiedOffer = (offerPath: string | null) => {
    setFetchingCopiedOffer(true);
    if (offerPath) {
      dispatch(getHotelOffer({ path: offerPath })).then((e) => {
        if (e.meta.requestStatus === 'fulfilled') {
          dispatch(handleChange({ name: 'isCopyOffer', value: true }));
        }
      });
    }
  };

  useEffect(() => {
    const { isUpdate } = store.getState().offerTool;
    const offerPath = queryParams.get('offerPath');
    if (!isUpdate && !offerPath) {
      if (offerCategory === '' || offerType === '') {
        const uid = uuid();
        dispatch(handleChange({ name: 'createOfferId', value: uid }));
        navigate(`/welcome/${uid}`);
      }
    } else {
      if (isUpdate) {
        setIsComesFromUpdate(true);
      } else {
        fetchCopiedOffer(offerPath);
      }
    }
  }, [offerCategory, offerType]);

  useEffect(() => {
    dispatch(handleChange({ name: 'isHotelSpiritCodesLoading', value: true }));
    dispatch(getSpiritCode()).then(() => {
      dispatch(handleChange({ name: 'isHotelSpiritCodesLoading', value: false }));
    });
  }, []);

  const hotelNames = allHotelNames?.map((hotel: SpiritCode) => ({
    value: hotel.title,
    label: hotel.name,
  }));

  const addAdditionalEmail = () => {
    setAddEmailClicked(true);
    const email = basicsAdditionalEmail;
    const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    if (!emailRegex.test(basicsAdditionalEmail ? basicsAdditionalEmail : additionEmailFromStore)) {
      setEmailError({
        error: true,
        message: 'Please enter a valid email',
      });
      return;
    }
    const isDuplicateEmail = additionalEmails?.filter((e: string) => e === basicsAdditionalEmail);
    if (isDuplicateEmail?.length === 0) {
      dispatch(handleChange({ name: 'additionalEmails', value: [...additionalEmails, email] }));
      dispatch(handleChange({ name: 'basicsAdditionalEmail', value: '' }));
      setAdditionEmailFromStore('');
    } else {
      setEmailError({
        error: true,
        message: 'Email address is already added',
      });
    }
  };

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 2 }));
  }, []);

  const handleHotelName = (value: string, text: string) => {
    if (value) {
      dispatch(handleChange({ name: 'hotelName', value: [value] }));
      dispatch(handleChange({ name: 'spiritCode', value: [text] }));
    } else {
      dispatch(handleChange({ name: 'hotelName', value: [] }));
      dispatch(handleChange({ name: 'spiritCode', value: [] }));
    }
  };

  useEffect(() => {
    if (promotionName !== '' && spiritCode?.length === 0) {
      dispatch(handleChange({ name: 'showError', value: false }));
    }
  }, [promotionName, spiritCode]);

  const onDelete = (key: any) => {
    const additialEmailsToSave = additionalEmails.filter(
      ({ additionalEmails }: any, index: number) => key !== index
    );
    dispatch(handleChange({ name: 'additionalEmails', value: additialEmailsToSave }));
  };

  useEffect(() => {
    return () => {
      const basicsAdditionalEmail = store.getState().offerTool.basicsAdditionalEmail;
      if (basicsAdditionalEmail) {
        setAdditionEmailFromStore(basicsAdditionalEmail);
      }
    };
  }, []);

  useEffect(() => {
    const emailMsg = setTimeout(() => {
      setEmailError({ error: false });
    }, 3000);
    return () => {
      clearTimeout(emailMsg);
    };
  }, [emailError]);

  useEffect(() => {
    setSelectedEmail(additionalEmails ? additionalEmails[additionalEmails?.length - 1] : '');
  }, [selectedEmail]);

  const getAdditionalEmails = () => {
    return (
      (additionalEmails?.length > 0 || addEmailClicked) &&
      additionalEmails?.map((email: any, index: number) => {
        return (
          <div className="additionalEmail" key={index}>
            <Input
              id="additional-email-value"
              dataAttribute="additional-email-value-input"
              key={index}
              className="basic-form"
              label=""
              placeHolder=""
              value={email}
            />
            <button
              className="blackout-delete-button"
              onClick={() => {
                onDelete(index);
              }}
            >
              <Trashcan />
            </button>
          </div>
        );
      })
    );
  };

  const getAdditionalEmailTextfield = () => {
    if (!addEmailClicked && basicsAdditionalEmail === '') {
      return additionalEmails[additionalEmails?.length - 1];
    } else {
      return basicsAdditionalEmail;
    }
  };

  useEffect(() => {
    showAlert &&
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
  }, [showAlert]);

  useEffect(() => {
    const { isUpdate } = store.getState().offerTool;
    if (!isUpdate) {
      dispatch(handleChange({ name: 'hotelName', value: [] }));
      dispatch(handleChange({ name: 'spiritCode', value: [] }));
      dispatch(handleChange({ name: 'spiritCodeToBeAdded', value: [] }));
    }
  }, [recordType]);

  const handleKeyDownOfferName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.metaKey || e.ctrlKey || e.altKey || e.key.length > 1) {
      return;
    }
    if (!isAlphaNumericOrSpace(e.key)) {
      e.preventDefault();

    }
  };

  const isRequiredFeildsAreEmpty = () => {
    if (promotionName === '' || spiritCode?.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const heading = isCopyOffer ? 'Copy Offer Basics' : `Let's start with the basics`;

  const bannerErrorMessage = () => {
    if (recordType === 'Chain' && spiritCode.length === 1) {
      return 'You must enter more than 1 spirit code to create a Multiple Hotel Offer.';
    } else {
      return 'Please correct the errors below.';
    }
  };

  return isAddHotelChian ? (
    <ChainHotel setShowAlert={setShowAlert} chainHotels={spiritCode} />
  ) : (
    <>
      <section className="container basics-container">
        {showAlert && (
          <Alert
            message={'Hotels have been added to offer'}
            title={'Hotels Added'}
            type={'success'}
            onClose={() => {
              setShowAlert(false);
            }}
          />
        )}
        <PageHeader
          title={heading}
          description={isCopyOffer && nls.COPY_BASICS_INSTRUCTIONS}
        />        
        {showError && (
          <div style={{ width: '75%' }}>
            <Banner title="Required Fields" message={bannerErrorMessage()} />
          </div>
        )}
        <div className="basics-info-elements basics-form">
          {offerCategory === 'hotel-special-offer' && (
            <>
              <Input
                id="offer-name"
                dataAttribute="offer-name-input"
                label="* Offer Name"
                labelClassName="p-base-small"
                placeHolder="Not a guest facing name – internal use only"
                name="promotionName"
                value={promotionName}
                className="basic-form"
                onChange={(e) =>{
                  // Handle input change to sanitize input value. Only alphanumeric or a space is allowed
                  const value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                  dispatch(handleChange({ name: e.target.name, value: value }))
                }
                }
                onKeyDown={handleKeyDownOfferName}
              />
              {showError && promotionName === '' && (
                <div className="inline-error">
                  <WarningIcon width={15} height={15} />
                  <p style={{ fontSize: '12px', color: 'red' }}>Provide a promotion name</p>
                </div>
              )}
            </>
          )}
          <Select
            id="record-type"
            dataAttribute="record-type-select"
            label="* Record Type"
            labelClassName="p-base-small"
            name="recordType"
            options={recordTypes}
            value={recordType}
            className="basic-form"
            disabled={isUpdate || allHotelNames?.length === 1 || isCopyOffer}
            onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
          />
          {recordType === 'Chain' && allHotelNames?.length > 1 && (
            <div className="add-record-type">
              <Button
                id='basics-add-or-edit-hotels'
                dataAttribute='basics-add-or-edit-hotels-button'
                label={spiritCode?.length > 0 ? 'Edit Hotel' : 'Add Hotels'}
                type="link"
                icon={<PlusIcon bgColor="#0072ce" />}
                name="addHotel"
                onClick={() => {
                  dispatch(handleChange({ name: 'isAddHotelChian', value: true }));
                }}
              />
              {spiritCode?.length > 0 && (
                <span className="chain-hotel-count-text">
                  <SuccessIcon /> &nbsp;{spiritCode?.length} Hotels Added
                </span>
              )}
            </div>
          )}
          {showError && recordType === 'Chain' && spiritCode?.length === 0 && (
            <div className="inline-error">
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red' }}>Please add hotels</p>
            </div>
          )}
          {recordType === 'Single' && (
            <div className={hotelName.length > 0 ? 'spiritCodeContainer' : ''}>
              {isComesFromUpdate ? (
                <Input
                  id="spirit-code"
                  dataAttribute="spirit-code-input"
                  label="* Spirit Code"
                  labelClassName="p-base-small"
                  className="p-base-normal disabled-hotel-name-input"
                  name="spiritCode"
                  disabled
                  value={spiritCode[0]}
                />
              ) : (
                <div>
                  <TypeAheadSelect
                    label="* Spirit Code"
                    labelClassName="p-base-small"
                    options={hotelNames}
                    onSelect={handleHotelName}
                    spiritCode={spiritCode[0]}
                  />
                  {showError && spiritCode?.length === 0 && (
                    <div className="inline-error">
                      <WarningIcon width={15} height={15} />
                      <p style={{ fontSize: '12px', color: 'red' }}>Select a hotel</p>
                    </div>
                  )}
                </div>
              )}
              {hotelName.length > 0 && (
                <label className="hotelLabel">
                  Hotel: {hotelName.length > 0 ? hotelName[0] : ''}
                </label>
              )}
            </div>
          )}
          {(addEmailClicked || additionalEmails.length === 0) && (
            <Input
              id="additional-email"
              dataAttribute="additional-email-input"
              label="Additional E-Mail Notification"
              labelClassName="p-base-small"
              placeHolder="mary@hyatt.com"
              name="additionalEmailControlled"
              value={getAdditionalEmailTextfield()}
              className="basic-form"
              onChange={(e) =>
                dispatch(
                  handleChange({ name: 'basicsAdditionalEmail', value: e.target.value.trim() })
                )
              }
            />
          )}
          {!emailError.error &&
            showError &&
            basicsAdditionalEmail !== '' &&
            !isRequiredFeildsAreEmpty() && (
              <div className="inline-error">
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Email is invalid or already exist</p>
              </div>
            )}
          {emailError.error && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '-10px',
                marginBottom: '10px',
              }}
            >
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red' }}>{emailError.message}</p>
            </div>
          )}
          {additionalEmails?.length !== 0 && !addEmailClicked && (
            <span className="p-base-small">Additional E-Mail Notification</span>
          )}
          {getAdditionalEmails()}
          <div className="add-email">
            <Button
              id='basics-add-email'
              dataAttribute='basics-add-email-input'
              label="Add Email"
              type="link"
              icon={<PlusIcon bgColor="#0072ce" />}
              name="additionalEmails"
              onClick={addAdditionalEmail}
            />
          </div>
          <div className="bottom-componenet"></div>
        </div>
      </section>
    </>
  );
};
