import React from 'react';
import './Shimmer.css';

interface ShimmerLoadingProps {
  count: number;
}

const ShimmerLoading = ({ count }: ShimmerLoadingProps) => {
  const shimmerLoadings = [];
  for (let i = 0; i < count; i++) {
    shimmerLoadings.push(
      <div key={i} className="shimmer-loading-container">
        <div className="shimmer-loading"></div>
      </div>
    );
  }
  return <>{shimmerLoadings}</>;
};

export default ShimmerLoading;
