import Modal from '../../../components/atoms/Modal';
import { Button } from '../../../components/atoms/Button';
import '../dashboard.css';
import nlsCommon from '../../../nls/common.json';
import { DatePickerComponent } from '../../../components/molecules/datePicker';
import { ExtendOfferParam } from '../../../store/offerToolSlice';
import { isPastDate, setTimeTo9AMCST } from '../../../utils/dateAndTimeUtils';
interface Props {
  handleClose: () => void;
  handleSubmit: () => void;
  setEndOffer: (param: ExtendOfferParam) => void;
  endOffer: ExtendOfferParam | undefined;
  currentBookingEndDate: string;
  currentStayEndDate: string;
  isEndLoading: boolean;
}

function EndOffer({
  handleClose,
  handleSubmit,
  setEndOffer,
  endOffer,
  currentBookingEndDate,
  currentStayEndDate,
  isEndLoading,
}: Props) {

  const currentOfferBookingEndDate = new Date(currentBookingEndDate);
  currentOfferBookingEndDate.setDate(currentOfferBookingEndDate.getDate() - 1);

  const currentOfferStayEndDate = new Date(currentStayEndDate);
  currentOfferStayEndDate.setDate(currentOfferStayEndDate.getDate() - 1);

  const isDisabled = (): boolean => {
    return !endOffer?.bookingEndDate || !endOffer?.stayEndDate || isEndLoading;
  }
  
  const minDateForNewBookingEndDate = () => {
    if(!isPastDate(currentBookingEndDate)){
      //currentBookingEndDate is future
      return new Date();
    }
    return undefined;
  }

  const minDateForNewStayEndDate = () => {
    if(!isPastDate(currentStayEndDate)){
      //currentStayEndDate is future
      return new Date();
    }
    return undefined;
  }

  return (
    <Modal titleSize="20px" title="End Offer" onClose={handleClose}>
      <div className="Modal_custom_content_extend_offer">
        <p>The new end date must be earlier than the original date.</p>
        <div className="end-offer-component">
          <DatePickerComponent
            label={nlsCommon.NEW_BOOKING_END_DATE}
            labelClassName="p-base-medium"
            placeholder={nlsCommon.SELECT_DATE}
            className="extend-date-chooser"
            format="MMM-dd-yyyy"
            name="newBookingEndDate"
            onChange={(date) => {
              setEndOffer({ ...endOffer, bookingEndDate: setTimeTo9AMCST(date.toDateString()) });
            }}
            value={endOffer?.bookingEndDate ? new Date(endOffer.bookingEndDate) : undefined}
            maxDate={currentOfferBookingEndDate}
            minDate={minDateForNewBookingEndDate()}
          />
          <DatePickerComponent
            label={nlsCommon.NEW_STAY_END_DATE}
            labelClassName="p-base-medium"
            placeholder={nlsCommon.SELECT_DATE}
            className="extend-date-chooser"
            format="MMM-dd-yyyy"
            name="newStayEndDate"
            onChange={(date) => {
              setEndOffer({
                ...endOffer,
                stayEndDate: setTimeTo9AMCST(date.toDateString()),
              });
            }}
            value={endOffer?.stayEndDate ? new Date(endOffer.stayEndDate) : undefined}
            maxDate={currentOfferStayEndDate}
            minDate={minDateForNewStayEndDate()}
          />
        </div>
        <div className="button-container">
          <div className="custom-button">
            <Button id='cancel-end-offer' dataAttribute='cancel-end-offer-button' onClick={handleClose} primary={false} label={'Cancel'}></Button>
          </div>
          <div className="custom-button">
            <Button
              id='submit-end-offer' 
              dataAttribute='submit-end-offer-button'
              onClick={handleSubmit}
              isDisable={isDisabled()}
              primary
              label="End offer"
            ></Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EndOffer;
