import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
const options: any = {
  offerRackOptions: [{ label: 'Offer is priced less than rack', value: 'less-than-rack' }],
};
export default function useDropdownOptions() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const [disableFirstRadioButton, setdisableFirstRadioButton] = useState(false);
  const [disableSecondRadioButton, setdisableSecondRadioButton] = useState(false);

  const {
    offers: {
      offerType,
      pricing: { offerRack, qualifiers },
      cancellationAndCodes
    },
    offerPricingDropdown,
  } = useSelector((state: any) => state.offerTool);

  let newOptions = [];
  let defaultOption = '';

  if (!(offerPricingDropdown === undefined)) {
    newOptions = offerPricingDropdown;
    defaultOption = qualifiers === '' && newOptions?.[0].value;
  } else {
    newOptions = options['offerRackOptions'];
    defaultOption = qualifiers === '' && newOptions?.[0].value;
  }

  const [selectedRack, setSelectedRack] = useState<string>(offerRack);
  const isPriveOffer = offerType === 'hyattpriveluxury-offer' || offerType === 'hyatt-prive';

  useEffect(() => {
    if (defaultOption) {
      if(!isPriveOffer){
        setSelectedRack(defaultOption);      
        dispatch(handleChange({ name: 'offerRack', value: defaultOption }));
      }
    }
  }, [defaultOption, dispatch]);

  useEffect(() => {
    switch (selectedRack) {
      case 'hyatt-prive-luxury-consortia':        
        dispatch(handleChange({ name: 'qualifiers', value: 'qualifiedCustomers' }));
        setdisableSecondRadioButton(true);
        break;
      default:
        setdisableSecondRadioButton(false);
        break;
    }
  }, [dispatch, selectedRack]);

  const optionDisableHandler = useCallback(
    (event: any) => {
      setSelectedRack(event.target.value);
      if(event.target.value === 'less-than-rack' && cancellationAndCodes.depositPolicy === 'Credit Card'){
        dispatch(handleChange({ name: 'depositPolicy', value: '' }));
        dispatch(handleChange({ name: 'penaltyPolicy', value: '' }));
        dispatch(handleChange({ name: 'cancellationPolicy', value: '' }));
      }
      dispatch(handleChange({ name: 'offerRack', value: event.target.value }));
    },
    [dispatch]
  );
  return {
    selectedRack,
    newOptions,
    optionDisableHandler,
    disableFirstRadioButton,
    disableSecondRadioButton,
  };

}
