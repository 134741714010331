import React, { useEffect } from 'react';
import { PageHeader } from '../../../components/molecules/PageHeader';
import './privePricing.css';
import { CheckBox } from '../../../components/atoms/Checkbox/Checkbox.stories';
import { Input } from '../../../components/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { handleChange } from '../../../store/offerToolSlice';
import { WarningIcon } from '../../../components/Icons/WarningIcon';
import Banner from '../../../components/atoms/Banner';
import nls from '../../../nls/HTBToolBox.json';

interface LuxuryConsortiaOptionsParam {
  label: string;
  value: string;
}

export const priveLuxuryConsortiaOptions: LuxuryConsortiaOptionsParam[] = [
  {
    label: 'Ensemble Amenity Program',
    value: 'ensemble-amenity-program',
  },
  {
    label: 'Frosch',
    value: 'frosch',
  },
  {
    label: 'Serendipians',
    value: 'serendipians',
  },
  {
    label: 'Internova Select',
    value: 'internova-select',
  },

  {
    label: 'Signature',
    value: 'signature',
  },
  {
    label: 'Internova Curated',
    value: 'internova-curated',
  },
  {
    label: 'Virtuoso',
    value: 'virtuoso',
  },
  {
    label: 'Kiwi',
    value: 'kiwi',
  },
];

export const luxuryConsortiaOptions: LuxuryConsortiaOptionsParam[] = [
  {
    label: 'Ensemble Amenity Program',
    value: 'ensemble-amenity-program',
  },
  {
    label: 'Frosch',
    value: 'frosch'
  },
  {
    label: 'Internova Select',
    value: 'internova-select',
  },
  {
    label: 'Internova Curated',
    value: 'internova-curated',
  },
  {
    label: 'Kiwi',
    value: 'kiwi',
  },
  {
    label: 'Serendipians',
    value: 'serendipians',
  },
  {
    label: 'Signature',
    value: 'signature',
  },
  {
    label: 'Virtuoso',
    value: 'virtuoso',
  },
];

export const PrivePricingDetail = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const {
    offers: {
      offerValidity: { minStayNights, maxStayNights },
      pricing: { qualifiers, priveValueAdd, consortiaOptions },
      offerType,
    },
    showError,
  } = useSelector((state: any) => state.offerTool);

  const minNight = parseFloat(minStayNights);
  const maxNight = parseFloat(maxStayNights);

  useEffect(() => {
    dispatch(handleChange({ name: 'priveValueAdd', value: ['hyatt-prive'] }));
  }, []);

  const heading =
    offerType === 'hyatt-luxury-consortia' ? nls.LUXURY_CONSORTIA_HEADING : nls.PRIVE_OFFER_HEADING;

  const options = offerType === 'hyatt-luxury-consortia' ? luxuryConsortiaOptions : priveLuxuryConsortiaOptions;

  return (
    <section className="prive-container">
      <PageHeader
        title={heading}
        description={
          offerType === 'hyatt-prive'
            ? 'If your offer involves complex pricing details or requires a rate grid, please use the attachments section.'
            : ''
        }
      />
      {showError && (
        <div className="prive-error-banner">
          <Banner
            className="prive-banner"
            title="Required Fields"
            message="Please correct the errors below."
          />
        </div>
      )}
      <div className="priveBody">
        <div className="firstSection">
          <div className="prive-value-add-detials-section">
            <p className="title"> Privé Value Add Details </p>
            <CheckBox
              id="pricing-detils-hyatt-privé-add-details"
              dataAttribute="pricing-detils-hyatt-privé-add-details-checkbox"
              label="Hyatt Privé"
              value="hyatt-prive"
              checked={priveValueAdd?.includes('hyatt-prive')}
              onChange={() => {}}
            />
          </div>
          <div className="luxury-consortia-options-section">
            <p className="p-base-normal"> Luxury Consortia Options </p>
            <p className="p-base-small">
              Select any additional Consortia that you wish to have this Value Add applied to.
            </p>
            <div className="luxury-consortia-options-checkbox-container">
              {options?.map((options, index) => {
                return (
                  <div className="luxury-consortia-options-checkbox" key={index}>
                    <CheckBox
                      id={`pricing-detils-luxury-consortia-options-${options.value}`}
                      dataAttribute={`pricing-detils-luxury-consortia-options-checkbox-${options.value}`}
                      label={options.label}
                      checked={consortiaOptions?.includes(options.value)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch(
                            handleChange({
                              name: 'consortiaOptions',
                              value: [...consortiaOptions, options.value],
                            })
                          );
                        } else {
                          const consortiaOptionsToSave = consortiaOptions?.filter((option: any) => {
                            return options.value !== option;
                          });
                          dispatch(
                            handleChange({
                              name: 'consortiaOptions',
                              value: consortiaOptionsToSave,
                            })
                          );
                        }
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {offerType === 'hyatt-prive' && (
          <>
            <hr className="offer-code-hr" />
            <div className="sectiontwo">
              <p className="p-base-normal" id="equal-1">
                * Who qualifies for this special offer?
              </p>
              <div className="pricing-details-checkbox">
                <CheckBox
                  id="pricing-details-guest"
                  dataAttribute="pricing-details-guest-checkbox"
                  label="Guests"
                  value="guests"
                  labelClassName="p-base-medium"
                  checked={qualifiers?.includes('guests')}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      if (qualifiers?.includes('travelAgents')) {
                        dispatch(
                          handleChange({ name: 'qualifiers', value: 'guests-and-travelAgents' })
                        );
                      } else {
                        dispatch(handleChange({ name: 'qualifiers', value: 'guests' }));
                      }
                    } else {
                      if (qualifiers?.includes('travelAgents')) {
                        dispatch(handleChange({ name: 'qualifiers', value: 'travelAgents' }));
                      } else {
                        dispatch(handleChange({ name: 'qualifiers', value: '' }));
                      }
                    }
                  }}
                  disabled={false}
                />
                <h2 className="p-base-small">This offer benefits the guest. For example, increased property credit.</h2>
              </div>
              <div className="pricing-details-checkbox">
                <CheckBox
                  id="pricing-details-travel-agents"
                  dataAttribute="pricing-details-travel-agents-checkbox"
                  label="Travel Advisors"
                  value="travelAgents"
                  labelClassName="p-base-medium"
                  checked={qualifiers?.includes('travelAgents')}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      if (qualifiers?.includes('guests')) {
                        dispatch(
                          handleChange({ name: 'qualifiers', value: 'guests-and-travelAgents' })
                        );
                      } else {
                        dispatch(handleChange({ name: 'qualifiers', value: 'travelAgents' }));
                      }
                    } else {
                      if (qualifiers?.includes('guests')) {
                        dispatch(handleChange({ name: 'qualifiers', value: 'guests' }));
                      } else {
                        dispatch(handleChange({ name: 'qualifiers', value: '' }));
                      }
                    }
                  }}
                  disabled={false}
                />
                <h2 className="p-base-small">
                  This offer benefits the travel advisor. For example, “earn 15% commission when you book this hotel during these dates”.
                </h2>
                {showError && qualifiers === '' && (
                  <div className="prive-error-message">
                    <WarningIcon width={15} height={15} />
                    <p style={{ fontSize: '12px', color: 'red' }}>Choose qualifiers for this offer</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <hr className="offer-code-hr" />
        <div className="sectionthree">
          <p className="p-base-normal valid-days-para">
            Length of stay should equal the standard Hyatt Privé LOS.
          </p>
          <div className="max-min-input">
            <div className="min-container">
              <Input
                id="pricing-details-min-stay-night"
                dataAttribute="pricing-details-min-stay-night-radio"
                label="Minimum Length of Stay (Nights)"
                labelClassName="p-base-small extra-space"
                placeHolder=""
                type="number"
                name="minStayNights"
                className="validays-input"
                value={Math.abs(minNight)}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                }
              />
              <span className="p-base-normal">{'Night(s)'}</span>
            </div>
            <div className="min-container">
              <Input
                id="pricing-details-max-stay-night-value"
                dataAttribute="pricing-details-max-stay-night-input"
                label="Maximum Length of Stay (Nights)"
                placeHolder=""
                type="number"
                name="maxStayNights"
                labelClassName="p-base-small extra-space"
                className="validays-input"
                value={Math.abs(maxNight)}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                }
              />
              <span className="p-base-normal">{'Night(s)'}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
