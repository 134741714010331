import React, { useEffect, useState } from 'react';
import Modal from '../../components/atoms/Modal';
import './AdvancedBooking.css';
import { Checkbox } from '../../components/atoms/Checkbox';
import { Button } from '../../components/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';

interface Props {
  handleClose: () => void;
  goToNextPage: () => void;
}

export const ShowAuthInfoModal = ({ handleClose, goToNextPage }: Props) => {
  const dispatch = useDispatch();
  const { haveValidAccessToCreateOffer } = useSelector((state: any) => state.offerTool);

  const onPermissionChanged = (isChecked: boolean) => {
    dispatch(handleChange({ name: 'haveValidAccessToCreateOffer', value: isChecked }));
    dispatch(handleChange({ name: 'isAuthroziedToCreateOffer', value: isChecked }));
  };

  useEffect(() => {
    dispatch(handleChange({ name: 'disableNext', value: true }));
    dispatch(handleChange({ name: 'isAuthroziedToCreateOffer', value: true }));
  }, []);

  const onClose = () => {
    dispatch(handleChange({ name: 'isAuthroziedToCreateOffer', value: false }));
    dispatch(handleChange({ name: 'disableNext', value: false }));
    handleClose();
  };

  return (
    <Modal title="Just Making Sure" onClose={onClose}>
      <p className="modal-paragraph">
        Special Offers that include the Hyatt.com Direct Sell Channel, World of Hyatt Bonus Points,
        or ones that are Included on a hotel Offers Page need the following to ensure approval:
      </p>
      <p className="modal-paragraph">- Marketing Spend Support (RDRM Approval)</p>
      <p className="modal-paragraph">
        Do you have what's necessary to submit this offer?
        <Checkbox
          id='advanced-booking-have-valid-access'
          dataAttribute='advanced-booking-have-valid-access-input'
          checked={haveValidAccessToCreateOffer}
          labelClassName="modal-checkbox"
          onChange={(e) => {
            onPermissionChanged(e.target.checked);
          }}
          label={`Yes, I have what's needed to create this offer`}
        />
      </p>
      <div className="modal-ok-button">
        <Button
          id='advanced-booking-ok'
          dataAttribute='advanced-booking-ok-button'
          label="OK"
          isDisable={!haveValidAccessToCreateOffer}
          primary
          onClick={() => {
            dispatch(handleChange({ name: 'disableNext', value: false }));
            goToNextPage();
          }}
        />
      </div>
    </Modal>
  );
};
