import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { CategoryAndTypes, handleChange } from '../../store/offerToolSlice';

import './Promotions.css';
import nls from '../../nls/HTBToolBox.json';
import Banner from '../../components/atoms/Banner';

interface offerType {
  name: string;
  description: string;
  title: string;
}

export const Promotions: React.FC = () => {
  const navigate = useNavigate();
  const { offers, offerCategoriesAndTypes, showError } = useSelector(
    (state: any) => state.offerTool
  );
  const { offerCategory, offerType } = offers;

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 1 }));
  }, []);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-promotions';
  }, []);

  useEffect(() => {
    if (offerCategory === '') {
      const uid = uuid();
      dispatch(handleChange({ name: 'createOfferId', value: uid }));
      navigate(`/welcome/${uid}`);
    }
  }, [offerCategory]);

  const dispatch = useDispatch();
  const handleClick = (offer: offerType) => {
    const { name } = offer;

    dispatch(handleChange({ name: 'offerType', value: name }));
  };
  const promotionOfferIndex = offerCategoriesAndTypes.findIndex(
    (item: CategoryAndTypes) => item.name === offerCategory
  );

  const desc = () => {
    if (offerCategoriesAndTypes.length === 0) {
      const uid = uuid();
      dispatch(handleChange({ name: 'createOfferId', value: uid }));
      navigate(`/welcome/${uid}`);
      return '';
    }
    else if (offerCategory === 'hotel-special-offer') {
      return nls.PROMOTION_TYPE_INSTRUCTIONS;
    } else {
      return nls.HTB_PROMOTION_TYPE_INSTRUCTIONS;
    }
  };

  useEffect(() => {
    document.cookie = 'likeToAdd=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }, []);

  return (
    <section className="container center">
      <PageHeader
        width="1094px"
        title="Great! Let's narrow it down even more"
        description={desc()}
      />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Offer type error" message="Please choose an offer type." />
        </div>
      )}
      {/* <TileContainer items={offers} /> */}
      <div className="promotions-card-container ">
        {offerCategoriesAndTypes[promotionOfferIndex]?.offerTypes?.map(
          (offer: offerType, index: number) => {
            const { description, title, name } = offer;
            return (
              <button
                key={index}
                className={`promotions-card ${offerType === name && 'promotions-btn-active'}`}
                name="offerType"
                value={name}
                onClick={() => handleClick(offer)}
              >
                <h1 className="promotion-heading">{title}</h1>
                {/* <p className="promotion-info">{description}</p> */}
              </button>
            );
          }
        )}
      </div>
    </section>
  );
};
