import React, { useCallback, useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderDetails } from '../../components/molecules/HeaderDetails/headerDetails';
import { OfferDetails } from '../../components/molecules/OfferDetails/offerDetails';
import './ViewOffer.scss';
import nls from '../../nls/offerDetails.json';
import { useLocation } from 'react-router-dom';
import ViewComponent from './ViewComponent';
import ReviewComponent from './ReviewComponent';
import { getComments, getOfferCategoriesAndTypes } from '../../service/api';
import { extractPath } from '../../utils/fileUtils';
import { CommentsParams, handleChange } from '../../store/offerToolSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { store } from '../../store/store';
import DataService from '../viewDataServicesTask/DataService';
import { useHelpText } from '../../hooks/useHelpText';

export const ViewOffer: React.FC = () => {
  const { pathname, ...location } = useLocation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [, clearHelpText] = useHelpText();

  const { offers, offerComments, viewOfferActiveTabIndex } = useSelector(
    (state: any) => state.offerTool
  );
  const offerPath = extractPath(location.search);
  const [userComments, setUserComments] = useState<CommentsParams[]>();
  const [rejectReason, setRejectReason] = useState('');
  const checkActive = (index: any, className: any) =>
    viewOfferActiveTabIndex === index ? className : '';

  useEffect(() => {
    clearHelpText('');
    dispatch(getOfferCategoriesAndTypes());
    getComment();
    window.utag_data.page_name = 'offertool-viewoffer';
  }, []);

  const getComment = useCallback(() => {
    try {
      dispatch(getComments(offerPath)).then((e) => {
        if (e.meta.requestStatus === 'fulfilled') {
          const offerComments = store.getState().offerTool.offerComments;
          let commentsArr: CommentsParams[] = [];
          for (const key in offerComments) {
            if (
              offerComments?.hasOwnProperty(key) &&
              typeof offerComments[key] === 'object' &&
              'jcr:description' in offerComments[key]
            ) {
              const dynamicObject = offerComments[key];
              const jcrDescription = dynamicObject['jcr:description'];
              const jcrCreatedBy = dynamicObject['jcr:createdBy'];
              const jcrCreated = dynamicObject['jcr:created'];
              if (jcrDescription?.split('/')[0] === 'reject') {
                setRejectReason(jcrDescription?.split('/').slice(1).join('/'));
              }
              if (
                jcrDescription?.split('/')[0] === 'reject' ||
                jcrDescription?.split('/')[0] === 'accept' ||
                jcrDescription?.split('/')[0] === 'stepback' ||
                jcrDescription?.split('/')[0] === 'Comment'
              ) {
                commentsArr.push({
                  author: jcrCreatedBy,
                  comments: (jcrDescription?.split('/').slice(1).join('/'))
                    ?.replace(/\$#@!/g, "%")
                    .replace(/\^\$@\*/g, "&")
                    .replace(/@!\$~/g, "+"),
                  date: jcrCreated,
                  status:
                    jcrDescription?.split('/')[0] === 'stepback' ||
                      jcrDescription?.split('/')[0] === 'Comment'
                      ? jcrDescription?.split('/')[0]
                      : jcrDescription?.split('/')[0] + 'ed',
                });
              } else {
                commentsArr.push({
                  author: jcrCreatedBy,
                  comments: (jcrDescription?.split('/').slice(1).join('/'))
                    ?.replace(/\$#@!/g, "%")
                    .replace(/\^\$@\*/g, "&")
                    .replace(/@!\$~/g, "+"),
                  date: jcrCreated,
                  status: '',
                });
              }
            }
          }
          setUserComments(commentsArr);
          commentsArr = [];
        }
      });
    } catch (error) {}
  }, [offerComments]);

  const handleClick = (index: number) => {
    dispatch(handleChange({ name: 'viewOfferActiveTabIndex', value: index }));
  };

  return (
    <section className="container">
      <PageHeader title={offers?.promotionName} description="" />
      <HeaderDetails />
      {pathname === '/view-offer' ? (
        <ViewComponent status={offers.status} reason={rejectReason} />
      ) : (
        <ReviewComponent />
      )}
      <div className="tabs">
        <button className={`tab ${checkActive(1, 'active')}`} onClick={() => handleClick(1)}>
          {nls.OFFER_DETAILS}
        </button>
        <button className={`tab ${checkActive(2, 'active active')}`} onClick={() => handleClick(2)}>
          {nls.DATA_SERVICES}
        </button>
      </div>
      <div className={`content ${checkActive(1, 'active')}`}>
        <OfferDetails userComments={userComments} getComment={getComment} />
      </div>
      <div className={`content data-services ${checkActive(2, 'active')}`}>
        {/* TODO:: need to update the handler functions */}
        <DataService
          addDirectSales={() => {}}
          addOtherRatePlans={() => {}}
          handleDelete={() => {}}
          handleDirectSallsDelete={() => {}}
          handleOfferCode={() => {}}
          handleOtherRatePlansDelete={() => {}}
          disableInputs={true}
        />
      </div>
    </section>
  );
};
