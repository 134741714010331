import React from 'react';
interface TrashcanProps {
  width?: string;
  height?: string;
}

const Trashcan: React.FC<TrashcanProps> = ({ height = '20px', width = '20px' }) => {
  return (
    <span>
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="---special-offers---create"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="09-blackout-dates-ext" transform="translate(-827.000000, -401.000000)">
            <g id="trash-can" transform="translate(821.000000, 395.000000)">
              <rect id="Bouding-Box" x="0" y="0" width="32" height="32"></rect>
              <g
                id="Trash-Can"
                transform="translate(6.000000, 6.000000)"
                fill="#0072CE"
                fillRule="nonzero"
              >
                <path
                  d="M14.0238095,0 L14.023,2.255 L19,2.25540627 L19,4.51081254 L17.3127975,4.51081254 L16.1008011,20 L3.0215202,20 L1.80952381,4.51081254 L0,4.51081254 L0,2.25540627 L4.976,2.255 L4.97619048,0 L14.0238095,0 Z M9.57644873,5.01734968 C9.11519993,5.01734968 8.73456599,5.36250294 8.6787362,5.80862018 L8.67168682,5.92211159 L8.67168682,17.6450254 C8.67168682,18.1447116 9.07676252,18.5497873 9.57644873,18.5497873 C10.0376975,18.5497873 10.4183315,18.204634 10.4741613,17.7585168 L10.4812106,17.6450254 L10.4812106,5.92211159 C10.4812106,5.42242539 10.0761349,5.01734968 9.57644873,5.01734968 Z M4.57491513,5.04025398 L4.46100019,5.04311685 C4.00154844,5.08342946 3.6527053,5.46033589 3.63608947,5.90939148 L3.63898517,6.02300545 L4.66365522,17.7013888 C4.70397566,18.1609296 5.08101703,18.5100514 5.53036328,18.526883 L5.64405317,18.5240398 L5.75688555,18.5070671 C6.1595781,18.4203875 6.45395899,18.0693797 6.46918904,17.6577455 L6.46629324,17.5441315 L5.44162319,5.86574816 C5.40466278,5.44450248 5.08476326,5.11603937 4.68559232,5.0512562 L4.57491513,5.04025398 Z M13.7112743,5.86574816 L12.6866042,17.5441315 C12.6463098,18.0033756 12.9567109,18.4125075 13.3960119,18.5070671 L13.5088443,18.5240398 C14.0067019,18.5674716 14.4455618,18.1992246 14.4892422,17.7013888 L15.5139123,6.02300545 C15.5542067,5.56376142 15.2438056,5.1546295 14.8045046,5.06006985 L14.6918973,5.04311685 C14.1938146,4.99966543 13.7549547,5.36791235 13.7112743,5.86574816 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default Trashcan;
