import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Welcome } from '../pages/welcome';
import { Promotions } from '../pages/promotions';
import { SuccessSubmit } from '../pages/successSubmit';
import { Basics } from '../pages/basics';
import { Inclusions } from '../pages/inclusions';
import { BookingStartAndEnd } from '../pages/bookingStart&End';
import { BlackoutDates } from '../pages/blackoutDates';
import { ValidDaysOfWeek } from '../pages/validDaysOfWeek';
import { PricingDetails } from '../pages/pricingDetails';
import { OfferCode } from '../pages/offrerCodeAndCancellationPolicy';
import { DistributionChannels } from '../pages/distributionChannels';
import { ViewOffer } from '../pages/viewOffer/ViewOffer';
import { ViewDataServicesTask } from '../pages/viewDataServicesTask/ViewDataServicesTask';
import { RejectOffer } from '../pages/rejectOffer/rejectOffer';
import { AcceptOffer } from '../pages/acceptOffer/acceptOffer';
import { ReviewAndSubmit } from '../pages/reviewAndSubmit';
import { Root } from '../components/molecules/Root';
import { Dashboard } from '../pages/dashboard';
import { ToolsInclusions } from '../pages/toolsInclusions';
import { PropertyWebsite } from '../pages/propertyWebsite';
import { HotelWebsiteOffer } from '../pages/hotelWebsiteOffer';
import { Attachments } from '../pages/attatchments';
import { AdvancedBookingExt } from '../pages/advancedBookingExt';
import { Reporting } from '../pages/reporting';
import NotFoundPage from '../components/molecules/Error/PageNotFoundError';
import ErrorBoundary from '../ErrorBoundary';
import { HtbSpecifics } from '../pages/htbSpecifics';
import ScrollToTop from '../ScrollToTop';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <ScrollToTop />
        <Root />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
        id: 'dashboard',
      },
      {
        path: '/welcome/:pageId',
        element: <Welcome />,
        id: 'welcome',
      },
      {
        path: '/view-offer',
        element: <ViewOffer />,
        id: 'view-offer',
      },
      {
        path: '/view-data-services-task',
        element: <ViewDataServicesTask />,
        id: 'view-data-services-task',
      },
      {
        path: '/reject-offer',
        element: <RejectOffer />,
        id: 'rejectoffer',
      },
      {
        path: '/accept-offer',
        element: <AcceptOffer />,
        id: 'acceptoffer',
      },
      {
        path: '/review-and-submit',
        element: <><ScrollToTop /><ReviewAndSubmit /></>,
        id: 'review-and-submit',
      },
      {
        path: '/success-submit',
        element: <SuccessSubmit />,
        id: 'success-submit',
      },
      {
        path: '/reporting',
        element: <Reporting />,
        id: 'reporting',
      },
    ],
  },
  {
    path: '/special-offer',
    element: (
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/special-offer/promotions',
        element: <><ScrollToTop /> <Promotions /> </>,
        id: 'promotions',
      },
      {
        path: '/special-offer/basics',
        element: <><ScrollToTop /> <Basics /></>,
        id: 'basics',
      },
      {
        path: '/special-offer/pricing-details',
        element: <><ScrollToTop /><PricingDetails /></>,
        id: 'pricing-details',
      },
      {
        path: '/special-offer/inclusions',
        element: <><ScrollToTop /><Inclusions /></>,
        id: 'inclusions',
      },
      {
        path: '/special-offer/advanced-booking',
        element: <><ScrollToTop /><AdvancedBookingExt /></>,
        id: 'advanced-booking',
      },
      {
        path: '/special-offer/booking-start-and-end-dates',
        element: <><ScrollToTop /><BookingStartAndEnd /></>,
        id: 'booking-start-and-end-dates',
      },
      {
        path: '/special-offer/blackout-dates',
        element: <><ScrollToTop /> <BlackoutDates /></>,
        id: 'blackout-dates',
      },
      {
        path: '/special-offer/valid-days-of-week',
        element: <><ScrollToTop /><ValidDaysOfWeek /></>,
        id: 'valid-days-of-week',
      },
      {
        path: '/special-offer/offer-code',
        element: <><ScrollToTop /><OfferCode /></>,
        id: 'offer-code',
      },
      {
        path: '/special-offer/distribution-channels',
        element: <><ScrollToTop /><DistributionChannels /></>,
        id: 'distribution-channels',
      },
      {
        path: '/special-offer/property-website',
        element: <><ScrollToTop /><PropertyWebsite /></>,
        id: 'property-website',
      },
      {
        path: '/special-offer/attachments',
        element: <><ScrollToTop /><Attachments /></>,
        id: 'attachments',
      },
      {
        path: '/special-offer/review-and-submit',
        element: <><ScrollToTop /> <ReviewAndSubmit /></>,
        id: 'special-offer-review-and-submit',
      }
    ],
  },
  {
    path: '/toolbox-offer',
    element: (
      <ErrorBoundary>
        <Root />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/toolbox-offer/promotions',
        element: <><ScrollToTop /><Promotions /></>,
        id: 'toolbox-promotions',
      },
      {
        path: '/toolbox-offer/basics',
        element: <><ScrollToTop /><Basics /></>, //TODO: Update
        id: 'toolbox-basics',
      },
      {
        path: '/toolbox-offer/htb-specific',
        element: <><ScrollToTop /><HtbSpecifics /></>,
        id: 'toolbox-htb-specific',
      },
      {
        path: '/toolbox-offer/inclusions',
        element: <><ScrollToTop /><ToolsInclusions /></>,
        id: 'toolbox-inclusions',
      },
      {
        path: '/toolbox-offer/booking-start-and-end-dates',
        element: <><ScrollToTop /><BookingStartAndEnd /></>,
        id: 'toolbox-booking-start-and-end-dates',
      },
      {
        path: '/toolbox-offer/offer-code',
        element: <><ScrollToTop /><OfferCode /></>,
        id: 'toolbox-offer-code',
      },
      {
        path: '/toolbox-offer/hotel-website-offers',
        element: <><ScrollToTop /><HotelWebsiteOffer /></>,
        id: 'toolbox-hotel-website-offers',
      },
      {
        path: '/toolbox-offer/attachments',
        element: <><ScrollToTop /><Attachments /></>,
        id: 'toolbox-attachments',
      },
      {
        path: '/toolbox-offer/review-and-submit',
        element: <><ScrollToTop /><ReviewAndSubmit /></>,
        id: 'toolbox-review-and-submit',
      },
    ],
  },
]);
