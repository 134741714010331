import React, { useCallback, useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { useSelector, useDispatch } from 'react-redux';
import { CommentsParams, handleChange, setDataServiceToDefault } from '../../store/offerToolSlice';
import { HeaderDetails } from '../../components/molecules/HeaderDetails/headerDetails';
import { GroupedOfferDetails } from '../../components/molecules/OfferDetails/groupedOfferDetail';
import { OfferDetails } from '../../components/molecules/OfferDetails/offerDetails';
import { Option, Select } from '../../components/atoms/Select';
import { Textarea } from '../../components/atoms/Teaxtarea';
import nls from '../../nls/offerDetails.json';
import './ViewDataServicesTask.scss';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import {
  getDataSerivceComments,
  getWorkItemId,
  getComments,
  getStepBackOptions,
  fetchAssignee,
} from '../../service/api';
import { useLocation } from 'react-router-dom';
import { extractPath } from '../../utils/fileUtils';
import { store } from '../../store/store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { REACT_URL } from '../../config/constants';
import DataService from './DataService';
import { isDSAssociate, isDSMarketing } from '../../utils/userUtils';

export const directSellOptions = [
  { label: 'VOLM', value: 'VOLM' },
  { label: 'SPEC', value: 'SPEC' },
  { label: 'RACK', value: 'RACK' },
  { label: 'MERC', value: 'MERC' },
  { label: 'HOTL', value: 'HOTL' },
  { label: 'DISC', value: 'DISC' },
  { label: 'LOYL', value: 'LOYL' },
];

export const allOtherRatePlanOptions = [
  { label: 'WHLA – ALLOTTED WHOLESALERS', value: 'WHLA – ALLOTTED WHOLESALERS' },
  {
    label: 'WHLB – GUARANTEE INTERNATIONAL WHOLESALERS',
    value: 'WHLB – GUARANTEE INTERNATIONAL WHOLESALERS',
  },
  { label: 'WHLS – TOUR OPERATORS', value: 'WHLS – TOUR OPERATORS' },
];

export const ViewDataServicesTask: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const {
    offers,
    offers: {
      offerId,
      cancellationAndCodes: { offerCodes },
      dataservices: { directSell, otherRatePlans },
      offerCategory
    },
    offerCodeState,
    dsAssignToState,
    dsDirectSalesState,
    dsOtherRatePlaneState,
    dataServiceActionReason,
    dataServiceStepBackReason,
    showRejectOfferScreen,
    showAcceptOfferScreen,
    showStepBackOfferScreen,
    stepBackError,
    rejectError,
    acceptError,
    workflowItemId,
    dataServiceActiveTabIndex,
    offerComments,
    stepBackOptionLoading,
  } = useSelector((state: any) => state.offerTool);
  const [userComments, setUserComments] = useState<CommentsParams[]>();
  const checkActive = (index: any, className: any) =>
    dataServiceActiveTabIndex === index ? className : '';
  const location = useLocation();
  const offerPath = extractPath(location.search);
  const [stepBackoptionsArray, setStepBackoptionsArray] = useState<Option[]>([]);
  const [showMore, setShowMore] = useState(false);

  const handleClick = (index: number) => {
    dispatch(handleChange({ name: 'dataServiceActiveTabIndex', value: index }));
  };

  const getAssignee = async () => {
    const assignee = await fetchAssignee(offerId);
    dispatch(handleChange({ name: 'dataServiceAssignee', value: assignee }));
  }

  useEffect(()=>{
    getAssignee();
  },[offerId]);

  useEffect(() => {
    getComment();
    window.utag_data.page_name = 'offertool-viewdataservicestask';
  }, []);

  const getComment = useCallback(() => {
    try {
      dispatch(getComments(offerPath)).then((e) => {
        if (e.meta.requestStatus === 'fulfilled') {
          const offerComments = store.getState().offerTool.offerComments;
          let commentsArr: CommentsParams[] = [];
          for (const key in offerComments) {
            if (
              offerComments?.hasOwnProperty(key) &&
              typeof offerComments[key] === 'object' &&
              'jcr:description' in offerComments[key]
            ) {
              const dynamicObject = offerComments[key];
              const jcrDescription = dynamicObject['jcr:description'];
              const jcrCreatedBy = dynamicObject['jcr:createdBy'];
              const jcrCreated = dynamicObject['jcr:created'];
              if (
                jcrDescription?.split('/')[0] === 'reject' ||
                jcrDescription?.split('/')[0] === 'accept'
              ) {
                commentsArr.push({
                  author: jcrCreatedBy,
                  comments: (jcrDescription?.split('/').slice(1).join('/'))
                    ?.replace(/\$#@!/g, "%")
                    .replace(/\^\$@\*/g, "&")
                    .replace(/@!\$~/g, "+"),
                  date: jcrCreated,
                  status: jcrDescription?.split('/')[0] + 'ed',
                });
              } else {
                commentsArr.push({
                  author: jcrCreatedBy,
                  comments: (jcrDescription?.split('/').slice(1).join('/'))
                    ?.replace(/\$#@!/g, "%")
                    .replace(/\^\$@\*/g, "&")
                    .replace(/@!\$~/g, "+"),
                  date: jcrCreated,
                  status: '',
                });
              }
            }
          }
          setUserComments(commentsArr);
          commentsArr = [];
        }
      });
    } catch (error) {}
  }, [offerComments]);

  useEffect(() => {
    try {
      const url = document.location.href;
      const params = url.split('?')[1].split('/');
      const pathElements = params.slice(3);
      const workFlowPath = pathElements.join('/');
      dispatch(handleChange({ name: 'workflowItemId', value: workFlowPath }));
      const spliturl = '?' + params.join('/');
      window.history.pushState({}, '', url.split('?')[0] + spliturl);
    } catch (error) {
      window.location.replace(REACT_URL);
    }
  }, []);

  useEffect(() => {
    if (offerPath) {
      dispatch(getWorkItemId(offerPath));
      dispatch(getDataSerivceComments(offerPath));
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { workflowItemId } = store.getState().offerTool;
        if (workflowItemId.includes('workflow')) {
          const response = await getStepBackOptions(workflowItemId);
          if (Object.keys(response.backSteps).length !== 0) {
            dispatch(handleChange({ name: 'isBackRoutesValueExists', value: true }));
            const optionArr: Option[] = Object.entries(response.backSteps).map(
              ([label, value]) => ({
                label,
                value,
              })
            ) as Option[];
            setStepBackoptionsArray(optionArr);
            dispatch(handleChange({ name: 'stepBackAssignee', value: optionArr[0].value }));
          } else {
            dispatch(handleChange({ name: 'isBackRoutesValueExists', value: false }));
          }
        }
      } catch (error) {}
    }
    fetchData();
  }, [workflowItemId]);

  const handleOfferCode = () => {
    if (!offerCodeState || offerCodeState.length < 4) {
      dispatch(handleChange({ name: 'offerCodeError', value: true }));
    } else {
      dispatch(handleChange({ name: 'offerCodes', value: [...offerCodes, offerCodeState] }));
      dispatch(handleChange({ name: 'offerCodeState', value: '' }));
      dispatch(handleChange({ name: 'offerCodeError', value: false }));
      dispatch(handleChange({ name: 'showError', value: false }));
    }
  };

  const handleDelete = (index: number) => {
    const filteredValue = offerCodes.filter((item: any, key: number) => key !== index);
    dispatch(handleChange({ name: 'offerCodes', value: filteredValue }));
  };

  const handleDirectSallsDelete = (id: number) => {
    const filteredValue = directSell?.filter((item: any, idx: number) => idx !== id);
    dispatch(handleChange({ name: 'dsDirectSell', value: filteredValue }));
  };

  const addDirectSales = () => {
    if (
      (dsDirectSalesState?.market &&
        (!dsDirectSalesState?.category || !dsDirectSalesState?.ratePlanCode)) ||
      (dsDirectSalesState?.category &&
        (!dsDirectSalesState?.market || !dsDirectSalesState?.ratePlanCode)) ||
      (dsDirectSalesState?.ratePlanCode &&
        (!dsDirectSalesState?.market || !dsDirectSalesState?.category))
    ) {
      dispatch(handleChange({ name: 'showError', value: true }));
    } else {
      if (dsDirectSalesState) {
        dispatch(
          handleChange({ name: 'dsDirectSell', value: [...directSell, dsDirectSalesState] })
        );
      } else {
        dispatch(handleChange({ name: 'dsDirectSell', value: [dsDirectSalesState] }));
      }
      dispatch(
        handleChange({
          name: 'dsDirectSalesState',
          value: {
            market: 'market',
            category: '',
            ratePlanCode: '',
          },
        })
      );
    }
  };

  const handleOtherRatePlansDelete = (id: number) => {
    const filteredValue = otherRatePlans?.filter((item: any, idx: number) => idx !== id);
    dispatch(handleChange({ name: 'dsOtherRatePlans', value: filteredValue }));
  };

  const addOtherRatePlans = () => {
    if (
      (dsOtherRatePlaneState?.market &&
        (!dsOtherRatePlaneState?.category || !dsOtherRatePlaneState?.ratePlanCode)) ||
      (dsOtherRatePlaneState?.category &&
        (!dsOtherRatePlaneState?.market || !dsOtherRatePlaneState?.ratePlanCode)) ||
      (dsOtherRatePlaneState?.ratePlanCode &&
        (!dsOtherRatePlaneState?.market || !dsOtherRatePlaneState?.category))
    ) {
      dispatch(handleChange({ name: 'showError', value: true }));
    } else {
      if (dsOtherRatePlaneState) {
        dispatch(
          handleChange({
            name: 'dsOtherRatePlans',
            value: [...otherRatePlans, dsOtherRatePlaneState],
          })
        );
      } else {
        dispatch(handleChange({ name: 'dsOtherRatePlans', value: [dsOtherRatePlaneState] }));
      }
      dispatch(
        handleChange({
          name: 'dsOtherRatePlaneState',
          value: {
            market: 'market',
            category: '',
            ratePlanCode: '',
          },
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(handleChange({ name: 'showRejectOfferScreen', value: false }));
      dispatch(handleChange({ name: 'showAcceptOfferScreen', value: false }));
      dispatch(handleChange({ name: 'showStepBackOfferScreen', value: false }));
      dispatch(setDataServiceToDefault());
    };
  }, []);

  const decideGroupingsShow = () =>{
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);

    if (offerCategory === 'toolbox-offer') {
      setShowMore(true); //dont show group offering for tool box
    }
    else if (!(isDSAssociate() || isDSMarketing())) {
      setShowMore(true); //dont show group offering if not DS Associate or DS Marketing
    }
    else if ((isDSAssociate() || isDSMarketing()) && (urlParams.has('more') && urlParams.get('more') === 'true')) {
      setShowMore(true); //dont show group offering if DS Associate or DS Marketing and has more tag

    }
    else {
      setShowMore(false); //else show group offering
    }
  }

  useEffect(() => {
    decideGroupingsShow();
  }, []);

  useEffect(() => {
    decideGroupingsShow();
  }, [offerCategory]);
  return (
    <section className="container">
      <PageHeader title={offers?.promotionName} description="" />
      <HeaderDetails />
      <hr className="hr" />
      {!showRejectOfferScreen && !showAcceptOfferScreen && !showStepBackOfferScreen && (
        <div className="tabs">
          <button className={`tab ${checkActive(1, 'active')}`} onClick={() => handleClick(1)}>
            Offer Details
          </button>
          <button
            className={`tab ${checkActive(2, 'active active')}`}
            onClick={() => handleClick(2)}
          >
            Distribution Services
          </button>
        </div>
      )}
      <div className="tab-content">
        {!showRejectOfferScreen && !showAcceptOfferScreen && !showStepBackOfferScreen && (
          <>
            <div className={`content ${checkActive(1, 'active')}`}>
              <p className="body1 review-details">{nls.REVIEW_OFFER_DETAILS}</p>
              {showMore ?
                <OfferDetails userComments={userComments} getComment={getComment} />
                :
                <GroupedOfferDetails />
              }
            </div>
            <div className={`content data-services ${checkActive(2, 'active')}`}>
              <DataService
                addDirectSales={addDirectSales}
                addOtherRatePlans={addOtherRatePlans}
                handleOtherRatePlansDelete={handleOtherRatePlansDelete}
                handleDirectSallsDelete={handleDirectSallsDelete}
                handleDelete={handleDelete}
                handleOfferCode={handleOfferCode}
              />
            </div>
          </>
        )}
      </div>
      {showRejectOfferScreen && (
        <div className={`confirm-offer`}>
          <p className="body1 review-details">{nls.OFFER_REJECTED}</p>
          <fieldset className="basics-info-elements">
            <Textarea
              id='rejection-reason'
              dataAttribute='rejection-reason-input'
              label="Reason For Rejection*"
              placeholder=""
              className="basic-form textarea-medium"
              name="rejectionReason"
              value={dataServiceActionReason}
              onChange={(e: any) =>
                dispatch(handleChange({ name: 'actionReason', value: e.target.value }))
              }
            />
          </fieldset>
          {rejectError && dataServiceActionReason === '' && (
            <div className="error-componenet">
              <WarningIcon width={15} height={15} />
              <p className="error-message">{nls.OFFER_REJECTED_ERROR}</p>
            </div>
          )}
        </div>
      )}
      {showAcceptOfferScreen && (
        <div className="confirm-offer">
          <p className="body1 review-details">{nls.OFFER_ACCEPTED}</p>
          <fieldset className="basics-info-elements">
            <Textarea
              id='accept-reason'
              dataAttribute='accept-reason-input'
              label={nls.OFFER_ACCEPTED_REASON}
              placeholder=""
              className="basic-form textarea-medium"
              name="acceptReason"
              value={dataServiceActionReason}
              onChange={(e: any) =>
                dispatch(handleChange({ name: 'actionReason', value: e.target.value }))
              }
            />
          </fieldset>
          {acceptError && dataServiceActionReason === '' && (
            <div className="error-componenet">
              <WarningIcon width={15} height={15} />
              <p className="error-message">{nls.OFFER_ACCEPTED_ERROR}</p>
            </div>
          )}
        </div>
      )}
      {showStepBackOfferScreen && (
        <div className="confirm-offer">
          <p className="body1 review-details">{nls.OFFER_STEPBACK}</p>
          <fieldset className="basics-info-elements">
            <Select
              id='assignee'
              dataAttribute='assignee-select'
              label="Assign to:*"
              name="assignee"
              options={stepBackoptionsArray}
              disabled={stepBackOptionLoading}
              className="basic-form select-medium"
              onChange={(e) => {
                const value = e.target.value;
                dispatch(handleChange({ name: 'stepBackAssignee', value: value }));
              }}
            />
            <Textarea
              id='step-back-reason'
              dataAttribute='set-back-reason-input'
              label={nls.OFFER_STEPBACK_REASON}
              placeholder=""
              className="basic-form textarea-medium"
              name="stepBackReason"
              value={dataServiceStepBackReason}
              onChange={(e: any) =>
                dispatch(handleChange({ name: 'stepBackReason', value: e.target.value }))
              }
            />
          </fieldset>
          {stepBackError && dsAssignToState === '' && (
            <div className="error-componenet">
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red' }}>{nls.OFFER_ASSIGNEE_ERROR}</p>
            </div>
          )}
          {stepBackError && dataServiceStepBackReason === '' && (
            <div className="error-componenet">
              <WarningIcon width={15} height={15} />
              <p className="error-message">{nls.OFFER_STEPBACK_ERROR}</p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
