import React, { CSSProperties } from 'react';
import './modal.css';
import { CrossIcon } from '../../Icons/CrossIcon';
import { CautionIcon } from '../../Icons/CautionIcon';

type ModalProps = {
  title?: string;
  onClose: () => void;
  children?: React.ReactNode;
  titleSize?: string;
  centerTitle?: boolean;
  cautionIconVisible?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const Modal: React.FC<ModalProps> = ({
  title,
  cautionIconVisible,
  titleSize,
  centerTitle,
  onClose,
  children,
  size
}) => {
  const titleStyle: CSSProperties = {
    fontSize: titleSize,
    justifyContent: centerTitle ? 'center' : 'left',
  };
  const modalClassName = size ? "modal modal-"+size: "modal modal-large";
  return (
    <div className="modal-overlay">
      <div className={modalClassName}>
        {cautionIconVisible && (
          <div className="icon-container">
            <CautionIcon />
          </div>
        )}
        <div className="modal-close">
          <CrossIcon onClick={onClose} />
        </div>
        <div className="modal-header" style={titleStyle}>
          {title && <h2 style={titleStyle}>{title}</h2>}
        </div>

        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
