export const getFileExtension = (file: File): string => {
  const fileName: string = file.name;
  const dotIndex: number = fileName.lastIndexOf('.');

  if (dotIndex === -1) {
    // No file extension found
    return '';
  }

  const extension: string = fileName.substr(dotIndex + 1);
  return extension;
};

export const getFileName = (url: string): string => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

export const extractSpiritCode = (locationSearch: string): string => {
  const searchParams = new URLSearchParams(locationSearch);

  const offerPath = searchParams.get('offerPath');

  if (!offerPath) return '';

  const segments = offerPath.split('/');
  return segments[4] || '';
};

export const extractOfferId = (locationSearch: string): string => {
  const searchParams = new URLSearchParams(locationSearch);
  let offerPath = searchParams.get('offerPath');

  if (!offerPath && !locationSearch) return '';

  if (!offerPath) {
    offerPath = locationSearch;
  }

  // Use a regex to match UUID format
  const uuidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  // Try to match UUID directly
  const uuidMatch = offerPath.match(uuidPattern);
  if (uuidMatch) {
    return uuidMatch[0];
  }

  // Try to match toolbox pattern
  const toolboxMatch = offerPath.match(/toolbox\/([0-9a-fA-F-]+)$/);
  if (toolboxMatch) {
    return toolboxMatch[1];
  }

  // Try to match hotel-special-offer pattern
  const hotelSpecialOfferMatch = offerPath.match(/hotels\/[^/]+\/([^/]+)$/);
  if (hotelSpecialOfferMatch) {
    return hotelSpecialOfferMatch[1];
  }

  // If no match is found, return an empty string
  return '';
};

export const extractOfferCategory = (
  locationSearch: string
): 'hotel-special-offer' | 'toolbox-offer' | '' => {
  const searchParams = new URLSearchParams(locationSearch);
  const offerPath = searchParams.get('offerPath');
  if (offerPath) {
    const containsToolbox = offerPath.includes('toolbox');

    return containsToolbox
      ? 'toolbox-offer'
      : offerPath.includes('hotels')
        ? 'hotel-special-offer'
        : '';
  } else {
    const containsToolbox = locationSearch?.includes('toolbox');
    return containsToolbox
      ? 'toolbox-offer'
      : locationSearch?.includes('hotels')
        ? 'hotel-special-offer'
        : '';
  }
};

export const extractUUID = (value: string) => {
  const id = value;
  const uuidPattern = /[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}/i;
  return id.match(uuidPattern);
};

export const formatDate = (date: string) => {
  const parsedDate = new Date(date);
  const formattedDate = parsedDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formattedDate;
};

export const getRedirectPath = () => {
  return window.location.href;
};

export const extractPath = (value: string) => {
  const currentURL = value;
  const urlParams = new URLSearchParams(currentURL);
  const offerPath = urlParams.get('offerPath');
  return offerPath || '';
};

export const extractSpiritCodeFromPath = (path: string) => {
  const spiritCode = path.split('/')[4];
  return spiritCode || '';
};

export const getPagePath = (inputPath: string | undefined): string => {
  if (!inputPath) return '';
  const prefix = '/content/offers/';
  if (inputPath?.startsWith(prefix)) {
    return inputPath.slice(prefix.length);
  }
  return inputPath;
};

export const parseHBMUrl = (inputString: string): string => {
  const targetSubstring = "brandmanager/details/image.html/";
  if (inputString.includes(targetSubstring)) {
    return inputString.replace(targetSubstring, "");
  } else {
    return inputString;
  }
}

export const getImageNameFromHBM = (inputString: string): string => {
  const regex = /\/([^\/]+?)(?:\.\d+x\d+)?(?:\.jpg)?$/;
  const match = inputString.match(regex);
  return match ? match[1] : '';
}