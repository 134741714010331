import React from 'react';
import './reporting.css';
import { DatePickerComponent } from '../../components/molecules/datePicker';
import { Select } from '../../components/atoms/Select';
import { Actions } from '../../components/molecules/Actions';
import { Button } from '../../components/atoms/Button';
import { WarningIcon } from '../../components/Icons/WarningIcon';

interface Params {
  title: string;
  onCancel: () => void;
  createdFrom: Date | undefined;
  setCreatedFrom: (date: Date) => void;
  createdTo: Date | undefined;
  setCreatedTo: (date: Date) => void;
  from: Date | undefined;
  setFrom: (date: Date) => void;
  to: Date | undefined;
  setTo: (date: Date) => void;
  dakHeaders: string;
  offerHeaders: string;
  exportDAK: () => void;
  isRequestLoading: boolean;
  IsError: boolean;
}

export const ReportDetails = ({
  title,
  onCancel,
  createdFrom,
  setCreatedFrom,
  createdTo,
  setCreatedTo,
  to,
  from,
  setTo,
  setFrom,
  dakHeaders,
  offerHeaders,
  exportDAK,
  isRequestLoading,
  IsError,
}: Params) => {
  return (
    <div className="ReportingDetails">
      <h2 className="reportDetails-heading">{title}</h2>
      <div className="reportingDetails-components">
        <div className="row-reporting">
          <h3 className="heading size">Dates</h3>
          <div className="start-end">
            <div>
              <DatePickerComponent
                label="* Created From"
                placeholder="Select Date"
                className="booking-date-picker"
                labelClassName="p-base-small"
                format="MMM-dd-yyyy"
                value={createdFrom}
                onChange={(e) => {
                  setCreatedFrom(e);
                }}
              />
            </div>
            <div>
              <DatePickerComponent
                label="* Created To"
                placeholder="Select Date"
                className="booking-date-picker"
                labelClassName="p-base-small"
                format="MMM-dd-yyyy"
                value={createdTo}
                onChange={(e) => {
                  setCreatedTo(e);
                }}
              />
            </div>
          </div>
          <div>
            <Select
              id="reporting-date-fields"
              dataAttribute="reporting-date-fields-select"
              isReporting={true}
              customCss="dropDownWidth"
              label="Date Field"
              options={[{ label: 'Offer Submitted', value: 'Offer-Submitted' }]}
              name="inclusionFrequency"
              value={'Offer Submitted'}
              labelClassName="p-base-small"
            />
          </div>
          <div className="start-end">
            <div>
              <DatePickerComponent
                label="* From"
                placeholder="Select Date"
                className="booking-date-picker"
                labelClassName="p-base-small"
                format="MMM-dd-yyyy"
                value={from}
                onChange={(e) => {
                  setFrom(e);
                }}
              />
              {IsError && !from && (
                <div style={{ display: 'flex' }}>
                  <WarningIcon width={15} height={15} />
                  <p style={{ fontSize: '12px', color: 'red' }}>Please choose from date</p>
                </div>
              )}
            </div>
            <div>
              <DatePickerComponent
                label="* To"
                placeholder="Select Date"
                className="booking-date-picker"
                labelClassName="p-base-small"
                format="MMM-dd-yyyy"
                value={to}
                onChange={(e) => {
                  setTo(e);
                }}
              />
              {IsError && !to && (
                <div style={{ display: 'flex' }}>
                  <WarningIcon width={15} height={15} />
                  <p style={{ fontSize: '12px', color: 'red' }}>Please choose to date</p>
                </div>
              )}
            </div>
          </div>
          <div style={{ paddingRight: '7px' }}>
            <h3 className="heading size">Report Conent</h3>
            <p>* Column Headers</p>
            <textarea
              name="reportDescription"
              value={title === 'DAK Import Special Offer' ? dakHeaders : offerHeaders}
              onChange={(e) => {}}
              className="reportingTextarea"
            />
          </div>
        </div>
        <div className="row-reporting" style={{ marginLeft: '80px' }}>
          <h3 className="heading size">Filters</h3>
          <Select
            id="reporting-assigned-to"
            dataAttribute="reporting-assigned-to-select"
            isReporting={true}
            customCss="dropDownWidth"
            label="Field"
            options={[{ label: 'Assigned To', value: 'Assigned To' }]}
            name=""
            value={'Assigned To'}
            labelClassName="p-base-small"
          />
          <Select
            id="reporting-operation"
            dataAttribute="reporting-operation-select"
            isReporting={true}
            customCss="dropDownWidth"
            label="Operation"
            options={[{ label: 'Equals', value: 'Equals' }]}
            name=""
            value={'Equals'}
            labelClassName="p-base-small"
          />
          <Select
            id="reporting-filter"
            dataAttribute="reporting-filter-select"
            isReporting={true}
            customCss="dropDownWidthLastOne"
            label="Filter Value"
            options={[
              { label: 'DS Associate 1, DS Marketing', value: 'DS Associate 1, DS Marketing' },
            ]}
            name=""
            value={'DS Associate 1, DS Marketing'}
            labelClassName="p-base-small"
          />
        </div>
      </div>
      <Actions className="footer-nav">
        <div className="next-back-btn-container">
          <Button
            id="reporting-cancel"
            dataAttribute="reporting-cancel-button"
            label="CANCEL"
            onClick={onCancel}
            className="save-btn"
          />
          <Button
            id="reporting-export"
            dataAttribute="reporting-export-button"
            label="EXPORT"
            primary
            onClick={exportDAK}
            isloading={isRequestLoading}
            isDisable={isRequestLoading}
          />
        </div>
      </Actions>
    </div>
  );
};
