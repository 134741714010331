import { DropDownIcon, HyattLogo, PersonIcon, QuestionIcon } from '../../Icons';
import './header.css';
import { ReactComponent as BellIcon } from '../../Icons/bell.svg';
import { useHelpText } from '../../../hooks/useHelpText';
import { useSelector } from 'react-redux';

type User = {
  name: string;
};

interface HeaderProps {
  appName?: string;
  pathname: string;
}

export const Header = ({ appName, pathname }: HeaderProps) => {
  const { authUser } = useSelector((state: any) => state.offerTool);

  const [setHelpText] = useHelpText();
  return (
    <header className="main-header">
      <div className="wrapper">
        <div className="logo">
          <a href="/" className="logo-container">
            <HyattLogo />
          </a>
          <h1 className="global-header">{appName}</h1>
        </div>
        <div className="flex-display">
          <button
            className="help-container"
            onClick={() => {
              setHelpText(pathname);
            }}
          >
            <QuestionIcon />
            <span className="help-text">Help</span>
          </button>
          <button className="bell-container">
            <BellIcon />
          </button>
          <>
            <div className="panel-container">
              <button type="button" className="signin">
                <PersonIcon />
                <span>
                  <span className="p-base-normal">{authUser}</span>
                </span>
              </button>
            </div>
          </>
        </div>
      </div>
    </header>
  );
};
