import React, { useState } from 'react';
import { Actions } from '../../components/molecules/Actions';
import { Button } from '../../components/atoms/Button';
import { DatePickerComponent } from '../../components/molecules/datePicker';
import { Input } from '../../components/atoms/Input';
import { ApprovalReportSearchParam } from '.';
import { WarningIcon } from '../../components/Icons/WarningIcon';

interface Params {
  title: string;
  onExportApprovalReport: () => void;
  onCancel: () => void;
  isRequestLoading: boolean;
  setApprovalReportingSearchParams: (params: ApprovalReportSearchParam) => void;
  approvalReportingSearchParams: ApprovalReportSearchParam | undefined;
}
export const ApprovalReport = ({
  isRequestLoading,
  title,
  onExportApprovalReport,
  onCancel,
  approvalReportingSearchParams,
  setApprovalReportingSearchParams,
}: Params) => {
  const [fromDateError, setFromDateError] = useState(false);

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 100)) {
      setApprovalReportingSearchParams({
        fromDate: undefined,
        toDate: undefined,
        noOfDays: e.target.value,
      });
      setFromDateError(false);
    }
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '+' || e.key === 'e' || e.key === '-') {
      e.preventDefault();
    }
  };

  const validate = () => {
    if (
      approvalReportingSearchParams &&
      approvalReportingSearchParams.toDate &&
      !approvalReportingSearchParams.fromDate
    ) {
      setFromDateError(true);
    } else {
      onExportApprovalReport();
    }
  };

  return (
    <div className="ReportingDetails">
      <h2 className="reportDetails-heading">{title}</h2>
      <div className="reportingDetails-components">
        <div className="row-reporting">
          <h3 className="heading size">Search By Dates</h3>
          <div className="start-end">
            <div>
              <DatePickerComponent
                label="From Date"
                placeholder="Select Date"
                className="booking-date-picker"
                labelClassName="p-base-small"
                format="MMM-dd-yyyy"
                value={
                  approvalReportingSearchParams?.fromDate
                    ? new Date(approvalReportingSearchParams.fromDate)
                    : undefined
                }
                onChange={(e) => {
                  setFromDateError(false);
                  setApprovalReportingSearchParams({
                    ...approvalReportingSearchParams,
                    fromDate: new Date(e).getTime(),
                    noOfDays: '',
                  });
                }}
              />
              {fromDateError && (
                <div className="approval-reporting-error-message">
                  <WarningIcon width={15} height={15} />
                  <p className="error-message">From date can't be empty</p>
                </div>
              )}
            </div>
            <div>
              <DatePickerComponent
                label="To Date"
                placeholder="Select Date"
                className="booking-date-picker"
                labelClassName="p-base-small"
                format="MMM-dd-yyyy"
                value={
                  approvalReportingSearchParams?.toDate
                    ? new Date(approvalReportingSearchParams.toDate)
                    : undefined
                }
                onChange={(e) => {
                  setApprovalReportingSearchParams({
                    ...approvalReportingSearchParams,
                    toDate: new Date(e).getTime(),
                    noOfDays: '',
                  });
                }}
              />
            </div>
          </div>
          <h3 className="heading size">Search By Day(s)</h3>
          <Input
            id="approval-reporting-days"
            dataAttribute="approval-reporting-days- input"
            label="Enter Day(s)"
            type="number"
            className="approval-reporting-input"
            value={
              approvalReportingSearchParams?.noOfDays ? approvalReportingSearchParams.noOfDays : ''
            }
            onKeyDown={handleKey}
            onChange={handleOnchange}
          />
          <div className="approval-reporting-default-message-container">
            <h3 className="approval-reporting-default-message">
              * Reporting supports both search by dates or search by days.
            </h3>
            <h3 className="approval-reporting-default-message">
              * When only from date is provided the report includes between from date and till date.
            </h3>
            <h3 className="approval-reporting-default-message">
              * The reporting will default to 5 days.
            </h3>
          </div>
        </div>
      </div>
      <Actions className="footer-nav">
        <div className="next-back-btn-container">
          <Button
            id="approval-reporting-cancel"
            dataAttribute="approval-reporting-cancel-button"
            label="CANCEL"
            onClick={onCancel}
            className="save-btn"
          />
          <Button
            id="approval-reporting-export"
            dataAttribute="approval-reporting-export-button"
            label="EXPORT"
            primary
            onClick={validate}
            isloading={isRequestLoading}
            isDisable={isRequestLoading}
          />
        </div>
      </Actions>
    </div>
  );
};
