import { useEffect, useState } from 'react';
import Modal from '../../../components/atoms/Modal';
import '../dashboard.css';
import { useSelector } from 'react-redux';
import { CommentsParams } from '../../../store/offerToolSlice';
import LoadingSpinner from '../../../components/atoms/Spinner';
import { formatDate } from '../../../utils/dateAndTimeUtils';
import { capitalizeWords, formatComment } from '../../../utils/textUtils';
interface Props {
  handleClose: () => void;
}

function CommentsModal({ handleClose }: Props) {
  const { offerComments, isCommentsLoading } = useSelector((state: any) => state.offerTool);
  const [userComments, setUserComments] = useState<CommentsParams[]>();

  const formatComments = () => {
    try {
      let commentsArr: CommentsParams[] = [];
      for (const key in offerComments) {
        if (
          offerComments?.hasOwnProperty(key) &&
          typeof offerComments[key] === 'object' &&
          'jcr:description' in offerComments[key]
        ) {
          const dynamicObject = offerComments[key];
          const jcrDescription = dynamicObject['jcr:description'];
          const jcrCreatedBy = dynamicObject['jcr:createdBy'];
          const jcrCreated = dynamicObject['jcr:created'];
          if (
            jcrDescription?.split('/')[0] === 'reject' ||
            jcrDescription?.split('/')[0] === 'accept' ||
            jcrDescription?.split('/')[0] === 'stepback' ||
            jcrDescription?.split('/')[0] === 'Comment'
          ) {
            commentsArr.push({
              author: jcrCreatedBy,
              comments: jcrDescription?.split('/').slice(1).join('/'),
              date: jcrCreated,
              status:
                jcrDescription?.split('/')[0] === 'stepback' ||
                jcrDescription?.split('/')[0] === 'Comment'
                  ? jcrDescription?.split('/')[0]
                  : jcrDescription?.split('/')[0] + 'ed',
            });
          } else {
            commentsArr.push({
              author: jcrCreatedBy,
              comments: jcrDescription?.split('/').slice(1).join('/'),
              date: jcrCreated,
              status: '',
            });
          }
        }
      }
      setUserComments(commentsArr);
      commentsArr = [];
    } catch (error) {}
  };

  useEffect(() => {
    formatComments();
  }, [offerComments]);

  const getAssignee = (comment: string) => {
    if (comment.substring(0, 9) === 'assignee:') {
      return comment.split('/')[0].split(':')[1];
    }
    return '';
  };

  const getCommentsView = () => {
    return userComments?.map((event, index) => (
      <div key={index}>
        <div className="comments_status_date comments-line-content">
          {event.status && capitalizeWords(event.status) + ' - ' + getAssignee(event.comments)}
        </div>
        <div className="comments_status_date comments-line-content">{formatDate(event.date)}</div>
        <div className="comments-line-content">{formatComment(event.comments)}</div>
        <div className="comments-line-content">Comment by: {event.author}</div>
        <hr />
      </div>
    ));
  };

  return (
    <Modal cautionIconVisible={false} titleSize="25px" title="Comments" onClose={handleClose}>
      {userComments && userComments?.length > 0 ? (
        <div className="Modal_comments_content">{getCommentsView()}</div>
      ) : isCommentsLoading ? (
        <LoadingSpinner />
      ) : (
        <p>No Comments Found</p>
      )}
    </Modal>
  );
}

export default CommentsModal;
