export const setCookie = (name: string, value: string, days = 14) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = ";expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + ";path=/";
}

export const getCookie = (name: string) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop()?.split(";").shift();
}
