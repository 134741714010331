import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { OfferData } from './offerData';
import nlsCommon from '../../../nls/common.json';
import './offerDetails.css';
import {
  fetchAssignee,
  getHotelOffer,
  getOfferCategoriesAndTypes,
  getTermsAndConditions,
  getToolboxOffer,
  updateTaskContent,
} from '../../../service/api';
import { frequency } from '../../../pages/inclusions';
import { BlackoutRange } from '../../../pages/blackoutDates';
import { CommentsParams, LikeToAdd, handleChange } from '../../../store/offerToolSlice';
import { BASE_URL } from '../../../config/constants';
import { convertToMMDDYYYY } from '../../../utils/dateAndTimeUtils';
import MenuEdit from './assets/MenuEdit';
import nls from '../../../nls/reviewAndSubmit.json';
import {
  getFileName,
  extractOfferId,
  extractOfferCategory,
  extractPath,
} from '../../../utils/fileUtils';
import ShimmerLoading from '../../atoms/Shimmer';
import { Title } from '../../atoms/Title';
import LoadingSpinner from '../../atoms/Spinner';
import Timeline from '../../atoms/Timeline';
import featureFlag from '../../../config/featureFlags';
import { store } from '../../../store/store';
import Banner from '../../atoms/Banner';
import { options } from '../../../pages/offrerCodeAndCancellationPolicy/useDropdownOfferCodeOptions';
import SeeMore from './assets/SeeMore';
import { getLanguageNames } from '../../../utils/textUtils';
import { luxuryConsortiaOptions, priveLuxuryConsortiaOptions } from '../../../pages/pricingDetails/priveScreen/privePricingDetails';

interface InclusionParams {
  inclusionText: string;
  inclusionFrequency: string;
  inclusionDetails: string;
}

interface ToolboxInclusionParams{
  inclusionDetails: string;
  inclusionText: string;
}

type EditParams =
  | 'Basic Information'
  | 'Inclusions & Frequency'
  | 'Advanced Booking'
  | 'Booking Dates'
  | 'Blackout Dates'
  | 'Valid Days'
  | 'Offer Code & Cancellation Policy'
  | 'Pricing Details'
  | 'Distribution Channels'
  | 'Property Website Offers Page'
  | 'Attachments'
  | 'HTB Percent Off'
  | 'Booking Lead Time';

interface Params {
  userComments: CommentsParams[] | undefined;
  getComment: () => void;
  isLaunchOffer?: boolean;
}

export const OfferDetails = ({ userComments, getComment, isLaunchOffer }: Params) => {
  const location = useLocation();
  const navigate = useNavigate();

  const offerId = extractOfferId(location.search);
  const offerCategoryParam = extractOfferCategory(location.search);
  const pagePath = extractPath(location.search);
  const [categoryIs, setCategoryIs] = useState('');
  const [typeIs, setTypeIs] = useState('');
  const [showComments, setShowComments] = useState(true);
  const [isSavingComments, setIsSavingComments] = useState(false);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [showSeeMoreSpiritCodeModal, setShowSeeMoreSpiritCodeModal] = useState(false);

  const {
    offers,
    disableSubmit,
    offerCategoriesAndTypes,
    isRequestLoading,
    isRequestFailed,
    toolboxOffer,
    offerPricingDropdown,
    canCreateOffer,
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isPriveOffer = offers?.offerType === 'hyatt-prive';
  const isPriveAmexLuxOffer = offers?.offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox';
  let showOfferCode = true;
  let showPricingDetails = true;
  let showIncusions = true;
  let showAdvancedBooking = true;
  let showBlackoutDates = true;
  let hideValidDays = false;
  let showDOWAndMaxLength = true;
  let showPropertyWebsiteOffersPage = true;
  let showDistributionChennals = true;
  let showHTBPercentOff = false;
  let showPriveDetails = false;
  if (
    offers?.offerCategory
      ? offers.offerCategory === 'toolbox-offer'
      : offerCategoryParam === 'toolbox-offer'
  ) {
    showAdvancedBooking = false;
    showBlackoutDates = false;
    showDistributionChennals = false;
    showDOWAndMaxLength = false;
    showPricingDetails = false;
    hideValidDays = true;
    showHTBPercentOff = true;
    const likeToAdd: LikeToAdd[] = toolboxOffer?.likeToAdd;
    if (!likeToAdd.includes('Guarantee/Cancellation Policy') && !offerCategoryParam) {
      showOfferCode = false;
    }
    if ((!likeToAdd.includes('Inclusions') && !offerCategoryParam) || isPriveAmexLuxOffer) {
      showIncusions = false;
    }
  } else if (
    offers?.offerCategory
      ? offers.offerCategory === 'hotel-special-offer'
      : offerCategoryParam === 'hotel-special-offer'
  ) {
    if (
      offers?.pricing.qualifiers === 'qualifiedCustomers' &&
      !offers?.distributionChannel.hyattCom
    ) {
      showPropertyWebsiteOffersPage = false;
    }
    if (isPriveOffer) {
      showAdvancedBooking = false;
      showBlackoutDates = false;
      showPricingDetails = false;
      showPriveDetails = true;
    }
    if (offers?.offerType === 'hyatt-luxury-consortia') {
      showPropertyWebsiteOffersPage = false;
      showPricingDetails = false;
      showPriveDetails = true;
    }
  }

  useEffect(() => {
    dispatch(handleChange({ name: 'isLaunchOffer', value: isLaunchOffer }));
  }, [isLaunchOffer]);

  const fetchCategoriesAndTypes = () => {
    try {
      if (offerCategoriesAndTypes.length === 0) {
        dispatch(getOfferCategoriesAndTypes());
      }
      const category = offerCategoriesAndTypes.filter(
        (e: any) => e.name === offers?.offerCategory && e
      );
      setCategoryIs(category[0]?.title);
      const type = category[0]?.offerTypes.filter((e: any) => e.name === offers?.offerType && e);
      setTypeIs(type[0]?.title);
    } catch (error) {
      setCategoryIs(offers?.offerCategory);
      setTypeIs(offers?.offerType);
    }
  };

  useEffect(() => {
    fetchCategoriesAndTypes();
  }, [offerCategoriesAndTypes, offers]);

  const getOfferPricingText = (value: string): string | null => {
    if (!offerPricingDropdown) {
      const pricingDetails =
        offers.pricing?.offerRack?.charAt(0).toUpperCase() + offers.pricing?.offerRack?.slice(1);
      return pricingDetails?.toString().replaceAll('-', ' ');
    }
    for (let item of offerPricingDropdown) {
      if (item.value === value) {
        return item.label;
      }
    }
    return null;
  };

  const isValidOffer = () => {
    const offerObj = store.getState().offerTool.offers;
    const isPriveOffer = offerObj?.offerType === 'hyatt-prive';
    dispatch(handleChange({ name: 'disableSubmit', value: false }));
    setErrorFields([]);
    const arr = [];
    if (location.pathname.includes('/review-and-submit')) {
      if (offerObj) {
        if (offerCategoryParam === 'hotel-special-offer') {
          const {
            advancedBooking: { advancedBookingEligibility },
            bookingDates: { bookingStartDate, bookingEndDate },
            pricing: { offerRack, qualifiers },
            cancellationAndCodes: { depositPolicy },
            distributionChannel,
            offerType,
            websiteOffers: { isOffer, offerTitle },
          } = offerObj;
          if (!isPriveOffer && advancedBookingEligibility === null) {
            arr.push('advancedBooking');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (bookingStartDate === '' || bookingEndDate === '') {
            arr.push('bookingDates');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (!isPriveOffer && offerType !== 'hyatt-luxury-consortia' && (offerRack === '' || qualifiers === '')) {            
            arr.push('pricing');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if(isPriveOffer && qualifiers === ''){
            arr.push('prive-pricing');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (depositPolicy === '') {
            arr.push('offerCode');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          const hasTrueValue = Object.values(distributionChannel).some((value) => value === true);
          if (!hasTrueValue) {
            arr.push('distributionChannel');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
          if (isOffer && offerTitle === '') {
            arr.push('websiteOffer');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
        } else {
          const {
            bookingDates: { bookingStartDate, bookingEndDate },
          } = offerObj;
          if (bookingStartDate === '' || bookingEndDate === '') {
            arr.push('bookingDates');
            dispatch(handleChange({ name: 'disableSubmit', value: true }));
          }
        }
        setErrorFields(arr);
      }
    }
  };

  useEffect(() => {
    if (offerId) {
      dispatch(handleChange({ name: 'isRequestLoading', value: true }));
      if (offerCategoryParam === 'toolbox-offer') {
        dispatch(getToolboxOffer({ path: pagePath })).then(({ meta }) => {
          dispatch(getTermsAndConditions(pagePath));
          dispatch(handleChange({ name: 'isRequestLoading', value: false }));
          if (meta.requestStatus === 'rejected') {
            dispatch(
              handleChange({
                name: 'isRequestFailed',
                value: {
                  isFailed: true,
                  reason: 'No data found for resource given identifier.',
                },
              })
            );
          }
          isValidOffer();
        });
      } else {
        dispatch(
          getHotelOffer({
            path: pagePath,
          })
        ).then(({ meta }) => {
          dispatch(getTermsAndConditions(pagePath));
          dispatch(handleChange({ name: 'isRequestLoading', value: false }));
          if (meta.requestStatus === 'rejected') {
            dispatch(
              handleChange({
                name: 'isRequestFailed',
                value: {
                  isFailed: true,
                  reason: 'No data found for resource given identifier.',
                },
              })
            );
          }
          isValidOffer();
        });
      }
    }
  }, [offerId]);
  
  const getDistributionChennals = () => {
    const arr: string[] = [];
    const { gds, hyattCom, voiceAndHotelDirect, otaStandalone, priveWebsite } = offers?.distributionChannel;
    if (priveWebsite) {
      arr.push('Privé Website')
    }
    if (hyattCom) {
      arr.push('Hyatt.com');
    }
    if (voiceAndHotelDirect) {
      arr.push('Voice and Hotel Direct');
    }
    if (gds) {
      arr.push('GDS');
    }
    if (otaStandalone) {
      arr.push('OTA - Standalone (OTA Public Rates');
    }
    return arr;
  };

  const wholeSaleConnectedPartnerValues = () => {
    const partnerArr: string[] = [];
    if (offers?.distributionChannel?.wholesaleConnected) {
      partnerArr.push('Wholesale - Connected');
      offers?.distributionChannel?.wholesaleConnectedSpecificPartner?.map((e: string) => {
        partnerArr.push(e);
      });
    }
    return partnerArr;
  };

  const wholeSaleNoConnectedPartnerValues = () => {
    const partnerArr: string[] = [];
    if (offers?.distributionChannel?.wholesaleNotConnected) {
      partnerArr.push('Wholesale - Not Connected');
      offers?.distributionChannel?.wholesaleNotConnectedSpecificPartner?.map((e: string) => {
        partnerArr.push(e);
      });
    }
    return partnerArr;
  };

  const otaBundledValues = () => {
    const partnerArr: string[] = [];
    if (offers?.distributionChannel?.otaBundled) {
      partnerArr.push('OTA - Bundled (OTA Bundled Opaque Rates)');
      offers?.distributionChannel?.otaBundledSpecificPartner?.map((e: string) => {
        partnerArr.push(e);
      });
    }
    return partnerArr;
  };

  const lcpValues = () => {
    const partnerArr: string[] = [];
    if (offers?.distributionChannel?.luxuryConsortiaPartners) {
      partnerArr.push('Luxury Consortia Partners');
      offers?.distributionChannel?.luxuryConsortiaPartnersSpecificPartner?.map((e: string) => {
        partnerArr.push(e);
      });
    }
    return partnerArr;
  };

  const getFormettedDays = () => {
    try {
      if (offers?.offerValidity?.validDays?.length > 0) {
        const days: string[] = [];
        offers?.offerValidity?.validDays?.map((e: string) => {
          days.push(e.charAt(0).toUpperCase() + e.slice(1));
        });
        const weekdayOrder: string[] = [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ];
        const sortedDays = days?.sort((a, b) => {
          const indexA = weekdayOrder.indexOf(a);
          const indexB = weekdayOrder.indexOf(b);
          return indexA - indexB;
        });
        return sortedDays.toString();
      }
    } catch (error) {}
  };

  const attachments = offers?.attachments ? (
    Object.entries(offers.attachments).map(([keys, value]: [any, any]) => {
      const url = BASE_URL + value;
      const linkText = getFileName(url);
      if (keys.includes('translationfile')) {
        return value !== '' ? (
          <MenuEdit
            name={nls.TRANSLATION_DOC}
            info={linkText}
            infoType={'link'}
            key={keys}
            linkUrl={url}
          />
        ) : (
          <></>
        );
      } else if (keys.includes('offerasset')) {
        if (value === '') return <></>;
        return value.map((item: string, index: number) =>
          item !== '' ? (
            <MenuEdit
              name={nls.OTHER_ATTACHMENT}
              info={getFileName(item)}
              infoType={'link'}
              key={`${keys}_${index}`}
              linkUrl={BASE_URL + item}
            />
          ) : (
            <React.Fragment key={`${keys}_${index}`} />
          )
        );
      } else if (keys.includes('rdrmfile')) {
        return value !== '' ? (
          <MenuEdit
            name={nls.RDRM_EMAIL}
            info={linkText}
            infoType={'link'}
            key={keys}
            linkUrl={url}
          />
        ) : (
          <></>
        );
      }
    })
  ) : (
    <></>
  );

  const isOfferParameterEmpty = () => {
    if (!offerId) {
      return false;
    } else {
      return true;
    }
  };

  const addNewComment = async (newComment: string) => {
    const { offerId } = store.getState().offerTool.offers;
    setIsSavingComments(true);
    await fetchAssignee(offerId).then(async (assigneeGroup)=>{
      const assignee = assigneeGroup ? assigneeGroup : '';
      const updatedComment = newComment
        .replace(/%/g, "$#@!")
        .replace(/&/g, "^$@*")
        .replace(/\+/g, "@!$~");
      await updateTaskContent(pagePath, `Comment/assignee:${assignee}/` + updatedComment).then((e) => {
      setIsSavingComments(false);
      if (e.status === 200) {
        getComment();
      }
    });
    })
  };

  const onEditClicked = (screen: EditParams) => {
    dispatch(handleChange({ name: 'isUpdate', value: isOfferParameterEmpty() }));
    dispatch(handleChange({ name: 'fromReviewAndSubmit', value: true }));
    switch (screen) {
      case 'Basic Information':
        offers.offerCategory === 'toolbox-offer'
          ? navigate(`/toolbox-offer/basics?offerPath=${pagePath}`, { state: { from: 'edit' } })
          : navigate(`/special-offer/basics?offerPath=${pagePath}`, { state: { from: 'edit' } });
        break;
      case 'Inclusions & Frequency':
        offers.offerCategory === 'toolbox-offer'
          ? navigate(`/toolbox-offer/inclusions?offerPath=${pagePath}`, { state: { from: 'edit' } })
          : navigate(`/special-offer/inclusions?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            });
        break;
      case 'Advanced Booking':
        navigate(`/special-offer/advanced-booking?offerPath=${pagePath}`, {
          state: { from: 'edit' },
        });
        break;
      case 'Booking Dates':
        offers.offerCategory === 'toolbox-offer'
          ? navigate(`/toolbox-offer/booking-start-and-end-dates?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            })
          : navigate(`/special-offer/booking-start-and-end-dates?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            });
        break;
      case 'Blackout Dates':
        navigate(`/special-offer/blackout-dates?offerPath=${pagePath}`, {
          state: { from: 'edit' },
        });
        break;
      case 'Valid Days':
        navigate(`/special-offer/valid-days-of-week?offerPath=${pagePath}`, {
          state: { from: 'edit' },
        });
        break;
      case 'Pricing Details':
        if (offers.offerCategory === 'hotel-special-offer') {
          navigate(`/special-offer/pricing-details?offerPath=${pagePath}`, {
            state: { from: 'edit' },
          });
        }
        break;
      case 'Offer Code & Cancellation Policy':
        offers.offerCategory === 'toolbox-offer'
          ? navigate(`/toolbox-offer/offer-code?offerPath=${pagePath}`, { state: { from: 'edit' } })
          : navigate(`/special-offer/offer-code?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            });
        break;
      case 'Distribution Channels':
        navigate(`/special-offer/distribution-channels?offerPath=${pagePath}`, {
          state: { from: 'edit' },
        });
        break;
      case 'Property Website Offers Page':
        offers.offerCategory === 'toolbox-offer'
          ? navigate(`/toolbox-offer/hotel-website-offers?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            })
          : navigate(`/special-offer/property-website?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            });
        break;
      case 'Attachments':
        offers.offerCategory === 'toolbox-offer'
          ? navigate(`/toolbox-offer/attachments?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            })
          : navigate(`/special-offer/attachments?offerPath=${pagePath}`, {
              state: { from: 'edit' },
            });
        break;
      //ToolBox Screens
      case 'HTB Percent Off':
        navigate(`/toolbox-offer/htb-specific?offerPath=${pagePath}`, { state: { from: 'edit' } });
        break;
      case 'Booking Lead Time':
        navigate(`/toolbox-offer/booking-lead-time?offerPath=${pagePath}`, {
          state: { from: 'edit' },
        });
        break;
      default:
        break;
    }
  };

  const isExpanded = (): boolean => {
    if (
      location.pathname === '/special-offer/review-and-submit' ||
      location.pathname === '/toolbox-offer/review-and-submit' ||
      location.pathname === '/view-offer' ||
      location.pathname === '/review-and-submit' ||
      location.pathname === '/view-data-services-task'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getLabelForDepositPolicyOption = (value: any) => {
    if (value === 'House Policy' || value === 'Credit Card') {
      return value;
    } else {
      const option = options.depositPolicyOptions.find((option) => option.value === value);
      return option ? option.label : 'Label not found';
    }
  };

  const getLabelForPenaltyPolicyOption = (value: any) => {
    if (value === 'House Policy') {
      return 'House Policy';
    } else {
      const option = options.penaltyPolicyOptions.find((option) => option.value === value);
      return option ? option.label : 'Label not found';
    }
  };

  const getLabelForCancelPolicyOption = (value: any) => {
    if (value === 'House Policy') {
      return 'House Policy';
    } else {
      const option = options.cancelPolicyOptions.find((option) => option.value === value);
      return option ? option.label : 'Label not found';
    }
  };

  let enableSeeMoreOption = false;
  const getSpiritCodeArray = () => {
    const stringValue = String(offers?.spiritCode);
    const spiritCodeArr = stringValue.split(',');
    const arr = spiritCodeArr.slice(0, 16);
    if (spiritCodeArr.length > 16) {
      enableSeeMoreOption = true;
    }
    return String(arr.join(', ')).toUpperCase();
  };

  const onSeeMoreSpiritCodeClick = () => {
    setShowSeeMoreSpiritCodeModal(true);
  };

  const getHotelInclusions = (e: InclusionParams) => {
    return e.inclusionText + ', ' + frequency[e.inclusionFrequency] + ', ' + e.inclusionDetails;
  };

  const getToolBoxIncusionsLabel = (text: string) => {
    let label = text;
    switch (text) {
      case 'parking':
        label = 'Parking';
        break;
      case 'hotel-credit':
        label = 'Hotel Credit';
        break;
      case 'breakfast':
        label = 'Breakfast';
        break;
      case 'self-parking':
        label = 'Self Parking';
        break;
      case 'valet':
        label = 'Valet';
        break;
      case 'per-night':
        label = 'Per Night';
        break;
      case 'per-stay':
        label = 'Per Stay';
        break;
    }
    return label;
  }

  const HTBSpecifics = {
    all : "Both WOH Member Rate & All Channels",
    woh : "WOH Member Rate only"
  } as { [key: string]: string };

  const getHTBSpecificLabel = (value:string):string | undefined => {
    return HTBSpecifics[value]
  }

  const getConsortiaOptionsLabelText = () => {
    let stringOptions = '';
    const options = offers?.offerType === 'hyatt-luxury-consortia' ? luxuryConsortiaOptions : priveLuxuryConsortiaOptions;
    offers?.pricing?.consortiaOptions?.map((value: string) => {
      const option = options.find(option => option.value === value);
      if(option){
        stringOptions += option.label +', ';
      }
    });
    return stringOptions;
  }

  const getQualifierLabel = useMemo(() => {
    switch (offers?.pricing?.qualifiers) {
      case 'guests-and-travelAgents':
        return 'Guest, Travel Agent';
      case 'guests':
        return 'Guests';
      case 'travelAgents':
        return 'Travel Agent';
      default:
        return 'N/A';
    }
  }, [offers?.pricing?.qualifiers]);
 
  return (
    <div>
      {showSeeMoreSpiritCodeModal && (
        <SeeMore
          handleClose={() => {
            setShowSeeMoreSpiritCodeModal(false);
          }}
          data={offers?.spiritCode}
        />
      )}
      {disableSubmit && (
        <div className="error-message-component">
          <Banner
            title={nlsCommon.REQUIRED_FIELDS}
            message={nlsCommon.REQUIRED_FIELDS_ARE_EMPTY_IN_DRAFT_OFFERS}
          />
        </div>
      )}
      {!isRequestLoading ? (
        !isRequestFailed.isFailed ? (
          <div className="root-view">
            <OfferData
              isCollapsed={isExpanded()}
              isEditable={false}
              title="Offer Category & Offer Type"
              children={
                <>
                  <MenuEdit name="Offer Category" info={categoryIs} />
                  <MenuEdit name="Offer Type" info={typeIs} />
                </>
              }
            />
            <OfferData
              isCollapsed={isExpanded()}
              title="Basic Information"
              isEditable={location.pathname !== '/view-offer'}
              onClickEdit={() => {
                onEditClicked('Basic Information');
              }}
              children={
                <>
                  {offers.offerCategory === 'hotel-special-offer' && (
                    <MenuEdit name="Offer Name" info={offers?.promotionName} />
                  )}
                  <MenuEdit
                    name="Record Type"
                    info={
                      offers?.recordType === 'Single' ? 'Single Hotel' : 'Multiple Hotel Selection'
                    }
                  />
                  {offers?.recordType === 'Single' && (
                    <MenuEdit name="Hotel Name" info={offers?.hotelName} />
                  )}
                  <MenuEdit
                    name={offers?.recordType === 'Single' ? 'Spirit Code' : 'Hotel Name(s)'}
                    info={getSpiritCodeArray()}
                    enableSeeMore={enableSeeMoreOption}
                    onSeeMoreSpiritCodeClick={onSeeMoreSpiritCodeClick}
                  />
                  <MenuEdit name="Initiator" info={offers?.owner} />
                  <MenuEdit name="Additional E-Mail Notification" info={offers?.additionalEmails} />
                </>
              }
            />
            {showHTBPercentOff && (
              <OfferData
                isEditable={location.pathname !== '/view-offer'}
                isCollapsed={isExpanded()}
                title={isPriveAmexLuxOffer ? "Hyatt Privé/Amex/Luxury Consortia Discount Toolbox":"HTB Specifics"}
                onClickEdit={() => {
                  onEditClicked('HTB Percent Off');
                }}
                isError={errorFields.includes('htb')}
                children={
                  <>
                    <>
                      {!isPriveAmexLuxOffer &&
                        <MenuEdit
                          name="Who is eligible for this offer?"
                          info={getHTBSpecificLabel(offers?.pricing?.qualifiers)}
                        />
                      }
                      {offers?.offerType !== 'htb-rack-plus-attribute' && (
                        <MenuEdit
                          name={isPriveAmexLuxOffer ? 'Which offer is being updated?': typeIs?.slice(typeIs.indexOf(' ') + 1)}
                          info={offers?.pricing?.offerRack}
                        />
                      )}
                    </>
                    {offers?.bookingDayLeadTime > 1 &&
                      <MenuEdit
                        name="How far in advance the offer must be booked before stay"
                        info={offers?.bookingDayLeadTime + ' Days'}
                      />}
                    {offers?.offerValidity?.minStayNights &&
                      offers?.offerValidity?.minStayNights !== 0 ? (
                      <MenuEdit
                        name="Minimum Length of Stay (Nights)"
                        info={offers?.offerValidity.minStayNights}
                      />
                    ) : (
                      <></>
                    )}
                    {offers?.offerValidity?.maxStayNights &&
                      offers?.offerValidity?.maxStayNights !== 0 ? (
                      <MenuEdit
                        name="Maximum Length of Stay (Nights)"
                        info={offers?.offerValidity.maxStayNights}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            )}
            {showPricingDetails && (
              <OfferData
                isCollapsed={isExpanded()}
                title="Pricing Details"
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Pricing Details');
                }}
                isError={errorFields.includes('pricing')}
                children={
                  <>
                    <MenuEdit
                      name="Offer Rate Details"
                      info={getOfferPricingText(offers.pricing?.offerRack)}
                    />
                    {offers?.offerType === 'percent-off-offer' && (
                      <MenuEdit
                        name="Offer Type: Percent Discount"
                        info={
                          offers?.pricing?.offerTypeValue ? offers.pricing.offerTypeValue : 'N/A'
                        }
                      />
                    )}
                    <MenuEdit
                      name="Who qualifies for this special offer"
                      info={
                        offers?.pricing?.qualifiers
                          ? offers?.pricing?.qualifiers === 'qualifiedCustomers'
                            ? 'Qualified Customers'
                            : 'Non-Qualified Customers'
                          : 'N/A'
                      }
                    />

                    <MenuEdit
                      name="Minimum Length of Stay (Nights)"
                      info={offers?.offerValidity?.minStayNights}
                    />
                    {showDOWAndMaxLength && (
                      <MenuEdit
                        name="Maximum Length of Stay (Nights)"
                        info={offers?.offerValidity?.maxStayNights}
                      />
                    )}
                  </>
                }
              />
            )}
            {showPriveDetails && (
              <OfferData
                isCollapsed={isExpanded()}
                title="Privé Value Add Details"
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Pricing Details');
                }}
                isError={errorFields.includes('prive-pricing')}
                children={
                  <>
                    <MenuEdit
                      name="Privé Value Add Details"
                      info={offers?.pricing?.priveValueAdd?.length > 0 ? 'Hyatt Privé': 'N/A'}
                    />
                    <MenuEdit
                      name="Luxury Consortia Options"
                      info={getConsortiaOptionsLabelText()}
                    />
                    {offers?.offerType === 'hyatt-prive' && (
                      <MenuEdit
                        name="Who qualifies for this special offer"
                        info={getQualifierLabel}
                      />
                    )}
                    <MenuEdit
                      name="Minimum Length of Stay (Nights)"
                      info={offers?.offerValidity?.minStayNights}
                    />
                    {showDOWAndMaxLength && (
                      <MenuEdit
                        name="Maximum Length of Stay (Nights)"
                        info={offers?.offerValidity?.maxStayNights}
                      />
                    )}
                  </>
                }
              />
            )}
            {showIncusions && (
              <OfferData
                isCollapsed={isExpanded()}
                title="Inclusions & Frequency"
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Inclusions & Frequency');
                }}
                children={
                  <>
                    {offers?.inclusions?.length > 0 ? (
                      offers?.offerCategory === 'toolbox-offer' ? (
                        offers?.inclusions?.map((e: ToolboxInclusionParams) => {
                          return (
                            <MenuEdit
                              name={getToolBoxIncusionsLabel(e.inclusionText)}
                              info={getToolBoxIncusionsLabel(e.inclusionDetails)}
                            />
                          );
                        })
                      ) : (
                        offers?.inclusions?.map((e: InclusionParams) => {
                          return (
                            <MenuEdit
                              className="container-width"
                              name="Inclusion"
                              info={getHotelInclusions(e)}
                            />
                          );
                        })
                      )
                    ) : (
                      <MenuEdit className="container-width" name="Inclusion" info={''} />
                    )}
                  </>
                }
              />
            )}
            {showAdvancedBooking && (
              <OfferData
                isCollapsed={isExpanded()}
                title="Advanced Booking"
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Advanced Booking');
                }}
                isError={errorFields.includes('advancedBooking')}
                children={
                  <>
                    <MenuEdit
                      name="Advanced Booking Eligiblity"
                      info={offers?.advancedBooking?.advancedBookingEligibility ? 'Yes' : 'No'}
                    />
                    <MenuEdit
                      name="How Far In Advance The Offer Must Be Booked Before Stay"
                      info={offers?.advancedBooking?.advancedBookingDays}
                    />
                    {!featureFlag.hideWOHBonusPoints && (
                      <>
                        <MenuEdit
                          name="Does This Offer Include World Of Hyatt Bouns Points"
                          info={offers?.advancedBooking?.hyattBonusPoints ? 'Yes' : 'No'}
                        />
                        <MenuEdit
                          name="Bonus Points Amount"
                          info={offers?.advancedBooking?.bonusPoints}
                        />
                        <MenuEdit
                          name="Bonus Points Max Amount"
                          info={offers?.advancedBooking?.maxBonusPoints}
                        />
                        <MenuEdit
                          name="Bonus Points Frequency"
                          info={offers?.advancedBooking?.bonusPointFrequency}
                        />
                      </>
                    )}
                  </>
                }
              />
            )}
            <OfferData
              isCollapsed={isExpanded()}
              title="Booking Dates"
              isEditable={location.pathname !== '/view-offer'}
              onClickEdit={() => {
                onEditClicked('Booking Dates');
              }}
              isError={errorFields.includes('bookingDates')}
              children={
                <>
                  {offers?.bookingDates?.bookingStartDate && (
                    <MenuEdit
                      name="Booking Start Date"
                      info={
                        offers?.bookingDates?.bookingStartDate
                          ? convertToMMDDYYYY(offers.bookingDates.bookingStartDate)
                          : 'N/A'
                      }
                    />
                  )}
                  {offers?.bookingDates?.bookingEndDate && (
                    <MenuEdit
                      name="Booking End Date"
                      info={
                        offers?.bookingDates?.bookingEndDate
                          ? convertToMMDDYYYY(offers.bookingDates.bookingEndDate)
                          : 'N/A'
                      }
                    />
                  )}
                  <MenuEdit
                    name="Stay Start Date"
                    info={
                      offers?.bookingDates?.stayStartDate
                        ? convertToMMDDYYYY(offers.bookingDates.stayStartDate)
                        : 'N/A'
                    }
                  />
                  <MenuEdit
                    name="Stay End Date"
                    info={
                      offers?.bookingDates?.stayEndDate
                        ? convertToMMDDYYYY(offers.bookingDates.stayEndDate)
                        : 'N/A'
                    }
                  />
                  {offers?.bookingDates?.materialDueDate && (
                    <MenuEdit
                      name="Material Due Date"
                      info={offers?.bookingDates?.materialDueDate}
                    />
                  )}
                </>
              }
            />
            {showBlackoutDates && (
              <OfferData
                isCollapsed={isExpanded()}
                title="Blackout Dates"
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Blackout Dates');
                }}
                children={
                  offers?.blackoutDates?.length > 0 ? (
                    offers?.blackoutDates?.map(
                      (e: {
                        blackoutRange: string;
                        blackoutStartDate: string;
                        blackoutEndDate: string;
                      }) => {
                        return (
                          <>
                            <MenuEdit
                              name="Blackout Range"
                              info={BlackoutRange[e?.blackoutRange]}
                            />
                            <MenuEdit
                              name="Blackout Start Date"
                              info={
                                e?.blackoutStartDate
                                  ? convertToMMDDYYYY(e.blackoutStartDate)
                                  : 'N/A'
                              }
                            />
                            {e.blackoutRange === 'date-range' && (
                              <MenuEdit
                                name="Blackout End Date"
                                info={
                                  e?.blackoutEndDate ? convertToMMDDYYYY(e.blackoutEndDate) : 'N/A'
                                }
                              />
                            )}
                          </>
                        );
                      }
                    )
                  ) : (
                    <>
                      <MenuEdit name="Blackout Range" info={''} />
                      <MenuEdit name="Blackout Start Date" info={''} />
                      <MenuEdit name="Blackout End Date" info={''} />
                    </>
                  )
                }
              />
            )}
            {!hideValidDays && (
              <OfferData
                isCollapsed={isExpanded()}
                title="Valid Days"
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Valid Days');
                }}
                children={
                  <>
                    {showDOWAndMaxLength && (
                      <MenuEdit
                        name="Days of the week the offer is Valid"
                        info={getFormettedDays()}
                      />
                    )}
                  </>
                }
              />
            )}
            {showOfferCode && (
              <OfferData
                isCollapsed={isExpanded()}
                title={isPriveOffer ? "Guarantee/Deposit, Penalty and Cancelation Policies" : "Offer Code & Cancellation Policy"}
                isEditable={location.pathname !== '/view-offer'}
                onClickEdit={() => {
                  onEditClicked('Offer Code & Cancellation Policy');
                }}
                isError={errorFields.includes('offerCode')}
                children={
                  <>
                    <MenuEdit
                      name="Guarantee/Deposit Policy"
                      info={getLabelForDepositPolicyOption(
                        offers?.cancellationAndCodes?.depositPolicy
                      )}
                    />
                    <MenuEdit
                      name="Cancellation Policy"
                      info={getLabelForCancelPolicyOption(
                        offers?.cancellationAndCodes?.cancellationPolicy
                      )}
                    />
                    <MenuEdit
                      name="Penalty Policy"
                      info={getLabelForPenaltyPolicyOption(
                        offers?.cancellationAndCodes?.penaltyPolicy
                      )}
                    />
                    {!isPriveOffer || offers?.offerType !== 'hyatt-luxury-consortia' &&
                      <MenuEdit name="Offer Codes" info={offers?.cancellationAndCodes?.offerCodes} />
                    }
                  </>
                }
              />
            )}
            {showDistributionChennals && (
              <OfferData
                isCollapsed={isExpanded()}
                isEditable={location.pathname !== '/view-offer'}
                title="Distribution Channels"
                onClickEdit={() => {
                  onEditClicked('Distribution Channels');
                }}
                isError={errorFields.includes('distributionChannel')}
                children={
                  <>
                    {(offers?.distributionChennal?.priveWebsite ||
                      offers?.distributionChannel?.gds ||
                      offers?.distributionChannel?.hyattCom ||
                      offers?.distributionChannel?.otaStandalone ||
                      offers?.distributionChannel?.voiceAndHotelDirect) && (
                      <MenuEdit name="Distribution Channel" info={getDistributionChennals()} />
                    )}
                    {offers?.distributionChannel?.wholesaleConnectedSpecificPartner?.length > 0 && (
                      <MenuEdit
                        name="Distribution Channel"
                        info={wholeSaleConnectedPartnerValues()}
                      />
                    )}
                    {offers?.distributionChannel?.wholesaleNotConnectedSpecificPartner?.length >
                      0 && (
                      <MenuEdit
                        name="Distribution Channel"
                        info={wholeSaleNoConnectedPartnerValues()}
                      />
                    )}
                    {offers?.distributionChannel?.otaBundledSpecificPartner?.length > 0 && (
                      <MenuEdit name="Distribution Channel" info={otaBundledValues()} />
                    )}
                    {offers?.distributionChannel?.luxuryConsortiaPartnersSpecificPartner?.length >
                      0 && <MenuEdit name="Distribution Channel" info={lcpValues()} />}
                  </>
                }
              />
            )}
            {showPropertyWebsiteOffersPage && (
              <OfferData
                isCollapsed={isExpanded()}
                isEditable={location.pathname !== '/view-offer'}
                title={isPriveOffer ? "Hyatt Privé Website Offer Details Page" : isPriveAmexLuxOffer ? "Hyatt Privé Website Offers Page" : "Property Website Offers Page"}                
                onClickEdit={() => {
                  onEditClicked('Property Website Offers Page');
                }}
                children={
                  <>
                    {offers?.offerCategory !== 'toolbox-offer' ? (
                      !offers?.websiteOffers?.isOffer && !isPriveOffer ? (
                        <MenuEdit
                          name="Included in the property website offers"
                          info={offers?.websiteOffers?.isOffer ? 'Yes' : 'No'}
                        />
                      ) : (
                        <>
                          <MenuEdit
                            name="Special Offer Title"
                            info={offers?.websiteOffers?.offerTitle}
                          />
                          <MenuEdit
                            name="Special Offer Main Description"
                            info={offers?.websiteOffers?.offerDescription}
                            isRichText={true}
                          />
                          <MenuEdit
                            name="Hyatt Brand Manager (HBM) Image URL"
                            info={offers?.websiteOffers?.brandManagerID}
                          />
                          <MenuEdit
                            name="Offer Materials Due Date"
                            info={
                              offers?.websiteOffers?.materialsDueDate
                                ? convertToMMDDYYYY(offers.websiteOffers.materialsDueDate)
                                : 'N/A'
                            }
                          />
                          {!isPriveOffer &&
                            <>
                              <MenuEdit
                                name="Translation Required"
                                info={offers?.websiteOffers.translationRequired ? 'Yes' : 'No'}
                              />
                              <MenuEdit
                                name="Languages"
                                info={
                                  offers?.websiteOffers?.languages
                                    ? getLanguageNames(offers?.websiteOffers?.languages)
                                    : 'N/A'
                                }
                              />
                            </>
                          }
                        </>
                      )
                    ) : !offers?.websiteOffers?.isOffer ? (
                      <MenuEdit
                          className="container-width"
                          name={isPriveAmexLuxOffer ? "Should this offer be included in the property website offers page?" :
                            "Should this offer be included in the property website offers page? (non-qualified offers only)"}
                          info={offers?.websiteOffers?.isOffer ? 'Yes' : 'No'}
                        />
                      ) : (
                        <>
                          <MenuEdit
                            name={isPriveAmexLuxOffer ? "Should this offer be included in the property website offers page?" :
                              "Should this offer be included in the property website offers page? (non-qualified offers only)"}
                            info={offers?.websiteOffers?.isOffer ? 'Yes' : 'No'}
                          />
                        <MenuEdit
                          name="Hyatt Brand Manager (HBM) Image URL"
                          info={offers?.websiteOffers?.brandManagerID}
                        />
                        {offers.websiteOffers?.changeOfferTitle && !isPriveAmexLuxOffer && (
                          <>
                            <MenuEdit
                              name="Change Offer Title To:"
                              info={
                                offers?.websiteOffers?.changeOfferTitle
                                  ? offers?.websiteOffers?.offerTitle
                                  : 'N/A'
                              }
                            />
                            <MenuEdit
                              name="Offer Materials Due Date"
                              info={
                                offers?.websiteOffers?.materialsDueDate
                                  ? convertToMMDDYYYY(offers.websiteOffers.materialsDueDate)
                                  : 'N/A'
                              }
                            />
                          </>
                        )}
                        {isPriveAmexLuxOffer &&
                          <>
                            <MenuEdit
                              name="Special Offer Title"
                              info={offers?.websiteOffers?.offerTitle}
                            />
                            <MenuEdit
                              name="Special Offer Main Description"
                              info={offers?.websiteOffers?.offerDescription}
                              isRichText={true}
                            />
                          </>
                        }
                        {!isPriveAmexLuxOffer &&
                          <>
                            <MenuEdit
                              name="Translation Required"
                              info={offers?.websiteOffers.translationRequired ? 'Yes' : 'No'}
                            />
                            <MenuEdit
                              name="Languages"
                              info={
                                offers?.websiteOffers?.languages
                                  ? getLanguageNames(offers?.websiteOffers?.languages)
                                  : 'N/A'
                              }
                            />
                          </>
                        }
                      </>
                    )}
                  </>
                }
              />
            )}
            <OfferData
              isCollapsed={isExpanded()}
              isEditable={location.pathname !== '/view-offer'}
              title="Attachments"
              onClickEdit={() => {
                onEditClicked('Attachments');
              }}
              children={<>{attachments}</>}
            />
            {showComments && (
              <OfferData
                isCollapsed={isExpanded()}
                isEditable={false}
                title="Comments"
                children={
                  <>
                    <Timeline
                      events={userComments}
                      addNewComment={addNewComment}
                      isSavingComments={isSavingComments}
                      isCommentDisabled={canCreateOffer}
                    />
                  </>
                }
              />
            )}
            <OfferData
              isCollapsed={isExpanded()}
              isEditable={false}
              title="Terms & Conditions"
              children={
                <>
                  <MenuEdit
                    className="container-width"
                    name="T&C Main Text"
                    info={
                      offers?.termsAndConditions?.mainText
                        ? offers?.termsAndConditions?.mainText
                        : 'N/A'
                    }
                  />
                  <MenuEdit
                    name="T&C Closing Text"
                    info={
                      offers?.termsAndConditions?.closingText
                        ? offers?.termsAndConditions?.closingText
                        : 'N/A'
                    }
                    className="container-width"
                  />
                </>
              }
            />
            <div className="bottom-componenet"></div>
          </div>
        ) : (
          <>
            <Title text="404" type="h1" />
            <Title text={isRequestFailed.reason} type="h3" />
          </>
        )
      ) : location.pathname === '/review-and-submit' ||
        location.pathname === '/view-data-services-task' ? (
        <ShimmerLoading count={13} />
      ) : (
        <>
          <LoadingSpinner />
        </>
      )}
    </div>
  );
};
