import React, { useEffect } from 'react';
import './errorPage.css';
import { Header } from '../Header';

const NotFoundPage: React.FC = () => {
  useEffect(() => {
    window.utag_data.page_name = 'offertool-pagenotfound';
  }, []);
  return (
    <div className="not-found-container">
      <Header appName="Special Offer Tool" pathname={'/'} />
      <h1 className="not-found-heading">404</h1>
      <p className="not-found-text">Oops! Page not found.</p>
      <p className="not-found-description">The page you are looking for might have been removed, 
      had its name changed, or is temporarily unavailable.</p>
      <a className='not-found-clickHere' href='/'>Click here to go to dashboard</a>
    </div>
  );
};

export default NotFoundPage;
