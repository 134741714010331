import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { Button } from '../../components/atoms/Button';
import { Description } from '../../components/atoms/Description';
import './successSubmit.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleChange, setOfferStateToDefault } from '../../store/offerToolSlice';
import { BUILD_CONTENT_URL, REACT_URL } from '../../config/constants';
import { extractOfferId } from '../../utils/fileUtils';
import { getBuildContentStatus } from '../../service/api';

export const SuccessSubmit: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.utag_data.page_name = 'offertool-submitsuccess';
  }, []);

  return (
    <section className="container success-submit-container">
      <PageHeader title="Success!" description="You have successfully submitted your offer!" />
      <Description text="Details and status of this offer are available on your Dashboard." />
      <div className="button-container">
        <Button
          id='button-success'
          dataAttribute='button-success-submit'
          label="Go to Dashboard"
          className="success-submit-button"
          onClick={() => {
            dispatch(handleChange({ name: 'page', value: 0 }));
            dispatch(setOfferStateToDefault());
            navigate('/');
            sessionStorage.setItem('redirectPath', `${REACT_URL}/`);
          }}
        />
      </div>
    </section>
  );
};
