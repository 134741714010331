import React from 'react';
import './Description.css';

interface DescriptionProps {
  text: string;
  type?: 'body1' | 'body2' | 'body3';
  className?: string;
  width?: string;
}

export const Description = ({ text, type = 'body1', className, width }: DescriptionProps) => {
  return (
    <p
      className={`${type} ${className}`}
      style={{ width }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
