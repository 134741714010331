export const WarningIcon = ({width=25, height=25, color="#B70404"}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M229 492 c-43 -22 -59 -38 -81 -81 -36 -68 -36 -114 0 -182 22 -43
38 -59 81 -81 68 -36 114 -36 182 0 43 22 59 38 81 81 36 68 36 114 0 182 -22
43 -38 59 -81 81 -31 16 -69 28 -91 28 -22 0 -60 -12 -91 -28z m119 -92 c4
-52 -11 -115 -28 -115 -10 0 -18 17 -23 53 -12 72 -3 104 26 100 17 -2 23 -11
25 -38z m-5 -162 c7 -21 -4 -38 -23 -38 -10 0 -20 6 -23 13 -7 20 4 37 23 37
10 0 20 -6 23 -12z"
        />
      </g>
    </svg>
  );
};
