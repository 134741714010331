import React, { useCallback, useEffect, useState } from 'react';
import './root.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { DashboardStates, setDashboardState } from '../../../store/offerToolSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHelpText } from '../../../hooks/useHelpText';

const documentationItems: NavResourceParams[] = [
  {
    title: 'Global Rate Plan SOPs',
    id: '1',
    to: 'https://www.hyattconnect.com/site/hc/#/binder-detail/content/dam/Hyattconnect/department-pages/revenue-management/americas/department-documents/global-rate-plan-sops-6b7bf76744225710VgnVCM1000006e2ea00aRCRD',
  },
  {
    title: 'Training Documentation',
    id: '2',
    to: 'https://scribehow.com/page/Special_Offers_Tool_Training_Documentation__dTMH0uT3QladcTxPh_6vpQ',
  },
  {
    title: 'FAQs',
    id: '3',
    to: require('../../../pdf/faqs.pdf'),
  },
];

export const Nav = () => {
  const history = useNavigate();
  const [, clearHelpText] = useHelpText();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const {
    totalOffers,
    totalSubmittedOffers,
    totalRejectedOffers,
    totalApprovedOffers,
    totalDraftOffers,
    isCountLoading,
  } = useSelector((state: any) => state.offerTool);
  const { pathname: path, ...location } = useLocation();
  const [CATEGORY, setCATEGORY] = useState<NavParams[]>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<DashboardStates>('all');
  const [dots, setDots] = useState('');

  const NavItems: NavParams[] = [
    {
      title: 'Dashboard',
      id: '1',
      to: '/',
      isOpened: true,
      subCategories: [
        {
          to: 'draft',
          count: totalDraftOffers && totalDraftOffers,
          title: 'Drafts',
        },
        {
          to: 'submitted',
          count: totalSubmittedOffers && totalSubmittedOffers,
          title: 'Submitted',
        },
        {
          to: 'approved',
          count: totalApprovedOffers && totalApprovedOffers,
          title: 'Approved',
        },
        {
          to: 'rejected',
          count: totalRejectedOffers && totalRejectedOffers,
          title: 'Rejected',
        },
        {
          to: 'all',
          count: totalOffers && totalOffers,
          title: 'All',
        },
      ],
    },
  ];

  const hasAccesstoReporting = () => {
    const session = sessionStorage.getItem('offer-tool-authenticated-user');
    if (session) {
      const sessionObj = JSON.parse(session);
      const isDSMarketing =
      sessionObj?.group?.includes('SPECIAL OFFERS DS MARKETING') ||
      sessionObj?.group?.includes('special-offers-ds-marketing');
      if (isDSMarketing) {
        NavItems.push({
          title: 'Reporting',
          id: '2',
          to: '/reporting',
          isOpened: false,
          subCategories: [],
        });
      }
    }
  };

  const navItems = useCallback(() => {
    hasAccesstoReporting();
    setCATEGORY(NavItems);
  }, [
    totalOffers,
    totalApprovedOffers,
    totalDraftOffers,
    totalRejectedOffers,
    totalSubmittedOffers,
  ]);

  useEffect(() => {
    navItems();
  }, [navItems]);

  useEffect(() => {
    const selectedCategory =
      selectedSubCategory.charAt(0).toUpperCase() + selectedSubCategory.slice(1);
    dispatch(setDashboardState(selectedCategory));
  }, [selectedSubCategory, dispatch]);

  useEffect(() => {
    clearHelpText('');
    CATEGORY?.forEach((category: any, i: number) => {
      category.subCategories.forEach((subCategory: any, i: number) => {
        if (path === subCategory.to) {
          history('/');
        }
      });
    });
  }, [history, path]);

  const checkMainCategory = (categoryId: string): boolean => {
    let isFound = false;
    if (CATEGORY) {
      CATEGORY[parseInt(categoryId) - 1]?.subCategories?.map((subcategory) => {
        if (subcategory.to === path) {
          return (isFound = true);
        } else {
          return null;
        }
      });
      return isFound;
    }
    return isFound;
  };

  const updateView = useCallback(
    (categoryId: string) => {
      let categories = CATEGORY?.map((category) => {
        if (category.id === categoryId) {
          category.isOpened = !category.isOpened;
        } else {
          category.isOpened = false;
          dispatch(setDashboardState('All'));
          setSelectedSubCategory('all');
        }
        return category;
      });
      setCATEGORY(categories);
    },
    [CATEGORY]
  );

  useEffect(() => {
    const from = location.state?.from;
    if (from && from === 'rejected') {
      dispatch(setDashboardState('Rejected'));
      setSelectedSubCategory('rejected');
      window.history.replaceState({}, '', undefined);
    }
  }, []);

  useEffect(() => {
    if (isCountLoading) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 5 ? '' : prevDots + '.'));
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isCountLoading]);

  return (
    <div className="nav-main">
      <div className="home">
        <h3 className="heading">Home</h3>
        {CATEGORY &&
          CATEGORY.map((e, i) => {
            return (
              <div className="home-items" key={i}>
                <Link
                  key={e.to}
                  to={e.to}
                  style={{
                    color: path === e.to ? '#ffffff' : '#ffffff',
                  }}
                >
                  <h3
                    className="home-nav-items"
                    style={{
                      fontFamily: 'Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                      color: checkMainCategory(e.id) ? 'black' : '#4f4f4f',
                      fontSize: '1rem',
                      cursor: 'pointer',
                      border: path === e.to ? '1px solid #0072ce' : 'none',
                      padding: '5px 0px 5px 3px',
                    }}
                    onClick={() => {
                      updateView(e.id);
                    }}
                  >
                    {e.title}
                  </h3>
                </Link>
                {e.isOpened &&
                  e.subCategories && e.subCategories.length > 0 &&
                  e.subCategories?.map((sub, i) => {
                    return (
                      <div
                        className={`home-sub-items ${sub.count === 0 && 'disableDiv'}`}
                        key={i}
                        onClick={() => {
                          setSelectedSubCategory(sub.to);
                        }}
                      >
                        <h3
                          className={`home-nav-items ${
                            selectedSubCategory === sub.to && 'underline'
                          }`}
                        >
                          {sub.title}
                        </h3>
                        {isCountLoading ? (
                          dots
                        ) : (
                          <label className={`navbar-label ${sub.to}`}>{sub.count}</label>
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
      <hr />
      <div className="resource">
        {documentationItems.map((e, i) => {
          return (
            <div className="resource-items" key={i}>
              <a
                key={e.to}
                href={e.to}
                style={{
                  color: path === e.to ? '#ffffff' : '#ffffff',
                }}
                target="_blank"
              >
                <h3
                  className="home-nav-items"
                  style={{
                    fontFamily: 'Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
                    color: checkMainCategory(e.id) ? 'black' : '#4f4f4f',
                    fontSize: '1rem',
                    cursor: 'pointer',
                    border: path === e.to ? '1px solid #0072ce' : 'none',
                    padding: '5px 0px 0px 3px',
                  }}
                >
                  {e.title}
                </h3>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export interface NavParams {
  id: string;
  title: string;
  to: string;
  isOpened: boolean;
  subCategories?: {
    to: DashboardStates;
    title: string;
    count: number;
  }[];
}

interface NavResourceParams {
  id: string;
  title: string;
  to: string;
}
