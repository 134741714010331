import React, { useState } from 'react';
import './alert.css';
import { Button } from '../Button';
import { CrossIcon } from '../../Icons/CrossIcon';
import { SuccessIcon } from '../../Icons/SuccessIcon';

export interface AlertProps {
  title: string;
  message: string;
  type: 'success' | 'warning' | 'error';
  css?: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, title, type, onClose, css }) => {
  return (
    <div className={`alert alert-${type} ${css ? css : ''}`}>
      <div className="alert-heading">{type === 'success' && <SuccessIcon />}</div>
      <div className="alert-body">
        <h3>{title}</h3>
        <p>{message}</p>
      </div>
      <CrossIcon onClick={onClose} />
    </div>
  );
};

export default Alert;
