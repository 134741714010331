import { useRef, useEffect } from 'react';
import Quill from 'quill';
import { debounce } from 'lodash';
import './QuillEditor.scss';

interface QuillEditorProps {
    onContentChange: (content: string) => void;
    initialContent: string;
}

const QuillEditor = ({ onContentChange, initialContent }: QuillEditorProps) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const isMounted = useRef(false);
    const debouncedOnContentChange = debounce(onContentChange, 300);

    useEffect(() => {
        if (!isMounted.current) {
            if (editorRef.current) {
                const editor = new Quill(editorRef.current, {
                    theme: 'snow',
                    modules: {
                        toolbar: [
                            [{ 'list': 'bullet' }],
                            ['link']]
                    }
                });
                isMounted.current = true;

                if (initialContent && editor.root) {
                    editor.root.innerHTML = initialContent;
                }
                editor.on('text-change', function () {
                    debouncedOnContentChange(editor.root.innerHTML);
                });
            }
        }
        return () => {
            debouncedOnContentChange.cancel();
        };
    }, [onContentChange, initialContent, debouncedOnContentChange]);

    return <div id="editor-container" ref={editorRef} data-testid="editor-container"></div>;
}

export default QuillEditor;
