import React from 'react';
import './errorPage.css';
import { Header } from '../Header';

const SiteMaintenance: React.FC = () => {
    return (
        <div className="not-found-container">
            <Header appName="Special Offer Tool" pathname={'/'} />
            <h1 className="not-found-heading">We are Under Maintenance</h1>
            <p className="not-found-text"> We are improving our website. We will be back soon with new cool features! </p>
        </div>
    );
};

export default SiteMaintenance;
