import Modal from '../../../components/atoms/Modal';
import { Button } from '../../../components/atoms/Button';
import '../dashboard.css';
interface Props {
  handleClose: () => void;
  handleSubmit: () => void;
  isDeletingInProgress: boolean;
}
function DeleteModal({ handleClose, handleSubmit, isDeletingInProgress }: Props) {
  return (
    <Modal
      cautionIconVisible
      titleSize="25px"
      centerTitle
      title="Delete Offer"
      onClose={handleClose}
    >
      <div className="Modal_custom_content">
        <p>
          Are you sure you want to delete this offer?All data associated will be lost.This action
          can not be undone
        </p>
        <div className="button-container">
          <div className="custom-button">
            <Button id='delete-modal-cancel' dataAttribute='delete-modal-cancel-button' onClick={handleClose} primary={false} label={'Cancel'}></Button>
          </div>
          <div className="custom-button">
            <Button
              id='delete-modal-confirm'
              dataAttribute='delete-modal-confirm-button'
              onClick={handleSubmit}
              primary
              label={'Yes,Delete Offer'}
              isloading={isDeletingInProgress}
              isDisable={isDeletingInProgress}
            ></Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
