import { useMemo } from 'react';

export const isDSAssociate = (): boolean => {
    const session = sessionStorage.getItem('offer-tool-authenticated-user');
    if (session) {
        const sessionObj = JSON.parse(session);
        const valuesToCheck = [
            'special-offers-ds-associate',
        ];
        return valuesToCheck.some((value) => sessionObj?.group.includes(value));
    }
    return false;
};


export const isDSMarketing = () => {
    const session = sessionStorage.getItem('offer-tool-authenticated-user');
    if (session) {
        const sessionObj = JSON.parse(session);
        const valuesToCheck = [
            'SPECIAL OFFERS DS MARKETING',
            'special-offers-ds-marketing',
        ];
        return valuesToCheck.some((value) => sessionObj?.group.includes(value));
    }
    return false;
};

export const useUserGroupRoles = () => useMemo(() => {
    const userGroupRoles = {
        DSAssociate: false,
        DSMarketing: false,
        contentOperations: false,
        hyattPrive: false
    }

    const session = sessionStorage.getItem('offer-tool-authenticated-user');

    if (!session) {
        return userGroupRoles;
    }

    const sessionObj = JSON.parse(session);

    const groups = new Set(sessionObj.group);

    const has = (val: string) => groups.has(val);

    if (has('special-offers-ds-associate')) {
        userGroupRoles.DSAssociate = true;
    }

    if (
        has('SPECIAL OFFERS DS MARKETING') ||
        has('special-offers-ds-marketing')
    ) {
        userGroupRoles.DSMarketing = true;
    }

    if (
        has('special-offers-content-operations') ||
        has('pe-content-approver') ||
        has('pe-content-author')
    ) {
        userGroupRoles.contentOperations = true;
    }

    if (has('special-offers-prive-content-operations')) {
        userGroupRoles.hyattPrive = true;
    }

    return userGroupRoles;
}, []);
