import React from 'react';
import { useHelpText } from '../../../hooks/useHelpText';
import { CrossIcon } from '../../Icons/CrossIcon';
import { Title } from '../../atoms/Title';
import { useSelector } from 'react-redux';
import { getHelpOptions } from './options';
import './help.css';

export const HelpPanel = () => {
  const [, clearHelpText] = useHelpText();
  const {
    selectedHelpText,
    dashboardState,
    selectedTab,
    offers: { offerType },
  } = useSelector((state: any) => state.offerTool);

  const getTitle = () => {
    let path = '';
    const pathname = selectedHelpText;
    if (selectedHelpText === '/') {
      switch (dashboardState) {
        case 'Draft':
          path = 'Draft';
          break;
        case 'Submitted':
          path = 'Submitted';
          break;
        case 'Approved':
          path = 'Approved';
          break;
        case 'Rejected':
          path = 'Rejected';
          break;
        default:
          if (selectedTab === 'tasks') {
            path = 'Tasks Tab';
            break;
          }
          path = 'Dashboard';
          break;
      }
    } else if (pathname.includes('/special-offer')) {
      path = pathname.split('/')[2].charAt(0).toUpperCase() + pathname.split('/')[2].slice(1);
      switch (path) {
        case 'Promotions':
          switch (offerType) {
            case 'package-inclusions-offer':
              path = 'Package with Inclusions';
              break;
            case 'percent-off-offer':
              path = 'Percent Off';
              break;
            case 'advanced-purchase-offer':
              path = 'Advanced Purchase';
              break;
            case 'hotelresortcredit-offer':
              path = 'Hotel Resort Credit Inclusions Options';
              break;
            case 'meetingpromotion-offer':
              path = 'Meeting Promotion';
              break;
            case 'weddingpromotion-offer':
              path = 'Wedding Promotion';
              break;
            default:
              path = 'Offer Types';
              break;
          }
          break;
        case 'Basics':
          path = 'Basics Page';
          break;
        case 'Inclusions':
          path = 'Inclusions & Frequency';
          break;
        case 'Advanced-booking':
          path = 'Advanced Booking';
          break;
        case 'Booking-start-and-end-dates':
          path = 'Booking Dates';
          break;
        case 'Blackout-dates':
          path = 'Blackout Dates';
          break;
        case 'Valid-days-of-week':
          path = 'Valid Days of Week';
          break;
        case 'Pricing-details':
          path = 'Pricing Details';
          break;
        case 'Offer-code':
          path = 'Offer Code & Cancel Policy';
          break;
        case 'Distribution-channels':
          path = 'Distribution Channels';
          break;
        case 'Property-website':
          path = 'Property website offers page';
          break;
        case 'Terms-and-conditions':
          path = 'Terms & Conditions';
          break;
        case 'Review-and-submit':
          path = 'Review & Submit';
          break;
        case 'Success-submit':
          path = 'Success';
          break;
        default:
          break;
      }
    } else if (pathname.includes('/toolbox-offer')) {
      path = pathname.split('/')[2].charAt(0).toUpperCase() + pathname.split('/')[2].slice(1);
      switch (path) {
        case 'Promotions':
          path = 'HTB Offer Types';
          break;
        case 'Basics':
          path = 'HTB Basics';
          break;
        case 'Inclusions':
          path = 'Inclusions & Frequency';
          break;
        case 'Htb-specific':
          path = 'HTB Specific';
          break;
        case 'Booking-lead-time':
          path = 'Lead Time';
          break;
        case 'Offer-code':
          path = 'HTB Offer Codes & Cancellation Policy';
          break;
        case 'Hotel-website-offers':
          path = 'HTB Hotel Website Offer';
          break;
        case 'Booking-start-and-end-dates':
          path = 'HTB Booking Dates';
          break;
        case 'Review-and-submit':
          path = 'HTB Review and submit';
          break;
        default:
          break;
      }
    } else {
      path = pathname.split('/')[1].charAt(0).toUpperCase() + pathname.split('/')[1].slice(1);
      switch (path) {
        case 'Welcome':
          path = 'Welcome Offer Category';
          break;
        case 'Reporting':
          path = 'Reporting';
          break;
        case 'Review-and-submit':
          path = 'Review & Submit';
          break;
        case 'View-offer':
          path = 'View Offer';
          break;
        default:
          break;
      }
    }
    return path;
  };

  const getHelpTextContent = () => {
    const helpTextObj = getHelpOptions(getTitle());
    const helpTextParagraph = helpTextObj?.paragraph;
    if (!helpTextParagraph) {
      return <div className="help-text-description">No Results Found!</div>;
    }
    return (
      <div
        className="help-text-description"
        dangerouslySetInnerHTML={{ __html: helpTextParagraph || '' }}
      ></div>
    );
  };

  return (
    <section className="right-help-panel">
      <div className="content">
        <CrossIcon
          onClick={() => {
            clearHelpText('');
          }}
        />
        <Title type="h3" text={getTitle()} />
        {getHelpTextContent()}
      </div>
    </section>
  );
};
