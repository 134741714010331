import React from 'react';
import './PopUp.scss';

interface Props {
  links: {
    label: string;
    icon: any;
    url?: string;
    onClick?: () => void;
  }[];
  customCss?: string;
}

const PopUpMenu: React.FC<Props> = ({ links, customCss }) => {
  return (
    <ul className={customCss ? customCss : 'root'}>
      {links.map((link, index) => (
        <li>
          <a key={index} {...link}>
            <span className="link-icon">{link.icon}</span>
            <span className="link-label">{link.label}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default PopUpMenu;
