import moment from 'moment-timezone';

export const setTimeTo9AMCST = (dateString: string): string => {
  const datePart =
    dateString.split(' ')[1] + ' ' + dateString.split(' ')[2] + ' ' + dateString.split(' ')[3];
  const newDate = moment
    .tz(datePart, 'MMM DD YYYY', 'America/Chicago')
    .set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  return newDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ').replace('+00:00', 'Z');
};

export const convertToMMDDYYYY = (dateString: string): string => {
  return moment(dateString).format('MM-DD-YYYY');
};

export const formatDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const amPm = hours >= 12 ? 'p.m.' : 'a.m.';
  hours = hours % 12 || 12;
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedDate = `${month} ${day}, ${year} | ${hours}.${minutes} ${amPm}`;
  return formattedDate;
};


// Function to calculate the difference in days between two dates
export const getDaysBetweenDates = (date1: Date, date2: Date): number => {
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const differenceInTime = utc2 - utc1;
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.round(differenceInDays);
}

//this will work when you pass the date in this formet 01-Jun-2024
export const isPastDate = (dateString: string): boolean => {
  const dateParts = dateString.split('-');
  const day = parseInt(dateParts[0], 10);
  const month = new Date(dateParts[1] + ' 1').getMonth();
  const year = parseInt(dateParts[2], 10);
  const date = new Date(year, month, day);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return date < now;
};