import React, { useEffect, useState } from 'react';
import { Select } from '../../components/atoms/Select';
import { PageHeader } from '../../components/molecules/PageHeader';

import './toolsInclusions.css';
import nls from '../../nls/HTBToolBox.json';
import { useDispatch, useSelector } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { Input } from '../../components/atoms/Input';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { Checkbox } from '../../components/atoms/Checkbox';

export const ToolsInclusions: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const location = useLocation();
  interface InclusionItem {
    inclusionDetails: string;
    inclusionText: string;
  }
  const {
    offers: { offerType, inclusions },
    showError,
  } = useSelector((state: any) => state.offerTool);
  const [showParking, setShowParking] = useState(false);
  const [showCollectUSD, setShowCollectUSD] = useState(false);
  const [showBreakfastType, setShowBreakfastType] = useState(false);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-toolboxoffer-toolsinclusions';
  }, []);

  useEffect(() => {
    const breakfastItem = inclusions.find((item: InclusionItem) => item.inclusionText === nls.BREAKFAST_VALUE);
    setShowBreakfastType(breakfastItem);
    const parkingItem = inclusions.find((item: InclusionItem) => item.inclusionText === nls.PARKING_VALUE);
    setShowParking(parkingItem);
    const creditItem = inclusions.find((item: InclusionItem) => item.inclusionText === nls.HOTEL_CREDIT_VALUE);
    setShowCollectUSD(creditItem);
  }, [inclusions]);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  let subHeading: string = '';
  let promotionOptionsSubHeader: string = nls.BREAKFAST_SUB_HEADER;
  switch (offerType) {
    case 'htb-percent-off':
      subHeading = nls.HTB_INCLUSIONS_INSTRUCTIONS;
      break;
    case 'htb-rack-plus-attribute':
      subHeading = nls.CHOOSE_AMENITY;
  }

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 4 }));
  }, []);

  const handleHotelCreditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value === '' ||
      (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 1000)
    ) {
      const updatedIncusions = inclusions.map((item: InclusionItem) => {
        if (item.inclusionText === nls.HOTEL_CREDIT_VALUE) {
          return { ...item, inclusionDetails: e.target.value }
        }
        return item;
      });

      dispatch(
        handleChange({
          name: 'toolboxInclusions',
          value: updatedIncusions
        }));
    }
  };
  const getValue = (type: string): string | undefined => {
    const item = inclusions.find((item: InclusionItem) => item.inclusionText === type);
    return item ? item.inclusionDetails : undefined;
  }
  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '+' || e.key === 'e' || e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <section className="container inclusions-htb">
      <PageHeader title="Inclusions" description={subHeading} />
      {showError && (
        <div className="error-banner">
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <div className="inclusions-main">
        <Checkbox
          id='inclusions-breakfast-type'
          dataAttribute='inclusions-breakfast-type-checkbox'
          checked={showBreakfastType}
          onChange={(e) => {
            let updatedIncusions = [];
            if (showBreakfastType) {
              updatedIncusions = inclusions.filter((item: InclusionItem) => item.inclusionText !== nls.BREAKFAST_VALUE);
            }
            else {
              updatedIncusions = [...inclusions, {
                inclusionText: nls.BREAKFAST_VALUE,
                inclusionDetails: nls.PER_NIGHT_VALUE
              }];
            }
            dispatch(
              handleChange({
                name: 'toolboxInclusions',
                value: updatedIncusions
              }));
            setShowBreakfastType(!showBreakfastType);
          }}
          label={`Breakfast`}
        />
        <p className="inclusions-type-sub">{promotionOptionsSubHeader}</p>

        {showBreakfastType &&
          <Select
            id='inclusions-breakfast-options'
            dataAttribute='inclusions-breakfast-options-select'
            label={nls.BREAKFAST_TYPE}
            className="breakfast-type"
            labelClassName="breakfast-type-label"
            options={[
              { label: nls.PER_NIGHT, value: nls.PER_NIGHT_VALUE },
              { label: nls.PER_STAY, value: nls.PER_STAY_VALUE },
            ]}
            value={getValue(nls.BREAKFAST_VALUE)}
            onChange={(e) => {
              const updatedIncusions = inclusions.map((item: InclusionItem) => {
                if (item.inclusionText === nls.BREAKFAST_VALUE) {
                  return { ...item, inclusionDetails: e.target.value }
                }
                return item;
              });
              dispatch(
                handleChange({
                  name: 'toolboxInclusions',
                  value: updatedIncusions
                }));
            }}
          />
        }

        <Checkbox
          id='inclusions-show-parking'
          dataAttribute='inclusions-show-parking-checkbox'
          checked={showParking}
          onChange={(e) => {
            let updatedIncusions = [];
            if (showParking) {
              updatedIncusions = inclusions.filter((item: InclusionItem) => item.inclusionText !== nls.PARKING_VALUE);
            }
            else {
              updatedIncusions = [...inclusions, {
                inclusionText: nls.PARKING_VALUE,
                inclusionDetails: nls.SELF_PARKING_VALUE
              }];
            }
            setShowParking(!showParking);
            dispatch(
              handleChange({
                name: 'toolboxInclusions',
                value: updatedIncusions
              }));
          }}
          label={`Parking`}
        />

        {showParking && (
          <Select
            id='inclusions-show-parking-select-option'
            dataAttribute='inclusions-show-parking-select-option-values'
            label={nls.PARKING_TYPE}
            className="parking-type"
            labelClassName="tools-inclusions-type-label"
            options={[
              { label: nls.SELF_PARKING, value: nls.SELF_PARKING_VALUE },
              { label: nls.VALET, value: nls.VALET.toLowerCase() },
            ]}
            value={getValue(nls.PARKING_VALUE)}
            onChange={(e) => {
              const updatedIncusions = inclusions.map((item: InclusionItem) => {
                if (item.inclusionText === nls.PARKING_VALUE) {
                  return { ...item, inclusionDetails: e.target.value }
                }
                return item;
              });
              dispatch(
                handleChange({
                  name: 'toolboxInclusions',
                  value: updatedIncusions
                }));
            }}
          />
        )}
        {offerType === 'htb-rack-plus-attribute' && <Checkbox
          id='enable-inclusions-show-usd-payment-option'
          dataAttribute='enable-inclusions-show-usd-payment-option-checkbox'
          checked={showCollectUSD}
          onChange={(e) => {
            let updatedIncusions = [];
            if (showCollectUSD) {
              updatedIncusions = inclusions.filter((item: InclusionItem) => item.inclusionText !== nls.HOTEL_CREDIT_VALUE);
            }
            else {
              updatedIncusions = [...inclusions, {
                inclusionText: nls.HOTEL_CREDIT_VALUE,
                inclusionDetails: ''
              }];
            }
            setShowCollectUSD(!showCollectUSD);
            dispatch(
              handleChange({
                name: 'toolboxInclusions',
                value: updatedIncusions
              }));
          }}
          label={`Hotel Credit`}
        />}
        {showCollectUSD && (
          <Input
            id='inclusions-show-usd-payment-option'
            dataAttribute='inclusions-show-usd-payment-option-input'
            label={nls.AMOUNT_FOR_HOTEL_CREDIT}
            placeHolder=""
            labelClassName="tools-inclusions-type-label"
            value={getValue(nls.HOTEL_CREDIT_VALUE)}
            onChange={handleHotelCreditChange}
            onKeyDown={handleKey}
            type="number"
          />
        )}
        {showError && showCollectUSD && getValue(nls.HOTEL_CREDIT_VALUE) === '' && (
          <div className="credit-error">
            <WarningIcon width={15} height={15} />
            <p className="error-label">Enter an amount</p>
          </div>
        )}
      </div>
    </section>
  );
};
