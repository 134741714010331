export const EllipsisIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <path
        fill="#444"
        d="M4 8a2 2 0 1 1-3.999.001A2 2 0 0 1 4 8zM10 8a2 2 0 1 1-3.999.001A2 2 0 0 1 10 8zM16 8a2 2 0 1 1-3.999.001A2 2 0 0 1 16 8z"
      />
    </svg>
  );
};
