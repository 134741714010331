import React, { useState, useEffect } from 'react';

interface Option {
  label: string;
  value: string;
}

interface TypeAheadSelectProps {
  label: string;
  labelClassName: string;
  options: Option[];
  onSelect: (selectedValue: string, selectedLabel: string) => void;
  isDisabled?: boolean;
  spiritCode?: string;
}

function TypeAheadSelect({
  options = [],
  onSelect,
  label,
  labelClassName,
  isDisabled,
  spiritCode,
}: TypeAheadSelectProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

  useEffect(()=>{
    if(spiritCode){
      setSearchTerm(spiritCode);
    }
  },[spiritCode]);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => option.label?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm, options]);

  const handleSelect = () => {
    const selectedOption = filteredOptions.find((option) => option.label === searchTerm);
    if (selectedOption) {
      onSelect(selectedOption.value, selectedOption.label);
    } else {
      onSelect('', '');
    }
  };

  return (
    <label className={`b-form-input`}>
      <span
        className={`b-form-input__label ${labelClassName}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <input
        id="type-to-search"
        data-role="type-to-search-input"
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onBlur={handleSelect}
        placeholder="Type to search..."
        list="options"
        className={'b-form-input__control input-text basic-form'}
        disabled={isDisabled}
      />
      <datalist id="options">
        {filteredOptions.map((option) => (
          <option key={option.value} value={option.label} />
        ))}
      </datalist>
    </label>
  );
}

export default TypeAheadSelect;
