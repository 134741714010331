import { Button } from '../../../components/atoms/Button';
import './FileUploader.css';
import { BASE_URL } from '../../../config/constants';

interface UploadedFileProps {
    filename: string | undefined;
    fileUrl: string | undefined;
    clear: any,
    type: string
}

const UploadedFile = ({ filename, fileUrl, clear, type }: UploadedFileProps) => {
    return (
        <div className='uploadedfile-container'>
            {
                filename && fileUrl &&
                <>
                    <a href={BASE_URL + fileUrl} download={filename} data-testid={type + '-file-name'}>
                        {filename}
                    </a>
                    <div className='uploadedfile-clear-button'>
                        {
                            <Button id='attachments-upload-files' dataAttribute='attachments-upload-files-button' onClick={clear} label='X' type='link' data-testid={type + '-clear-button'} />
                        }
                    </div>
                </>
            }

        </div>
    );
};

export default UploadedFile;
