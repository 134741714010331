import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import nls from '../../nls/offerDetails.json';
import nlsHTB from '../../nls/HTBToolBox.json';
import nlsCommon from '../../nls/common.json';
import './ViewDataServicesTask.scss';
import Banner from '../../components/atoms/Banner';
import { Button } from '../../components/atoms/Button';
import { Input } from '../../components/atoms/Input';
import { Select } from '../../components/atoms/Select';
import { Textarea } from '../../components/atoms/Teaxtarea';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { handleChange } from '../../store/offerToolSlice';
import { useSelector } from 'react-redux';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { PlusIcon } from '../../components/Icons/PlusIcon';
import Trashcan from '../../components/Icons/Trashcan';
import { allOtherRatePlanOptions, directSellOptions } from './ViewDataServicesTask';
import { useHelpText } from '../../hooks/useHelpText';
import { FilePicker } from '../../components/molecules/FilePicker';
import { extractPath } from '../../utils/fileUtils';
import { uploadMultipleOfferAttachments } from '../../service/api';
import MenuEdit from '../../components/molecules/OfferDetails/assets/MenuEdit';
import { BASE_URL } from '../../config/constants';

import './DataService.scss';

const warningIcon = <PlusIcon bgColor="#0072ce" />;

const acceptedFileTypes = [
  '.pdf',
  'application/pdf',
  '.jpg',
  '.jpeg',
  'image/jpeg',
  '.png',
  'image/png'
];

const AttachmentsList = ({ fileURLs }: {fileURLs: string[]}) => {
  const list = fileURLs.map((url) => {
    const fileName = url.split('/').at(-1) ?? 'untitled';
    const linkURL = BASE_URL + url;

    return (
      <MenuEdit
        className='attachment-item'
        info={fileName}
        infoType='link'
        key={linkURL}
        name='DS Attachment'
        linkUrl={linkURL}
      />
    );
  });

  return (
    <div className='attachments-container'>
      {list}
    </div>
  );
};

interface Params {
  addDirectSales: () => void;
  addOtherRatePlans: () => void;
  handleOtherRatePlansDelete: (index: number) => void;
  handleDirectSallsDelete: (index: number) => void;
  handleDelete: (index: number) => void;
  handleOfferCode: () => void;
  disableInputs?: boolean;
}

export default function DataService({
  addDirectSales,
  addOtherRatePlans,
  handleOtherRatePlansDelete,
  handleDirectSallsDelete,
  handleDelete,
  handleOfferCode,
  disableInputs,
}: Params) {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [, clearHelpText] = useHelpText();
  const {
    offers: {
      cancellationAndCodes: { offerCodes },
      dataservices: { crNumber, directSell, otherRatePlans, dsAssociateTime, dsMarketingTime, attachments },
    },
    dataServiceNotes,
    offerCodeState,
    dsDirectSalesState,
    dsOtherRatePlaneState,
    showDataServiceError,
  } = useSelector((state: any) => state.offerTool);

  const location = useLocation();
  const offerPath = extractPath(location.search);
  const dsAttachments = attachments?.dataServices;

  const [hasAccessToViewDataServiceNotes, setHasAccessToViewDataServiceNotes] = useState(false);
  const [fileUploadPending, setFileUploadPending] = useState(false);

  useEffect(() => {
    clearHelpText('');
    hasAccessToViewDSNotes();
  }, []);

  const hasAccessToViewDSNotes = () => {
    const session = sessionStorage.getItem('offer-tool-authenticated-user');
    if (session) {
      const sessionObj = JSON.parse(session);
      const valuesToCheck = [
        'SPECIAL OFFERS DS MARKETING',
        'special-offers-ds-associate',
        'special-offers-ds-marketing',
      ];
      const exists = valuesToCheck.some((value) => sessionObj?.group.includes(value));
      if (exists) {
        setHasAccessToViewDataServiceNotes(true);
      }
    }
  };

  const loadOfferCodes = () => {
    return (
      offerCodes &&
      offerCodes?.map((offer: any, index: number) => {
        return (
          <div className="ds-offercodes" key={index}>
            <Input
              id='additional-offer-code'
              dataAttribute='additional-offer-code-input'
              className="additional-offer-code-added"
              placeHolder=""
              type="text"
              key={index}
              value={offer}
              readOnly
            />
            <button className="addtional-offer-delete-button" onClick={() => handleDelete(index)}>
              <Trashcan />
            </button>
          </div>
        );
      })
    );
  };

  const handleFileUpload = async (files: FileList): Promise<void> => {
    try {
      setFileUploadPending(true);

      const res = await uploadMultipleOfferAttachments({
        files,
        offerPath,
        attachmentType: 'dataServices'
      });
      
      const uploadedAttachmentURLs = res.data.offerPath;

      dispatch(handleChange({
        name: 'addDsAttachments',
        value: uploadedAttachmentURLs
      }));
    } catch (error) {
      console.log(error) 
    } finally {
      setFileUploadPending(false);
    }
  };

  return (
    <div>
      {!disableInputs && <div className="body1 review-details">{nls.OFFER_DETAILS_ACCEPTED}</div>}
      {showDataServiceError && (
        <div className="error-banner">
          <Banner
            title={nlsCommon.REQUIRED_FIELDS}
            message={nlsCommon.GENERAL_ERROR_MESSAGE_BELOW}
          />
        </div>
      )}
      <fieldset className="basics-info-elements">
        <div className="p-base-width-small p-base-plus">
          <>
            <Input
              id='offer-codes'
              dataAttribute='offer-codes-input'
              label={nlsHTB.OFFER_CODE}
              labelClassName="p-base-small"
              name="offerCodes"
              placeHolder=""
              value={offerCodeState}
              disabled={disableInputs}
              className="basic-form"
              onChange={(e) => {
                dispatch(handleChange({ name: 'offerCodeState', value: e.target.value }));
              }}
              maxLength={9}
            />
            {showDataServiceError && offerCodeState !== '' && offerCodeState.length < 4 && (
              <div className="error-componenet">
                <WarningIcon width={15} height={15} />
                <p className="error-message">{nls.OFFER_CODE_LENGTH_ERROR}</p>
              </div>
            )}
            <Button
              id='link'
              dataAttribute='link-button'
              label={nlsHTB.ADD_OFFER_CODE}
              type="link"
              icon={warningIcon}
              onClick={handleOfferCode}
              className="plus-button"
            />
          </>
          {loadOfferCodes()}
        </div>
        <Input
          id='cr-number'
          dataAttribute='cr-number-input'
          label={nls.CR_NUMBER}
          labelClassName="p-base-small"
          name="crNumber"
          placeHolder=""
          value={crNumber}
          className="basic-form"
          onChange={(e) => {
            !disableInputs && dispatch(handleChange({ name: 'crNumber', value: e.target.value }));
          }}
        />
        {showDataServiceError && offerCodeState === '' && !crNumber && offerCodes?.length === 0 && (
          <div className="error-componenet">
            <WarningIcon width={15} height={15} />
            <p className="error-message">{nls.OFFER_CR_OFFER_CODE_ERROR}</p>
          </div>
        )}
      </fieldset>
      <hr />
      <div className="p-base-plus">
        <fieldset className="basics-info-elements inline-form">
          <legend>Direct Sell</legend>
          <Select
            id='data-service-market'
            dataAttribute='data-service-market-select'
            label="Market"
            labelClassName="p-base-small"
            name="market"
            options={directSellOptions}
            value={dsDirectSalesState?.market}
            disabled={disableInputs}
            className="basic-form"
            onChange={(e) => {
              dispatch(
                handleChange({
                  name: 'dsDirectSalesState',
                  value: { ...dsDirectSalesState, [e.target.name]: e.target.value },
                })
              );
            }}
          />
          <div className="input-small">
            <Input
              id='data-service-category'
              dataAttribute='data-service-category-input'
              label="Category"
              labelClassName="p-base-small"
              name="category"
              placeHolder=""
              value={dsDirectSalesState?.category}
              className="basic-form"
              onChange={(e) => {
                !disableInputs &&
                  dispatch(
                    handleChange({
                      name: 'dsDirectSalesState',
                      value: { ...dsDirectSalesState, [e.target.name]: e.target.value },
                    })
                  );
              }}
            />
            <Input
              id='data-service-rate-plan-code'
              dataAttribute='data-service-rate-plan-code-input'
              label="Rate Plan Code"
              labelClassName="p-base-small"
              name="ratePlanCode"
              placeHolder=""
              value={dsDirectSalesState.ratePlanCode}
              className="basic-form"
              onChange={(e) => {
                !disableInputs &&
                  dispatch(
                    handleChange({
                      name: 'dsDirectSalesState',
                      value: { ...dsDirectSalesState, [e.target.name]: e.target.value },
                    })
                  );
              }}
            />
          </div>
        </fieldset>
        {directSell &&
          directSell?.map(({ market, category, ratePlanCode }: any, index: number) => {
            return (
              <div key={index} className="inclusions-form-container inclusions-map">
                <Input id='data-service-market' dataAttribute='data-service-market-input' value={market} readOnly className="inclusion-main-select" />
                <Input id='data-service-category' dataAttribute='data-service-category-input' value={category} readOnly className="inclusion-main-select-frequency" />
                <Input id='data-service-rate-plan-code' dataAttribute='data-service-rate-plan-code-input' defaultValue={ratePlanCode} readOnly className="inclusion-details-input" />
                <button onClick={() => handleDirectSallsDelete(index)}>
                  <Trashcan />
                </button>
              </div>
            );
          })}
        <Button
          id='add-market'
          dataAttribute='add-market-button'
          label="Add Market"
          type="link"
          icon={warningIcon}
          className="plus-button"
          onClick={addDirectSales}
        />
      </div>
      <div className="p-base-plus">
        <fieldset className="basics-info-elements inline-form p-base-plus">
          <legend>All other rate plans built to support offer</legend>
          <Select
            id='ds-market-droppdown'
            dataAttribute='ds-market-droppdown'
            label="Market"
            labelClassName="p-base-small"
            name="market"
            options={allOtherRatePlanOptions}
            value={dsOtherRatePlaneState?.market}
            disabled={disableInputs}
            className="basic-form"
            onChange={(e) => {
              dispatch(
                handleChange({
                  name: 'dsOtherRatePlaneState',
                  value: { ...dsOtherRatePlaneState, [e.target.name]: e.target.value },
                })
              );
            }}
          />
          <div className="input-small">
            <Input
              id='data-service-other-rate-plan-category'
              dataAttribute='data-service-other-rate-plan-category'
              label="Category"
              labelClassName="p-base-small"
              name="category"
              placeHolder=""
              value={dsOtherRatePlaneState?.category}
              className="basic-form"
              onChange={(e) => {
                !disableInputs &&
                  dispatch(
                    handleChange({
                      name: 'dsOtherRatePlaneState',
                      value: { ...dsOtherRatePlaneState, [e.target.name]: e.target.value },
                    })
                  );
              }}
            />
            <Input
              id='data-service-other-rate-plan-input'
              dataAttribute='data-service-other-rate-plan-input'
              label="Rate Plan Code"
              labelClassName="p-base-small"
              name="ratePlanCode"
              placeHolder=""
              value={dsOtherRatePlaneState?.ratePlanCode}
              className="basic-form"
              onChange={(e) => {
                !disableInputs &&
                  dispatch(
                    handleChange({
                      name: 'dsOtherRatePlaneState',
                      value: { ...dsOtherRatePlaneState, [e.target.name]: e.target.value },
                    })
                  );
              }}
            />
          </div>
        </fieldset>
        {otherRatePlans &&
          otherRatePlans?.map(({ market, category, ratePlanCode }: any, index: number) => {
            return (
              <div key={index} className="inclusions-form-container inclusions-map">
                <Input id='value-of-rate-plan-market' dataAttribute='value-of-rate-plan-market' value={market} readOnly className="inclusion-main-select" />
                <Input id='value-of-rate-plan-category' dataAttribute='value-of-rate-plan-category' value={category} readOnly className="inclusion-main-select-frequency" />
                <Input id='value-of-rate-plans' dataAttribute='value-of-rate-plans' defaultValue={ratePlanCode} readOnly className="inclusion-details-input" />
                <button onClick={() => handleOtherRatePlansDelete(index)}>
                  <Trashcan />
                </button>
              </div>
            );
          })}
        <Button
          id='add-to-market-button'
          dataAttribute='add-to-market-button'
          label="Add Market"
          type="link"
          icon={warningIcon}
          className="plus-button"
          onClick={addOtherRatePlans}
        />
      </div>
      <hr />
      <fieldset className="basics-info-elements inline-form">
        <legend>Distribution Service</legend>
        {hasAccessToViewDataServiceNotes && (
          <Textarea
            id='data-service-distribution-service-notes'
            dataAttribute='data-service-distribution-service-notes-input'
            label="Notes"
            labelClassName="p-base-small"
            placeholder=""
            className="basic-form textarea-medium"
            name="dsNotes"
            value={dataServiceNotes}
            onChange={(e: any) =>
              !disableInputs &&
              dispatch(handleChange({ name: e.target.name, value: e.target.value }))
            }
          />
        )}        
        <div className="input-small inline-form">
          <Input
            id='data-service-ds-associate-time-to-build-offer'
            dataAttribute='data-service-ds-associate-time-to-build-offer-input'
            label="DS Associate: Time spent to build offer"
            labelClassName="p-base-small"
            name="dsAssociateTime"
            placeHolder=""
            value={dsAssociateTime}
            disabled={disableInputs}
            className="basic-form"
            onChange={(e) => {
              dispatch(handleChange({ name: e.target.name, value: e.target.value }));
            }}
          />
          <Input
            id='data-service-ds-marketing-time-to-build-offer'
            dataAttribute='data-service-ds-marketing-time-to-build-offer'
            label="DS Marketing: Time spent to build offer"
            labelClassName="p-base-small"
            name="dsMarketingTime"
            placeHolder=""
            value={dsMarketingTime}
            disabled={disableInputs}
            className="basic-form"
            onChange={(e) => {
              dispatch(handleChange({ name: e.target.name, value: e.target.value }));
            }}
          />
        </div>
      </fieldset>
      <hr />
      <fieldset className="basics-info-elements">
        <legend>Attachments</legend>
        {dsAttachments && (
          <AttachmentsList fileURLs={dsAttachments} />
        )}

        <FilePicker 
          acceptedFileTypes={acceptedFileTypes}
          disable={fileUploadPending}
          onAddFile={handleFileUpload}
          onError={(err) => console.log(err)}
        />
      </fieldset>
    </div>
  );
}
