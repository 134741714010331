import './Badge.css';

interface BadgeProps {
  text: string;
  className: 'success' | 'danger';
}

const Badge = ({ text, className }: BadgeProps) => {
  return <span className={`badge ${className}`}>{text}</span>;
};

export default Badge;
