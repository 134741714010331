import React, { ReactNode } from 'react';
import Collapse from '../../atoms/Collapse';

interface Props {
  title: string;
  children: ReactNode;
  isCollapsed: boolean;
  isEditable?: boolean;
  onClickEdit?: () => void;
  badgeText?: string;
  badgeClassName?: 'success' | 'danger';
  isError?: boolean;
}

export const OfferData = ({
  title,
  children,
  isEditable,
  isCollapsed,
  isError = false,
  badgeClassName = 'danger',
  badgeText = 'Required',
  onClickEdit,
}: Props) => {
  return (
    <Collapse
      children={children}
      title={title}
      isEditable={isEditable}
      isCollapsed={isCollapsed}
      isError={isError}
      onClickEdit={onClickEdit}
      badgeClassName={badgeClassName}
      badgeText={badgeText}
    />
  );
};
