import '../dashboard.css';
import { DatePickerComponent } from '../../../components/molecules/datePicker';
import nlsCommon from '../../../nls/common.json';
import { ExtendOfferParam } from '../../../store/offerToolSlice';
import { setTimeTo9AMCST } from '../../../utils/dateAndTimeUtils';

interface Props {
  extendOffer: ExtendOfferParam | undefined;
  setExtendOffer: (param: ExtendOfferParam) => void;
  currentEndDate: string;
}

function ExtendOnlyDates({ setExtendOffer, extendOffer, currentEndDate }: Props) {
  const date = new Date();
  const currentOfferEndDate = new Date(currentEndDate);
  currentOfferEndDate.setDate(currentOfferEndDate.getDate() + 1);

  return (
    <div>
      <p className="extendHeading">
        {nlsCommon.NEW_BOOKING_END_DATE} must be after original end date.
      </p>
      <DatePickerComponent
        label={'* ' + nlsCommon.NEW_BOOKING_END_DATE}
        labelClassName="p-base-medium"
        placeholder={nlsCommon.SELECT_DATE}
        className="extend-date-chooser"
        format="MMM-dd-yyyy"
        name="newBookingExtendDate"
        onChange={(date) => {
          setExtendOffer({ ...extendOffer, bookingEndDate: setTimeTo9AMCST(date.toDateString()) });
        }}
        value={extendOffer?.bookingEndDate ? new Date(extendOffer.bookingEndDate) : undefined}
        minDate={currentOfferEndDate}
      />
      <DatePickerComponent
        label={nlsCommon.NEW_STAY_END_DATE}
        labelClassName="p-base-medium"
        placeholder={nlsCommon.SELECT_DATE}
        className="extend-date-chooser"
        format="MMM-dd-yyyy"
        name="newStayEndDate"
        onChange={(date) => {
          setExtendOffer({
            ...extendOffer,
            stayEndDate: setTimeTo9AMCST(date.toDateString()),
          });
        }}
        value={extendOffer?.stayEndDate ? new Date(extendOffer.stayEndDate) : undefined}
        minDate={date}
      />
    </div>
  );
}

export default ExtendOnlyDates;
