import { FC } from 'react';

interface RadioProps {
  id: string;
  name?: string;
  label: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value?: string;
  labelClassName?: string;
  className?: string;
  dataAttribute: string;
}

export const Radio: FC<RadioProps> = ({
  id,
  name = 'input name',
  label,
  disabled = false,
  onChange = () => {},
  checked,
  value,
  labelClassName,
  className,
  dataAttribute,
}) => {
  return (
    <label className={`b-form-radio ${disabled ? 'b-is-disabled' : ''}`}>
      <input
        id={id}
        type="radio"
        name={name}
        disabled={disabled}
        onChange={onChange}
        value={value}
        checked={checked}
        className={className}
        data-role={dataAttribute}
      />
      <span className="b-form-radio__control" />
      <span className={`b-form-radio__label ${labelClassName}`}>{label}</span>
    </label>
  );
};
